import tagsConfigType from "../types/tags-config-type";
import { config } from "../../config/config";
import { METRIC_INFO } from "../../config/constants";
import endpoints from "../../config/endpoints";

export const getTagsConfig = () => ({
  type: tagsConfigType.GET_TAGS_CONFIG,
  http: {
    cognito: {
      effect: {
        url: config.BASE_URL + endpoints.fileTagConfigEndpoint(),
        method: "GET",
      },
      commit: {
        type: tagsConfigType.GET_TAGS_CONFIG_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
        },
      },
      rollback: {
        type: tagsConfigType.GET_TAGS_CONFIG_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
        },
      },
    },
  },
});

export const resetTagsConfig = (): any => ({
  type: tagsConfigType.RESET_TAGS_CONFIG,
});
