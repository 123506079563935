import createCategoryType from "../types/create-category-type";
import { config } from "../../config/config";
import { METRIC_INFO } from "../../config/constants";
import endpoints from "../../config/endpoints";
import { createCategoryRequest } from "../../model/http-json";

export const createCategory = (values: createCategoryRequest): any => ({
  type: createCategoryType.CREATE_CATEGORY,
  http: {
    cognito: {
      effect: {
        url: config.COMPLIANCE_BASE_URL + endpoints.categoryEndpoint(),
        method: "POST",
        json: values,
      },
      commit: {
        type: createCategoryType.CREATE_CATEGORY_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.CATEGORY,
        },
      },
      rollback: {
        type: createCategoryType.CREATE_CATEGORY_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.CATEGORY,
        },
      },
    },
  },
});

export const resetCategory = (): any => ({
  type: createCategoryType.RESET_CATEGORY,
});
