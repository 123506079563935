import addDocumentsType from "../types/add-documents-type";
import { config } from "../../config/config";
import { METRIC_INFO } from "../../config/constants";
import endpoints from "../../config/endpoints";
import { addDocumentsRequest } from "../../model/http-json";

export const addDocuments = (values: addDocumentsRequest): any => ({
  type: addDocumentsType.ADD_DOCUMENTS,
  http: {
    cognito: {
      effect: {
        url: config.BASE_URL + endpoints.addDocsEndpoint(),
        method: "POST",
        json: values,
      },
      commit: {
        type: addDocumentsType.ADD_DOCUMENTS_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.ADD_DOCUMENTS,
        },
      },
      rollback: {
        type: addDocumentsType.ADD_DOCUMENTS_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.ADD_DOCUMENTS,
        },
      },
    },
  },
});

export const resetAddDocuments = (): any => ({
  type: addDocumentsType.RESET_ADD_DOCUMENTS,
});
