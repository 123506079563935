import { deleteFileType } from "../types/delete-file-type";

export const setDeleteFiles = (selectedFiles: Array<any>): any => ({
  type: deleteFileType.SET_DELETE_FILES,
  selectedFiles,
});

export const resetDeleteFiles = (): any => ({
  type: deleteFileType.RESET_DELETE_FILES,
});
