import { expiringFileType } from "./../types/expiring-file-type";
import constants, { HttpCodes, NOT_AUTHORIZED_NOTIFICATION } from "../../config/constants";
import { repositoryOverviewData } from "../../model/repository";
import { formatSearchResults } from "../../utils/general-utils";
import { notification } from "../../model/doc-overview";

interface ExpiringFilesObj {
  loadingStatus: number;
  message: string;
  expiringFiles: Array<repositoryOverviewData>;
  notifications: notification[];
}

const initialState: ExpiringFilesObj = {
  loadingStatus: constants.LOADING_DEFAULT,
  message: "",
  expiringFiles: [],
  notifications: [],
};

const expiringFileReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case expiringFileType.GET_EXPIRING_FILE:
      return {
        ...state,
        loadingStatus: constants.LOADING_LOAD,
        message: "",
        expiringFiles: [],
      };
    case expiringFileType.GET_EXPIRING_FILE_COMMIT:
      return {
        ...state,
        loadingStatus: constants.LOADING_SUCCESS,
        message: action.payload.message,
        expiringFiles: formatSearchResults(action.payload.data.hits.hits),
      };
    case expiringFileType.GET_EXPIRING_FILE_ROLLBACK:
      const notifications: notification[] = [
        ...(state.notifications
          ? state.notifications
          : []),
      ];
      if(action.statusCode === HttpCodes.FORBIDDEN)
        notifications.push(NOT_AUTHORIZED_NOTIFICATION)

      return {
        ...state,
        loadingStatus: constants.LOADING_FAIL,
        message: action.payload.message,
        expiringFiles: [],
        notifications,
      };
    default:
      return state;
  }
};

export default expiringFileReducer;
