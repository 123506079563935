import React, { useState } from "react";
import Multiselect from "@amzn/awsui-components-react/polaris/multiselect";
import { Header, PieChart, SpaceBetween } from "@amzn/awsui-components-react";
import {
  EmptyState,
  NoMatchState,
} from "../../components/polaris/pie-chart/common-components";
import { PIE_CHART_I18N_CONSTANTS } from "../../components/polaris/pie-chart/labels";
import constants, {
  pieChartSegmentDescriptionValue,
  STATUS_DISPLAY_NAME,
} from "../../config/constants";

type DocumentPercentageProps = {
  children: Array<any>;
  loadingStatus: number;
  activeTabId: any;
};

function DocumentPercentage({
  children,
  loadingStatus,
  activeTabId,
}: DocumentPercentageProps) {
  const [selectedCountries, setSelectedCountries] = useState<
    Array<{
      value: string | undefined;
      label: string | undefined;
    }>
  >([]);

  const dropdownItems = children.map((value) => {
    return {
      label: value.doc_name,
      options: value.children.map((child_value) => {
        return {
          label: child_value.doc_name,
          value:
            child_value.doc_name +
            " " +
            child_value.upload_status_number?.pending.toString() +
            " " +
            child_value.upload_status_number?.completed.toString() +
            " " +
            child_value.upload_status_number?.in_progress.toString()
        };
      }),
    };
  });

  const getPieChartData = () => {
    let activeTabIdNumber = Number(activeTabId);
    let inProgressCount: number = 0;
    let upcomingCount: number = 0;
    let completedCount: number = 0;
    let totalCount: number = 0;
    if (children && children.length > activeTabIdNumber) {
      let child = children[activeTabIdNumber];
      if (child.children) {
        inProgressCount += child.children.filter(
          (grandChild) => grandChild.status === constants.STATUS_PROGRESS
        ).length;
        upcomingCount += child.children.filter(
          (grandChild) => grandChild.status === constants.STATUS_UPCOMING
        ).length;
        completedCount += child.children.filter(
          (grandChild) => grandChild.status === constants.STATUS_COMPLETED
        ).length;
        totalCount += child.children.length;
      }
    }

    let completedDocCount = 0, pendingDocCount = 0, inProgressDocCount = 0;
    for (let selected_value of selectedCountries) {
      /* for each country selected_value is in the form of "1 2 3"
      * 1 is count of pending doc count
      * 2 is count of completed doc count
      * 3 is count of in_progress doc count
      */
      let success_pending_value = selected_value.value?.split(" ").splice(-3);
      pendingDocCount += success_pending_value ? Number(success_pending_value[0]) : 0;
      completedDocCount += success_pending_value ? Number(success_pending_value[1]) : 0;
      inProgressDocCount += success_pending_value ? Number(success_pending_value[2]) : 0;
    }
    const totalDocs  = completedDocCount + inProgressDocCount + pendingDocCount;

    const pieChartData =
      selectedCountries.length !== 0
        ? [
            {
              title: STATUS_DISPLAY_NAME.STATUS_COMPLETED,
              value:
                totalDocs !== 0
                  ? (completedDocCount / totalDocs ) * 100
                  : 100,
              count: completedDocCount,
              color: "#037f0c",
            },
            {
              title: STATUS_DISPLAY_NAME.STATUS_PROGRESS,
              value:
                totalDocs !== 0
                  ? (inProgressDocCount / totalDocs ) * 100
                  : 100,
              count: inProgressDocCount,
              color: "#ea7158",
            },
            {
              title: STATUS_DISPLAY_NAME.STATUS_PENDING,
              value:
                totalDocs !== 0
                  ? (pendingDocCount / totalDocs) * 100
                  : 0,
              count: pendingDocCount,
              color: "#0972d3",
            },
          ]
        : [
            {
              title: STATUS_DISPLAY_NAME.STATUS_PROGRESS,
              value: (inProgressCount / totalCount) * 100,
              count: inProgressCount,
              color: "#ea7158",
            },
            {
              title: STATUS_DISPLAY_NAME.STATUS_UPCOMING,
              value: (upcomingCount / totalCount) * 100,
              count: upcomingCount,
              color: "#0972d3",
            },
            {
              title: STATUS_DISPLAY_NAME.STATUS_COMPLETED,
              value: (completedCount / totalCount) * 100,
              count: completedCount,
              color: "#037f0c",
            },
          ];
    return pieChartData;
  };

  const segmentDescriptionValue =
    selectedCountries.length !== 0
      ? pieChartSegmentDescriptionValue.SELECTED_COUNTRIES
      : pieChartSegmentDescriptionValue.DEFAULT;

  const pieChartData = getPieChartData();
  const pieChartLoadingStatus =
    loadingStatus === constants.LOADING_LOAD ? "loading" : "finished";

  return (
    <SpaceBetween size="l">
      <Header variant="h2">Document Percentage</Header>

      <Multiselect
        selectedOptions={selectedCountries}
        onChange={({ detail }) => {
          setSelectedCountries(
            detail.selectedOptions.map((selected_option) => {
              return {
                value: selected_option.value,
                label: selected_option.label,
              };
            })
          );
        }}
        options={dropdownItems}
        placeholder="Choose Countries/Industries"
      />

      <PieChart
        data={pieChartData}
        detailPopoverContent={(datum, sum) => [
          { key: "Count", value: datum.count },
          {
            key: "Percentage",
            value: `${((datum.value / sum) * 100).toFixed(0)}%`,
          },
        ]}
        segmentDescription={(datum, sum) =>
          `${datum.count} ${segmentDescriptionValue}, ${(
            (datum.value / sum) *
            100
          ).toFixed(0)}%`
        }
        i18nStrings={PIE_CHART_I18N_CONSTANTS}
        errorText="Error loading data."
        hideFilter
        loadingText="Loading chart"
        statusType={pieChartLoadingStatus}
        recoveryText="Retry"
        size="small"
        empty={<EmptyState />}
        noMatch={<NoMatchState />}
      />
    </SpaceBetween>
  );
}

export default DocumentPercentage;
