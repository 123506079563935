// As requested by customers the document name should contain Project and Model No as prefix
export const formatDocName = (
  docName: string,
  project: string = "",
  modelNumber: string = ""
) => {
  if (project === "" || modelNumber === "") {
    return docName;
  }
  return project + "_" + modelNumber + "_" + docName;
};
