import {
  Alert,
  Container,
  Header,
  SpaceBetween,
  Flashbar,
  ProgressBar,
  ColumnLayout,
  Link,
  StatusIndicator,
} from "@amzn/awsui-components-react";
import { ValueWithLabel } from "../../../../components/common/labels";

//import constants from "../../../config/constants";
import DeleteStatusTable from "./table/table";

const DeleteStatus = ({
  files,
  inProgressFiles,
  successfulFiles,
  failedFiles,
}) => {
  // state
  const progressPercentage =
    (100 * (failedFiles + successfulFiles)) /
    (inProgressFiles + failedFiles + successfulFiles);

  return (
    <SpaceBetween size="m">
      {progressPercentage !== 100 && (
        <Flashbar
          items={[
            {
              content: (
                <ProgressBar
                  status="in-progress"
                  value={progressPercentage}
                  variant="flash"
                  label="Objects Deletion Progress"
                />
              ),
            },
          ]}
        />
      )}
      <Alert
        type="error"
        visible={progressPercentage === 100 && failedFiles !== 0}
        dismissAriaLabel="Close alert"
        header="Delete Failed"
      >
        Error deleting some files. View details below.
      </Alert>
      <Alert
        type="success"
        visible={progressPercentage === 100 && failedFiles === 0}
        dismissAriaLabel="Close alert"
        header="Delete Successful"
      >
        View details below.
      </Alert>
      <Alert visible={true} dismissAriaLabel="Close alert">
        The information below will no longer be available after you navigate
        away from this page.
      </Alert>
      <Container header={<Header variant="h2">Summary</Header>}>
        <ColumnLayout columns={3} variant="text-grid">
          <ValueWithLabel label="Source">
            {`Repository`}
            <Link href={`/repository`} external={true}></Link>
          </ValueWithLabel>
          <ValueWithLabel label="Succeeded">
            <StatusIndicator type="success">
              {successfulFiles} Objects (
              {(100 * successfulFiles) /
                (inProgressFiles + failedFiles + successfulFiles)}
              %)
            </StatusIndicator>
          </ValueWithLabel>
          <ValueWithLabel label="Failed">
            <StatusIndicator type="error">
              {failedFiles} Objects (
              {(100 * failedFiles) /
                (inProgressFiles + failedFiles + successfulFiles)}
              %)
            </StatusIndicator>
          </ValueWithLabel>
        </ColumnLayout>
      </Container>
      <DeleteStatusTable data={files} loadingStatus={true} />
    </SpaceBetween>
  );
};

export default DeleteStatus;
