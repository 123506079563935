import Landing from "../landing/landing";
import CreateCategory from "../create/category/create-category";
import CreateProject from "../create/project/create-project";
import { AboutUs } from "@amzn/lens-react-ui";
import CategoryOverview from "../category-overview/category-overview";
import ProjectOverview from "../project-overview/project-overview";
import FolderOverview from "../folder-overview/folder-overview";
import FileOverview from "../file-overview/file-overview";
import CreateFolder from "../create/folder/create-folder";
import Upload from "../upload/upload";
import DeleteDoc from "../delete/doc/delete-doc";
import ExpiringDocuments from "../expiring-documents/expiring-documents";
import Trash from "../trash/trash";
import SearchResults from "../search-results/search-results";
import AddCountry from "../project-overview/add-country";
import AddIndustry from "../project-overview/add-industry";
import ShareDoc from "../share/share-doc";
import RepositoryOverview from "../repository-overview/repository-overview";
import DocumentOverview from "../document-overview/document-overview";
import AddDocuments from "../create/document/add-documents";
import ExpiringFiles from "../expiring-files/expiring-files";
import FileTrash from "../file-trash/file-trash";
import DeleteFile from "../delete/file/delete-file";


export const routes: {component: any, path: string}[] = [
  {component: Landing, path: "/"},
  {component: CreateCategory, path: "/categories/create"},
  {component: CreateProject, path: "/categories/:categoryId/projects/create"},
  {component: CategoryOverview, path: "/categories/:categoryId/overview"},
  {component: ProjectOverview, path: "/categories/:categoryId/projects/:projectId/overview"},
  {component: FolderOverview, path: "/folders/:folderId/overview"},
  {component: FileOverview, path: "/files/:fileId/overview"},
  {component: CreateFolder, path: "/folders/:folderId/create"},
  {component: DocumentOverview, path: "/documents/:documentId/overview"},
  {component: AddDocuments, path: "/documents/:documentId/add"},
  {component: DeleteDoc, path: "/folders/:folderId/delete"},
  {component: DeleteFile, path: "/files/:fileId/delete"},
  {component: Upload, path: "/repository/upload"},
  {component: ShareDoc, path: "/folders/:folderId/share"},
  {component: ExpiringDocuments, path: "/expiring-documents"},
  {component: SearchResults, path: "/search"},
  {component: AddCountry, path: "/categories/:categoryId/projects/:projectId/update/addCountries"},
  {component: AddIndustry, path: "/categories/:categoryId/projects/:projectId/update/addIndustries"},
  {component: Trash, path: "/trash"},
  {component: AboutUs, path: "/about-us"},
  {component: RepositoryOverview, path: "/repository"},
  {component: ExpiringFiles, path: "/expiring-files"},
  {component: FileTrash, path: "/file-trash"},
]