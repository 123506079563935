import { RouteComponentProps } from "react-router-dom";
import React, { Component } from "react";
import {
  Box,
  Button,
  Grid,
  Modal,
  SpaceBetween,
  Form,
  FormField,
  DatePicker,
  Textarea,
  SelectProps,
  TextContent,
} from "@amzn/awsui-components-react";

import { connect } from "react-redux";

import {
  getFileOverview,
  resetFileOverview,
} from "../../../redux/actions/file-overview-action";

import constants, { FILE_UPLOAD } from "../../../config/constants";
import Dropzone, { DropEvent, FileRejection } from "react-dropzone";

import { fileObject } from "../../../model/file";
import { fileUploadS3Call, uploadFileToCabinet } from "./utils";
//css
import "./new-version.css";
import { StatusLabel } from "../../../components/common/labels";

interface StateProps {
  fileOverviewReducer: any;
}

interface MatchParams {
  fileId: number;
}

// declare prop check
type Props = {
  dispatch: any;
  fileId: string;
  openUploadNewVersionModal: boolean;
  setOpenUploadNewVersionModal: any;
} & StateProps &
  RouteComponentProps<MatchParams>;

type State = {
  files: Array<fileObject>;
  showRejectModal: any;
  rejectModalData: any;
  fileType: SelectProps.Option;
  fileTypeError: string;
  fileError: string;
  description: string;
  expiryDate: any;
  descriptionError: string;
  buttonLoadingStatus: number;
  isFormSubmitted: boolean;
  inProgressFiles: number;
  successfulFiles: number;
  failedFiles: number;
};

class NewVersion extends Component<Props, State> {
  state: State = Object.freeze({
    files: [],
    showRejectModal: false,
    rejectModalData: "",
    fileType: {},
    fileTypeError: "",
    fileError: "",
    description: "",
    expiryDate: "",
    descriptionError: "",
    buttonLoadingStatus: constants.LOADING_DEFAULT,
    isFormSubmitted: false,
    inProgressFiles: 0,
    successfulFiles: 0,
    failedFiles: 0,
  });

  _onCancelButtonClick = (event) => {
    this.props.setOpenUploadNewVersionModal(false);
  };

  _onUploadButtonClick = (event) => {
    this.setState({ buttonLoadingStatus: constants.LOADING_LOAD });

    let filesToUpload: Array<fileObject> = this.state.files;

    this.setState({
      isFormSubmitted: true,
      inProgressFiles: filesToUpload.length,
    });

    filesToUpload.forEach((file: fileObject, index) => {
      fileUploadS3Call(file).then((response1) => {
        if (response1.status === constants.STATUS_SUCCESS) {
          let s3_link = response1.s3_link;
          uploadFileToCabinet(
            file?.file?.name ?? "",
            decodeURI(s3_link.split("?")[0]).replace(
              new RegExp(
                "^[a-zA-Z0-9_:.-]+//[a-zA-Z0-9_:.-]+/[a-zA-Z0-9_:.-]+/"
              ),
              ""
            ),
            file.file.size,
            this.state.expiryDate,
            this.state.description
          ).then((response2) => {
            let { files, inProgressFiles, successfulFiles, failedFiles } =
              this.state;
            files[index].status = response2.status;
            files[index].msg = response2.message;
            inProgressFiles--;
            if (response2.status === constants.STATUS_SUCCESS) {
              successfulFiles++;
            } else {
              failedFiles++;
            }
            this.setState({
              files,
              inProgressFiles,
              successfulFiles,
              failedFiles,
              buttonLoadingStatus: constants.LOADING_SUCCESS,
            });
            this.props.setOpenUploadNewVersionModal(false);
            this.props.dispatch(resetFileOverview(this.props.fileId));
            this.props.dispatch(getFileOverview(this.props.fileId));
          });
        } else {
          let { files, inProgressFiles, failedFiles } = this.state;
          files[index].status = response1.status;
          inProgressFiles--;
          failedFiles++;
          this.setState({
            files,
            inProgressFiles,
            failedFiles,
            buttonLoadingStatus: constants.LOADING_SUCCESS,
          });
          this.props.setOpenUploadNewVersionModal(false);
          this.props.dispatch(resetFileOverview(this.props.fileId));
          this.props.dispatch(getFileOverview(this.props.fileId));
        }
      });
    });
  };

  _onChangeDescription = (event) => {
    if (event.detail.value.length === 0)
      this.setState({ descriptionError: "* Required" });
    else {
      this.setState({ descriptionError: "" });
    }
    this.setState({ description: event.detail.value });
  };

  _onChangeExpiryDate = (event) => {
    this.setState({ expiryDate: event.detail.value });
  };

  onDrop = (acceptedFiles, rejectedFiles, event) => {
    const file_name =
      this.props.fileOverviewReducer[this.props.fileId].fileOverviewData
        .file_name;

    let fileArray: Array<fileObject> = acceptedFiles.map((oldFile) => {
      // Copying the file to a new file to rename it
      let blob = oldFile.slice(0, oldFile.size, oldFile.type);
      let newFile = new File([blob], file_name, { type: oldFile.type });
      return {
        file: newFile,
        type: "",
      };
    });

    fileArray = [...this.state.files, ...fileArray];
    this.setState({ files: fileArray, fileError: "" });

    let description =
      this.props.fileOverviewReducer[this.props.fileId].fileOverviewData
        .description;

    let expiryDate =
      this.props.fileOverviewReducer[this.props.fileId].fileOverviewData
        .expiry_date;

    this.setState({ description: description, expiryDate: expiryDate });
  };

  onFileReject = (fileRejections: FileRejection[], event: DropEvent) => {
    let rejectModalData = fileRejections.map((file) => (
      <Grid
        key={file.file.name}
        gridDefinition={[{ colspan: 6 }, { colspan: 2 }, { colspan: 4 }]}
      >
        <p key={file.file.name}>{file.file.name}</p>

        <StatusLabel
          key={constants.STATUS_ERROR}
          status={constants.STATUS_ERROR}
        ></StatusLabel>
        <p key={file.errors[0].code}>{file.errors[0].code}</p>
      </Grid>
    ));
    this.setState({
      description: "",
      expiryDate: "",
      showRejectModal: true,
      rejectModalData,
    });
  };

  deleteFile = (name: string) => {
    let files = [...this.state.files];
    let indexToDelete = -1;
    files.forEach((file: fileObject, index) => {
      if (file.file.name === name) {
        indexToDelete = index;
      }
    });
    if (indexToDelete > -1) {
      files.splice(indexToDelete, 1);
      this.setState({ files, description: "", expiryDate: "" });
    }
  };

  render() {
    const file_name =
      this.props.fileOverviewReducer[this.props.fileId].fileOverviewData
        .file_name;

    return (
      <Modal
        size="large"
        onDismiss={() => this.props.setOpenUploadNewVersionModal(false)}
        visible={true}
        closeAriaLabel="Close modal"
        header="Upload New File Version"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                variant="link"
                onClick={this._onCancelButtonClick}
                disabled={
                  this.state.buttonLoadingStatus === constants.LOADING_LOAD
                }
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={this._onUploadButtonClick}
                loading={
                  this.state.buttonLoadingStatus === constants.LOADING_LOAD
                }
                disabled={
                  this.state.files.length === 0 ||
                  this.state.description.length === 0
                }
              >
                Upload
              </Button>
            </SpaceBetween>
          </Box>
        }
        className="awsui-visual-refresh"
      >
        <FormField
          label="Drag and drop to upload file"
          errorText={this.state.fileError}
          description={
            <>
              <small>{`* Required. Check if the file name is `}</small>
              <strong>{file_name}</strong>
              <small>{`. If it's not, we'll rename it for you. File max size: ${FILE_UPLOAD.MAX_FILE_SIZE_IN_MB} MB`}</small>
            </>
          }
          stretch
        >
          <Dropzone
            maxSize={FILE_UPLOAD.MAX_FILE_SIZE_IN_MB * 1024 * 1024}
            onDrop={this.onDrop}
            noClick={true}
            onDropRejected={this.onFileReject}
            multiple={false}
            disabled={this.state.files.length !== 0}
            accept={"." + file_name.split(".").pop()}
          >
            {({ getRootProps, getInputProps, open }) => {
              return (
                <div className="upload-dropzone-container">
                  <div
                    {...getRootProps({
                      className: "upload-dropzone",
                    })}
                  >
                    <input {...getInputProps()} />
                    <i
                      className="fas fa-cloud-upload-alt"
                      style={{ fontSize: "30px" }}
                    ></i>
                    <p style={{ fontSize: "18px" }}>Drag and drop file here</p>
                    <br />
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button
                        onClick={open}
                        iconName="upload"
                        iconAlign="left"
                        disabled={this.state.files.length !== 0}
                      >
                        Click to select file
                      </Button>
                    </SpaceBetween>
                  </div>
                </div>
              );
            }}
          </Dropzone>
        </FormField>
        {this.state.files.length > 0 && (
          <TextContent>
            <b>File: </b>
            <ul>
              {this.state.files.map((file: fileObject) => (
                <li key={file.file.name}>
                  {file.file.name}
                  <Button
                    iconAlign="left"
                    iconName="close"
                    variant="icon"
                    onClick={() => this.deleteFile(file.file.name)}
                  />
                </li>
              ))}
            </ul>
          </TextContent>
        )}

        <Modal
          visible={this.state.showRejectModal}
          onDismiss={() => this.setState({ showRejectModal: false })}
          header="Failure"
          footer={
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  variant="primary"
                  onClick={() => this.setState({ showRejectModal: false })}
                >
                  Ok
                </Button>
              </SpaceBetween>
            </Box>
          }
          className="awsui-visual-refresh"
        >
          <Grid
            gridDefinition={[{ colspan: 6 }, { colspan: 2 }, { colspan: 4 }]}
          >
            <b>File Name</b>
            <b>Status</b>
            <b>Error Message</b>
          </Grid>
          {this.state.rejectModalData}
        </Modal>

        <Form>
          <Grid gridDefinition={[{ colspan: 8 }, { colspan: 4 }]}>
            <FormField
              label="Description"
              errorText={this.state.descriptionError}
            >
              <Textarea
                onChange={this._onChangeDescription}
                value={this.state.description}
                rows={1}
              ></Textarea>
            </FormField>

            <FormField label="Expiry Date">
              <DatePicker
                id="expiry_date"
                placeholder="YYYY/MM/DD"
                todayAriaLabel="Today"
                nextMonthAriaLabel="Next month"
                previousMonthAriaLabel="Previous month"
                onChange={this._onChangeExpiryDate}
                value={this.state.expiryDate}
              ></DatePicker>
            </FormField>
          </Grid>
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fileOverviewReducer: state.fileOverviewReducer,
  };
};

export default connect(mapStateToProps)(NewVersion);
