import fileTrashType from "../types/file-trash-type";
import constants, { HttpCodes, NOT_AUTHORIZED_NOTIFICATION } from "../../config/constants";
import { deletedFileData } from "../../model/trash";
import { notification } from "../../model/file-overview";

export interface state {
  loadingStatus: number;
  message: string;
  deletedFileData: Array<deletedFileData>;
  notifications: notification[];
}

const initialState: state = {
  loadingStatus: constants.LOADING_DEFAULT,
  message: "",
  deletedFileData: [],
  notifications: [],
};

const fileTrashReducer = (state: state = initialState, action: any) => {
  switch (action.type) {
    case fileTrashType.GET_DELETED_FILES:
      return {
        ...state,
        loadingStatus: constants.LOADING_LOAD,
      };
    case fileTrashType.GET_DELETED_FILES_COMMIT:
      return {
        ...state,
        loadingStatus: constants.LOADING_SUCCESS,
        message: action.payload.message,
        deletedFileData: action.payload.data,
      };
    case fileTrashType.GET_DELETED_FILES_ROLLBACK:
      const notifications: notification[] = [
        ...(state.notifications
          ? state.notifications
          : []),
      ];
      if(action.statusCode === HttpCodes.FORBIDDEN)
        notifications.push(NOT_AUTHORIZED_NOTIFICATION)

      return {
        ...state,
        loadingStatus: constants.LOADING_FAIL,
        message: action.payload.message,
        notifications
      };
    default:
      return state;
  }
};

export default fileTrashReducer;
