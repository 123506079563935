import removeAssociationType from "../types/remove-association-type";
import constants, { HttpCodes, NOT_AUTHORIZED_NOTIFICATION } from "../../config/constants";
import { notification } from "../../model/file-overview";

interface removeAssociationState {
  loadingStatus: number;
  message: string;
  responseData: any;
  notifications: notification[];
}

const removeAssociationInitialState: removeAssociationState = {
  loadingStatus: constants.LOADING_DEFAULT,
  message: "",
  responseData: {},
  notifications: [],
};

export const removeAssociationReducer = (
  state = removeAssociationInitialState,
  action: any
) => {
  switch (action.type) {
    case removeAssociationType.REMOVE_ASSOCIATION:
      return {
        ...state,
        loadingStatus: constants.LOADING_LOAD,
      };
    case removeAssociationType.REMOVE_ASSOCIATION_COMMIT:
      return {
        ...state,
        loadingStatus: constants.LOADING_SUCCESS,
        message: action.payload.message,
        responseData: action.payload.data,
      };
    case removeAssociationType.REMOVE_ASSOCIATION_ROLLBACK:
      const notifications: notification[] = [
        ...(state.notifications
          ? state.notifications
          : []),
      ];
      if(action.statusCode === HttpCodes.FORBIDDEN)
        notifications.push(NOT_AUTHORIZED_NOTIFICATION)

      return {
        ...state,
        loadingStatus: constants.LOADING_FAIL,
        message: action.payload.message,
        responseData: action.payload.data,
        notifications,
      };
    case removeAssociationType.RESET_REMOVE_ASSOCIATION:
      return {
        ...state,
        loadingStatus: constants.LOADING_DEFAULT,
        message: "",
        responseData: {},
      };
    default:
      return state;
  }
};

export default removeAssociationReducer;
