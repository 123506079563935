import docOverviewType from "../types/doc-overview-type";
import { config } from "../../config/config";
import { METRIC_INFO } from "../../config/constants";
import endpoints from "../../config/endpoints";
import { notification } from "../../model/doc-overview";

export const getDocOverview = (docId: string, grandChildren: number) => ({
  type: docOverviewType.GET_DOC_OVERVIEW,
  docId: docId,
  http: {
    cognito: {
      effect: {
        url:
          config.BASE_URL +
          endpoints.docOverviewEndpoint(docId) +
          "?grand_children=" +
          grandChildren,
        method: "GET",
        docId: docId,
      },
      commit: {
        type: docOverviewType.GET_DOC_OVERVIEW_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
        },
        docId: docId,
      },
      rollback: {
        type: docOverviewType.GET_DOC_OVERVIEW_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
        },
        docId: docId,
      },
    },
  },
});

export const resetDocOverview = (docId: string): any => ({
  type: docOverviewType.RESET_DOC_OVERVIEW,
  docId,
});

export const getDocAssociaitons = (docId: string) => ({
  type: docOverviewType.GET_DOC_ASSOCIATIONS,
  docId: docId,
  http: {
    cognito: {
      effect: {
        url: config.BASE_URL + endpoints.docAssociationsEndpoint(docId),
        method: "GET",
        docId: docId,
      },
      commit: {
        type: docOverviewType.GET_DOC_ASSOCIATIONS_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
        },
        docId: docId,
      },
      rollback: {
        type: docOverviewType.GET_DOC_OVERVIEW_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
        },
        docId: docId,
      },
    },
  },
});

export const setNotifications = (
  notifications: notification[],
  docId: string
): any => ({
  type: docOverviewType.SET_NOTIFICATION,
  notifications,
  docId,
});

export const deleteNotification = (index: number, docId: string): any => ({
  type: docOverviewType.DELETE_NOTIFICATION,
  index,
  docId,
});

export const getColorCode = (docId: string) => ({
  type: docOverviewType.GET_COLOR_CODE,
  http: {
    cognito: {
      effect: {
        url: config.BASE_URL + endpoints.getColorCodeEndpoint(docId),
        method: "GET",
      },
      commit: {
        type: docOverviewType.GET_COLOR_CODE_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
        },
      },
      rollback: {
        type: docOverviewType.GET_COLOR_CODE_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
        },
      },
    },
  },
});

export const updateProjectDetails = (
  project_id: number | null,
  project: any,
  doc_id: string
): any => ({
  type: docOverviewType.UPDATE_PROJECT_DETAILS,
  http: {
    cognito: {
      effect: {
        url:
          config.BASE_URL + endpoints.updateProjectDetailsEndpoint(project_id),
        method: "POST",
        json: {
          project: project,
          doc_id: doc_id,
        },
      },
      commit: {
        type: docOverviewType.UPDATE_PROJECT_DETAILS_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.UPDATE_PROJECT,
        },
      },
      rollback: {
        type: docOverviewType.UPDATE_PROJECT_DETAILS_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.UPDATE_PROJECT,
        },
      },
    },
  },
});
