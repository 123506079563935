import { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
//redux
import { connect } from "react-redux";
import {
  getDocOverview,
  deleteNotification,
  resetDocOverview,
} from "../../redux/actions/doc-overview-action";
import { Dispatch } from "redux";
// sub components
import { Spinner, AppLayout } from "@amzn/awsui-components-react";
import Notification from "../../components/common/notification/notification";
import constants from "../../config/constants";
import { documentOverviewData, notification } from "../../model/doc-overview";
//css
import "../../index.css";
import { config } from "../../config/config";
import LandingTable from "./table/table";
import { HeaderStyle } from "../../config/constants";

// declare prop check
type Props = {
  dispatch: Dispatch<any>;
  history: any;
  docOverviewReducer: any;
} & RouteComponentProps<any>;

// declare state check
type State = {};

class Landing extends Component<Props, State> {
  state: State = Object.freeze({});

  componentDidMount() {
    this.getData();
  }

  componentWillUnmount() {
    this.props.dispatch(resetDocOverview(config.HOME_DOC_ID));
  }

  getData = () => {
    this.props.dispatch(getDocOverview(config.HOME_DOC_ID, 0));
  };

  onDismissNotification = (index: number) => {
    this.props.dispatch(deleteNotification(index, config.HOME_DOC_ID));
  };

  render() {
    const homeId = config.HOME_DOC_ID;
    if (
      !this.props.docOverviewReducer[homeId] ||
      !this.props.docOverviewReducer[homeId].loadingStatus ||
      [constants.LOADING_LOAD, constants.LOADING_DEFAULT].includes(
        this.props.docOverviewReducer[homeId].loadingStatus
      )
    ) {
      return <Spinner size="large" />;
    }
    const {
      docOverviewData,
      loadingStatus,
      notifications,
    }: {
      docOverviewData: documentOverviewData;
      loadingStatus: number;
      notifications: notification[];
    } = this.props.docOverviewReducer[homeId];
    const { doc_name, children } = docOverviewData;

    return (
      <AppLayout
        contentHeader={<HeaderStyle variant="h1">{doc_name}</HeaderStyle>}
        navigationHide
        toolsHide
        content={
          <LandingTable
            data={children}
            loadingStatus={loadingStatus}
            getData={this.getData}
            history={this.props.history}
          />
        }
        notifications={
          <Notification
            notifications={notifications}
            onDismiss={this.onDismissNotification}
          />
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    docOverviewReducer: state.docOverviewReducer,
  };
};

export default connect(mapStateToProps)(Landing);
