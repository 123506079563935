import {
  CollectionPreferences,
  PropertyFilterProps,
} from "@amzn/awsui-components-react";
import { ResourceLabel } from "../../../components/common/labels";
import {
  changeToShortDateFormat,
  changeToShortDateFormatAndAddColor,
} from "../../../utils/date-utils";
import { formatBytes } from "../../../utils/general-utils";
import { NavLink } from "react-router-dom";
import { ExpiryDate } from "../../common-container/expiry-date";
import { EXPIRY_DATE_COLOR_CODE } from "../../../config/constants";

// define columns
export const COLUMN_DEFINITIONS = [
  {
    id: "doc_name",
    sortingField: "doc_name",
    header: "Name",
    cell: (item) => (
      <NavLink to={`documents/${item.doc_id}/overview`}>
        {item.doc_name}
      </NavLink>
    ),
    visible: true,
    minWidth: 300,
  },
  {
    id: "parent",
    sortingField: "parent",
    header: "Parent",
    cell: (item) =>
      item.parent && (
        <NavLink to={item.parent.path}>{item.parent.doc_name}</NavLink>
      ),
    visible: true,
    width: 150,
  },
  {
    id: "category",
    sortingField: "category",
    header: "Category",
    cell: (item) => item.category && item.category.name,
    visible: true,
    width: 150,
  },
  {
    id: "project",
    sortingField: "project",
    header: "Project",
    cell: (item) => item.project && item.project.name,

    visible: true,
    width: 150,
  },
  {
    id: "type",
    sortingField: "type",
    header: "Type",
    cell: (item) => <ResourceLabel resource={item.type} />,
    visible: true,
    minWidth: 80,
  },
  {
    id: "doc_type",
    sortingField: "doc_type",
    header: "Type of Document",
    cell: (item) => (item.doc_type ? item.doc_type : "__"),
    visible: true,
    minWidth: 150,
  },

  {
    id: "expiry_date",
    sortingField: "expiry_date",
    header: "Expiry Status",
    cell: (item) => (
      <ExpiryDate
        informatiomText={
          item.expiry_date
            ? changeToShortDateFormatAndAddColor(item.expiry_date).Date
            : "No expiry date available"
        }
        color={
          item.expiry_date
            ? changeToShortDateFormatAndAddColor(item.expiry_date).color
            : EXPIRY_DATE_COLOR_CODE.GREEN_COLOR_HEX
        }
      />
    ),
    visible: true,
    minWidth: 220,
  },
  {
    id: "created_time",
    sortingField: "created_time",
    header: "Creation Time",
    cell: (item) => changeToShortDateFormat(item.created_time),
    visible: true,
    minWidth: 250,
  },
  {
    id: "size",
    sortingField: "size",
    header: "Size",
    cell: (item) => (item.size ? formatBytes(item.size) : "__"),
    visible: true,
    minWidth: 80,
  },
];

// define visible columns
export const VISIBLE_CONTENT_OPTIONS = [
  {
    label: "Properties",
    options: [
      { id: "doc_name", label: "Name", visible: true, editable: false },
      { id: "parent", label: "Parent", visible: true, editable: false },
      { id: "category", label: "Category", visible: true, editable: false },
      { id: "project", label: "Project", visible: true, editable: false },
      { id: "type", label: "Type", visible: true, editable: false },
      {
        id: "doc_type",
        label: "Type of Document",
        visible: true,
        editable: true,
      },
      {
        id: "expiry_date",
        label: "Expiry Status",
        visible: true,
        editable: false,
      },
      { id: "size", label: "Size", visible: true, editable: false },
      {
        id: "created_time",
        label: "Creation Time",
        visible: true,
        editable: true,
      },
    ],
  },
];

// define filtering properties
export const FILTERING_PROPERTIES: PropertyFilterProps.FilteringProperty[] = [
  {
    propertyLabel: "Name",
    key: "doc_name",
    groupValuesLabel: "Name",
    operators: [":", "!:", "=", "!="],
  },
  // commenting because default filtering doesn't work on nested fields
  // {
  //   propertyLabel: "Parent",
  //   key: "parent",
  //   groupValuesLabel: "Type",
  //   operators: [":", "!:", "=", "!="],
  // },
  // {
  //   propertyLabel: "Category",
  //   key: "category",
  //   groupValuesLabel: "Type",
  //   operators: [":", "!:", "=", "!="],
  // },
  // {
  //   propertyLabel: "Project",
  //   key: "project",
  //   groupValuesLabel: "Type",
  //   operators: [":", "!:", "=", "!="],
  // },
  {
    propertyLabel: "Type",
    key: "type",
    groupValuesLabel: "Type",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Type of Document",
    key: "doc_type",
    groupValuesLabel: "Type of Document",
    operators: [":", "!:", "=", "!="],
  },

  {
    propertyLabel: "Expiry Status",
    key: "expiry_date",
    groupValuesLabel: "Expiry Status",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Size",
    key: "size",
    groupValuesLabel: "Size",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Creation Time",
    key: "created_time",
    groupValuesLabel: "Creation Time",
    operators: [":", "!:", "=", "!="],
  },
];

// define page size options
export const PAGE_SIZE_OPTIONS = [
  { value: 50, label: "50 items" },
  { value: 100, label: "100 items" },
  { value: 300, label: "300 items" },
];

// define default preferences
export const DEFAULT_PREFERENCES = {
  pageSize: 300,
  visibleContent: COLUMN_DEFINITIONS.filter((column) => column.visible).map(
    (column) => column.id
  ),
  wrapLines: true,
};

export const Preferences = ({
  preferences,
  setPreferences,
  disabled,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
  visibleContentOptions = VISIBLE_CONTENT_OPTIONS,
}) => (
  <CollectionPreferences
    title="Preferences"
    confirmLabel="Confirm"
    cancelLabel="Cancel"
    disabled={disabled}
    preferences={preferences}
    onConfirm={({ detail }) => setPreferences(detail)}
    pageSizePreference={{
      title: "Page size",
      options: pageSizeOptions,
    }}
    wrapLinesPreference={{
      label: "Wrap lines",
      description: "Check to see all the text and wrap the lines",
    }}
    visibleContentPreference={{
      title: "Select visible content",
      options: visibleContentOptions,
    }}
  />
);
// define default sorting
export const DEFAULT_SORTING_COLUMN = {
  id: "expiry_date",
  sortingField: "expiry_date",
};
export const DEFAULT_SORTING_DESCENDING = false;
