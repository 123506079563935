import repositoryOverviewType from "../types/repository-overview-type";
import constants, { HttpCodes, NOT_AUTHORIZED_NOTIFICATION } from "../../config/constants";
import { repositoryOverviewData } from "../../model/repository";
import { notification } from "../../model/repository";

export interface state {
  loadingStatus: number;
  message: string;
  repositoryOverviewData: Array<repositoryOverviewData>;
  notifications: notification[];
}

const initialState: state = {
  loadingStatus: constants.LOADING_DEFAULT,
  message: "",
  repositoryOverviewData: [],
  notifications: [],
};

const repositoryOverviewReducer = (
  state: state = initialState,
  action: any
) => {
  switch (action.type) {
    case repositoryOverviewType.GET_REPOSITORY_OVERVIEW:
      return {
        ...state,
        loadingStatus: constants.LOADING_LOAD,
      };

    case repositoryOverviewType.GET_REPOSITORY_OVERVIEW_COMMIT:
      return {
        ...state,
        loadingStatus: constants.LOADING_SUCCESS,
        message: action.payload.message,
        repositoryOverviewData: action.payload.data,
      };

    case repositoryOverviewType.GET_REPOSITORY_OVERVIEW_ROLLBACK:
      const notifications: notification[] = [
        ...(state.notifications
          ? state.notifications
          : []),
      ];
      if(action.statusCode === HttpCodes.FORBIDDEN)
        notifications.push(NOT_AUTHORIZED_NOTIFICATION)

      return {
        ...state,
        loadingStatus: constants.LOADING_FAIL,
        message: action.payload.message,
        notifications
      };

    case repositoryOverviewType.SET_REPOSITORY_NOTIFICATION: {
      const notifications: notification[] = [
        ...state.notifications,
        ...action.notifications,
      ];

      return {
        ...state,
        notifications: notifications,
      };
    }

    case repositoryOverviewType.DELETE_REPOSITORY_NOTIFICATION: {
      state.notifications.splice(action.index, 1);
      return {
        ...state,
      };
    }

    default:
      return state;
  }
};

export default repositoryOverviewReducer;
