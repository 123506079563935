import {
  Box,
  Icon,
  SpaceBetween,
  StatusIndicator,
} from "@amzn/awsui-components-react";
import constants, { FOLDER_GROUP } from "../../config/constants";

//css
import "./labels.css";

export const ValueWithLabel = ({ label, children }) => (
  <div>
    <Box
      variant="awsui-key-label"
      margin={{ bottom: "xxxs" }}
      color="text-label"
    >
      {label}
    </Box>
    <div>{children}</div>
  </div>
);

export const StatusLabel = ({ status, icon = true }) => {
  const split_status = status.split("_");

  for (var i = 0; i < split_status.length; i++) {
    split_status[i] =
      split_status[i].charAt(0).toUpperCase() + split_status[i].slice(1);
  }
  const status_with_first_letter_uppercase = split_status.join(" ");

  return (
    <SpaceBetween direction="horizontal" size="xs">
      <StatusIndicator
        type={
          [constants.STATUS_PROGRESS].includes(status)
            ? "in-progress"
            : [constants.STATUS_UPCOMING, constants.STATUS_NOT_START].includes(
                status
              )
            ? "pending"
            : [constants.STATUS_COMPLETED, constants.STATUS_SUCCESS].includes(
                status
              )
            ? "success"
            : [constants.STATUS_ERROR, constants.STATUS_FAIL].includes(status)
            ? "error"
            : [constants.STATUS_PROCESS].includes(status)
            ? "loading"
            : "info"
        }
      >
        {status_with_first_letter_uppercase}
      </StatusIndicator>
    </SpaceBetween>
  );
};

export const ResourceLabel = ({ resource, icon = true }) => (
  <SpaceBetween direction="horizontal" size="xs">
    {icon === true && (
      <Icon
        name={FOLDER_GROUP.includes(resource) ? "folder" : "file"}
        className="mt-1"
      />
    )}
    <p>{resource}</p>
  </SpaceBetween>
);

export const UploadLabel = ({ uploadStatus }) => {
  let label, icon;

  switch(uploadStatus){
    case constants.STATUS_PENDING:
      icon = "pending";
      label = "Pending";
      break;
    case constants.STATUS_SUCCESS:
      icon = "success";
      label = "Completed";
      break;
    case constants.STATUS_COMPLETED:
      icon = "success";
      label = "Completed";
      break;
    default:
      icon = "in-progress";
      label = "Inprogress"
  }

  return (
    <SpaceBetween direction="horizontal" size="xs">
      <StatusIndicator type={icon}>{label}</StatusIndicator>
    </SpaceBetween>
  );
};

export const RequiredLabel = ({ required }) => {
  const label = required ? "True" : "False";
  const icon = required ? "success" : "stopped";

  return (
    <SpaceBetween direction="horizontal" size="xs">
      <StatusIndicator type={icon} colorOverride="grey">
        {label}
      </StatusIndicator>
    </SpaceBetween>
  );
};

// Re usable when color coding is implemented
export const ExpiryStatusLabel = ({ expiryDate }) => {
  return <div className={`rectangle-${expiryDate}`}></div>;
};
