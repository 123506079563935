import constants from "../../config/constants";
import { shareDocType } from "../types/share-doc-type";

interface state {
  selectedDocs: Array<any>;
}

/* 
const initialState: state = {
  selectedDocs: [
    {
      doc_id: "01G1RM5Z5JZZPCEWV874XZRJ33",
      doc_name: "Test Folder_ab_Application Form.xls",
      parent_doc_id: "01G1RM3EFFFRYC20Y5NG66QRYG",
      type: "xlsm",
      size: 12345,
      created_time: "2021-10-09",
    },
    {
      doc_id: "01G1T04BRVXY3XYHKQ83QB7HFH",
      doc_name: "Test Folder_cd_RF GNSS_EN301489-1.xls",
      parent_doc_id: "01G1RM3EFFFRYC20Y5NG66QRYG",
      type: "xlsm",
      size: 4567,
      created_time: "2021-10-09",
    },
  ],
};
*/

const initialState: state = {
  selectedDocs: [],
};

const shareDocReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case shareDocType.SET_SHARE_DOCS:
      const selectedDocs = action.selectedDocs.map((doc) => {
        return { ...doc, sharedStatus: constants.STATUS_PROCESS };
      });

      return {
        ...state,
        selectedDocs,
      };
    case shareDocType.RESET_SHARE_DOCS:
      return {
        ...state,
        selectedDocs: [],
      };
    default:
      return state;
  }
};

export default shareDocReducer;
