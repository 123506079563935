import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { getDocOverview } from "../../../redux/actions/doc-overview-action";
import { getProjectConfig } from "../../../redux/actions/create-project-action";

import { AppLayout, Spinner } from "@amzn/awsui-components-react/polaris";

import { documentOverviewData } from "../../../model/doc-overview";
import ProjectForm from "./project-form";
import Breadcrumb from "../../../components/common/breadcrumb/breadcrumb";
import constants from "../../../config/constants";
import "../create-form.css";
import { HeaderStyle } from "../../../config/constants";

interface StateProps {
  createProjectReducer: any;
  getProjectConfigReducer: any;
  docOverviewReducer: any;
}

// declare prop check
type Props = {
  dispatch: Dispatch<any>;
} & StateProps &
  RouteComponentProps<any>;

class CreateProject extends Component<Props> {
  componentDidMount() {
    this.getData();
    this.props.dispatch(getProjectConfig());
  }

  getData = () => {
    const categoryId = this.props.match.params.categoryId;
    this.props.dispatch(getDocOverview(categoryId, 0));
  };

  render() {
    const categoryId = this.props.match.params.categoryId;
    if (
      !this.props.docOverviewReducer[categoryId] ||
      !this.props.docOverviewReducer[categoryId].loadingStatus ||
      [constants.LOADING_LOAD, constants.LOADING_DEFAULT].includes(
        this.props.docOverviewReducer[categoryId].loadingStatus
      ) ||
      !this.props.getProjectConfigReducer ||
      [constants.LOADING_LOAD, constants.LOADING_DEFAULT].includes(
        this.props.getProjectConfigReducer.loadingStatus
      )
    ) {
      return <Spinner size="large" />;
    }

    const {
      docOverviewData,
    }: {
      docOverviewData: documentOverviewData;
    } = this.props.docOverviewReducer[categoryId];
    const { breadcrumb_data } = docOverviewData;

    //------------------------------------------------Breadcrumb------------------------------------------------------//
    const breadcrumb = (
      <Breadcrumb
        breadcrumb_data={[
          ...breadcrumb_data,
          {
            doc_name: "Create Project",
            path: `/categories/${categoryId}/projects/create`,
          },
        ]}
        history={this.props.history}
      />
    );

    //--------------------------------------------------Header--------------------------------------------------------//
    const header = (
      <HeaderStyle variant="h1" description="use this page to create projects">
        Create Project
      </HeaderStyle>
    );

    //-----------------------------------------------Main Content-----------------------------------------------------//
    const mainContent = <ProjectForm history={this.props.history} />;

    return (
      <AppLayout
        navigationHide
        toolsHide
        contentHeader={header}
        breadcrumbs={breadcrumb}
        content={mainContent}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    createProjectReducer: state.createProjectReducer,
    getProjectConfigReducer: state.getProjectConfigReducer,
    docOverviewReducer: state.docOverviewReducer,
  };
};

export default withRouter(connect<StateProps>(mapStateToProps)(CreateProject));
