import associationConfigType from "../types/association-config-type";
import constants, { NOT_AUTHORIZED_NOTIFICATION, HttpCodes } from "../../config/constants";
import { notification } from "../../model/doc-overview";

export interface state {
  notifications: notification[];
}

const initialState: state = {
  notifications: [],
};

const associationConfigReducer = (state: state = initialState, action: any) => {
  let parentType = action.parentType;

  switch (action.type) {
    case associationConfigType.GET_ASSOCIATION_CONFIG: {
      state[parentType] = {
        ...state[parentType],
        loadingStatus: constants.LOADING_LOAD,
        message: "",
      };
      return {
        ...state,
      };
    }
    case associationConfigType.GET_ASSOCIATION_CONFIG_COMMIT: {
      state[parentType] = {
        ...state[parentType],
        loadingStatus: constants.LOADING_SUCCESS,
        message: action.payload.message,
        associationConfigData: action.payload.data,
      };
      return {
        ...state,
      };
    }

    case associationConfigType.GET_ASSOCIATION_CONFIG_ROLLBACK: {
      const notifications: notification[] = [
        ...(state.notifications
          ? state.notifications
          : []),
      ];

      if(action.statusCode === HttpCodes.FORBIDDEN)
        notifications.push(NOT_AUTHORIZED_NOTIFICATION)

      state[parentType] = {
        ...state[parentType],
        loadingStatus: constants.LOADING_FAIL,
        message: action.payload.message,
        notifications
      };
      return {
        ...state,
      };
    }

    case associationConfigType.RESET_ASSOCIATION_CONFIG: {
      delete state[parentType];

      return {
        ...state,
      };
    }

    default:
      return state;
  }
};

export default associationConfigReducer;
