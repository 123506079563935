export const disableExistingDocFromDocList = (doc_list, existing_docs) => {
  // initially marking all documents as enabled
  Object.keys(doc_list).forEach((doc_type) => {
    const formatted_doc_list = doc_list[doc_type].map((item) => {
      return {
        doc_name: item.doc_name,
        // doc_class: item.doc_class,
        disabled: false,
      };
    });
    doc_list[doc_type] = formatted_doc_list;
  });

  existing_docs.forEach((document) => {
    // type of existing document
    const doc_type = document.doc_type;
    const doc_name = document.doc_name;

    // if the same document is present in doc list then mark it as disabled
    let idx = -1;
    doc_list[doc_type].forEach((item, index) => {
      if (item.doc_name === doc_name) idx = index;
    });
    if (idx !== -1) {
      doc_list[doc_type][idx] = {
        ...doc_list[doc_type][idx],
        disabled: true,
      };
    }
  });
  return doc_list;
};
