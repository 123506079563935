import checklistType from "../types/checklist-type";

export const initializeChecklist = (
  grandparent: any,
  parent: any,
  value: any
) => ({
  type: checklistType.INTIALIZE_CHECKLIST,
  grandparent,
  parent,
  value,
});

export const updateChecklist = (
  grandparent: any,
  parent: any,
  docType: any,
  value: any
) => ({
  type: checklistType.UPDATE_CHECKLIST,
  grandparent,
  parent,
  docType,
  value,
});

export const resetChecklist = () => ({
  type: checklistType.RESET_CHECKLIST,
});
