import { useState } from "react";
// sub components
import {
  Table,
  Header,
  Pagination,
  PropertyFilter,
  SpaceBetween,
  Button,
  ButtonDropdown,
} from "@amzn/awsui-components-react";
import {
  TableEmptyState,
  TableNoMatchState,
} from "../../../components/polaris/table/common-components";
import { useCollection } from "@amzn/awsui-collection-hooks";
//config, constants
import {
  COLUMN_DEFINITIONS,
  DEFAULT_PREFERENCES,
  FILTERING_PROPERTIES,
  Preferences,
} from "./table-config";
import {
  paginationLabels,
  PROPERTY_FILTERING_I18N_CONSTANTS,
} from "../../../components/polaris/table/labels";
import { getFilterCounterText } from "../../../components/polaris/table/table-counter-strings";
// use this for sample data
// import { TABLE_DATA } from "./sample-data";
import constants, {
  CERTIFICATION_TYPES,
  STATUS_DISPLAY_NAME,
} from "../../../config/constants";
import { pluralize } from "../../../utils/general-utils";
import { updateDoc } from "../../../redux/actions/update-doc-action";

//css

const CertificationOverviewTable = ({
  folderId,
  categoryId,
  projectId,
  data,
  loadingStatus,
  getData,
  history,
  dispatch,
  grandChildType,
  updateDocLoadingStatus,
}) => {
  // selected Items in the table
  const [selectedItems, setSelectedItems] = useState<any | never>([]);

  // default table preferences
  const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);

  // using polaris useCollection hook to define table props
  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    propertyFilterProps,
    paginationProps,
  } = useCollection(data, {
    propertyFiltering: {
      filteringProperties: FILTERING_PROPERTIES,
      empty: <TableEmptyState resourceName={constants.FOLDER} />,
      noMatch: (
        <TableNoMatchState
          onClearFilter={() =>
            actions.setPropertyFiltering({ tokens: [], operation: "and" })
          }
        />
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
  });

  const onUpdateStatusButtonClick = ({ detail }) => {
    dispatch(
      updateDoc(selectedItems[0].doc_id, {
        status: detail.id,
      })
    );
  };
  // table loading status
  const loading = loadingStatus === constants.LOADING_LOAD;

  return (
    <Table
      {...collectionProps}
      columnDefinitions={COLUMN_DEFINITIONS}
      visibleColumns={preferences.visibleContent}
      items={items}
      selectionType="single"
      loadingText="Loading resources"
      loading={loading}
      resizableColumns={true}
      wrapLines={preferences.wrapLines}
      header={
        <Header
          counter={
            selectedItems.length
              ? `(${selectedItems.length}/${data.length})`
              : `(${data.length})`
          }
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button iconName="refresh" onClick={getData} />
              <ButtonDropdown
                items={[
                  {
                    text: STATUS_DISPLAY_NAME.STATUS_UPCOMING,
                    id: constants.STATUS_UPCOMING,
                    disabled:
                      !selectedItems ||
                      selectedItems.length === 0 ||
                      selectedItems[0].status === constants.STATUS_UPCOMING,
                  },
                  {
                    text: STATUS_DISPLAY_NAME.STATUS_PROGRESS,
                    id: constants.STATUS_PROGRESS,
                    disabled:
                      !selectedItems ||
                      selectedItems.length === 0 ||
                      selectedItems[0].status === constants.STATUS_PROGRESS,
                  },
                  {
                    text: STATUS_DISPLAY_NAME.STATUS_COMPLETED,
                    id: constants.STATUS_COMPLETED,
                    disabled:
                      !selectedItems ||
                      selectedItems.length === 0 ||
                      selectedItems[0].status === constants.STATUS_COMPLETED,
                  },
                ]}
                onItemClick={onUpdateStatusButtonClick}
                disabled={selectedItems.length !== 1}
                loading={updateDocLoadingStatus === constants.LOADING_LOAD}
              >
                Update Status
              </ButtonDropdown>
              <Button
                variant="primary"
                onClick={() => {
                  if (
                    grandChildType.toLowerCase() === CERTIFICATION_TYPES.COUNTRY
                  ) {
                    history.push(
                      `/categories/${categoryId}/projects/${projectId}/update/addCountries`
                    );
                  } else if (
                    grandChildType.toLowerCase() ===
                    CERTIFICATION_TYPES.INDUSTRY
                  ) {
                    history.push(
                      `/categories/${categoryId}/projects/${projectId}/update/addIndustries`
                    );
                  }
                }}
              >
                Add {grandChildType}
              </Button>
            </SpaceBetween>
          }
        >
          {pluralize(grandChildType)}
        </Header>
      }
      filter={
        <PropertyFilter
          i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
          {...propertyFilterProps}
          countText={getFilterCounterText(filteredItemsCount)}
        />
      }
      pagination={
        <Pagination {...paginationProps} ariaLabels={paginationLabels} />
      }
      preferences={
        <Preferences
          preferences={preferences}
          setPreferences={setPreferences}
          disabled={false}
        />
      }
      onSelectionChange={({ detail }) => setSelectedItems(detail.selectedItems)}
      selectedItems={selectedItems}
    />
  );
};

export default CertificationOverviewTable;
