// import { showClassAsDescription } from "../../utils/general-utils";
import { formatDocName } from "../../utils/doc-name-utils";
import { CHECKLIST } from "../../config/constants";

export const formatChecklist = (
  project: string,
  modelNo: string,
  checklist: any
) => {
  let formattedChecklist = {};
  Object.keys(checklist).forEach((key) => {
    formattedChecklist[key] = checklist[key].map((item) => {
      return {
        label: formatDocName(item.doc_name, project, modelNo),
        value: item.doc_name,
        // doc_class: item.doc_class,
        // description: showClassAsDescription(item.doc_class),
      };
    });
  });
  return formattedChecklist;
};

export const formatSelectedOptions = (
  project: string,
  modelNo: string,
  options: any
) => {
  let formattedOptions: any = [];
  options.forEach((item) => {
    formattedOptions.push({
      label: formatDocName(item.value, project, modelNo),
      value: item.value,
      // doc_class: item.doc_class,
      // description: item.description,
    });
  });
  return formattedOptions;
};

export const convertToDropdownOptions = (
  options: any,
  selectedTechnicalDocuments = []
) => {
  let convertToDropdownOptions: any = [];
  options.forEach((item) => {
    convertToDropdownOptions.push({
      label: item.doc_name,
      value: item.doc_name,
    });
  });
  if (selectedTechnicalDocuments.length === 0) {
    convertToDropdownOptions.unshift(CHECKLIST.CHECK_ALL);
  } else {
    convertToDropdownOptions.unshift(CHECKLIST.UNCHECK_ALL);
  }

  return convertToDropdownOptions;
};

// NOTE: All the V2 functions are used by add-doc-checklist component
export const convertToDropdownOptionsV2 = (options: any) => {
  let dropdownOptions: any = [];
  options.forEach((item) => {
    dropdownOptions.push({
      label: item.doc_name,
      value: item.doc_name,
      disabled: item.disabled,
      // doc_class: item.doc_class,
      // description: showClassAsDescription(item.doc_class),
    });
  });
  return dropdownOptions;
};

export const formatSelectedOptionsV2 = (
  project: string,
  modelNo: string,
  options: any
) => {
  let formattedOptions: any = [];
  options.forEach((item) => {
    formattedOptions.push({
      label: formatDocName(item.value, project, modelNo),
      value: item.value,
      disabled: item.disabled,
      // doc_class: item.doc_class,
      // description: showClassAsDescription(item.doc_class),
    });
  });
  return formattedOptions;
};
