import { useState } from "react";
// sub components
import {
  Table,
  Header,
  Pagination,
  PropertyFilter,
  SpaceBetween,
  Button,
} from "@amzn/awsui-components-react";
import {
  TableEmptyState,
  TableNoMatchState,
} from "../../../components/polaris/table/common-components";
import { useCollection } from "@amzn/awsui-collection-hooks";
//config, constants
import {
  COLUMN_DEFINITIONS,
  DEFAULT_PREFERENCES,
  FILTERING_PROPERTIES,
  Preferences,
  DEFAULT_SORTING_COLUMN,
} from "./table-config";
import {
  paginationLabels,
  PROPERTY_FILTERING_I18N_CONSTANTS,
} from "../../../components/polaris/table/labels";
import { getFilterCounterText } from "../../../components/polaris/table/table-counter-strings";
// use this for sample data
import constants, { FOLDER_GROUP } from "../../../config/constants";
import { s3URIToLink } from "../../../utils/general-utils";
//css

const TrashTable = ({ data, loadingStatus, getData }) => {
  // selected Items in the table
  const [selectedItems, setSelectedItems] = useState<any | never>([]);

  // default table preferences
  const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);

  // using polaris useCollection hook to define table props
  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    propertyFilterProps,
    paginationProps,
  } = useCollection(data, {
    propertyFiltering: {
      filteringProperties: FILTERING_PROPERTIES,
      empty: <TableEmptyState resourceName={constants.FILE} />,
      noMatch: (
        <TableNoMatchState
          onClearFilter={() =>
            actions.setPropertyFiltering({ tokens: [], operation: "and" })
          }
        />
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {
      defaultState: {
        sortingColumn: DEFAULT_SORTING_COLUMN,
        isDescending: true,
      },
    },
    selection: {},
  });

  // table loading status
  const loading = loadingStatus === constants.LOADING_LOAD;

  return (
    <Table
      {...collectionProps}
      columnDefinitions={COLUMN_DEFINITIONS}
      visibleColumns={preferences.visibleContent}
      items={items}
      selectionType="single"
      loadingText="Loading resources"
      loading={loading}
      resizableColumns={true}
      wrapLines={preferences.wrapLines}
      header={
        <Header
          counter={
            selectedItems.length
              ? `(${selectedItems.length}/${data.length})`
              : `(${data.length})`
          }
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button iconName="refresh" onClick={getData} />
              <Button
                variant="primary"
                disabled={
                  !selectedItems.length ||
                  FOLDER_GROUP.includes(selectedItems[0].type)
                }
                iconName="download"
                href={
                  !selectedItems.length
                    ? undefined
                    : s3URIToLink(selectedItems[0].s3_link)
                }
              >
                Download
              </Button>
            </SpaceBetween>
          }
        >
          Deleted Files
        </Header>
      }
      filter={
        <PropertyFilter
          i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
          {...propertyFilterProps}
          countText={getFilterCounterText(filteredItemsCount)}
        />
      }
      pagination={
        <Pagination {...paginationProps} ariaLabels={paginationLabels} />
      }
      preferences={
        <Preferences
          preferences={preferences}
          setPreferences={setPreferences}
          disabled={false}
        />
      }
      onSelectionChange={({ detail }) => setSelectedItems(detail.selectedItems)}
      selectedItems={selectedItems}
    />
  );
};

export default TrashTable;
