import { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Dispatch } from "redux";
import Breadcrumb from "../../components/common/breadcrumb/breadcrumb";
import constants from "../../config/constants";
import { Button, AppLayout, Spinner } from "@amzn/awsui-components-react";
import { fileObject } from "../../model/file";
import UploadForm from "./upload-form/upload-form";
import UploadPage from "./upload-page/upload-page";
import { fileUploadS3Call, uploadFileToCabinet } from "./utils";
import { connect } from "react-redux";
import { getTagsConfig } from "../../redux/actions/tags-config-action";
import { HeaderStyle } from "../../config/constants";

// declare prop check
type Props = {
  dispatch: Dispatch<any>;
  history: any;
  tagsConfigReducer: any;
  documentId?: string;
  onCancel?: () => void;
} & RouteComponentProps<any>;

// declare state check
type State = {
  files: Array<fileObject>;
  isFormSubmitted: boolean;
  inProgressFiles: number;
  successfulFiles: number;
  failedFiles: number;
};

class Upload extends Component<Props> {
  state: State = Object.freeze({
    files: [],
    isFormSubmitted: false,
    inProgressFiles: 0,
    successfulFiles: 0,
    failedFiles: 0,
  });

  componentDidMount() {
    this.props.dispatch(getTagsConfig());
  }

  onUpload = (filesToUpload: Array<fileObject>) => {
    this.setState({
      files: filesToUpload,
      isFormSubmitted: true,
      inProgressFiles: filesToUpload.length,
    });
    filesToUpload.forEach((file: fileObject, index) => {
      fileUploadS3Call(file).then((response1) => {
        if (response1.status === constants.STATUS_SUCCESS) {
          let s3_link = response1.s3_link;
          uploadFileToCabinet(
            file?.file?.name ?? "",
            decodeURI(s3_link.split("?")[0]).replace(
              new RegExp(
                "^[a-zA-Z0-9_:.-]+//[a-zA-Z0-9_:.-]+/[a-zA-Z0-9_:.-]+/"
              ),
              ""
            ),
            file.file.size,
            file?.expiryDate,
            file?.description,
            file?.tags,
            this.props.documentId ?? ""
          ).then((response2) => {
            let { files, inProgressFiles, successfulFiles, failedFiles } =
              this.state;
            files[index].status = response2.status;
            files[index].msg = response2.message;
            inProgressFiles--;
            if (response2.status === constants.STATUS_SUCCESS) {
              successfulFiles++;
            } else {
              failedFiles++;
            }
            this.setState({
              files,
              inProgressFiles,
              successfulFiles,
              failedFiles,
            });
          });
        } else {
          let { files, inProgressFiles, failedFiles } = this.state;
          files[index].status = response1.status;
          inProgressFiles--;
          failedFiles++;
          this.setState({
            files,
            inProgressFiles,
            failedFiles,
          });
        }
      });
    });
  };

  render() {
    if (
      !this.props.tagsConfigReducer ||
      !this.props.tagsConfigReducer.loadingStatus ||
      [constants.LOADING_LOAD, constants.LOADING_DEFAULT].includes(
        this.props.tagsConfigReducer.loadingStatus
      )
    ) {
      return (
      <div style={{ maxHeight: "500px", overflow: "auto", textAlign: "center", paddingTop: "200px" }}>
        <Spinner size="large" />
        <br/>
        Loading resources
      </div>);
    }

    //------------------------------------------------Breadcrumb------------------------------------------------------//
    const breadcrumb = (
      <Breadcrumb
        breadcrumb_data={[
          { doc_name: "File Repository", path: "/repository" },
          { doc_name: "File Upload", path: "#" },
        ]}
        history={this.props.history}
      />
    );

    //--------------------------------------------------Header--------------------------------------------------------//
    const header = this.state.isFormSubmitted ? (
      <HeaderStyle
        variant="h1"
        actions={
          <Button
            variant="primary"
            onClick={() =>
              // if onCancel is passed, call onCancel, otherwise redirect to repository page
              this.props.onCancel ? this.props.onCancel() : this.props.history.push("/repository")
            }
          >
            Close
          </Button>
        }
      >
        Upload : Status
      </HeaderStyle>
    ) : (
      <HeaderStyle variant="h1" description="use this page to upload files">
        File Upload
      </HeaderStyle>
    );

    //-----------------------------------------------Main Content-----------------------------------------------------//
    const uploadPage = (
      <UploadPage
        inProgressFiles={this.state.inProgressFiles}
        successfulFiles={this.state.successfulFiles}
        failedFiles={this.state.failedFiles}
        files={this.state.files}
        history={this.props.history}
      />
    );
    const uploadForm = (
      <UploadForm onUpload={this.onUpload} history={this.props.history} onCancel={this.props.onCancel}/>
    );
    const mainContent = this.state.isFormSubmitted ? uploadPage : uploadForm;

    /* If user has passed the documentId for association don't show breadcrumb  */
    return (
      <AppLayout
        navigationHide
        toolsHide
        contentHeader={header}
        breadcrumbs={this.props.documentId ?? breadcrumb}
        content={mainContent}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tagsConfigReducer: state.tagsConfigReducer,
  };
};

export default connect(mapStateToProps)(Upload);
