const repositoryOverviewType = {
  GET_REPOSITORY_OVERVIEW: "GET_REPOSITORY_OVERVIEW",
  GET_REPOSITORY_OVERVIEW_COMMIT: "GET_REPOSITORY_OVERVIEW_COMMIT",
  GET_REPOSITORY_OVERVIEW_ROLLBACK: "GET_REPOSITORY_OVERVIEW_ROLLBACK",

  SET_REPOSITORY_NOTIFICATION: "SET_REPOSITORY_NOTIFICATION",
  DELETE_REPOSITORY_NOTIFICATION: "DELETE_REPOSITORY_NOTIFICATION",
};

export default repositoryOverviewType;
