import constants from "../config/constants";
import { config } from "../config/config.js";
import endpoints from "../config/endpoints";
import { getData, postData } from "../utils/cognito-fetch";
import { downloadDocumentStatus } from "../model/http-json";

export interface download_files_payload {
  file_ids: [string],
  folder_name: string
}

export interface download_document_payload {
  document_ids: [string],
  folder_name: string
}

export async function fileDownloadS3Call(
  documentId: string
): Promise<downloadDocumentStatus> {
  /*
      Ajax call to get document download url
  */
  // init return
  const result: downloadDocumentStatus = {
    status: constants.STATUS_ERROR,
    message: "",
    s3_url: "",
  };

  try {
    const url: string =
      config.BASE_URL + endpoints.downloadDocEndpoint(documentId);
    const response = await getData(url);
    if (!response.ok) throw response.status_text;
    const preSignedURLResponse = response.json;
    result.status = constants.STATUS_SUCCESS;
    result.message = preSignedURLResponse.message;
    result.s3_url = preSignedURLResponse.data.url;
  } catch (error: any) {
    result.status = constants.STATUS_ERROR;
    result.message = error["message"];
  } finally {
    return result;
  }
}

export async function downloadDocumentAsZip(
  documentIds: [string],
  fileName: string
): Promise<downloadDocumentStatus> {
  const url: string =
    config.BASE_URL + endpoints.downloadFolderAsZipGetPresignedURLEndpoint();
  const payload: download_document_payload = {
    "document_ids": documentIds,
    "folder_name": fileName
  }
  return postCallForDownload(url, payload)
}

export async function downloadFilesAsZip(
  fileIds: [string],
  fileName: string
): Promise<downloadDocumentStatus> {
  const url: string =
    config.BASE_URL + endpoints.downloadFilesAsZipGetPresignedURLEndpoint();
  const payload: download_files_payload = {
    "file_ids": fileIds,
    "folder_name": fileName
  }
  return postCallForDownload(url, payload)

}

async function postCallForDownload(url: string, payload: Object): Promise<downloadDocumentStatus> {
  /*
      Ajax call to get document download url
  */
  // init return
  const result: downloadDocumentStatus = {
    status: constants.STATUS_ERROR,
    message: "",
    s3_url: "",
  };

  try {
    const response = await postData(url, payload);
    if (!response.ok) throw response.status_text;
    const preSignedURLResponse = response.json;
    result.status = constants.STATUS_SUCCESS;
    result.message = preSignedURLResponse.message;
    result.s3_url = preSignedURLResponse.data.url;
  } catch (error: any) {
    result.status = constants.STATUS_ERROR;
    result.message = error["message"];
  } finally {
    return result;
  }
}
