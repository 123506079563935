import {
  CollectionPreferences,
  PropertyFilterProps,
} from "@amzn/awsui-components-react";
import { changeToShortDateFormat } from "../../../utils/date-utils";
import { formatBytes } from "../../../utils/general-utils";

// define columns
export const COLUMN_DEFINITIONS = [
  {
    id: "versionId",
    sortingField: "versionId",
    header: "Version ID",
    cell: (item) => (
      <a href={item.s3_link}>
        {item.isLatest ? `${item.versionId} (Current Version)` : item.versionId}
      </a>
    ),
    visible: true,
    minWidth: 400,
  },
  {
    id: "type",
    sortingField: "type",
    header: "Type",
    cell: (item) => item.type,
    visible: true,
    minWidth: 100,
  },
  {
    id: "last_modified",
    sortingField: "last_modified",
    header: "Last Modified",
    cell: (item) => changeToShortDateFormat(item.lastModified),
    visible: true,
    minWidth: 200,
  },
  {
    id: "size",
    sortingField: "size",
    header: "Size",
    cell: (item) => (item.size ? formatBytes(item.size) : "__"),
    visible: true,
    minWidth: 100,
  },
];

// define visible columns
export const VISIBLE_CONTENT_OPTIONS = [
  {
    label: "Properties",
    options: [
      { id: "versionId", label: "Name", visible: true, editable: false },
      { id: "type", label: "Type", visible: true, editable: false },
      { id: "size", label: "Size", visible: true, editable: false },
      {
        id: "last_modified",
        label: "Last Modified",
        visible: true,
        editable: false,
      },
    ],
  },
];

// define filtering properties
export const FILTERING_PROPERTIES: PropertyFilterProps.FilteringProperty[] = [
  {
    propertyLabel: "Version ID",
    key: "versionId",
    groupValuesLabel: "Version ID",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Type",
    key: "type",
    groupValuesLabel: "Type",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Size",
    key: "size",
    groupValuesLabel: "Size",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Last Modified",
    key: "last_modified",
    groupValuesLabel: "Last Modified",
    operators: [":", "!:", "=", "!="],
  },
];

// define page size options
export const PAGE_SIZE_OPTIONS = [
  { value: 50, label: "50 items" },
  { value: 100, label: "100 items" },
  { value: 300, label: "300 items" },
];

// define default preferences
export const DEFAULT_PREFERENCES = {
  pageSize: 300,
  visibleContent: COLUMN_DEFINITIONS.filter((column) => column.visible).map(
    (column) => column.id
  ),
  wrapLines: true,
};

export const Preferences = ({
  preferences,
  setPreferences,
  disabled,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
  visibleContentOptions = VISIBLE_CONTENT_OPTIONS,
}) => (
  <CollectionPreferences
    title="Preferences"
    confirmLabel="Confirm"
    cancelLabel="Cancel"
    disabled={disabled}
    preferences={preferences}
    onConfirm={({ detail }) => setPreferences(detail)}
    pageSizePreference={{
      title: "Page size",
      options: pageSizeOptions,
    }}
    wrapLinesPreference={{
      label: "Wrap lines",
      description: "Check to see all the text and wrap the lines",
    }}
    visibleContentPreference={{
      title: "Select visible content",
      options: visibleContentOptions,
    }}
  />
);
