import base_config from "./base_config";

const dev_config = {
  ...base_config,
  STAGE: "dev",

  // Amplify config for cognito
  AMPLIFY_CONFIG: {
    aws_project_region: base_config.REGION,
    aws_cognito_region: base_config.REGION,
    aws_user_pools_id: "us-west-2_98VCRNfCo",
    aws_user_pools_web_client_id: "6utmd5dcgqb781q9q4urlkvv8i",
    oauth: {
      domain: "wise-general-cog-dev.auth.us-west-2.amazoncognito.com",
      scope: base_config.TOKEN_SCOPES_ARRAY,
      redirectSignIn: base_config.CALL_BACK_URL,
      redirectSignOut: base_config.CALL_BACK_URL,
      responseType: base_config.OAUTH_RESPONSE_TYPE,
    },
  },

  // Base url
  BASE_URL: "https://bsnxg079nl.execute-api.us-west-2.amazonaws.com/dev/v1",
  COMPLIANCE_BASE_URL:
    "https://rh8p0k1wnk.execute-api.us-west-2.amazonaws.com/dev/v1",

  BASE_AUTHZ_URL: "https://bsnxg079nl.execute-api.us-west-2.amazonaws.com/dev",
  // Upload service base url
  UPLOAD_BASE_URL: "https://wrcqb3byf5.execute-api.us-west-2.amazonaws.com/dev",
  ANALYTICS_DASHBOARD: "",
  // Configuration for Cloudwatch RUM
  APPLICATION_ID: "38c84cb8-331b-49b4-89e1-5e4550685426",
  APPLICATION_VERSION: "1.0.0",
  APPLICATION_REGION: "us-west-2",
  rum_config: {
    sessionSampleRate: 1,
    guestRoleArn: "arn:aws:iam::517953405512:role/RUM-Monitor-us-west-2-517953405512-2609128517861-Unauth",
    identityPoolId: "us-west-2:bcb6a14e-838e-4054-a723-2f29bc71b183",
    endpoint: "https://dataplane.rum.us-west-2.amazonaws.com",
    telemetries: ["performance","errors", ["http",{recordAllRequests: true}]],
    allowCookies: true,
    enableXRay: false,
    disableAutoPageView: true
  }
};

export default dev_config;
