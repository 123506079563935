import { TABS_ID } from "../../config/constants";

export const TABS = [
  {
    LABEL: "Associations",
    ID: TABS_ID.FIRST,
  },
  {
    LABEL: "Versions",
    ID: TABS_ID.SECOND,
  },
  {
    LABEL: "Tags",
    ID: TABS_ID.THIRD,
  },
];
