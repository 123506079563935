import { useState } from "react";
import {
  Alert,
  Container,
  Header,
  FormField,
  Input,
  SpaceBetween,
  Button,
} from "@amzn/awsui-components-react";
import DeleteFilesTable from "./table/table";
import constants from "../../../../config/constants";

const DeleteForm = ({ data, fileId, onDelete, history }) => {
  // state
  const [confirmDelete, setConfirmDelete] = useState("");
  const [confirmDeleteError, setConfirmDeleteError] = useState("");

  const validate = () => {
    if (confirmDelete !== "delete") {
      setConfirmDeleteError(
        "Please type delete in lower case without spaces to confirm deletion"
      );
      return false;
    }
    return true;
  };

  const deleteObjects = () => {
    if (!validate()) return;
    onDelete(data);
  };

  const cancelDeletion = () => {
    history.push(`/repository`);
  };

  return (
    <div
      style={{
        maxWidth: "800px",
      }}
    >
      <Alert
        visible={true}
        dismissAriaLabel="Close alert"
        header="Deleted objects cannnot be restored"
      >
        - If you need the deleted files, you can download it from{" "}
        <strong>Trash</strong> folder within 90 days of deletion.
      </Alert>
      <br />
      <DeleteFilesTable data={data} loadingStatus={constants.LOADING_SUCCESS} />
      <br />
      <Container header={<Header> Delete Files? </Header>}>
        <FormField
          label="To confirm deletion, type delete in the text input below"
          errorText={confirmDeleteError}
        >
          <Input
            id={constants.FILE}
            placeholder="delete"
            value={confirmDelete}
            onChange={(event) => {
              setConfirmDelete(event.detail.value);
              setConfirmDeleteError("");
            }}
          />
        </FormField>
      </Container>
      <br />
      <div
        style={{
          display: "flex",
          flexDirection: "row-reverse",
        }}
      >
        <SpaceBetween direction="horizontal" size="s">
          <Button variant="normal" onClick={cancelDeletion}>
            Cancel
          </Button>
          <Button variant="primary" onClick={deleteObjects}>
            Delete Files
          </Button>
        </SpaceBetween>
      </div>
    </div>
  );
};

export default DeleteForm;
