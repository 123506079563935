import React from "react";
import { withRouter } from "react-router-dom";
import { useState, useEffect } from "react";
import AppLayout from "@amzn/awsui-components-react/polaris/app-layout";
import Input from "@amzn/awsui-components-react/polaris/input";
import SideNavigation from "@amzn/awsui-components-react/polaris/side-navigation";
import { TopNavigation } from "@amzn/awsui-components-react/polaris";
import { Footer } from "@amzn/lens-react-ui";
import MainRouter from "./main-router";
import { username } from "../../cognito-auth/session";
import constants from "../../config/constants";
import { useHistory } from "react-router-dom";
import ScrollToTop from "../../components/common/scroll-to-top/scroll-to-top";
import "./main.css";
import { getData } from "../../utils/cognito-fetch";
import { config } from "../../config/config.js";
import endpoints from "../../config/endpoints";
import { awsRum } from "../../index";

const i18nStrings = {
  searchIconAriaLabel: "Search",
  searchDismissIconAriaLabel: "Close search",
  overflowMenuTriggerText: "More",
  overflowMenuTitleText: "All",
};

const SIDE_NAVIGATION_DEFAULT_ITEMS: Array<any> = [
  {
    type: "link",
    text: "Home",
    href: "/",
  },
  {
    type: "link",
    text: "Expiring Documents",
    href: "/expiring-documents",
  },
  {
    type: "divider",
  },
  {
    type: "link",
    text: "Repository",
    href: "/repository",
  },
  {
    type: "link",
    text: "Expiring Files",
    href: "/expiring-files",
  },
  {
    type: "link",
    text: "File Trash",
    href: "/file-trash",
  },
  {
    type: "divider",
  },
];

const Main = () => {
  const [docOverviewData, setDocOverviewData] = useState<any>(null);
  const [sidebarOptionsData, setSidebarOptionsData] = useState<Array<any>>([]);
  const [searchString, setSearchString] = useState("");
  const history = useHistory();

  const fetchDocOverviewData = async (docId: string, grandchildren: number) => {
    try {
      const response = await getData(
        `${config.BASE_URL}${endpoints.docOverviewEndpoint(
          docId
        )}?grand_children=${grandchildren}`
      );

      if (response.json) {
        setDocOverviewData(response.json.data);
      }
    } catch (error) {
      console.error(`Error fetching doc overview data: `, error);
    }
  };

  useEffect(() => {
    fetchDocOverviewData(config.HOME_DOC_ID, 0);
  }, []);

  useEffect(() => {
    const updateSidebarOptions = (items: Array<any>) => {
      for (let item = 0; item < items.length; item++) {
        if (items[item].id === docOverviewData.doc_id) {
          const updatedItems = docOverviewData.children.map((child) => ({
            type: child.type !== "doc" ? "expandable-link-group" : "link",
            text: child.doc_name,
            href:
              child.type !== "doc"
                ? child.path
                : `/documents/${child.doc_id}/overview`,
            items: [],
            entity: child.type,
            id: child.doc_id,
          }));
          return updatedItems;
        }
        if (
          items[item].items.length > 0 &&
          updateSidebarOptions(items[item].items)
        ) {
          return true;
        }
      }
      return false;
    };

    if (docOverviewData) {
      setSidebarOptionsData((prevSidebarOptionsData) => {
        if (prevSidebarOptionsData.length === 0) {
          return docOverviewData.children.map((child: any) => ({
            type: "expandable-link-group",
            text: child.doc_name,
            href: child.path,
            items: [],
            entity: child.type,
            id: child.doc_id,
          }));
        } else {
          const updatedSidebarOptions = [...prevSidebarOptionsData];
          let sidebarOptionsUpdated = false;

          const updateOptions = (items: Array<any>) => {
            for (let item = 0; item < items.length; item++) {
              if (
                items[item].id === docOverviewData.doc_id &&
                items[item].items.length === 0
              ) {
                items[item].items = updateSidebarOptions(items);
                items[item].defaultExpanded = true;
                sidebarOptionsUpdated = true;
                return;
              }
              if (items[item].items.length > 0) {
                updateOptions(items[item].items);
              }
            }
          };

          updateOptions(updatedSidebarOptions);

          if (sidebarOptionsUpdated) {
            return updatedSidebarOptions;
          } else {
            return prevSidebarOptionsData;
          }
        }
      });
    }
  }, [docOverviewData, setSidebarOptionsData]); 

  const handleOnSubmit = (e) => {
    history.push({
      pathname: "/search",
      search: "?search_string=" + searchString,
    });
    e.preventDefault();
  };

  const onSideNavigationFollow = (event: any) => {
    if (!event.detail.external) {
      event.preventDefault();
      history.push({ pathname: event.detail.href });
    }
  };

  const onSideNavigationChange = (event: any) => {
    awsRum?.recordEvent("side_navigation_event", {
      isExternal: event.detail.external,
      href: event.detail.href,
      metricCategory: "sidebar_metric",
    });

    let parentItem = event.detail.item;
    let doc_id = parentItem.id;
    fetchDocOverviewData(doc_id, 0);
  };

  const onTopNavigationFollow = (event) => {
    event.preventDefault();
    history.push("/");
  };

  return (
    <>
      <TopNavigation
        className="top-navigation awsui-visual-refresh"
        id="top-navigation"
        i18nStrings={i18nStrings}
        identity={{
          href: "/",
          title: constants.INITIATIVE_DISPLAY_NAME,
          logo: { src: "./cabinet-logo.png" },
          onFollow: onTopNavigationFollow,
        }}
        search={
          <form onSubmit={handleOnSubmit}>
            <Input
              value={searchString}
              type="search"
              inputMode="search"
              placeholder="Search"
              onChange={({ detail }) => setSearchString(detail.value)}
            />
          </form>
        }
        utilities={[
          {
            type: "button",
            variant: "link",
            text: "Request Feature",
            external: true,
            href: "https://issues.amazon.com/issues/create?template=bc304dff-a79f-48d8-b4f7-46eb6649f0de",
          },
          {
            type: "button",
            variant: "link",
            text: "Report Bug",
            external: true,
            href: "https://issues.amazon.com/issues/create?template=e643c120-2964-4575-bd8f-cffbf78e4961",
          },
          {
            type: "button",
            text: username,
            iconName: "user-profile",
            href: "#",
          },
        ]}
      />
      <AppLayout
        disableContentPaddings
        toolsHide
        headerSelector="#top-navigation"
        navigation={
          <SideNavigation
            header={{ href: "/", text: constants.INITIATIVE_DISPLAY_NAME }}
            activeHref={history.location.pathname || "/"}
            onFollow={onSideNavigationFollow}
            onChange={onSideNavigationChange}
            className="awsui-visual-refresh"
            items={[...SIDE_NAVIGATION_DEFAULT_ITEMS, ...sidebarOptionsData]}
          />
        }
        content={
          <React.Fragment>
            <div className="main-content awsui-visual-refresh">
              <ScrollToTop />
              <MainRouter />
            </div>
            <Footer />
          </React.Fragment>
        }
      />
    </>
  );
};

export default withRouter(Main);
