import ReactDOM from "react-dom";
import App from "./App";
// integrate Bootstrap
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.min.js";
// font awesome
// import "@fortawesome/fontawesome-free/css/all.css";
// polaris global css
// https://polaris.a2z.com/develop/styles/global_styles/
import "@amzn/awsui-global-styles/polaris.css";
// our own library, contains css style and colors
// https://code.amazon.com/packages/LENS-React-UI/blobs/mainline/--/src/index.css
import "@amzn/lens-react-ui/lib/main.min.css";
// bind redux
import { Provider } from "react-redux";
import store from "./redux/store";
// react router dom
import { BrowserRouter } from "react-router-dom";
// load config
import { loadAppConfig } from "./config/config";

// global css
import "./index.css";

import { AwsRum } from 'aws-rum-web';

document.body.classList.add("awsui-visual-refresh");
export let awsRum;

async function configRumClient(config){
  if(config.STAGE === "dev") return;
  try {
    awsRum = new AwsRum(
      config.APPLICATION_ID,
      config.APPLICATION_VERSION,
      config.APPLICATION_REGION,
      config.rum_config
    );
  } catch (error) {
    // Ignore errors thrown during CloudWatch RUM web client initialization
    console.log("CloudWatch RUM web client initialization failed")
    console.log(error)
  }
}

loadAppConfig().then((config) => {
  configRumClient(config);
  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <div className="awsui">
          <App />
        </div>
      </BrowserRouter>
      ,
    </Provider>,
    document.getElementById("root")
  );
});
