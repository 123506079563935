import constants from "../../config/constants";
import { deleteFileType } from "../types/delete-file-type";

interface state {
  selectedFiles: Array<any>;
}

const initialState: state = {
  selectedFiles: [],
};

const deleteFileReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case deleteFileType.SET_DELETE_FILES:
      const selectedFiles = action.selectedFiles.map((file) => {
        return { ...file, deleteStatus: constants.STATUS_PROCESS };
      });
      return {
        ...state,
        selectedFiles,
      };
    case deleteFileType.RESET_DELETE_FILES:
      return {
        ...state,
        selectedFiles: [],
      };
    default:
      return state;
  }
};

export default deleteFileReducer;
