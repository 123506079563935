import {
  CollectionPreferences,
  PropertyFilterProps,
} from "@amzn/awsui-components-react";
import { changeToShortDateFormat } from "../../../../../utils/date-utils";
import { formatBytes } from "../../../../../utils/general-utils";

// define columns
export const COLUMN_DEFINITIONS = [
  {
    id: "file_name",
    sortingField: "file_name",
    header: "File Name",
    cell: (item) => item.file_name,
    visible: true,
    minWidth: 200,
  },
  {
    id: "description",
    sortingField: "description",
    header: "Description",
    cell: (item) => item.description,
    visible: true,
    width: 120,
  },
  {
    id: "upload_time",
    sortingField: "upload_time",
    header: "Upload Time",
    cell: (item) => changeToShortDateFormat(item.upload_time),
    visible: true,
    width: 250,
  },
  {
    id: "size",
    sortingField: "size",
    header: "Size",
    cell: (item) => (item.size ? formatBytes(item.size) : "__"),
    visible: true,
    width: 120,
  },
];

// define visible columns
export const VISIBLE_CONTENT_OPTIONS = [
  {
    label: "Properties",
    options: [
      { id: "file_name", label: "File Name", visible: true, editable: false },
      {
        id: "description",
        label: "Description",
        visible: true,
        editable: false,
      },
      { id: "size", label: "Size", visible: true, editable: false },
      {
        id: "upload_time",
        label: "Upload Time",
        visible: true,
        editable: false,
      },
    ],
  },
];

// define filtering properties
export const FILTERING_PROPERTIES: PropertyFilterProps.FilteringProperty[] = [
  {
    propertyLabel: "File Name",
    key: "file_name",
    groupValuesLabel: "File Name",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Description",
    key: "description",
    groupValuesLabel: "Description",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Size",
    key: "size",
    groupValuesLabel: "Size",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Upload Time",
    key: "upload_time",
    groupValuesLabel: "Upload Time",
    operators: [":", "!:", "=", "!="],
  },
];

// define page size options
export const PAGE_SIZE_OPTIONS = [
  { value: 50, label: "50 items" },
  { value: 100, label: "100 items" },
];

// define default preferences
export const DEFAULT_PREFERENCES = (is_file_upload_allowed?: boolean) => {
  return {
    pageSize: 300,
    visibleContent: COLUMN_DEFINITIONS.filter((column) => column.visible).map(
      (column) => column.id
    ),
    wrapLines: false,
  };
};

export const Preferences = ({
  preferences,
  setPreferences,
  disabled,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
  visibleContentOptions = VISIBLE_CONTENT_OPTIONS,
}) => (
  <CollectionPreferences
    title="Preferences"
    confirmLabel="Confirm"
    cancelLabel="Cancel"
    disabled={disabled}
    preferences={preferences}
    onConfirm={({ detail }) => setPreferences(detail)}
    pageSizePreference={{
      title: "Page size",
      options: pageSizeOptions,
    }}
    wrapLinesPreference={{
      label: "Wrap lines",
      description: "Check to see all the text and wrap the lines",
    }}
    visibleContentPreference={{
      title: "Select visible content",
      options: visibleContentOptions,
    }}
  />
);
