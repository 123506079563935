import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Input from "@amzn/awsui-components-react/polaris/input";
import { Button } from "@amzn/awsui-components-react";
import { updateDoc } from "../../redux/actions/update-doc-action";
import { updateFile } from "../../redux/actions/update-file-action";
import { RENAME_TYPE } from "../../config/constants";
import styled from "styled-components";

const Div = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 4px;
`;

function Rename({
  doc_id,
  initial_value,
  setIsRename,
  type,
  setFileName,
  loadingStatus,
}) {
  let fileExt = initial_value.split(".").pop();
  let fileValue = initial_value.substr(0, initial_value.lastIndexOf("."));
  if (!initial_value.includes(".")) {
    fileExt = "";
    fileValue = initial_value;
  }
  const [renameValue, setValue] = useState(fileValue);

  useEffect(() => {
    setValue(fileValue);
  }, [fileValue]);
  const dispatch = useDispatch();

  if (fileExt !== "") {
    fileExt = "." + fileExt;
  }
  function onUpdateRequiredFieldPromise() {
    if (type === RENAME_TYPE.DOC) {
      dispatch(
        updateDoc(doc_id, {
          doc_name: renameValue + fileExt,
        })
      );
    } else {
      dispatch(
        updateFile(doc_id, {
          file_name: renameValue + fileExt,
        })
      );
    }
  }

  function onUpdateRequiredField() {
    onUpdateRequiredFieldPromise();
    setFileName(renameValue + fileExt);
    setValue("");
    setIsRename(false);
  }

  return (
    <div>
      <Input
        onChange={({ detail }) => setValue(detail.value)}
        value={renameValue}
      />
      <Div>
        <Button
          disabled={renameValue === ""}
          variant="primary"
          onClick={onUpdateRequiredField}
        >
          {" "}
          Save{" "}
        </Button>
        <Button onClick={() => setIsRename(false)}>Cancel </Button>
      </Div>
    </div>
  );
}

export default Rename;
