import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
//redux
//redux
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  getDocOverview,
  setNotifications,
} from "../../../redux/actions/doc-overview-action";
import { resetCreateFolder } from "../../../redux/actions/create-folder-action";
// sub components
import { AppLayout, Spinner, Flashbar } from "@amzn/awsui-components-react";
import Breadcrumb from "../../../components/common/breadcrumb/breadcrumb";
import FolderForm from "./folder-form";
//config, constants
import { documentOverviewData } from "../../../model/doc-overview";
import constants from "../../../config/constants";
import { HeaderStyle } from "../../../config/constants";
//css

// declare prop check
type Props = {
  dispatch: Dispatch<any>;
  history: any;
  docOverviewReducer: any;
  createFolderReducer: any;
} & RouteComponentProps<any>;

// declare state check
type State = {};

class CreateFolder extends Component<Props, State> {
  state: State = Object.freeze({});

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps: Props, prevstate: State) {
    if (this.props.match.params.folderId !== prevProps.match.params.folderId) {
      this.getData();
      this.props.dispatch(resetCreateFolder());
    }

    const { loadingStatus, responseData } = this.props.createFolderReducer;
    if (loadingStatus === constants.LOADING_SUCCESS) {
      this.onSuccess(responseData.name);
    }
    if (loadingStatus === constants.LOADING_FAIL) {
      this.onFailure();
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetCreateFolder());
  }

  getData = () => {
    const { folderId } = this.props.match.params;
    this.props.dispatch(getDocOverview(folderId, 0));
  };

  onSuccess = (folderName: string) => {
    // set notification
    const notifications = [
      {
        type: constants.STATUS_SUCCESS,
        content: (
          <span>
            Successfully created folder <strong>{folderName}</strong>
          </span>
        ),
      },
    ];
    const { folderId } = this.props.match.params;
    this.props.dispatch(setNotifications(notifications, folderId));
    // redirect to parent page
    this.redirect();
  };

  onFailure = () => {
    // set notification
    const notifications = [
      {
        type: constants.STATUS_ERROR,
        content: <span>Failed to create folder, please try again.</span>,
      },
    ];
    const { folderId } = this.props.match.params;
    this.props.dispatch(setNotifications(notifications, folderId));
    // redirect to parent page
    this.redirect();
  };

  redirect = () => {
    // redirect to parent page
    const { folderId } = this.props.match.params;
    const { docOverviewData }: { docOverviewData: documentOverviewData } =
      this.props.docOverviewReducer[folderId];
    const { path } = docOverviewData;
    this.props.history.push(path);
  };

  render() {
    const folderId = this.props.match.params.folderId;
    if (
      !this.props.docOverviewReducer[folderId] ||
      [constants.LOADING_LOAD, constants.LOADING_DEFAULT].includes(
        this.props.docOverviewReducer[folderId].loadingStatus
      )
    ) {
      return <Spinner size="large" />;
    }
    const { docOverviewData }: { docOverviewData: documentOverviewData } =
      this.props.docOverviewReducer[folderId];

    const { breadcrumb_data, is_sub_folder_allowed } = docOverviewData;

    if (!is_sub_folder_allowed) {
      return (
        <Flashbar
          items={[
            {
              type: "error",
              dismissible: false,
              content: "Invalid URL, please use a valid URL.",
            },
          ]}
          className="flashbar"
        />
      );
    }

    const { loadingStatus } = this.props.createFolderReducer;

    //------------------------------------------------Breadcrumb------------------------------------------------------//
    const breadcrumb = (
      <Breadcrumb
        breadcrumb_data={[
          ...breadcrumb_data,
          {
            doc_name: "Create Folder",
            path: `folders/${folderId}/create`,
          },
        ]}
        history={this.props.history}
      />
    );

    //--------------------------------------------------Header--------------------------------------------------------//
    const header = (
      <HeaderStyle variant="h1" description="use this page to create folders">
        Create Folder
      </HeaderStyle>
    );

    //-----------------------------------------------Main Content-----------------------------------------------------//
    const mainContent = (
      <FolderForm
        folderId={folderId}
        dispatch={this.props.dispatch}
        history={this.props.history}
        loadingStatus={loadingStatus}
      />
    );

    return (
      <AppLayout
        navigationHide
        toolsHide
        contentHeader={header}
        breadcrumbs={breadcrumb}
        content={mainContent}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    docOverviewReducer: state.docOverviewReducer,
    createFolderReducer: state.createFolderReducer,
  };
};

export default connect(mapStateToProps)(CreateFolder);
