import { Component } from "react";
//redux
import { connect } from "react-redux";
import { Dispatch } from "redux";
// sub components
import { AppLayout, Header } from "@amzn/awsui-components-react";
import ExpiringDocTable from "./table/table";
//config, constants
import { getExpiringDoc } from "../../redux/actions/expiring-doc-action";
import { documentOverviewData } from "../../model/doc-overview";
//css

// declare prop check
interface StateProps {
  expiringDocReducer: any;
}

type Props = {
  dispatch: Dispatch<any>;
  history: any;
} & StateProps;

// declare state check
type State = {};

class ExpiringDocuments extends Component<Props, State> {
  state: State = Object.freeze({});

  componentDidMount() {}

  getData = (startDate: string, endDate: string) => {
    this.props.dispatch(getExpiringDoc(startDate, endDate));
  };

  render() {
    const {
      expiringDocs: expiringDocsOneWeek,
      loadingStatus: loadingStatusOneWeek,
    }: {
      expiringDocs: Array<documentOverviewData>;
      loadingStatus: number;
    } = this.props.expiringDocReducer;

    //--------------------------------------------------Header--------------------------------------------------------//
    const header = (
      <Header variant="h1" className="pt-4">
        {"Expiring Documents"}
      </Header>
    );

    //-----------------------------------------------Main Content-----------------------------------------------------//
    const mainContent = (
      <ExpiringDocTable
        data={expiringDocsOneWeek}
        loadingStatus={loadingStatusOneWeek}
        getData={(startDate: string, endDate: string) =>
          this.getData(startDate, endDate)
        }
      />
    );

    return (
      <AppLayout
        navigationHide
        toolsHide
        contentHeader={header}
        content={mainContent}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    expiringDocReducer: state.expiringDocReducer,
  };
};

export default connect(mapStateToProps)(ExpiringDocuments);
