import { Component } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { Dispatch } from "redux";
import { AppLayout, Button } from "@amzn/awsui-components-react";
import DeleteForm from "./delete-form/delete-form";
import DeleteStatus from "./delete-status/delete-status";
import constants from "../../../config/constants";
import { deleteFileObject } from "../../../model/delete-file";
import { resetDeleteFiles } from "../../../redux/actions/delete-file-action";
import { deleteFileCall } from "../utils";
import { HeaderStyle } from "../../../config/constants";

// declare prop check
type Props = {
  dispatch: Dispatch<any>;
  history: any;
  repositoryOverviewReducer: any;
  deleteFileReducer: any;
} & RouteComponentProps<any>;

// declare state check
type State = {
  files: Array<deleteFileObject>;
  isFormSubmitted: boolean;
  inProgressFiles: number;
  successfulFiles: number;
  failedFiles: number;
};

class DeleteFile extends Component<Props> {
  state: State = Object.freeze({
    files: [],
    isFormSubmitted: false,
    inProgressFiles: 0,
    successfulFiles: 0,
    failedFiles: 0,
  });

  componentDidMount() {}

  componentWillUnmount() {
    this.props.dispatch(resetDeleteFiles());
  }

  onDelete = (filesToDelete: Array<deleteFileObject>) => {
    this.setState({
      files: filesToDelete,
      isFormSubmitted: true,
      inProgressFiles: filesToDelete.length,
    });

    filesToDelete.forEach((file: deleteFileObject, index) => {
      deleteFileCall(file).then((response) => {
        let { files, inProgressFiles, successfulFiles, failedFiles } =
          this.state;
        files[index].deleteStatus = response.status;
        files[index].deleteMessage = response.message;
        inProgressFiles--;
        if (response.status === constants.STATUS_SUCCESS) {
          successfulFiles++;
        } else {
          failedFiles++;
        }
        this.setState({
          files,
          inProgressFiles,
          successfulFiles,
          failedFiles,
        });
      });
    });
  };

  render() {
    const fileId = this.props.match.params.fileId;

    const { selectedFiles } = this.props.deleteFileReducer;

    //--------------------------------------------------Header--------------------------------------------------------//
    const header = this.state.isFormSubmitted ? (
      <HeaderStyle
        variant="h1"
        actions={
          <Button
            variant="primary"
            onClick={() => this.props.history.push(`/repository`)}
          >
            Close
          </Button>
        }
      >
        Delete Files: Status
      </HeaderStyle>
    ) : (
      <HeaderStyle variant="h1">Delete Files</HeaderStyle>
    );

    //-----------------------------------------------Main Content-----------------------------------------------------//
    const deleteForm = (
      <DeleteForm
        data={selectedFiles}
        fileId={fileId}
        onDelete={this.onDelete}
        history={this.props.history}
      />
    );

    const deletePage = (
      <DeleteStatus
        files={this.state.files}
        inProgressFiles={this.state.inProgressFiles}
        successfulFiles={this.state.successfulFiles}
        failedFiles={this.state.failedFiles}
      />
    );
    const mainContent = this.state.isFormSubmitted ? deletePage : deleteForm;

    return (
      <AppLayout
        navigationHide
        toolsHide
        contentHeader={header}
        content={mainContent}
      />
    );
  }
}
const mapStateToProps = (state) => {
  return {
    repositoryOverviewReducer: state.repositoryOverviewReducer,
    deleteFileReducer: state.deleteFileReducer,
  };
};
export default connect(mapStateToProps)(DeleteFile);
