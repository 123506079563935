import fileOverviewType from "../types/file-overview-type";
import constants, { HttpCodes, NOT_AUTHORIZED_NOTIFICATION } from "../../config/constants";
import { fileOverviewData, notification } from "../../model/file-overview";

export interface state {
  notifications: notification[];
}

const defaultFileOverviewData: fileOverviewData = {
  file_id: "-1",
  file_name: "",
  description: "",
  type: constants.FILE,
  file_type: "",
  size: 0,
  associations_count: 0,
  s3_link: "",
  uploaded_by: "",
  upload_time: "",
  expiry_date: "",
  tags: [],
  versions: [],
  associations: [],
  unAuthorized: false,
};

const initialState: state = {
  notifications: [],
};

const fileOverviewReducer = (state: state = initialState, action: any) => {
  let fileId = action.fileId;
  switch (action.type) {
    case fileOverviewType.GET_FILE_OVERVIEW: {
      state[fileId] = {
        ...state[fileId],
        loadingStatus: constants.LOADING_LOAD,
        message: "",
        fileOverviewData: defaultFileOverviewData,
      };

      return {
        ...state,
      };
    }

    case fileOverviewType.GET_FILE_OVERVIEW_COMMIT: {
      state[fileId] = {
        ...state[fileId],
        loadingStatus: constants.LOADING_SUCCESS,
        message: action.payload.message,
        fileOverviewData: action.payload.data,
      };

      return {
        ...state,
      };
    }

    case fileOverviewType.GET_FILE_OVERVIEW_ROLLBACK: {
      const notifications: notification[] = [
        ...(state.notifications
          ? state.notifications
          : []),
      ];
      if(action.statusCode === HttpCodes.FORBIDDEN)
        notifications.push(NOT_AUTHORIZED_NOTIFICATION)

      state[fileId] = {
        ...state[fileId],
        loadingStatus: constants.LOADING_FAIL,
        message: action.payload.message,
        notifications,
        unAuthorized: action.statusCode === HttpCodes.FORBIDDEN,
      };

      return {
        ...state,
      };
    }

    case fileOverviewType.SET_FILE_NOTIFICATION: {
      const notifications: notification[] = [
        ...(state[fileId] && state[fileId].notifications
          ? state[fileId].notifications
          : []),
        ...action.notifications,
      ];

      state[fileId] = {
        ...state[fileId],
        notifications,
      };

      return {
        ...state,
      };
    }

    case fileOverviewType.DELETE_FILE_NOTIFICATION: {
      state[fileId].notifications.splice(action.index, 1);

      return {
        ...state,
      };
    }

    case fileOverviewType.RESET_FILE_OVERVIEW: {
      delete state[fileId];

      return {
        ...state,
      };
    }

    default:
      return state;
  }
};

export default fileOverviewReducer;
