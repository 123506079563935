import { config } from "../../config/config.js";
import constants from "../../config/constants";
import endpoints from "../../config/endpoints";
import { postData, Response } from "../../utils/cognito-fetch";

export async function getShareURLCall(docs: any[]): Promise<any> {
  const result: any = {
    status: constants.STATUS_ERROR,
    message: "",
    data: null,
  };
  try {
    const url: string = config.BASE_URL + endpoints.publicURLEndpoint();
    const data = {
      docs: docs,
    };

    const response: Response = await postData(url, data);
    if (!response.ok) {
      throw response.json ? response.json : response.status_text;
    }
    result.status = constants.STATUS_SUCCESS;
    result.message = "";
    result.data =
      response.json && response.json["data"] ? response.json["data"] : null;
  } catch (error: any) {
    result.status = constants.STATUS_ERROR;
    result.message = error["message"];
  } finally {
    return result;
  }
}

export async function shareDocCall(
  emailList: string[],
  docsToShare: any[],
  message: string
): Promise<any> {
  const result: any = {
    status: constants.STATUS_ERROR,
    message: "",
    data: null,
  };
  try {
    const url: string = config.BASE_URL + endpoints.shareDocEndpoint();
    const data = {
      email_list: emailList,
      docs: docsToShare,
      message: message,
    };

    const response: Response = await postData(url, data);
    if (!response.ok) {
      throw response.json ? response.json : response.status_text;
    }
    result.status = constants.STATUS_SUCCESS;
    result.message = "";
    result.data =
      response.json && response.json["data"] ? response.json["data"] : null;
  } catch (error: any) {
    result.status = constants.STATUS_ERROR;
    result.message = error["message"];
  } finally {
    return result;
  }
}
