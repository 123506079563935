export const getProjectConfigType = {
  GET_PROJECT_CONFIG: "GET_PROJECT_CONFIG",
  GET_PROJECT_CONFIG_COMMIT: "GET_PROJECT_CONFIG_COMMIT",
  GET_PROJECT_CONFIG_ROLLBACK: "GET_PROJECT_CONFIG_ROLLBACK",
};

export const createProjectType = {
  CREATE_PROJECT: "CREATE_PROJECT",
  CREATE_PROJECT_COMMIT: "CREATE_PROJECT_COMMIT",
  CREATE_PROJECT_ROLLBACK: "CREATE_PROJECT_ROLLBACK",

  RESET_PROJECT: "RESET_PROJECT",
};
