import searchType from "../types/search-type";
import constants from "../../config/constants";
import { documentOverviewData } from "../../model/doc-overview";
import { formatSearchResults } from "../../utils/general-utils";

export interface state {
  loadingStatus: number;
  message: string;
  searchResults: Array<documentOverviewData>;
}

const initialState: state = {
  loadingStatus: constants.LOADING_DEFAULT,
  message: "",
  searchResults: [],
};

const searchReducer = (state: state = initialState, action: any) => {
  switch (action.type) {
    case searchType.GET_SEARCH_RESULTS:
      return {
        ...state,
        loadingStatus: constants.LOADING_LOAD,
      };
    case searchType.GET_SEARCH_RESULTS_COMMIT:
      return {
        ...state,
        loadingStatus: constants.LOADING_SUCCESS,
        message: action.payload.message,
        searchResults: formatSearchResults(action.payload.data.hits.hits),
      };
    case searchType.GET_SEARCH_RESULTS_ROLLBACK:
      return {
        ...state,
        loadingStatus: constants.LOADING_FAIL,
        message: action.payload.message,
      };
    default:
      return state;
  }
};

export default searchReducer;
