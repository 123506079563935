import createFolderType from "../types/create-folder-type";
import constants, { HttpCodes, NOT_AUTHORIZED_NOTIFICATION } from "../../config/constants";
import { notification } from "../../model/doc-overview";

interface state {
  loadingStatus: number;
  message: string;
  responseData: any;
  notifications: notification[];
}

const initialState: state = {
  loadingStatus: constants.LOADING_DEFAULT,
  message: "",
  responseData: {},
  notifications: [],
};

const createFolderReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case createFolderType.CREATE_FOLDER:
      return {
        ...state,
        loadingStatus: constants.LOADING_LOAD,
      };
    case createFolderType.CREATE_FOLDER_COMMIT:
      return {
        ...state,
        loadingStatus: constants.LOADING_SUCCESS,
        message: action.payload.message,
        responseData: action.payload.data,
      };
    case createFolderType.CREATE_FOLDER_ROLLBACK:
      const notifications: notification[] = [
        ...(state.notifications
          ? state.notifications
          : []),
      ];

      if(action.statusCode === HttpCodes.FORBIDDEN)
        notifications.push(NOT_AUTHORIZED_NOTIFICATION)
      return {
        ...state,
        loadingStatus: constants.LOADING_FAIL,
        message: action.payload.message,
        responseData: action.payload.data,
        notifications
      };
    case createFolderType.RESET_CREATE_FOLDER:
      return {
        ...state,
        loadingStatus: constants.LOADING_DEFAULT,
        message: "",
        responseData: {},
      };
    default:
      return state;
  }
};

export default createFolderReducer;
