import base_config from "./base_config";

const prod_config = {
  ...base_config,
  STAGE: "prod",

  // Amplify config for cognito
  AMPLIFY_CONFIG: {
    aws_project_region: base_config.REGION,
    aws_cognito_region: base_config.REGION,
    aws_user_pools_id: "us-west-2_7jaT8GnzE",
    aws_user_pools_web_client_id: "7h5ec3if4vohn56bdh9ol04p8v",
    oauth: {
      domain: "wise-general-cog-prod.auth.us-west-2.amazoncognito.com",
      scope: base_config.TOKEN_SCOPES_ARRAY,
      redirectSignIn: base_config.CALL_BACK_URL,
      redirectSignOut: base_config.CALL_BACK_URL,
      responseType: base_config.OAUTH_RESPONSE_TYPE,
    },
  },
  // Base url
  BASE_URL: "https://d2nmjzo2hl.execute-api.us-west-2.amazonaws.com/prod/v1",
  COMPLIANCE_BASE_URL:
    "https://7g3805yjs8.execute-api.us-west-2.amazonaws.com/prod/v1",

  BASE_AUTHZ_URL: "https://d2nmjzo2hl.execute-api.us-west-2.amazonaws.com/prod",
  // Upload service base url
  UPLOAD_BASE_URL:
    "https://oyyu1bze98.execute-api.us-west-2.amazonaws.com/prod",
  WEBSITE_BASE_URL: "",
  ANALYTICS_DASHBOARD: "",
  // Configuration for Cloudwatch RUM
  rum_config: {
    sessionSampleRate: 1,
    guestRoleArn: "arn:aws:iam::393266312988:role/RUM-Monitor-us-west-2-393266312988-7174157947861-Unauth",
    identityPoolId: "us-west-2:9ca58e95-fc45-4cf8-8a50-86cd2ef4ab9e",
    endpoint: "https://dataplane.rum.us-west-2.amazonaws.com",
    telemetries: ["performance", "errors", ["http", { recordAllRequests: true }]],
    allowCookies: true,
    enableXRay: false,
    disableAutoPageView: true
  },
  APPLICATION_ID: '555b5fb9-7439-4a0a-ae56-a60964d9b870',
  APPLICATION_VERSION: '1.0.0',
  APPLICATION_REGION: 'us-west-2',
};

export default prod_config;
