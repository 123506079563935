import createFolderType from "../types/create-folder-type";
import { config } from "../../config/config";
import { METRIC_INFO } from "../../config/constants";
import endpoints from "../../config/endpoints";
import { createFolderRequest } from "../../model/http-json";

export const createFolder = (
  values: createFolderRequest,
  parentDocId: number,
  folder: number
): any => ({
  type: createFolderType.CREATE_FOLDER,
  http: {
    cognito: {
      effect: {
        url:
          config.BASE_URL +
          endpoints.docEndpont(parentDocId) +
          `?folder=${folder}`,
        method: "POST",
        json: values,
      },
      commit: {
        type: createFolderType.CREATE_FOLDER_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.FOLDER,
        },
      },
      rollback: {
        type: createFolderType.CREATE_FOLDER_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.FOLDER,
        },
      },
    },
  },
});

export const resetCreateFolder = (): any => ({
  type: createFolderType.RESET_CREATE_FOLDER,
});
