import {
  CollectionPreferences,
  Popover,
  StatusIndicator,
  Button,
  PropertyFilterProps,
} from "@amzn/awsui-components-react";
import { StatusLabel } from "../../../../components/common/labels";
import copy from "copy-to-clipboard";
import constants from "../../../../config/constants";

// define columns
export const COLUMN_DEFINITIONS = [
  {
    id: "doc_name",
    sortingField: "doc_name",
    header: "Name",
    cell: (item) => item.doc_name,
    visible: true,
    minWidth: 150,
  },
  {
    id: "share_url",
    sortingField: "share_url",
    header: "Shareable URL",
    cell: (item) =>
      item.status === constants.STATUS_SUCCESS ? (
        <span
          style={{
            display: "flex",
          }}
        >
          <Popover
            size="small"
            position="top"
            triggerType="custom"
            dismissButton={false}
            content={
              <StatusIndicator type="success"> URL copied</StatusIndicator>
            }
          >
            <Button
              iconName="copy"
              variant="inline-icon"
              onClick={() => {
                copy(item.share_url);
              }}
            />
          </Popover>
          {item.share_url}
        </span>
      ) : (
        <StatusLabel status={item.status} />
      ),
    visible: true,
    minWidth: 500,
  },
  {
    id: "error",
    sortingField: "error",
    header: "Error",
    cell: (item) => (item.error ? item.error : "__"),
    visible: true,
    minWidth: 100,
  },
];

// define visible columns
export const VISIBLE_CONTENT_OPTIONS = [
  {
    label: "Properties",
    options: [
      { id: "doc_name", label: "Name", visible: true, editable: false },
      {
        id: "share_url",
        label: "Shareable URL",
        visible: true,
        editable: false,
      },
      { id: "error", label: "Error", visible: true, editable: false },
    ],
  },
];

// define filtering properties
export const FILTERING_PROPERTIES: PropertyFilterProps.FilteringProperty[] = [
  {
    propertyLabel: "Name",
    key: "doc_name",
    groupValuesLabel: "Name",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Shareable URL",
    key: "share_url",
    groupValuesLabel: "Shareable URL",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Error",
    key: "error",
    groupValuesLabel: "Error",
    operators: [":", "!:", "=", "!="],
  },
];

// define page size options
export const PAGE_SIZE_OPTIONS = [
  { value: 5, label: "50 items" },
  { value: 10, label: "100 items" },
  { value: 30, label: "100 items" },
  { value: 100, label: "100 items" },
];

// define default preferences
export const DEFAULT_PREFERENCES = {
  pageSize: 5,
  visibleContent: COLUMN_DEFINITIONS.filter((column) => column.visible).map(
    (column) => column.id
  ),
  wrapLines: false,
};

export const Preferences = ({
  preferences,
  setPreferences,
  disabled,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
  visibleContentOptions = VISIBLE_CONTENT_OPTIONS,
}) => (
  <CollectionPreferences
    title="Preferences"
    confirmLabel="Confirm"
    cancelLabel="Cancel"
    disabled={disabled}
    preferences={preferences}
    onConfirm={({ detail }) => setPreferences(detail)}
    pageSizePreference={{
      title: "Page size",
      options: pageSizeOptions,
    }}
    wrapLinesPreference={{
      label: "Wrap lines",
      description: "Check to see all the text and wrap the lines",
    }}
    visibleContentPreference={{
      title: "Select visible content",
      options: visibleContentOptions,
    }}
  />
);
