import {
  CollectionPreferences,
  PropertyFilterProps,
} from "@amzn/awsui-components-react";
import {
  changeToShortDateFormat,
  changeToShortDateFormatAndAddColor,
} from "../../../utils/date-utils";
import { formatBytes } from "../../../utils/general-utils";
import { NavLink } from "react-router-dom";
import { ExpiryDate } from "../../common-container/expiry-date";
import { EXPIRY_DATE_COLOR_CODE } from "../../../config/constants";

// define columns
export const COLUMN_DEFINITIONS = [
  {
    id: "file_id",
    sortingField: "file_id",
    header: "File id",
    cell: (item) => (
      <NavLink to={`/files/${item.file_id}/overview`}>{item.file_id}</NavLink>
    ),
    visible: true,
    minWidth: 150,
  },
  {
    id: "file_name",
    sortingField: "file_name",
    header: "File Name",
    cell: (item) => item.file_name,
    visible: true,
    minWidth: 150,
  },
  {
    id: "description",
    sortingField: "description",
    header: "Description",
    cell: (item) => item.description,
    visible: true,
    minWidth: 150,
  },
  {
    id: "expiry_date",
    sortingField: "expiry_date",
    header: "Expiry Status",
    cell: (item) => (
      <ExpiryDate
        informatiomText={
          item.expiry_date
            ? changeToShortDateFormatAndAddColor(item.expiry_date).Date
            : "No expiry date available"
        }
        color={
          item.expiry_date
            ? changeToShortDateFormatAndAddColor(item.expiry_date).color
            : EXPIRY_DATE_COLOR_CODE.GREEN_COLOR_HEX
        }
      />
    ),
    visible: true,
    minWidth: 150,
  },
  {
    id: "type",
    sortingField: "type",
    header: "Type",
    cell: (item) => item.type,
    visible: false,
    minWidth: 150,
  },
  {
    id: "file_type",
    sortingField: "file_type",
    header: "File Type",
    cell: (item) => item.file_type,
    visible: false,
    minWidth: 150,
  },
  {
    id: "associations_count",
    sortingField: "associations_count",
    header: "Associations",
    cell: (item) => item.associations_count,
    visible: true,
    minWidth: 150,
  },
  {
    id: "tags",
    sortingField: "tags",
    header: "Tags",
    cell: (item) => {
      if (item.tags == null) return "---";
      else return item.tags.join(", ");
    },
    visible: true,
    minWidth: 150,
  },
  {
    id: "uploaded_by",
    sortingField: "uploaded_by",
    header: "Uploaded By",
    cell: (item) => item.uploaded_by,
    visible: false,
    minWidth: 150,
  },
  {
    id: "upload_time",
    sortingField: "upload_time",
    header: "Upload Time",
    cell: (item) => changeToShortDateFormat(item.upload_time),
    visible: true,
    minWidth: 150,
  },
  {
    id: "size",
    sortingField: "size",
    header: "Size",
    cell: (item) => formatBytes(item.size),
    visible: false,
    minWidth: 150,
  },
];

// define visible columns
export const VISIBLE_CONTENT_OPTIONS = [
  {
    label: "Properties",
    options: [
      { id: "file_id", label: "file_id", visible: true, editable: false },
      { id: "file_name", label: "file_name", visible: true, editable: false },
      {
        id: "description",
        label: "description",
        visible: true,
        editable: false,
      },
      {
        id: "expiry_date",
        label: "expiry_date",
        visible: true,
        editable: false,
      },
      {
        id: "type",
        label: "type",
        visible: true,
        editable: true,
      },
      {
        id: "file_type",
        label: "file_type",
        visible: true,
        editable: true,
      },
      {
        id: "size",
        label: "size",
        visible: true,
        editable: true,
      },
      {
        id: "associations_count",
        label: "associations_count",
        visible: true,
        editable: false,
      },
      { id: "tags", label: "tags", visible: true, editable: false },
      {
        id: "uploaded_by",
        label: "uploaded_by",
        visible: true,
        editable: true,
      },
      {
        id: "upload_time",
        label: "upload_time",
        visible: false,
        editable: true,
      },
    ],
  },
];

// define filtering options
export const FILTERING_PROPERTIES: PropertyFilterProps.FilteringProperty[] = [
  {
    propertyLabel: "File id",
    key: "file_id",
    groupValuesLabel: "File id",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "File Name",
    key: "file_name",
    groupValuesLabel: "File Name",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Description",
    key: "description",
    groupValuesLabel: "File id",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Expiry Status",
    key: "expiry_date",
    groupValuesLabel: "Expiry Status",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "File Type",
    key: "file_type",
    groupValuesLabel: "File Type",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Tags",
    key: "tags",
    groupValuesLabel: "Tags",
    operators: [":"],
  },
  {
    propertyLabel: "Uploaded By",
    key: "uploaded_by",
    groupValuesLabel: "Uploaded By",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Upload Time",
    key: "uploaded_time",
    groupValuesLabel: "Upload Time",
    operators: [":", "!:", "=", "!="],
  },
];

// define page size options
export const PAGE_SIZE_OPTIONS = [
  { value: 50, label: "50 items" },
  { value: 100, label: "100 items" },
  { value: 300, label: "300 items" },
];

// define default preferences
export const DEFAULT_PREFERENCES = {
  pageSize: 300,
  visibleContent: COLUMN_DEFINITIONS.filter((column) => column.visible).map(
    (column) => column.id
  ),
  wrapLines: true,
};

export const Preferences = ({
  preferences,
  setPreferences,
  disabled,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
  visibleContentOptions = VISIBLE_CONTENT_OPTIONS,
}) => (
  <CollectionPreferences
    title="Preferences"
    confirmLabel="Confirm"
    cancelLabel="Cancel"
    disabled={disabled}
    preferences={preferences}
    onConfirm={({ detail }) => setPreferences(detail)}
    pageSizePreference={{
      title: "Page size",
      options: pageSizeOptions,
    }}
    wrapLinesPreference={{
      label: "Wrap lines",
      description: "Check to see all the text and wrap the lines",
    }}
    visibleContentPreference={{
      title: "Select visible content",
      options: visibleContentOptions,
    }}
  />
);

// define default sorting
export const DEFAULT_SORTING_COLUMN = {
  id: "expiry_date",
  sortingField: "expiry_date",
};
export const DEFAULT_SORTING_DESCENDING = false;
