import { useState } from "react";
import {
  Button,
  Modal,
  Input,
  Box,
  SpaceBetween,
  // Select,
  FormField,
  // SelectProps,
} from "@amzn/awsui-components-react";

const AddItemToChecklist = ({
  showModal,
  setShowModal,
  modalType,
  updateSelection,
  checkAlreadySelected,
  checkExistsInDropdown,
}) => {
  const [value, setValue] = useState("");
  // const [selectedOption, setSelectedOption] = useState<SelectProps.Option>({});
  const [valueError, setValueError] = useState("");
  // const [selectError, setSelectError] = useState("");

  const validate = () => {
    let isValid = true;
    if (!value) {
      setValueError("Required");
      isValid = false;
    }
    // if (!Object.keys(selectedOption).length) {
    //   setSelectError("Required");
    //   isValid = false;
    // }
    const item = {
      doc_name: value,
      // doc_class: selectedOption.value,
    };
    if (checkAlreadySelected(item)) {
      setValueError("Document already selected/created");
      isValid = false;
    } else if (checkExistsInDropdown(item)) {
      setValueError("Document exists in dropdown, please select from there");
      isValid = false;
    }
    return isValid;
  };

  const onAdd = () => {
    if (!validate()) return;
    const item = {
      doc_name: value,
      // doc_class: selectedOption.value,
    };
    updateSelection(item);
    reset();
    setShowModal(false);
  };

  const reset = () => {
    // setSelectedOption({});
    setValue("");
    // setSelectError("");
    setValueError("");
  };

  return (
    <Modal
      onDismiss={() => {
        reset();
        setShowModal(false);
      }}
      visible={showModal}
      closeAriaLabel="Close modal"
      header={`Add New ${modalType}`}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              variant="link"
              onClick={() => {
                reset();
                setShowModal(false);
              }}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={onAdd}>
              Add
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <SpaceBetween direction="vertical" size="m">
        <FormField
          label="Document Name"
          errorText={valueError}
          description="If you enter document name as `XYZ`, it'll be saved as ProjectName_ModelNumber_XYZ"
        >
          <Input
            placeholder={`Input here`}
            value={value}
            onChange={({ detail }) => {
              setValue(detail.value);
              setValueError("");
            }}
          />
        </FormField>
        {/* <FormField label="Document Class" errorText={selectError}>
          <Select
            placeholder="select"
            // selectedOption={selectedOption}
            onChange={({ detail }) => {
              setSelectedOption(detail.selectedOption);
              setSelectError("");
            }}
            options={[
              { label: "Submission Document", value: "submission" },
              { label: "Final Document", value: "final" },
            ]}
          />
        </FormField> */}
      </SpaceBetween>
    </Modal>
  );
};

export default AddItemToChecklist;
