import {
  CollectionPreferences,
  PropertyFilterProps,
} from "@amzn/awsui-components-react";
import { changeToShortDateFormat } from "../../../../utils/date-utils";
import { formatBytes, formatTagsView } from "../../../../utils/general-utils";

// define columns
export const COLUMN_DEFINITIONS = [
  {
    id: "name",
    sortingField: "name",
    header: "File Name",
    cell: (item) => item.file.name,
    visible: true,
    minWidth: 250,
  },
  {
    id: "description",
    sortingField: "description",
    header: "Description",
    cell: (item) => item.description,
    visible: true,
    minWidth: 100,
  },
  {
    id: "expiryDate",
    sortingField: "expiryDate",
    header: "Expiry Date",
    cell: (item) =>
      item.expiryDate ? changeToShortDateFormat(item.expiryDate) : "__",
    visible: true,
    minWidth: 150,
  },
  {
    id: "tags",
    sortingField: "tags",
    header: "Tags",
    cell: (item) => formatTagsView(item?.tags ?? []),
    visible: true,
    minWidth: 150,
  },
  {
    id: "size",
    sortingField: "size",
    header: "Size",
    cell: (item) => formatBytes(item.file.size),
    visible: true,
    minWidth: 100,
  },
];

// define visible columns
export const VISIBLE_CONTENT_OPTIONS = [
  {
    label: "Properties",
    options: [
      {
        id: "name",
        label: "File Name",
        visible: true,
        editable: false,
      },
      {
        id: "description",
        label: "Description",
        visible: true,
        editable: false,
      },
      {
        id: "expiryDate",
        label: "Expiry Date",
        visible: true,
        editable: false,
      },
      { id: "size", label: "Size", visible: true, editable: false },
      { id: "tags", label: "Tags", visible: true, editable: false },
    ],
  },
];

// define filtering properties
export const FILTERING_PROPERTIES: PropertyFilterProps.FilteringProperty[] = [
  {
    propertyLabel: "File Name",
    key: "name",
    groupValuesLabel: "File Name",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Description",
    key: "description",
    groupValuesLabel: "Description",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Expiry Date",
    key: "expiryDate",
    groupValuesLabel: "Expiry Date",
    operators: [":", "!:", "=", "!="],
  },
  // commenting because default filtering doesn't work on nested fields
  // {
  //   propertyLabel: "Size",
  //   key: "size",
  //   groupValuesLabel: "Size",
  //   operators: [":", "!:", "=", "!="],
  // },
];

// define page size options
export const PAGE_SIZE_OPTIONS = [
  { value: 50, label: "50 items" },
  { value: 100, label: "100 items" },
];

// define default preferences
export const DEFAULT_PREFERENCES = {
  pageSize: 100,
  visibleContent: COLUMN_DEFINITIONS.filter((column) => column.visible).map(
    (column) => column.id
  ),
  wrapLines: false,
};

export const Preferences = ({
  preferences,
  setPreferences,
  disabled,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
  visibleContentOptions = VISIBLE_CONTENT_OPTIONS,
}) => (
  <CollectionPreferences
    title="Preferences"
    confirmLabel="Confirm"
    cancelLabel="Cancel"
    disabled={disabled}
    preferences={preferences}
    onConfirm={({ detail }) => setPreferences(detail)}
    pageSizePreference={{
      title: "Page size",
      options: pageSizeOptions,
    }}
    wrapLinesPreference={{
      label: "Wrap lines",
      description: "Check to see all the text and wrap the lines",
    }}
    visibleContentPreference={{
      title: "Select visible content",
      options: visibleContentOptions,
    }}
  />
);
