import { useState } from "react";
// sub components
import {
  Table,
  Header,
  Pagination,
  PropertyFilter,
  Button,
  SpaceBetween,
  Modal,
} from "@amzn/awsui-components-react";
import {
  TableEmptyState,
  TableNoMatchState,
} from "../../../components/polaris/table/common-components";
import { useCollection } from "@amzn/awsui-collection-hooks";
//config, constants
import {
  COLUMN_DEFINITIONS,
  DEFAULT_PREFERENCES,
  FILTERING_PROPERTIES,
  Preferences,
} from "./table-config";
import {
  paginationLabels,
  PROPERTY_FILTERING_I18N_CONSTANTS,
} from "../../../components/polaris/table/labels";
import { getFilterCounterText } from "../../../components/polaris/table/table-counter-strings";
import constants, { RENAME_TYPE } from "../../../config/constants";
import { setNotifications } from "../../../redux/actions/repository-overview-action";
import { setDeleteFiles } from "../../../redux/actions/delete-file-action";
import { upcomingFeature } from "../../../components/common/notification/common-notification";
import { DOCUMENT_ACTIONS } from "../../../config/constants";
import { fileDownloadS3Call } from "../../../utils/file-download";
import { FILE_DOWNLOAD_FAIL_NOTIFICATION } from "../../../config/constants";
import { useSelector } from "react-redux";
import Rename from "../../common-container/rename";
import { username } from "../../../cognito-auth/session";

//css

const RepositoryTable = ({
  data,
  loadingStatus,
  getData,
  history,
  dispatch,
}) => {
  // selected items in the table
  const [selectedItems, setSelectedItems] = useState<any | never>([]);
  const [showModal, setShowModal] = useState(false);
  const [fileData, setFileData] = useState({
    fileId: "",
    fileName: "",
  });
  // redux
  const { updateFileReducer } = useSelector((state: any) => ({
    updateFileReducer: state.updateFileReducer,
  }));

  //default table preferences
  const [preferences, setPreferences] = useState(
    DEFAULT_PREFERENCES(
      updateFileReducer.loadingStatus,
      setShowModal,
      fileData,
      setFileData
    )
  );

  // file download status
  const [fileDownloadStatus, setFileDownloadStatus] = useState(
    constants.LOADING_DEFAULT
  );

  // using polaris useCollection hook to define table props
  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    propertyFilterProps,
    paginationProps,
  } = useCollection(data, {
    propertyFiltering: {
      filteringProperties: FILTERING_PROPERTIES,
      empty: <TableEmptyState resourceName={constants.FILE} />,
      noMatch: (
        <TableNoMatchState
          onClearFilter={() =>
            actions.setPropertyFiltering({ tokens: [], operation: "and" })
          }
        />
      ),
      defaultQuery: {tokens: [{propertyKey: "uploaded_by", value: username, operator: "="}], operation: "and"}
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
  });

  // table loading status
  const loading = loadingStatus === constants.LOADING_LOAD;

  // TODO: udpate this once the actions are ready
  const onShareButtonClick = ({ detail }) => {
    dispatch(setNotifications([upcomingFeature]));
    // TODO: update this when share functionality is ready
  };

  const onDeleteButtonClick = () => {
    let isAssociationCountZero = false;
    if (selectedItems.length > 0) {
      for (let item of selectedItems) {
        if (item.associations_count === 0) isAssociationCountZero = true;
      }
    }

    if (!isAssociationCountZero) {
      dispatch(
        setNotifications([
          {
            type: constants.STATUS_ERROR,
            content: (
              <span>
                Can not delete file(s) as one or more selected files has{" "}
                <strong>documents associated</strong> with it.
              </span>
            ),
          },
        ])
      );
    } else {
      dispatch(setDeleteFiles(selectedItems));
      const fileId = selectedItems[0].file_id;
      history.push(`/files/${fileId}/delete`);
    }
  };

  const onDownloadButtonClick = () => {
    setFileDownloadStatus(constants.LOADING_LOAD);
    const fileId = selectedItems[0].file_id;

    fileDownloadS3Call(fileId).then((response) => {
      if (response.status === constants.STATUS_SUCCESS) {
        window.location.href = response.s3_url;
        setFileDownloadStatus(constants.LOADING_SUCCESS);
      } else {
        setFileDownloadStatus(constants.LOADING_FAIL);
        // set notification
        dispatch(setNotifications([FILE_DOWNLOAD_FAIL_NOTIFICATION]));
      }
    });
  };

  return (
    <div>
      <Table
        {...collectionProps}
        columnDefinitions={COLUMN_DEFINITIONS(
          updateFileReducer.loadingStatus,
          setShowModal,
          fileData,
          setFileData
        )}
        visibleColumns={preferences.visibleContent}
        items={items}
        selectionType="single"
        loadingText="Loading resources"
        loading={loading}
        resizableColumns={true}
        wrapLines={preferences.wrapLines}
        header={
          <Header
            counter={
              selectedItems.length
                ? `(${selectedItems.length}/${data.length})`
                : `(${data.length})`
            }
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  variant="primary"
                  disabled={selectedItems.length === 0}
                  iconName="download"
                  loading={fileDownloadStatus === constants.LOADING_LOAD}
                  onClick={onDownloadButtonClick}
                >
                  Download
                </Button>

                <Button
                  onClick={onDeleteButtonClick}
                  disabled={selectedItems.length === 0}
                >
                  Delete
                </Button>
                <Button
                  onClick={onShareButtonClick}
                  disabled={!selectedItems.length}
                >
                  {DOCUMENT_ACTIONS.SHARE.TEXT}
                </Button>

                <Button
                  iconName="upload"
                  variant="primary"
                  onClick={() => history.push(`/repository/upload`)}
                >
                  Upload
                </Button>
              </SpaceBetween>
            }
          >
            Files
          </Header>
        }
        filter={
          <PropertyFilter
            i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
            {...propertyFilterProps}
            countText={getFilterCounterText(filteredItemsCount)}
          />
        }
        pagination={
          <Pagination {...paginationProps} ariaLabels={paginationLabels} />
        }
        preferences={
          <Preferences
            preferences={preferences}
            setPreferences={setPreferences}
            disabled={false}
          />
        }
        onSelectionChange={({ detail }) => {
          return setSelectedItems(detail.selectedItems);
        }}
        selectedItems={selectedItems}
      />
      <Modal
        visible={showModal}
        onDismiss={() => setShowModal(false)}
        header="Rename File"
      >
        <Rename
          doc_id={fileData.fileId}
          initial_value={fileData.fileName}
          setIsRename={(val) => {
            setShowModal(val);
          }}
          setFileName={(val) => {
            setFileData({ ...fileData, fileName: val });
          }}
          type={RENAME_TYPE.REPOSITORY}
          loadingStatus={loadingStatus}
        />
      </Modal>
    </div>
  );
};

export default RepositoryTable;
