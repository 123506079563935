import { updateDocType } from "../types/update-doc-type";
import { config } from "../../config/config";
import { METRIC_INFO } from "../../config/constants";
import endpoints from "../../config/endpoints";
import { updateDocRequest } from "../../model/http-json";

export const updateDoc = (doc_id: number, values: updateDocRequest): any => ({
  type: updateDocType.UPDATE_DOC,
  http: {
    cognito: {
      effect: {
        url: config.BASE_URL + endpoints.updateDocEndpoint(doc_id),
        method: "PUT",
        json: values,
      },
      commit: {
        type: updateDocType.UPDATE_DOC_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.DOC,
        },
      },
      rollback: {
        type: updateDocType.UPDATE_DOC_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.DOC,
        },
      },
    },
  },
});

export const resetUpdateDoc = (): any => ({
  type: updateDocType.RESET_UPDATE_DOC,
});

export const updateDocDissmissNotification = (index): any => ({
  type: updateDocType.DELETE_NOTIFICATION,
  index: index
});
