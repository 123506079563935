import { combineReducers } from "redux";
import authzReducer from "./reducers/authz-reducer";
import createCategoryReducer from "./reducers/create-category-reducer";
import docOverviewReducer from "./reducers/doc-overview-reducer";
import createFolderReducer from "./reducers/create-folder-reducer";
import deleteDocReducer from "./reducers/delete-doc-reducer";
import shareDocReducer from "./reducers/share-doc-reducer";
import trashReducer from "./reducers/trash-reducer";
import searchReducer from "./reducers/search-reducer";
import expiringDocReducer from "./reducers/expiring-doc-reducer";
import { updateProjectReducer } from "./reducers/update-project-reducer";
import { updateDocReducer } from "./reducers/update-doc-reducer";
import {
  getProjectConfigReducer,
  createProjectReducer,
} from "./reducers/create-project-reducer";
import checklistReducer from "./reducers/checklist-reducer";
import repositoryOverviewReducer from "./reducers/repository-overview-reducer";
import fileOverviewReducer from "./reducers/file-overview-reducer";
import tagsConfigReducer from "./reducers/tags-config-reducer";
import updateFileReducer from "./reducers/update-file-reducer";
import addDocumentsReducer from "./reducers/add-documents-reducer";
import associationConfigReducer from "./reducers/association-config-reducer";
import createAssociationReducer from "./reducers/create-association-reducer";
import removeAssociationReducer from "./reducers/remove-association-reducer";
import expiringFileReducer from "./reducers/expiring-file-reducer";
import fileTrashReducer from "./reducers/file-trash-reducer";
import deleteFileReducer from "./reducers/delete-file-reducer";

//reducer list
const reducers = {
  authzReducer,
  createCategoryReducer,
  docOverviewReducer,
  createFolderReducer,
  getProjectConfigReducer,
  createProjectReducer,
  deleteDocReducer,
  trashReducer,
  searchReducer,
  shareDocReducer,
  expiringDocReducer,
  updateProjectReducer,
  updateDocReducer,
  checklistReducer,
  repositoryOverviewReducer,
  fileOverviewReducer,
  tagsConfigReducer,
  updateFileReducer,
  addDocumentsReducer,
  associationConfigReducer,
  createAssociationReducer,
  removeAssociationReducer,
  expiringFileReducer,
  fileTrashReducer,
  deleteFileReducer,
};

// combine all reducers to rootReducer
const rootReducer = combineReducers(reducers);

export default rootReducer;
