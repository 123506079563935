import { useEffect, useState } from "react";
// sub components
import {
  Table,
  Header,
  Pagination,
  PropertyFilter,
  SpaceBetween,
} from "@amzn/awsui-components-react";
import {
  TableEmptyState,
  TableNoMatchState,
} from "../../../components/polaris/table/common-components";
import { DateRangeSelector } from "../../../components/date-range-selector/date-range-selector";
import { useCollection } from "@amzn/awsui-collection-hooks";
//config, constants
import {
  COLUMN_DEFINITIONS,
  DEFAULT_PREFERENCES,
  DEFAULT_SORTING_COLUMN,
  DEFAULT_SORTING_DESCENDING,
  FILTERING_PROPERTIES,
  Preferences,
} from "./table-config";
import {
  paginationLabels,
  PROPERTY_FILTERING_I18N_CONSTANTS,
} from "../../../components/polaris/table/labels";
import { getFilterCounterText } from "../../../components/polaris/table/table-counter-strings";
import constants, { TIME_UNIT } from "../../../config/constants";
import {
  getTodayDate,
  getFutureDate,
} from "../../../components/date-range-selector/date-range-utils";
import { repositoryOverviewData } from "../../../model/repository";
//css

const ExpiringFileTable = ({
  data,
  loadingStatus,
  getData,
}: {
  data: Array<repositoryOverviewData>;
  loadingStatus: number;
  getData: any;
}) => {
  // selected Items in the table
  const [selectedItems, setSelectedItems] = useState<any | never>([]);

  // default table preferences
  const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);

  // date range value, default 1 week
  const [dateRange, setDateRange] = useState<any>({
    key: "next-6-month",
    amount: 6,
    unit: "month",
    type: "relative",
  });

  // get data whenever date range changes
  useEffect(() => {
    if (dateRange && dateRange.type === "relative") {
      const startDate = getTodayDate();
      const endDate = getFutureDate(dateRange.amount, dateRange.unit);
      getData(startDate, endDate);
    }
    if (dateRange && dateRange.type === "absolute") {
      const { startDate, endDate } = dateRange;
      getData(startDate, endDate);
    }
  }, [dateRange]); // eslint-disable-line

  // using polaris useCollection hook to define table props
  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    propertyFilterProps,
    paginationProps,
  } = useCollection(data, {
    propertyFiltering: {
      filteringProperties: FILTERING_PROPERTIES,
      empty: <TableEmptyState resourceName={constants.FILE} />,
      noMatch: (
        <TableNoMatchState
          onClearFilter={() =>
            actions.setPropertyFiltering({ tokens: [], operation: "and" })
          }
        />
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {
      defaultState: {
        sortingColumn: DEFAULT_SORTING_COLUMN,
        isDescending: DEFAULT_SORTING_DESCENDING,
      },
    },
    selection: {},
  });

  // table loading status
  const loading = loadingStatus === constants.LOADING_LOAD;
  return (
    <Table
      {...collectionProps}
      columnDefinitions={COLUMN_DEFINITIONS}
      visibleColumns={preferences.visibleContent}
      items={items}
      selectionType="single"
      loadingText="Loading objects"
      loading={loading}
      resizableColumns={true}
      wrapLines={preferences.wrapLines}
      header={
        <Header
          counter={
            selectedItems.length
              ? `(${selectedItems.length}/${data.length})`
              : `(${data.length})`
          }
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <DateRangeSelector
                relativeOptions={[
                  {
                    amount: 1,
                    unit: TIME_UNIT.DAY,
                  },
                  {
                    amount: 1,
                    unit: TIME_UNIT.WEEK,
                  },
                  {
                    amount: 1,
                    unit: TIME_UNIT.MONTH,
                  },
                  {
                    amount: 6,
                    unit: TIME_UNIT.MONTH,
                  },
                ]}
                isFutureRange={true}
                minimumRangeInDays={1}
                value={dateRange}
                setValue={setDateRange}
              />
            </SpaceBetween>
          }
        >
          Objects
        </Header>
      }
      filter={
        <PropertyFilter
          i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
          {...propertyFilterProps}
          countText={getFilterCounterText(filteredItemsCount)}
        />
      }
      pagination={
        <Pagination {...paginationProps} ariaLabels={paginationLabels} />
      }
      preferences={
        <Preferences
          preferences={preferences}
          setPreferences={setPreferences}
          disabled={false}
        />
      }
      onSelectionChange={({ detail }) => setSelectedItems(detail.selectedItems)}
      selectedItems={selectedItems}
    />
  );
};

export default ExpiringFileTable;
