import { BreadcrumbGroup } from "@amzn/awsui-components-react";

const Breadcrumb = ({ breadcrumb_data, history }) => {
  const breadcrumbItems = [
    ...breadcrumb_data.map((data) => ({
      text: data.doc_name,
      href: data.path,
    })),
  ];
  return (
    <BreadcrumbGroup
      items={breadcrumbItems}
      ariaLabel="Breadcrumbs"
      onFollow={(e) => {
        e.preventDefault();
        if (e.detail.href) {
          history.push(e.detail.href);
        }
      }}
    />
  );
};

export default Breadcrumb;
