// sub components
import { Flashbar, Alert } from "@amzn/awsui-components-react";
import ShareByEmailStatusTable from "./email-status-table/table";
import DocumentPublicURLTable from "./public-url-table/table";
import constants from "../../../config/constants";

const isAnyFailedEvent = (shareByEmailStatus) => {
  let isFailed = false;
  shareByEmailStatus.forEach((event) => {
    if (!isFailed)
      isFailed = event.status !== constants.STATUS_SUCCESS ? true : false;
  });
  return isFailed;
};

const ShareStatus = ({ inProgress, docsWithShareURL, shareByEmailStatus }) => {
  return (
    <>
      {inProgress && (
        <Flashbar
          items={[
            {
              type: "success",
              loading: true,
              content: "In progress, sending emails",
            },
          ]}
        />
      )}
      <Alert
        type="success"
        visible={!inProgress && !isAnyFailedEvent(shareByEmailStatus)}
        dismissAriaLabel="Close alert"
        header="Sharing Complete"
      >
        View details below. The information below will no longer be available
        after you navigate away from this page.
      </Alert>
      <Alert
        type="error"
        visible={!inProgress && isAnyFailedEvent(shareByEmailStatus)}
        dismissAriaLabel="Close alert"
        header="Error Sharing Documents"
      >
        View details below. The information below will no longer be available
        after you navigate away from this page.
      </Alert>
      <br />
      <ShareByEmailStatusTable data={shareByEmailStatus} loadingStatus={true} />
      <br />
      <Alert visible={true} dismissAriaLabel="Close alert" className="mb-2">
        For unsuccessful emails, you can share the download URL mentioned below
        directly with the user.
      </Alert>
      <DocumentPublicURLTable data={docsWithShareURL} loadingStatus={true} />
    </>
  );
};

export default ShareStatus;
