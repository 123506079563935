import {
  CollectionPreferences,
  PropertyFilterProps,
} from "@amzn/awsui-components-react";
import {
  changeToShortDateFormat,
  changeToShortDateFormatAndAddColor,
} from "../../../utils/date-utils";
import { formatBytes } from "../../../utils/general-utils";
import { ExpiryDate } from "../../common-container/expiry-date";
import { EXPIRY_DATE_COLOR_CODE } from "../../../config/constants";

// define columns
export const COLUMN_DEFINITIONS = [
  {
    id: "file_name",
    sortingField: "file_name",
    header: "File Name",
    cell: (item) => item.file_name,
    visible: true,
    width: 300,
    minWidth: 200,
  },
  {
    id: "description",
    sortingField: "description",
    header: "Description",
    cell: (item) => item.description,
    visible: true,
    width: 100,
  },
  {
    id: "expiry_date",
    sortingField: "expiry_date",
    header: "Expiry Status",
    cell: (item) => (
      <ExpiryDate
        informatiomText={
          item.expiry_date
            ? changeToShortDateFormatAndAddColor(item.expiry_date).Date
            : "No expiry date available"
        }
        color={
          item.expiry_date
            ? changeToShortDateFormatAndAddColor(item.expiry_date).color
            : EXPIRY_DATE_COLOR_CODE.GREEN_COLOR_HEX
        }
      />
    ),
    visible: true,
    minWidth: 150,
  },
  {
    id: "file_type",
    sortingField: "file_type",
    header: "File Type",
    cell: (item) => item.file_type,
    visible: true,
    width: 150,
  },
  {
    id: "deleted_by",
    sortingField: "deleted_by",
    header: "Deleted By",
    cell: (item) => item.deleted_by,
    visible: true,
    width: 150,
  },
  {
    id: "deleted_time",
    sortingField: "deleted_time",
    header: "Deletion Time",
    cell: (item) => changeToShortDateFormat(item.deleted_time),
    visible: true,
    width: 250,
    minWidth: 100,
  },
  {
    id: "upload_time",
    sortingField: "upload_time",
    header: "Upload Time",
    cell: (item) => changeToShortDateFormat(item.upload_time),
    visible: false,
    minWidth: 100,
  },
  {
    id: "uploaded_by",
    sortingField: "uploaded_by",
    header: "Uploaded By",
    cell: (item) => changeToShortDateFormat(item.uploaded_by),
    visible: false,
    width: 150,
  },
  {
    id: "size",
    sortingField: "size",
    header: "Size",
    cell: (item) => (item.size ? formatBytes(item.size) : "__"),
    visible: true,
    width: 150,
  },
];

// define visible columns
export const VISIBLE_CONTENT_OPTIONS = [
  {
    label: "Properties",
    options: [
      { id: "file_name", label: "File Name", visible: true, editable: false },
      {
        id: "description",
        label: "Description",
        visible: false,
        editable: true,
      },
      {
        id: "expiry_date",
        label: "Expiry Status",
        visible: false,
        editable: true,
      },
      { id: "file_type", label: "File Type", visible: true, editable: false },
      { id: "size", label: "Size", visible: true, editable: false },
      { id: "deleted_by", label: "Deleted By", visible: true, editable: false },
      {
        id: "deleted_time",
        label: "Deletion Time",
        visible: true,
        editable: false,
      },
      {
        id: "upload_time",
        label: "Upload Time",
        visible: false,
        editable: true,
      },
      {
        id: "uploaded_by",
        label: "Uploaded By",
        visible: false,
        editable: true,
      },
    ],
  },
];

// define filtering properties
export const FILTERING_PROPERTIES: PropertyFilterProps.FilteringProperty[] = [
  {
    propertyLabel: "File Name",
    key: "file_name",
    groupValuesLabel: "Name",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Description",
    key: "description",
    groupValuesLabel: "Type of Document",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "File Type",
    key: "file_type",
    groupValuesLabel: "Type",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Size",
    key: "size",
    groupValuesLabel: "Size",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Deleted By",
    key: "deleted_by",
    groupValuesLabel: "Deleted By",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Deletion Time",
    key: "deleted_time",
    groupValuesLabel: "Deletion Time",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Upload Time",
    key: "upload_time",
    groupValuesLabel: "Upload Time",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Uploaded By",
    key: "uploaded_by",
    groupValuesLabel: "Uploaded By",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Expiry Status",
    key: "expiry_date",
    groupValuesLabel: "Expiry Status",
    operators: [":", "!:", "=", "!="],
  },
];

// define page size options
export const PAGE_SIZE_OPTIONS = [
  { value: 50, label: "50 items" },
  { value: 100, label: "100 items" },
  { value: 300, label: "100 items" },
];

// define default preferences
export const DEFAULT_PREFERENCES = {
  pageSize: 300,
  visibleContent: COLUMN_DEFINITIONS.filter((column) => column.visible).map(
    (column) => column.id
  ),
  wrapLines: false,
};

export const Preferences = ({
  preferences,
  setPreferences,
  disabled,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
  visibleContentOptions = VISIBLE_CONTENT_OPTIONS,
}) => (
  <CollectionPreferences
    title="Preferences"
    confirmLabel="Confirm"
    cancelLabel="Cancel"
    disabled={disabled}
    preferences={preferences}
    onConfirm={({ detail }) => setPreferences(detail)}
    pageSizePreference={{
      title: "Page size",
      options: pageSizeOptions,
    }}
    wrapLinesPreference={{
      label: "Wrap lines",
      description: "Check to see all the text and wrap the lines",
    }}
    visibleContentPreference={{
      title: "Select visible content",
      options: visibleContentOptions,
    }}
  />
);

// define default sorting
export const DEFAULT_SORTING_COLUMN = {
  id: "expiry_date",
  sortingField: "expiry_date",
};
