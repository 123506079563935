import { useState } from "react";
import { useSelector } from "react-redux";
// redux
import { setNotifications } from "../../../redux/actions/doc-overview-action";

// sub components
import {
  Table,
  Header,
  Pagination,
  PropertyFilter,
  SpaceBetween,
  Button,
} from "@amzn/awsui-components-react";
import {
  TableEmptyState,
  TableNoMatchState,
} from "../../../components/polaris/table/common-components";
import { useCollection } from "@amzn/awsui-collection-hooks";
//config, constants
import {
  COLUMN_DEFINITIONS,
  DEFAULT_PREFERENCES,
  FILTERING_PROPERTIES,
  Preferences,
} from "./table-config";
import {
  paginationLabels,
  PROPERTY_FILTERING_I18N_CONSTANTS,
} from "../../../components/polaris/table/labels";
import { getFilterCounterText } from "../../../components/polaris/table/table-counter-strings";
// use this for sample data
import { createDisassociationRequest } from "../../../model/http-json";
import { s3FileVersion } from "../../../model/doc-overview";
import constants, { DOWNLOAD_START_NOTIFICATION, DOWNLOAD_FAILED_NOTIFICATION } from "../../../config/constants";
import { removeAssociation } from "../../../redux/actions/remove-association-action";
import { downloadFilesAsZip } from "../../../utils"
//css

const DocAssociationsTable = ({
  documentId,
  data,
  loadingStatus,
  dispatch,
  setUploadDocument,
  onRefresh,
  projectName
}: {
  documentId: string;
  data: s3FileVersion[];
  loadingStatus: number;
  dispatch: any;
  setUploadDocument: Function;
  onRefresh: any;
  projectName: undefined | string;
}) => {
  // redux
  const { removeAssociationReducer } = useSelector((state: any) => ({
    removeAssociationReducer: state.removeAssociationReducer,
  }));

  // selected Items in the table
  const [selectedItems, setSelectedItems] = useState<any | never>([]);

  // default table preferences
  const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);


  const [documentDownloadStatus, setDocumentDownloadStatus] = useState(constants.LOADING_DEFAULT)

  // using polaris useCollection hook to define table props
  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    propertyFilterProps,
    paginationProps,
  } = useCollection(data, {
    propertyFiltering: {
      filteringProperties: FILTERING_PROPERTIES,
      empty: <TableEmptyState resourceName={constants.FILE} />,
      noMatch: (
        <TableNoMatchState
          onClearFilter={() =>
            actions.setPropertyFiltering({ tokens: [], operation: "and" })
          }
        />
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
  });

  // TODO: udpate this once the actions are ready
  // const onActionButtonClick = ({ detail }) => {
  //   dispatch(setNotifications([upcomingFeature], fileId));
  // };

  // table loading status
  const loading = loadingStatus === constants.LOADING_LOAD;

  const onDownloadasZip = () => {
    setDocumentDownloadStatus(constants.LOADING_LOAD);
    dispatch(setNotifications(DOWNLOAD_START_NOTIFICATION, documentId));
    downloadFilesAsZip(selectedItems.map(file => file.file_id), `${projectName}_CabinetFilesFolder-${(new Date()).toISOString()}`).then((response) => {
      if (response.status === constants.STATUS_SUCCESS) {
        window.location.href = response.s3_url;
        setDocumentDownloadStatus(constants.LOADING_SUCCESS);
      } else {
        setDocumentDownloadStatus(constants.LOADING_FAIL);
        dispatch(setNotifications(DOWNLOAD_FAILED_NOTIFICATION, documentId));
      }
    });
  };

  return (
    <Table
      {...collectionProps}
      columnDefinitions={COLUMN_DEFINITIONS}
      visibleColumns={preferences.visibleContent}
      items={items}
      selectionType="multi"
      loadingText="Loading resources"
      loading={loading}
      resizableColumns={true}
      wrapLines={preferences.wrapLines}
      header={
        <Header
          counter={
            selectedItems.length
              ? `(${selectedItems.length}/${data.length})`
              : `(${data.length})`
          }
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button iconName="refresh" onClick={onRefresh} />
              <Button
                loading={documentDownloadStatus === constants.LOADING_LOAD}
                iconName="download"
                onClick={onDownloadasZip}
                disabled={selectedItems.length === 0}
              >
                Download
              </Button>
              <Button
                disabled={selectedItems.length === 0}
                variant="normal"
                loading={
                  removeAssociationReducer?.loadingStatus ===
                  constants.LOADING_LOAD
                }
                onClick={() => {
                  let associations: any = [];
                  selectedItems.forEach((file) => {
                    associations.push({
                      file_id: file.file_id,
                      docs: [documentId],
                    });
                  });

                  const values: createDisassociationRequest = {
                    associations: associations,
                  };

                  dispatch(removeAssociation(values));
                }}
              >
                Diassociate
              </Button>
              <Button
                iconName="upload"
                variant="primary"
                onClick={() => setUploadDocument()}
              >
                Upload
              </Button>
            </SpaceBetween>
          }
        >
          Files
        </Header>
      }
      filter={
        <PropertyFilter
          i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
          {...propertyFilterProps}
          countText={getFilterCounterText(filteredItemsCount)}
        />
      }
      pagination={
        <Pagination {...paginationProps} ariaLabels={paginationLabels} />
      }
      preferences={
        <Preferences
          preferences={preferences}
          setPreferences={setPreferences}
          disabled={false}
        />
      }
      onSelectionChange={({ detail }) => setSelectedItems(detail.selectedItems)}
      selectedItems={selectedItems}
    />
  );
};

export default DocAssociationsTable;
