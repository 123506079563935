export const PIE_CHART_I18N_CONSTANTS = {
  detailsValue: "Value",
  detailsPercentage: "Percentage",
  filterLabel: "Filter displayed data",
  filterPlaceholder: "Filter data",
  filterSelectedAriaLabel: "selected",
  detailPopoverDismissAriaLabel: "Dismiss",
  legendAriaLabel: "Legend",
  chartAriaRoleDescription: "pie chart",
  segmentAriaRoleDescription: "segment",
};
