import { RouteComponentProps } from "react-router-dom";
import { Component } from "react";
//redux
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  getDocOverview,
  deleteNotification,
  resetDocOverview,
  setNotifications
} from "../../redux/actions/doc-overview-action";
// sub components
import {
  AppLayout,
  Container,
  Tabs,
  Header,
  ColumnLayout,
  Spinner,
  Button,
  SpaceBetween
} from "@amzn/awsui-components-react";
import FolderOverviewTable from "./table/table";
import { ValueWithLabel } from "../../components/common/labels";
import Notification from "../../components/common/notification/notification";
import Breadcrumb from "../../components/common/breadcrumb/breadcrumb";
//config, constants
import { TABS } from "./config";
import { documentOverviewData, notification } from "../../model/doc-overview";
import { changeToShortDateFormat } from "../../utils/date-utils";
import constants, { DOWNLOAD_FAILED_NOTIFICATION } from "../../config/constants";
import { resetUpdateDoc } from "../../redux/actions/update-doc-action";
import { HeaderStyle } from "../../config/constants";
import { fileDownloadS3Call } from "../../utils";
import NoAuthz from "../no-authz/no-authz";
//css

// declare prop check
type Props = {
  dispatch: Dispatch<any>;
  history: any;
  docOverviewReducer: any;
  updateDocReducer: any;
} & RouteComponentProps<any>;

// declare state check
type State = {
  documentDownloadStatus: number;
};

class FolderOverview extends Component<Props, State> {
  state: State = Object.freeze({
    documentDownloadStatus: constants.LOADING_DEFAULT
  });

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps: Props, prevstate: State) {
    if (this.props.match.params.folderId !== prevProps.match.params.folderId) {
      const { folderId } = prevProps.match.params;
      this.props.dispatch(resetDocOverview(folderId));
      this.getData();
    }
    if (
      this.props.updateDocReducer.loadingStatus === constants.LOADING_SUCCESS
    ) {
      this.getData();
      this.props.dispatch(resetUpdateDoc());
    }
  }

  componentWillUnmount() {
    const { folderId } = this.props.match.params;
    this.props.dispatch(resetDocOverview(folderId));
  }

  getData = () => {
    const { folderId } = this.props.match.params;
    this.props.dispatch(getDocOverview(folderId, 0));
  };

  onDismissNotification = (index: number) => {
    const { folderId } = this.props.match.params;
    this.props.dispatch(deleteNotification(index, folderId));
  };

  isCountryOrIndustryGroupDoc = (doc_name: string) => {
    return [
      constants.COUNTRY_CERTIFICATE,
      constants.INDUSTRY_CERTIFICATE,
    ].includes(doc_name.toLowerCase());
  };

  onDownloadasZip = () => {
    this.setState({
      documentDownloadStatus: constants.LOADING_LOAD,
    });
    const { folderId } = this.props.match.params;
    fileDownloadS3Call(folderId).then((response) => {
      if (response.status === constants.STATUS_SUCCESS) {
        window.location.href = response.s3_url;
        this.setState({
          documentDownloadStatus: constants.LOADING_SUCCESS,
        });
      } else {
        this.setState({
          documentDownloadStatus: constants.LOADING_FAIL,
        });
        this.props.dispatch(setNotifications(DOWNLOAD_FAILED_NOTIFICATION, folderId));
      }
    });
  };


  render() {
    const folderId = this.props.match.params.folderId;
    if (
      !this.props.docOverviewReducer[folderId] ||
      !this.props.docOverviewReducer[folderId].loadingStatus ||
      [constants.LOADING_LOAD, constants.LOADING_DEFAULT].includes(
        this.props.docOverviewReducer[folderId].loadingStatus
      )
    ) {
      return <Spinner size="large" />;
    }
    if(this.props.docOverviewReducer[folderId].unAuthorized)
      return <NoAuthz resource={`the given folder`}/>
    
      const {
      docOverviewData,
      loadingStatus,
      notifications,
    }: {
      docOverviewData: documentOverviewData;
      loadingStatus: number;
      notifications: notification[];
    } = this.props.docOverviewReducer[folderId];
    const {
      doc_name,
      doc_id,
      created_by,
      created_time,
      breadcrumb_data,
      children,
      is_file_upload_allowed,
      project,
    } = docOverviewData;

    const parent_doc_type = breadcrumb_data[breadcrumb_data.length - 1].type;
    const projectName = project?.name;
    const modelNumber = project?.model_number;

    //-----------------------------------------------Notifications-----------------------------------------------------//
    const notification = (
      <Notification
        notifications={notifications}
        onDismiss={this.onDismissNotification}
      />
    );

    //------------------------------------------------Breadcrumb------------------------------------------------------//
    const breadcrumb = (
      <Breadcrumb
        breadcrumb_data={breadcrumb_data}
        history={this.props.history}
      />
    );

    //--------------------------------------------------Header--------------------------------------------------------//
    const header = (
    <HeaderStyle 
      variant="h1"
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          <Button
              loading={
                this.state.documentDownloadStatus === constants.LOADING_LOAD
              }
              iconName="download"
              variant="primary"
              onClick={this.onDownloadasZip}
            >
              Download
            </Button>
        </SpaceBetween>
      }
    >
      {doc_name}
    </HeaderStyle>);

    //-----------------------------------------------Main Content-----------------------------------------------------//
    const folderOverview = (
      <Container header={<Header variant="h2">Folder Overview</Header>}>
        <ColumnLayout columns={3} variant="text-grid">
          <ValueWithLabel label="Name">{doc_name}</ValueWithLabel>
          <ValueWithLabel label="Created By">{created_by}</ValueWithLabel>
          <ValueWithLabel label="Creation Time">
            {changeToShortDateFormat(created_time)}
          </ValueWithLabel>
        </ColumnLayout>
      </Container>
    );
    const mainContent = (
      <Container>
        <Tabs
          tabs={[
            {
              label: TABS[0].LABEL,
              id: TABS[0].ID,
              content: (
                <FolderOverviewTable
                  data={children}
                  folderId={doc_id}
                  loadingStatus={loadingStatus}
                  parent_doc_type={parent_doc_type}
                  name={doc_name}
                  getData={this.getData}
                  history={this.props.history}
                  dispatch={this.props.dispatch}
                  updateDocLoadingStatus={
                    this.props.updateDocReducer.loadingStatus
                  }
                  is_file_upload_allowed={is_file_upload_allowed}
                  is_country_or_industry_group_doc={this.isCountryOrIndustryGroupDoc(
                    doc_name
                  )}
                  projectName={projectName}
                  modelNumber={modelNumber}
                />
              ),
            },
            {
              label: TABS[1].LABEL,
              id: TABS[1].ID,
              content: folderOverview,
            },
          ]}
        />
      </Container>
    );

    return (
      <AppLayout
        navigationHide
        toolsHide
        contentHeader={header}
        notifications={notification}
        breadcrumbs={breadcrumb}
        content={mainContent}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    docOverviewReducer: state.docOverviewReducer,
    updateDocReducer: state.updateDocReducer,
  };
};

export default connect(mapStateToProps)(FolderOverview);
