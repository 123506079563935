import { Header } from "@amzn/awsui-components-react";

import styled from "styled-components";
import { notification } from "../model/doc-overview";

export const HeaderStyle = styled(Header)`
  padding-bottom: 20px !important;
`;

const constants = {
  INITIATIVE: "cabinet",
  INITIATIVE_DISPLAY_NAME: "Lens Cabinet",

  NA: "N/A",
  NULL: "Null",

  SIGNIN: "signIn",
  UNAUTHENTICATED: "unauthenticated",
  AUTHENTICATED: "authenticated",
  SIGNIN_FAILURE: "signIn_failure",
  SIGNOUT: "signOut",
  CUSTOM_OAUTH_STATE: "customOAuthState",

  // These are used for dispatching/listening to custom Hub events.
  SESSION_EXPIRED: "session_expired",
  SESSION_WILL_EXPIRE: "session_will_expire",
  SESSION_TIMER_INTERVAL: 30000,

  // loading status
  LOADING_LOAD: 0,
  LOADING_SUCCESS: 1,
  LOADING_FAIL: -1,
  LOADING_DEFAULT: 10,

  // general status(we can keep all our status values uniformed)
  // file parse status is using this
  STATUS_SUCCESS: "success",
  STATUS_FAIL: "fail",
  STATUS_ERROR: "error", // there are some legacy status using error
  STATUS_PROCESS: "processing",
  STATUS_NOT_START: "not_start",
  STATUS_PROGRESS: "in_progress",
  STATUS_UPCOMING: "upcoming",
  STATUS_COMPLETED: "completed",
  STATUS_INFO: "info",
  STATUS_PENDING: "pending",

  // resource
  FOLDER: "folder",
  FILE: "file",
  CATEGORY: "category",
  PROJECT: "project",
  COUNTRY_CERTIFICATE: "country certificates",
  INDUSTRY_CERTIFICATE: "industry certificates",
  COUNTRY: "country",
  INDUSTRY: "industry",

  UPLOAD_CHECK_FILE_SUCCESS: "succeed",
  UPLOAD_CHECK_FILE_FAIL: "failed",

  LANDING_STATUS_PROCESS: "in_process",
  LANDING_STATUS_ARCHIVED: "archived",
  LANDING_STATUS_NOT_START: "not_start",

  TABLE_DEFAULT_MIN_WIDTH: "100px",

  UI_TYPE: {
    UI_TYPE_INPUT: "input",
    UI_TYPE_SELECT: "select",
    UI_TYPE_MULTI_SELECT: "multiselect",
    UI_TYPE_TOGGLE: "toggle",
  },
};
export default constants;

export const ERROR_RENAME = {
  ERROR_FILE_NAME:
    "Error occurred while renaming the file, please try again and if that fails again, please contact Wise teams",
  ERROR_DOC_NAME:
    "Error occurred while renaming the document, please try again and if that fails again, please contact Wise team",
};

export const EXPIRY_DATE_COLOR_CODE = {
  GREEN_COLOR_HEX: "#578E3B", // Green Color
  YELLOW_COLOR_HEX: "#F29E38", // Yellow Color
  RED_COLOR_HEX: "#C14126", // Red Color
};

export const FOLDER_GROUP = [
  constants.FOLDER,
  constants.CATEGORY,
  constants.PROJECT,
  constants.COUNTRY,
  constants.INDUSTRY,
];

export const IFRAME = {
  QUERY_STRING: "?inIframe=true",
};

export const METRIC_INFO = {
  INITIATIVE: "Lens Cabinet",
  LANDING: "Landing Page",
  META_DATA: "Meta Data",
  OVERVIEW: "Project Overview",
  REPORT: "Report",
  MANUAL_UPLOAD: "Manual Upload",
  CATEGORY: "Categories",
  PROJECT: "Projects",
  FOLDER: "Folders",
  CREATE_CATEGORY: "Create Category",
  CREATE_PROJECT: "Create Project",
  UPDATE_PROJECT: "Update Project",
  ADD_DOCUMENTS: "Add Documents",
  CREATE_ASSOCIATION: "Create Association",
  REMOVE_ASSOCIATION: "Remove Association",
  METRIC: "Metric",
  AUTHORIZATION: "Authorization",
  FILTER_PREFERENCES: "Filter",
  EXPORT: "Export",
  DOC: "Doc",
  FILE: "File",
};

export const COMPONENT_ID = {
  CATEGORY: "category",
  COMMENT: "comment",
  PROJECT: "project",
  MODEL: "model_number",
  DEVICE_CATEGORY: "device_category",
  PVT_RR: "pvt_rr",
  ANNOUNCE_DATE: "announce_date",
  START_DATE: "start_date",
  END_DATE: "end_date",
  STREET_DATE: "street_date",
  COUNTRY: "country",
  TECHNOLOGY: "technology",
};

export const TABS_ID = {
  FIRST: "first",
  SECOND: "second",
  THIRD: "third",
  FOURTH: "fourth",
};

export const DOCUMENT_ACTIONS = {
  RENAME: {
    TEXT: "Rename",
    ID: "rn",
  },
  DELETE: {
    TEXT: "Delete",
    ID: "rm",
  },
  SHARE: {
    TEXT: "Share",
    ID: "sr",
  },
  MOVE: {
    TEXT: "move",
    ID: "mv",
  },
};

export const DOCUMENT_TYPES = {
  TECHNICAL_DOCUMENTATION: "Technical Documentation",
  LETTER_FORMS: "Letter/Forms",
  CERTIFICATES: "Certificates",
  TEST_REPORTS: "Test Reports",
  DEVICE_PHOTOS: "Device Photos",
  TEST_PHOTOS: "Test Photos",
};

export const FILE_UPLOAD = {
  MAX_FILE_SIZE_IN_MB: 100,
  DESCRIPTION_CHARACTER_LENGTH: 100,
  GRID_SIZE: {
    FILE_NAME: 4,
    DESCRIPTION: 3,
    TAGS: 3,
    EXPIRY_DATE: 2,
    TOTAL: 12,
  },
};

export enum TIME_UNIT {
  SECOND = "second",
  MINUTE = "minute",
  HOUR = "hour",
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
  YEAR = "year",
}

// status display names
export const STATUS_DISPLAY_NAME = {
  STATUS_PROGRESS: "In Progress",
  STATUS_UPCOMING: "Upcoming",
  STATUS_COMPLETED: "Completed",
  STATUS_SUCCESS: "Success",
  STATUS_PENDING: "Pending",
};

export const CERTIFICATION_TYPES = { COUNTRY: "country", INDUSTRY: "industry" };

export const deviceCategories = ["V0", "V1", "V2"];

// Parent type for association dropdown selects on file overview page
export const PARENT_TYPE = {
  HOME: "HOME",
  PROJECT: "PROJECT",
  COUNTRY_OR_INDUSTRY: "COUNTRY_OR_INDUSTRY",
};

export const FILE_DOWNLOAD_FAIL_NOTIFICATION = {
  type: constants.STATUS_ERROR,
  content: `Failed to download file. Please refresh and try again.`,
};

export const FILE_UPDATE_NOTIFICATION = {
  FAILED: "Failed to update file, please try again.",
  SUCCESS: "File is successfully updated.",
};

export const RENAME_TYPE = {
  DOC: "Doc",
  REPOSITORY: "Repository",
};

export const DOWNLOAD_START_NOTIFICATION = [
  {
    type: constants.STATUS_INFO,
    content: "Wait for few second, we are preparing your bundle",
  },
];

export const DOWNLOAD_FAILED_NOTIFICATION = [
  {
    type: constants.STATUS_ERROR,
    content: "Failed to Download Document, please refresh and try again.",
  },
];

export const NOT_AUTHORIZED_NOTIFICATION: notification = {
  type: constants.STATUS_ERROR,
  content: `Sorry, you are not authorized perform this action. To request the access, please create a
  https://sim.amazon.com/issues/create?template=f54292fe-5e20-4a46-819d-56997631b88d
  ticket to WISe team.`,
};

export const CHECKLIST = {
  CHECK_ALL: {
    label: "Check All",
    value: "Check",
  },
  UNCHECK_ALL: {
    label: "Uncheck All",
    value: "Uncheck",
  },
};

export const HttpCodes = {
  OK: 200,
  CREATED: 201,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
};

export const pieChartSegmentDescriptionValue = {
  DEFAULT: "countries",
  SELECTED_COUNTRIES: "docs",
};

export const PROJECT_DETAILS = {
  STREET_DATE: "Street Date",
  ANNOUNCE_DATE: "Announce Date",
  DESCRIPTION: "Description",
  MODEL_NUMBER: "Model Number",
  DEVICE_CATEGORY: "Device Category",
  PVT_RR: "PVT RR",
  CREATION_TIME: "Creation Time",
  CREATED_BY: "Created By",
  STATUS: "Status",
  NAME: "Name",
};
