export const CHARS_TO_AVOID_MSG = [
  [
    `Ampersand ("&")`,
    `Dollar ("$")`,
    `'At' symbol ("@")`,
    `Equals ("=")`,
    `Semicolon (";")`,
    `Colon (":")`,
    `Plus ("+")`,
    `Space – Significant sequences of spaces might be lost in some uses (especially multiple spaces)`,
    `Comma (",")`,
    `Question mark ("?")`,
    `ASCII character ranges 00–1F hex (0–31 decimal) and 7F (127 decimal)`,
    `Backslash ("\\")`,
    `Forwardslash ("/")`,
  ],
  [
    `Left curly brace ("{")`,
    `Non-printable ASCII characters (128–255 decimal characters)`,
    `Caret ("^")`,
    `Right curly brace ("}")`,
    `Percent character ("%")`,
    `Right square bracket ("]")`,
    `Quotation marks`,
    `'Greater Than' symbol (">")`,
    `Left square bracket ("[")`,
    `Tilde ("~")`,
    `'Less Than' symbol ("<")`,
    `'Pound' character ("#")`,
    `Vertical bar / pipe ("|")`,
  ],
];

export const CHARS_TO_AVOID_LIST = [
  "&",
  "$",
  "@",
  "=",
  ";",
  ":",
  "+",
  ",",
  "?",
  "\\",
  "/",
  "^",
  "}",
  "%",
  "]",
  ">",
  "~",
  "#",
  "|",
];
