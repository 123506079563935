import {
  Box,
  Button,
  CollectionPreferences,
  PropertyFilterProps,
  SpaceBetween,
  Spinner,
} from "@amzn/awsui-components-react";
import {
  ResourceLabel,
  RequiredLabel,
  UploadLabel,
} from "../../../components/common/labels";
import {
  changeToShortDateFormat,
  changeToShortDateFormatAndAddColor,
} from "../../../utils/date-utils";
import { NavLink } from "react-router-dom";
import {
  formatBytes,
  // showClassAsDescription,
} from "../../../utils/general-utils";
import { formatDocName } from "../../../utils/doc-name-utils";
import { ExpiryDate } from "../../common-container/expiry-date";
import { EXPIRY_DATE_COLOR_CODE } from "../../../config/constants";
import constants, { ERROR_RENAME } from "../../../config/constants";

const renameDoc = (
  is_country_or_industry_group_doc,
  item,
  projectName,
  modelNumber,
  setFileData,
  setShowModal,
  loadingStatus,
  fileData
) => {
  return (
    <div>
      <SpaceBetween size="s" direction="horizontal">
        <NavLink
          to={
            is_country_or_industry_group_doc
              ? `/folders/${item.doc_id}/overview`
              : `/documents/${item.doc_id}/overview`
          }
        >
          {is_country_or_industry_group_doc
            ? item.doc_name
            : formatDocName(item.doc_name, projectName, modelNumber)}
        </NavLink>
        <Button
          onClick={() => {
            setFileData({
              fileId: item.doc_id,
              fileName: item.doc_name,
            });
            setShowModal(true);
          }}
          iconName="edit"
          variant="icon"
        ></Button>
      </SpaceBetween>
      {fileData.fileId === item.file_id && (
        <Box color="text-status-error" variant="p">
          {" "}
          {ERROR_RENAME.ERROR_DOC_NAME}
        </Box>
      )}
    </div>
  );
};
// define columns
export const COLUMN_DEFINITIONS = (
  is_file_upload_allowed?: boolean,
  is_country_or_industry_group_doc?: boolean,
  loadingStatus?: number,
  projectName?: string,
  modelNumber?: string,
  setShowModal?: any,
  fileData?: any,
  setFileData?: any
) => {
  return [
    {
      id: "doc_name",
      sortingField: "doc_name",
      header: "Name",

      cell: (item) => {
        if (
          loadingStatus === constants.LOADING_LOAD &&
          fileData.fileId === item.file_id
        ) {
          return <Spinner size="normal"></Spinner>;
        } else {
          return renameDoc(
            is_country_or_industry_group_doc,
            item,
            projectName,
            modelNumber,
            setFileData,
            setShowModal,
            loadingStatus,
            fileData
          );
        }
      },
      visible: true,
      minWidth: 400,
    },
    {
      id: "type",
      sortingField: "type",
      header: "Type",
      cell: (item) => <ResourceLabel resource={item.type} />,
      visible: is_country_or_industry_group_doc,
      minWidth: 80,
    },
    {
      id: "doc_type",
      sortingField: "doc_type",
      header: "Type of Document",
      cell: (item) => (item.doc_type ? item.doc_type : "__"),
      visible: !is_country_or_industry_group_doc,
      minWidth: 70,
    },
    // {
    //   id: "doc_class",
    //   sortingField: "doc_class",
    //   header: "Class of Document",
    //   cell: (item) =>
    //     item.doc_class ? showClassAsDescription(item.doc_class) : "__",
    //   visible: !is_country_or_industry_group_doc,
    //   minWidth: 70,
    // },
    {
      id: "required",
      sortingField: "required",
      header: "Required",
      cell: (item) => <RequiredLabel required={item.required}></RequiredLabel>,
      visible: !is_country_or_industry_group_doc,
      minWidth: 70,
      width: 150,
    },
    {
      id: "upload_status",
      sortingField: "upload_status",
      header: "Upload Status",
      cell: (item) => (
        <UploadLabel uploadStatus={item.upload_status}></UploadLabel>
      ),
      visible: !is_country_or_industry_group_doc,
      minWidth: 70,
      width: 150,
    },
    {
      id: "expiry_date",
      sortingField: "expiry_date",
      header: "Expiry Status",
      cell: (item) => (
        <ExpiryDate
          informatiomText={
            item.expiry_date
              ? changeToShortDateFormatAndAddColor(item.expiry_date).Date
              : "No expiry date available"
          }
          color={
            item.expiry_date
              ? changeToShortDateFormatAndAddColor(item.expiry_date).color
              : EXPIRY_DATE_COLOR_CODE.GREEN_COLOR_HEX
          }
        />
      ),
      visible: true,
      minWidth: 150,
    },
    {
      id: "created_time",
      sortingField: "created_time",
      header: "Creation Time",
      cell: (item) => changeToShortDateFormat(item.created_time),
      visible: true,
      minWidth: 250,
    },
    {
      id: "size",
      sortingField: "size",
      header: "Size",
      cell: (item) =>
        item.size && item.size !== "0" ? formatBytes(item.size) : "__",
      visible: true,
      minWidth: 80,
      width: 100,
    },
  ];
};

// define visible columns
export const VISIBLE_CONTENT_OPTIONS = [
  {
    label: "Properties",
    options: [
      { id: "doc_name", label: "Name", visible: true, editable: false },
      { id: "type", label: "Type", visible: true, editable: true },
      {
        id: "doc_type",
        label: "Type of Document",
        visible: true,
        editable: false,
      },
      // {
      //   id: "doc_class",
      //   label: "Class of Document",
      //   visible: true,
      //   editable: false,
      // },
      {
        id: "required",
        label: "Required",
        visible: true,
        editable: false,
      },
      {
        id: "upload_status",
        label: "Upload Status",
        visible: true,
        editable: false,
      },
      {
        id: "expiry_date",
        label: "Expiry Status",
        visible: true,
        editable: true,
      },
      { id: "size", label: "Size", visible: true, editable: false },
      {
        id: "created_time",
        label: "Creation Time",
        visible: true,
        editable: false,
      },
    ],
  },
];

// define filtering properties
export const FILTERING_PROPERTIES: PropertyFilterProps.FilteringProperty[] = [
  {
    propertyLabel: "Name",
    key: "doc_name",
    groupValuesLabel: "Name",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Type",
    key: "type",
    groupValuesLabel: "Type",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Type of Document",
    key: "doc_type",
    groupValuesLabel: "Type of Document",
    operators: [":", "!:", "=", "!="],
  },
  // {
  //   propertyLabel: "Class of Document",
  //   key: "doc_class",
  //   groupValuesLabel: "Class of Document",
  //   operators: [":", "!:", "=", "!="],
  // },
  {
    propertyLabel: "Upload Status",
    key: "upload_status",
    groupValuesLabel: "Upload Status",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Required",
    key: "required",
    groupValuesLabel: "Required",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Expiry Status",
    key: "expiry_date",
    groupValuesLabel: "Expiry Status",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Size",
    key: "size",
    groupValuesLabel: "Size",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Creation Time",
    key: "created_time",
    groupValuesLabel: "Creation Time",
    operators: [":", "!:", "=", "!="],
  },
];

// define page size options
export const PAGE_SIZE_OPTIONS = [
  { value: 50, label: "50 items" },
  { value: 100, label: "100 items" },
  { value: 300, label: "300 items" },
];

// define default preferences
export const DEFAULT_PREFERENCES = (
  is_file_upload_allowed?: boolean,
  is_country_or_industry_group_doc?: boolean,
  loadingStatus?: number,
  setShowModal?: any,
  fileData?: any,
  setFileData?: any
) => {
  return {
    pageSize: 300,
    visibleContent: COLUMN_DEFINITIONS(
      is_file_upload_allowed,
      is_country_or_industry_group_doc,
      loadingStatus,
      setShowModal,
      fileData,
      setFileData
    )
      .filter((column) => column.visible)
      .map((column) => column.id),
    wrapLines: false,
  };
};

export const Preferences = ({
  preferences,
  setPreferences,
  disabled,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
  visibleContentOptions = VISIBLE_CONTENT_OPTIONS,
}) => (
  <CollectionPreferences
    title="Preferences"
    confirmLabel="Confirm"
    cancelLabel="Cancel"
    disabled={disabled}
    preferences={preferences}
    onConfirm={({ detail }) => setPreferences(detail)}
    pageSizePreference={{
      title: "Page size",
      options: pageSizeOptions,
    }}
    wrapLinesPreference={{
      label: "Wrap lines",
      description: "Check to see all the text and wrap the lines",
    }}
    visibleContentPreference={{
      title: "Select visible content",
      options: visibleContentOptions,
    }}
  />
);
