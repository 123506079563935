import createAssociationType from "../types/create-association-type";
import { config } from "../../config/config";
import { METRIC_INFO } from "../../config/constants";
import endpoints from "../../config/endpoints";
import { createAssociationRequest } from "../../model/http-json";

export const createAssociation = (values: createAssociationRequest): any => ({
  type: createAssociationType.CREATE_ASSOCIATION,
  http: {
    cognito: {
      effect: {
        url: config.BASE_URL + endpoints.associateDocsToFileEndpoint(),
        method: "PUT",
        json: values,
      },
      commit: {
        type: createAssociationType.CREATE_ASSOCIATION_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.CREATE_ASSOCIATION,
        },
      },
      rollback: {
        type: createAssociationType.CREATE_ASSOCIATION_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.CREATE_ASSOCIATION,
        },
      },
    },
  },
});

export const resetAssociation = (file_id: string): any => ({
  type: createAssociationType.RESET_ASSOCIATION,
  file_id,
});
