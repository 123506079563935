import {
  CollectionPreferences,
  PropertyFilterProps,
} from "@amzn/awsui-components-react";
import { StatusLabel } from "../../../../../components/common/labels";
import constants from "../../../../../config/constants";
import { formatBytes } from "../../../../../utils/general-utils";

// define columns
export const COLUMN_DEFINITIONS = [
  {
    id: "name",
    sortingField: "name",
    header: "Name",
    cell: (item) => item.doc_name,
    visible: true,
    minWidth: 350,
  },
  {
    id: "deleteStatus",
    sortingField: "deleteStatus",
    header: "Status",
    cell: (item) => <StatusLabel status={item.deleteStatus} />,
    visible: true,
    minWidth: 80,
  },
  {
    id: "type",
    sortingField: "type",
    header: "Type",
    cell: (item) => item.type,
    visible: true,
    minWidth: 80,
  },
  {
    id: "deleteMessage",
    sortingField: "deleteMessage",
    header: "Error",
    cell: (item) =>
      [constants.STATUS_FAIL, constants.STATUS_ERROR].includes(
        item.deleteStatus
      )
        ? item.deleteMessage
        : "__",
    visible: true,
    minWidth: 150,
  },
  {
    id: "size",
    sortingField: "size",
    header: "Size",
    cell: (item) => (item.size ? formatBytes(item.size) : "__"),
    visible: true,
    minWidth: 80,
  },
];

// define visible columns
export const VISIBLE_CONTENT_OPTIONS = [
  {
    label: "Properties",
    options: [
      { id: "name", label: "Name", visible: true, editable: false },
      { id: "deleteStatus", label: "Status", visible: true, editable: false },
      { id: "type", label: "Type", visible: true, editable: false },
      { id: "size", label: "Size", visible: true, editable: false },
      { id: "deleteMessage", label: "Error", visible: true, editable: false },
    ],
  },
];

// define filtering properties
export const FILTERING_PROPERTIES: PropertyFilterProps.FilteringProperty[] = [
  {
    propertyLabel: "Name",
    key: "name",
    groupValuesLabel: "Final Name",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Status",
    key: "deleteStatus",
    groupValuesLabel: "Status",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Type",
    key: "type",
    groupValuesLabel: "Type",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Size",
    key: "size",
    groupValuesLabel: "Size",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Error",
    key: "deleteMessage",
    groupValuesLabel: "Error",
    operators: [":", "!:", "=", "!="],
  },
];

// define page size options
export const PAGE_SIZE_OPTIONS = [
  { value: 50, label: "50 items" },
  { value: 100, label: "100 items" },
];

// define default preferences
export const DEFAULT_PREFERENCES = {
  pageSize: 100,
  visibleContent: COLUMN_DEFINITIONS.filter((column) => column.visible).map(
    (column) => column.id
  ),
  wrapLines: false,
};

export const Preferences = ({
  preferences,
  setPreferences,
  disabled,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
  visibleContentOptions = VISIBLE_CONTENT_OPTIONS,
}) => (
  <CollectionPreferences
    title="Preferences"
    confirmLabel="Confirm"
    cancelLabel="Cancel"
    disabled={disabled}
    preferences={preferences}
    onConfirm={({ detail }) => setPreferences(detail)}
    pageSizePreference={{
      title: "Page size",
      options: pageSizeOptions,
    }}
    wrapLinesPreference={{
      label: "Wrap lines",
      description: "Check to see all the text and wrap the lines",
    }}
    visibleContentPreference={{
      title: "Select visible content",
      options: visibleContentOptions,
    }}
  />
);
