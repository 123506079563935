import { RouteComponentProps } from "react-router-dom";
import React, { Component } from "react";
import {
  Modal,
  SpaceBetween,
  Form,
  Select,
  Multiselect,
  SelectProps,
  FormField,
  Spinner,
  Wizard,
  Table,
} from "@amzn/awsui-components-react";

import { connect } from "react-redux";
import {
  getAssociationDropdownConfig,
  resetAssociationDropdownConfig,
} from "../../../redux/actions/association-config-action";

import { createAssociation } from "../../../redux/actions/create-association-action";

import constants from "../../../config/constants";
import { createAssociationRequest } from "../../../model/http-json";
import { PARENT_TYPE } from "../../../config/constants";
import { ValueWithLabel } from "../../../components/common/labels";

interface StateProps {
  associationConfigReducer: any;
}

interface MatchParams {
  fileId: number;
}

// declare prop check
type Props = {
  dispatch: any;
  fileId: string;
  openAssociationsModal: boolean;
  setOpenAssociationsModal: any;
  associatedDocs: any;
} & StateProps &
  RouteComponentProps<MatchParams>;

type State = {
  dropdownOptions: any;
  selectedProject: SelectProps.Option | any;
  selectedCountryOrTechnology: SelectProps.Option | any;
  selectedDocuments: SelectProps.Option[];
  activeStepIndex: number;
  selectedDocumentStatus: {};
};

class Associations extends Component<Props, State> {
  state: State = Object.freeze({
    dropdownOptions: {},
    selectedProject: null,
    selectedCountryOrTechnology: null,
    selectedDocuments: [],
    activeStepIndex: 0,
    selectedDocumentStatus: {},
  });

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {}

  componentWillUnmount() {
    this.props.dispatch(resetAssociationDropdownConfig(PARENT_TYPE.HOME));
    this.props.dispatch(resetAssociationDropdownConfig(PARENT_TYPE.PROJECT));
    this.props.dispatch(
      resetAssociationDropdownConfig(PARENT_TYPE.COUNTRY_OR_INDUSTRY)
    );
  }

  getData = () => {
    this.props.dispatch(getAssociationDropdownConfig("1", PARENT_TYPE.HOME));
  };

  getProjectDropdownOptions(project_list: any) {
    if (project_list === undefined) return [];
    let dropdown_options: Array<any> = [];
    for (const key of Object.keys(project_list)) {
      const options = project_list[key].map((el) => {
        return { label: el.doc_name, value: el.doc_id };
      });
      dropdown_options.push({ label: key, options: options });
    }
    return dropdown_options;
  }

  getCountryOrTechnologyDropdownOptions(country_technology_list: any) {
    if (country_technology_list === undefined) return [];
    let dropdown_options: Array<any> = [];
    for (const key of Object.keys(country_technology_list)) {
      const options = country_technology_list[key].map((el) => {
        return { label: el.doc_name, value: el.doc_id };
      });
      const label = key === "Country Documents" ? "Country" : "Technology";
      dropdown_options.push({ label: label, options: options });
    }
    return dropdown_options;
  }

  getDocumentsDropdownOptions(doc_list: any) {
    if (doc_list === undefined) return [];
    let dropdown_options: Array<any> = [];

    const associated_doc_ids = this.props.associatedDocs.map(
      (doc) => doc.doc_id
    );

    for (const key of Object.keys(doc_list)) {
      const options = doc_list[key].map((el) => {
        return {
          label: el.doc_name,
          value: el.doc_id,
          disabled: associated_doc_ids.includes(el.doc_id),
        };
      });
      dropdown_options.push({ label: key, options: options });
    }
    return dropdown_options;
  }

  _onChangeProject = (event) => {
    this.setState({ selectedProject: event.detail.selectedOption });
    this.setState({ selectedCountryOrTechnology: null });
    this.setState({ selectedDocuments: [] });
    this.props.dispatch(
      getAssociationDropdownConfig(
        event.detail.selectedOption.value,
        PARENT_TYPE.PROJECT
      )
    );

    this.props.dispatch(resetAssociationDropdownConfig(PARENT_TYPE.PROJECT));
    this.props.dispatch(
      resetAssociationDropdownConfig(PARENT_TYPE.COUNTRY_OR_INDUSTRY)
    );
  };

  _onChangeCountryOrIndustry = (event) => {
    this.setState({ selectedCountryOrTechnology: event.detail.selectedOption });
    this.setState({ selectedDocuments: [] });
    this.props.dispatch(
      getAssociationDropdownConfig(
        event.detail.selectedOption.value,
        "COUNTRY_OR_INDUSTRY"
      )
    );

    this.props.dispatch(resetAssociationDropdownConfig("COUNTRY_OR_INDUSTRY"));
  };

  _onChangeDocument = (event) => {
    this.setState({
      selectedDocuments: event.detail.selectedOptions,
    });
  };

  _onCancelButtonClicked = (event) => {
    this.props.setOpenAssociationsModal(false);

    this.props.dispatch(resetAssociationDropdownConfig(PARENT_TYPE.HOME));
    this.props.dispatch(resetAssociationDropdownConfig(PARENT_TYPE.PROJECT));
    this.props.dispatch(
      resetAssociationDropdownConfig(PARENT_TYPE.COUNTRY_OR_INDUSTRY)
    );
  };

  _onSubmitButtonClicked = (event) => {
    const file_id = this.props.fileId;

    let docsToAssociate: any = [];

    if (this.state.selectedDocuments.length !== 0) {
      this.state.selectedDocuments.forEach((doc) =>
        docsToAssociate.push({id: doc.value, next_status: this.state.selectedDocumentStatus[doc.value!]})
      );

      const values: createAssociationRequest = {
        associations: [{ file_id: file_id, docs: docsToAssociate }],
      };

      this.props.dispatch(createAssociation(values));
    }
    this.props.setOpenAssociationsModal(false);
  };

  createStatusesForDocuments(){
    let statusMap = {...this.state.selectedDocumentStatus};
    this.state.selectedDocuments.forEach(
      (document) => {
        if(document !== undefined && statusMap[document.value!] === undefined){
          statusMap[document.value!] = "completed"
        }
      }
    );
    this.setState({selectedDocumentStatus: {...statusMap}});
  }

  render() {
    if (
      !this.props.associationConfigReducer[PARENT_TYPE.HOME] ||
      !this.props.associationConfigReducer[PARENT_TYPE.HOME].loadingStatus ||
      [constants.LOADING_LOAD, constants.LOADING_DEFAULT].includes(
        this.props.associationConfigReducer[PARENT_TYPE.HOME].loadingStatus
      )
    ) {
      return (
        <Modal
          size="large"
          visible={true}
          header="Create Association"
          className="awsui-visual-refresh"
        >
          <Spinner size="large"></Spinner>
        </Modal>
      );
    }

    const project_dropdown_data =
      this.props?.associationConfigReducer[PARENT_TYPE.HOME]
        ?.associationConfigData;
    const country_industry_dropdown_data =
      this.props?.associationConfigReducer[PARENT_TYPE.PROJECT]
        ?.associationConfigData;
    const document_dropdown_data =
      this.props?.associationConfigReducer[PARENT_TYPE.COUNTRY_OR_INDUSTRY]
        ?.associationConfigData;

    const project_loading_status =
      this.props?.associationConfigReducer[PARENT_TYPE.HOME]?.loadingStatus;
    const country_industry_loading_status =
      this.props?.associationConfigReducer[PARENT_TYPE.PROJECT]?.loadingStatus;
    const document_loading_status =
      this.props?.associationConfigReducer[PARENT_TYPE.COUNTRY_OR_INDUSTRY]
        ?.loadingStatus;

    return (
      <Modal
        size="max"
        onDismiss={() => this.props.setOpenAssociationsModal(false)}
        visible={this.props.openAssociationsModal}
        closeAriaLabel="Close modal"
        header="Create Association"
        className="awsui-visual-refresh"
      >
        <Wizard
          i18nStrings={{
            stepNumberLabel: stepNumber => `Step ${stepNumber}`,
            collapsedStepsLabel: (stepNumber, stepsCount) =>
              `Step ${stepNumber} of ${stepsCount}`,
            skipToButtonLabel: (step, stepNumber) => `Skip to ${step.title}`,
            navigationAriaLabel: 'Steps',
            cancelButton: 'Cancel',
            previousButton: 'Previous',
            nextButton: 'Next',
            submitButton: 'Submit',
            optional: 'optional',
            submitButtonLoadingAnnouncement:"Starting associating file to documents",
          }}
          onSubmit={this._onSubmitButtonClicked}
          onCancel={this._onCancelButtonClicked}
          onNavigate={({ detail }) => {
            this.setState({activeStepIndex: detail.requestedStepIndex})
            if(detail.reason === "next"){
              this.createStatusesForDocuments();
            }
          }}
          activeStepIndex={this.state.activeStepIndex}
          steps={[
            {
              title: "Choose documents",
              content: (
                <Form>
                  <SpaceBetween direction="horizontal" size="xl"
                  >
                    <FormField label="Project">
                      <Select
                        selectedOption={this.state.selectedProject}
                        onChange={this._onChangeProject}
                        options={this.getProjectDropdownOptions(project_dropdown_data)}
                        placeholder="Choose a project"
                        disabled={
                          project_loading_status === constants.LOADING_SUCCESS
                            ? false
                            : true
                        }
                      />
                    </FormField>

                    <FormField label="Country/Technology">
                      <Select
                        selectedOption={this.state.selectedCountryOrTechnology}
                        onChange={this._onChangeCountryOrIndustry}
                        options={this.getCountryOrTechnologyDropdownOptions(
                          country_industry_dropdown_data
                        )}
                        placeholder="Choose a country/technology"
                        disabled={
                          country_industry_loading_status === constants.LOADING_SUCCESS
                            ? false
                            : true
                        }
                      />
                    </FormField>

                    <FormField label="Document">
                      <Multiselect
                        selectedOptions={this.state.selectedDocuments}
                        onChange={this._onChangeDocument}
                        options={this.getDocumentsDropdownOptions(
                          document_dropdown_data
                        )}
                        placeholder="Choose documents"
                        disabled={
                          document_loading_status === constants.LOADING_SUCCESS &&
                            country_industry_loading_status === constants.LOADING_SUCCESS
                            ? false
                            : true
                        }
                      />
                    </FormField>
                  </SpaceBetween>
                </Form>
              )
            },
            {
              title: "Select document next status",
              content: (
                <>
                  <SpaceBetween direction="horizontal" size="l">
                    <ValueWithLabel label="Project">{this.state.selectedProject?.label}</ValueWithLabel>
                    <ValueWithLabel label="Country/Technology">{this.state.selectedCountryOrTechnology?.label}</ValueWithLabel>
                  </SpaceBetween>
                  <Table
                    variant="embedded"
                    columnDefinitions={
                      [
                        {
                          id: "id",
                          header: "Document",
                          cell: item => item.label,
                        },
                        {
                          id: "next_status",
                          header: "Next status",
                          cell: item => this.state.selectedDocumentStatus[item.value!],
                          editConfig: {
                            editingCell: (item, { currentValue, setValue }) => {
                              const value = currentValue ?? this.state.selectedDocumentStatus[item.value!];
                              return (
                                <Select
                                  autoFocus={true}
                                  expandToViewport={true}
                                  selectedOption={
                                    [
                                      { label: 'Completed', value: 'completed' },
                                      { label: 'Inprogress', value: 'in_progress' },
                                      { label: 'Pending', value: 'pending' },
                                    ].find(option => option.value === value) ?? null
                                  }
                                  onChange={event => {
                                    let selectedDocumentStatus = this.state.selectedDocumentStatus;
                                    selectedDocumentStatus[item.value!] = event.detail.selectedOption.value ?? item.value;
                                    this.setState({ selectedDocumentStatus: { ...selectedDocumentStatus } })
                                  }}
                                  options={[
                                    { label: 'Completed', value: 'completed' },
                                    { label: 'Inprogress', value: 'in_progress' },
                                    { label: 'Pending', value: 'pending' },
                                  ]}
                                />
                              );
                            }
                          }
                        }
                      ]
                    }
                    items={this.state.selectedDocuments}
                  />
                </>
              )
            }
          ]}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    associationConfigReducer: state.associationConfigReducer,
  };
};

export default connect(mapStateToProps)(Associations);
