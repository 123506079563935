import { Route, Switch } from "react-router-dom";
import { routes } from "./routes";
import { LogPageView } from "../../components/aws-rum-hoc"

const MainRouter = () => {
  return (
    <LogPageView>
      <Switch>
        {routes.map((route, key) => (<Route exact path={route.path} key={key} component={route.component} />))}
      </Switch>
    </LogPageView>
  );
};

export default MainRouter;
