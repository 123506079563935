import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { AppLayout } from "@amzn/awsui-components-react/polaris";
import Breadcrumb from "../../../components/common/breadcrumb/breadcrumb";
import CategoryForm from "./category-form";
import "./../create-form.css";
import { HeaderStyle } from "../../../config/constants";

interface StateProps {
  categoryReducer: any;
}

// declare prop check
type Props = {
  dispatch: Dispatch<any>;
} & StateProps &
  RouteComponentProps<any>;

class CreateCategory extends Component<Props> {
  render() {
    //------------------------------------------------Breadcrumb------------------------------------------------------//
    const breadcrumb = (
      <Breadcrumb
        breadcrumb_data={[
          {
            doc_id: "1",
            doc_name: "Home",
            path: "/",
            type: "folder",
          },
          {
            doc_name: "Create Category",
            path: `/categories/create`,
          },
        ]}
        history={this.props.history}
      />
    );

    //--------------------------------------------------Header--------------------------------------------------------//
    const header = (
      <HeaderStyle variant="h1" description="use this page to create category">
        Create Category
      </HeaderStyle>
    );

    //-----------------------------------------------Main Content-----------------------------------------------------//
    const mainContent = <CategoryForm history={this.props.history} />;

    return (
      <AppLayout
        navigationHide
        toolsHide
        contentHeader={header}
        breadcrumbs={breadcrumb}
        content={mainContent}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    categoryReducer: state.categoryReducer,
  };
};

export default withRouter(connect<StateProps>(mapStateToProps)(CreateCategory));
