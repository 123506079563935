import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  FormField,
  Multiselect,
  ColumnLayout,
  Grid,
} from "@amzn/awsui-components-react";
import AddItemToChecklist from "./add-item-to-checklist";
import { updateChecklist } from "../../redux/actions/checklist-action";
import { formatSelectedOptionsV2, convertToDropdownOptionsV2 } from "./config";
import { DOCUMENT_TYPES } from "../../config/constants";
import { formatDocName } from "../../utils/doc-name-utils";
// import { showClassAsDescription } from "../../utils/general-utils";

const AddDocChecklist = ({
  grandparent,
  parent,
  docList,
  project,
  modelNo,
}) => {
  const dispatch = useDispatch();

  // redux
  const { checklist } = useSelector((state: any) => ({
    checklist: state.checklistReducer.checklist,
  }));

  // states
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState(
    DOCUMENT_TYPES.TECHNICAL_DOCUMENTATION
  );

  const selectedTechnicalDocuments =
    checklist[grandparent] &&
    checklist[grandparent][parent] &&
    checklist[grandparent][parent][DOCUMENT_TYPES.TECHNICAL_DOCUMENTATION]
      ? checklist[grandparent][parent][DOCUMENT_TYPES.TECHNICAL_DOCUMENTATION]
      : [];
  const selectedLettersForms =
    checklist[grandparent] &&
    checklist[grandparent][parent] &&
    checklist[grandparent][parent][DOCUMENT_TYPES.LETTER_FORMS]
      ? checklist[grandparent][parent][DOCUMENT_TYPES.LETTER_FORMS]
      : [];
  const selectedCertificates =
    checklist[grandparent] &&
    checklist[grandparent][parent] &&
    checklist[grandparent][parent][DOCUMENT_TYPES.CERTIFICATES]
      ? checklist[grandparent][parent][DOCUMENT_TYPES.CERTIFICATES]
      : [];
  const selectedTestReports =
    checklist[grandparent] &&
    checklist[grandparent][parent] &&
    checklist[grandparent][parent][DOCUMENT_TYPES.TEST_REPORTS]
      ? checklist[grandparent][parent][DOCUMENT_TYPES.TEST_REPORTS]
      : [];
  const selectedDevicePhotos =
    checklist[grandparent] &&
    checklist[grandparent][parent] &&
    checklist[grandparent][parent][DOCUMENT_TYPES.DEVICE_PHOTOS]
      ? checklist[grandparent][parent][DOCUMENT_TYPES.DEVICE_PHOTOS]
      : [];
  const selectedTestPhotos =
    checklist[grandparent] &&
    checklist[grandparent][parent] &&
    checklist[grandparent][parent][DOCUMENT_TYPES.TEST_PHOTOS]
      ? checklist[grandparent][parent][DOCUMENT_TYPES.TEST_PHOTOS]
      : [];

  const updateSelection = (item) => {
    let newSelection: any = [];
    let index = -1;
    switch (modalType) {
      case DOCUMENT_TYPES.TECHNICAL_DOCUMENTATION:
        newSelection = selectedTechnicalDocuments;
        index = selectedTechnicalDocuments.findIndex(
          (option) => option.value === item.doc_name
        );
        if (index === -1) {
          newSelection = [
            ...selectedTechnicalDocuments,
            {
              label: formatDocName(item.doc_name, project, modelNo),
              value: item.doc_name,
              disabled: false,
              // doc_class: item.doc_class,
              // description: showClassAsDescription(item.doc_class),
            },
          ];
        }
        break;
      case DOCUMENT_TYPES.LETTER_FORMS:
        newSelection = selectedLettersForms;
        index = selectedLettersForms.findIndex(
          (option) => option.value === item.doc_name
        );
        if (index === -1) {
          newSelection = [
            ...selectedLettersForms,
            {
              label: formatDocName(item.doc_name, project, modelNo),
              value: item.doc_name,
              disabled: false,
              // doc_class: item.doc_class,
              // description: showClassAsDescription(item.doc_class),
            },
          ];
        }
        break;
      case DOCUMENT_TYPES.CERTIFICATES:
        newSelection = selectedCertificates;
        index = selectedCertificates.findIndex(
          (option) => option.value === item.doc_name
        );
        if (index === -1) {
          newSelection = [
            ...selectedCertificates,
            {
              label: formatDocName(item.doc_name, project, modelNo),
              value: item.doc_name,
              disabled: false,
              // doc_class: item.doc_class,
              // description: showClassAsDescription(item.doc_class),
            },
          ];
        }
        break;
      case DOCUMENT_TYPES.TEST_REPORTS:
        newSelection = selectedTestReports;
        index = selectedTestReports.findIndex(
          (option) => option.value === item.doc_name
        );
        if (index === -1) {
          newSelection = [
            ...selectedTestReports,
            {
              label: formatDocName(item.doc_name, project, modelNo),
              value: item.doc_name,
              disabled: false,
              // doc_class: item.doc_class,
              // description: showClassAsDescription(item.doc_class),
            },
          ];
        }
        break;
      case DOCUMENT_TYPES.DEVICE_PHOTOS:
        newSelection = selectedDevicePhotos;
        index = selectedDevicePhotos.findIndex(
          (option) => option.value === item.doc_name
        );
        if (index === -1) {
          newSelection = [
            ...selectedDevicePhotos,
            {
              label: formatDocName(item.doc_name, project, modelNo),
              value: item.doc_name,
              disabled: false,
              // doc_class: item.doc_class,
              // description: showClassAsDescription(item.doc_class),
            },
          ];
        }
        break;
      case DOCUMENT_TYPES.TEST_PHOTOS:
        newSelection = selectedTestPhotos;
        index = selectedTestPhotos.findIndex(
          (option) => option.value === item.doc_name
        );
        if (index === -1) {
          newSelection = [
            ...selectedTestPhotos,
            {
              label: formatDocName(item.doc_name, project, modelNo),
              value: item.doc_name,
              disabled: false,
              // doc_class: item.doc_class,
              // description: showClassAsDescription(item.doc_class),
            },
          ];
        }
        break;
      default:
        newSelection = [];
    }
    dispatch(updateChecklist(grandparent, parent, modalType, newSelection));
  };

  // checks if the user entered document name is already selected
  const checkAlreadySelected = (item) => {
    let index = -1;
    switch (modalType) {
      case DOCUMENT_TYPES.TECHNICAL_DOCUMENTATION:
        index = selectedTechnicalDocuments.findIndex(
          (option) => option.value === item.doc_name
        );
        break;
      case DOCUMENT_TYPES.LETTER_FORMS:
        index = selectedLettersForms.findIndex(
          (option) => option.value === item.doc_name
        );
        break;
      case DOCUMENT_TYPES.CERTIFICATES:
        index = selectedCertificates.findIndex(
          (option) => option.value === item.doc_name
        );
        break;
      case DOCUMENT_TYPES.TEST_REPORTS:
        index = selectedTestReports.findIndex(
          (option) => option.value === item.doc_name
        );
        break;
      case DOCUMENT_TYPES.DEVICE_PHOTOS:
        index = selectedDevicePhotos.findIndex(
          (option) => option.value === item.doc_name
        );
        break;
      case DOCUMENT_TYPES.TEST_PHOTOS:
        index = selectedTestPhotos.findIndex(
          (option) => option.value === item.doc_name
        );
        break;
      default:
        index = -1;
    }
    return index !== -1;
  };

  // checks if the user entered document name already exists in dropdown
  const checkExistsInDropdown = (item) => {
    let dropDownOptions = [];
    switch (modalType) {
      case DOCUMENT_TYPES.TECHNICAL_DOCUMENTATION:
        dropDownOptions = convertToDropdownOptionsV2(
          docList[DOCUMENT_TYPES.TECHNICAL_DOCUMENTATION]
        );
        break;
      case DOCUMENT_TYPES.LETTER_FORMS:
        dropDownOptions = convertToDropdownOptionsV2(
          docList[DOCUMENT_TYPES.LETTER_FORMS]
        );
        break;
      case DOCUMENT_TYPES.CERTIFICATES:
        dropDownOptions = convertToDropdownOptionsV2(
          docList[DOCUMENT_TYPES.CERTIFICATES]
        );
        break;
      case DOCUMENT_TYPES.TEST_REPORTS:
        dropDownOptions = convertToDropdownOptionsV2(
          docList[DOCUMENT_TYPES.TEST_REPORTS]
        );
        break;
      case DOCUMENT_TYPES.DEVICE_PHOTOS:
        dropDownOptions = convertToDropdownOptionsV2(
          docList[DOCUMENT_TYPES.DEVICE_PHOTOS]
        );
        break;
      case DOCUMENT_TYPES.TEST_PHOTOS:
        dropDownOptions = convertToDropdownOptionsV2(
          docList[DOCUMENT_TYPES.TEST_PHOTOS]
        );
        break;
      default:
        dropDownOptions = [];
    }
    const index = dropDownOptions.findIndex(
      (option: any) => option.value === item.doc_name
    );
    return index !== -1;
  };

  const editChecklist = (type) => {
    setModalType(type);
    setShowModal(true);
  };

  const onChangeTechnicalDocumentation = ({ detail }) => {
    dispatch(
      updateChecklist(
        grandparent,
        parent,
        DOCUMENT_TYPES.TECHNICAL_DOCUMENTATION,
        formatSelectedOptionsV2(project, modelNo, detail.selectedOptions)
      )
    );
  };

  const onChangeLettersForms = ({ detail }) => {
    dispatch(
      updateChecklist(
        grandparent,
        parent,
        DOCUMENT_TYPES.LETTER_FORMS,
        formatSelectedOptionsV2(project, modelNo, detail.selectedOptions)
      )
    );
  };

  const onChangeCertificates = ({ detail }) => {
    dispatch(
      updateChecklist(
        grandparent,
        parent,
        DOCUMENT_TYPES.CERTIFICATES,
        formatSelectedOptionsV2(project, modelNo, detail.selectedOptions)
      )
    );
  };

  const onChangeTestReports = ({ detail }) => {
    dispatch(
      updateChecklist(
        grandparent,
        parent,
        DOCUMENT_TYPES.TEST_REPORTS,
        formatSelectedOptionsV2(project, modelNo, detail.selectedOptions)
      )
    );
  };

  const onChangeDevicePhotos = ({ detail }) => {
    dispatch(
      updateChecklist(
        grandparent,
        parent,
        DOCUMENT_TYPES.DEVICE_PHOTOS,
        formatSelectedOptionsV2(project, modelNo, detail.selectedOptions)
      )
    );
  };

  const onChangeTestPhotos = ({ detail }) => {
    dispatch(
      updateChecklist(
        grandparent,
        parent,
        DOCUMENT_TYPES.TEST_PHOTOS,
        formatSelectedOptionsV2(project, modelNo, detail.selectedOptions)
      )
    );
  };

  return (
    <>
      <ColumnLayout columns={2}>
        <FormField
          label={DOCUMENT_TYPES.TECHNICAL_DOCUMENTATION}
          errorText={""}
        >
          <Grid
            disableGutters
            gridDefinition={[{ colspan: 11 }, { colspan: 1 }]}
          >
            <Multiselect
              options={convertToDropdownOptionsV2(
                docList[DOCUMENT_TYPES.TECHNICAL_DOCUMENTATION]
              )}
              placeholder="Select Technical Docs"
              onChange={onChangeTechnicalDocumentation}
              selectedOptions={selectedTechnicalDocuments}
            />
            <Button
              iconName="edit"
              variant="icon"
              onClick={() =>
                editChecklist(DOCUMENT_TYPES.TECHNICAL_DOCUMENTATION)
              }
            />
          </Grid>
        </FormField>
        <FormField label={DOCUMENT_TYPES.LETTER_FORMS} errorText={""}>
          <Grid
            disableGutters
            gridDefinition={[{ colspan: 11 }, { colspan: 1 }]}
          >
            <Multiselect
              options={convertToDropdownOptionsV2(
                docList[DOCUMENT_TYPES.LETTER_FORMS]
              )}
              placeholder="Select Letter Forms"
              onChange={onChangeLettersForms}
              selectedOptions={selectedLettersForms}
            />
            <Button
              iconName="edit"
              variant="icon"
              onClick={() => editChecklist(DOCUMENT_TYPES.LETTER_FORMS)}
            />
          </Grid>
        </FormField>
        <FormField label={DOCUMENT_TYPES.CERTIFICATES} errorText={""}>
          <Grid
            disableGutters
            gridDefinition={[{ colspan: 11 }, { colspan: 1 }]}
          >
            <Multiselect
              options={convertToDropdownOptionsV2(
                docList[DOCUMENT_TYPES.CERTIFICATES]
              )}
              placeholder="Select Certificates"
              onChange={onChangeCertificates}
              selectedOptions={selectedCertificates}
            />
            <Button
              iconName="edit"
              variant="icon"
              onClick={() => editChecklist(DOCUMENT_TYPES.CERTIFICATES)}
            />
          </Grid>
        </FormField>
        <FormField label={DOCUMENT_TYPES.TEST_REPORTS} errorText={""}>
          <Grid
            disableGutters
            gridDefinition={[{ colspan: 11 }, { colspan: 1 }]}
          >
            <Multiselect
              options={convertToDropdownOptionsV2(
                docList[DOCUMENT_TYPES.TEST_REPORTS]
              )}
              placeholder="Select Test Report"
              onChange={onChangeTestReports}
              selectedOptions={selectedTestReports}
            />
            <Button
              iconName="edit"
              variant="icon"
              onClick={() => editChecklist(DOCUMENT_TYPES.TEST_REPORTS)}
            />
          </Grid>
        </FormField>
        <FormField label={DOCUMENT_TYPES.DEVICE_PHOTOS} errorText={""}>
          <Grid
            disableGutters
            gridDefinition={[{ colspan: 11 }, { colspan: 1 }]}
          >
            <Multiselect
              options={convertToDropdownOptionsV2(
                docList[DOCUMENT_TYPES.DEVICE_PHOTOS]
              )}
              placeholder="Select Device Photo"
              onChange={onChangeDevicePhotos}
              selectedOptions={selectedDevicePhotos}
            />
            <Button
              iconName="edit"
              variant="icon"
              onClick={() => editChecklist(DOCUMENT_TYPES.DEVICE_PHOTOS)}
            />
          </Grid>
        </FormField>
        <FormField label={DOCUMENT_TYPES.TEST_PHOTOS} errorText={""}>
          <Grid
            disableGutters
            gridDefinition={[{ colspan: 11 }, { colspan: 1 }]}
          >
            <Multiselect
              options={convertToDropdownOptionsV2(
                docList[DOCUMENT_TYPES.TEST_PHOTOS]
              )}
              placeholder="Select Test Photo"
              onChange={onChangeTestPhotos}
              selectedOptions={selectedTestPhotos}
            />
            <Button
              iconName="edit"
              variant="icon"
              onClick={() => editChecklist(DOCUMENT_TYPES.TEST_PHOTOS)}
            />
          </Grid>
        </FormField>
      </ColumnLayout>
      <AddItemToChecklist
        showModal={showModal}
        setShowModal={setShowModal}
        modalType={modalType}
        updateSelection={updateSelection}
        checkAlreadySelected={checkAlreadySelected}
        checkExistsInDropdown={checkExistsInDropdown}
      />
    </>
  );
};

export default AddDocChecklist;
