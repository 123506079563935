import { TABS_ID } from "../../config/constants";

export const TABS = [
  {
    LABEL: "Objects",
    ID: TABS_ID.FIRST,
  },
  {
    LABEL: "Properties",
    ID: TABS_ID.SECOND,
  },
];
