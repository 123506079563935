import { updateFileType } from "../types/update-file-type";
import constants, { HttpCodes, NOT_AUTHORIZED_NOTIFICATION } from "../../config/constants";
import { notification } from "../../model/repository";

interface updateFileState {
  loadingStatus: number;
  message: string;
  responseData: any;
  notifications: notification[];
}

const updateFileInitialState: updateFileState = {
  loadingStatus: constants.LOADING_DEFAULT,
  message: "",
  responseData: {},
  notifications: [],
};

const updateFileReducer = (state = updateFileInitialState, action: any) => {
  switch (action.type) {
    case updateFileType.UPDATE_FILE:
      return {
        ...state,
        loadingStatus: constants.LOADING_LOAD,
      };

    case updateFileType.UPDATE_FILE_COMMIT:
      return {
        ...state,
        loadingStatus: constants.LOADING_SUCCESS,
        message: action.payload.message,
        responseData: action.payload.data,
      };

    case updateFileType.UPDATE_FILE_ROLLBACK:
      const notifications: notification[] = [
        ...(state.notifications
          ? state.notifications
          : []),
      ];
      if(action.statusCode === HttpCodes.FORBIDDEN)
        notifications.push(NOT_AUTHORIZED_NOTIFICATION)

      return {
        ...state,
        loadingStatus: constants.LOADING_FAIL,
        notifications,
      };

    case updateFileType.RESET_UPDATE_FILE:
      return {
        ...state,
        loadingStatus: constants.LOADING_DEFAULT,
        message: "",
        responseData: {},
      };

    default:
      return state;
  }
};

export default updateFileReducer;
