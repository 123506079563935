import addDocumentsType from "../types/add-documents-type";
import { notification } from "../../model/doc-overview";
import constants, { NOT_AUTHORIZED_NOTIFICATION, HttpCodes } from "../../config/constants";
interface state {
  loadingStatus: number;
  message: string;
  responseData: any;
  notifications: notification[];
}

const initialState: state = {
  loadingStatus: constants.LOADING_DEFAULT,
  message: "",
  responseData: {},
  notifications: [],
};

const addDocumentsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case addDocumentsType.ADD_DOCUMENTS:
      return {
        ...state,
        loadingStatus: constants.LOADING_LOAD,
      };
    case addDocumentsType.ADD_DOCUMENTS_COMMIT:
      return {
        ...state,
        loadingStatus: constants.LOADING_SUCCESS,
        message: action.payload.message,
        responseData: action.payload.data,
      };
    case addDocumentsType.ADD_DOCUMENTS_ROLLBACK:
      const notifications: notification[] = [
        ...(state.notifications
          ? state.notifications
          : []),
      ];
      if(action.statusCode === HttpCodes.FORBIDDEN)
        notifications.push(NOT_AUTHORIZED_NOTIFICATION)

      return {
        ...state,
        loadingStatus: constants.LOADING_FAIL,
        message: action?.payload?.message,
        responseData: {},
        notifications
      };
    case addDocumentsType.RESET_ADD_DOCUMENTS:
      return {
        ...state,
        loadingStatus: constants.LOADING_DEFAULT,
        message: "",
        responseData: {},
      };
    default:
      return state;
  }
};

export default addDocumentsReducer;
