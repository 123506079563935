import constants from "../config/constants";
import { config } from "../config/config.js";
import endpoints from "../config/endpoints";
import { getData } from "../utils/cognito-fetch";
import { downloadFileStatus } from "../model/http-json";

export async function fileDownloadS3Call(
  fileId: string
): Promise<downloadFileStatus> {
  /*
      Ajax call to get file download url 
  */
  // init return
  const result: downloadFileStatus = {
    status: constants.STATUS_ERROR,
    message: "",
    s3_url: "",
  };

  try {
    const url: string =
      config.BASE_URL + endpoints.downloadFileEndpoint(fileId);
    const response = await getData(url);

    if (!response.ok) throw response.status_text;
    const preSignedURLResponse = response?.json;
    result.status = constants.STATUS_SUCCESS;
    result.message = preSignedURLResponse?.message;
    result.s3_url = preSignedURLResponse?.data;
  } catch (error: any) {
    result.status = constants.STATUS_ERROR;
    result.message = error["message"];
  } finally {
    return result;
  }
}
