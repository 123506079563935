import base_config from "./base_config";

const beta_config = {
  ...base_config,
  STAGE: "beta",

  // Amplify config for cognito
  AMPLIFY_CONFIG: {
    aws_project_region: base_config.REGION,
    aws_cognito_region: base_config.REGION,
    aws_user_pools_id: "us-west-2_ttcebY1zR",
    aws_user_pools_web_client_id: "37e2vc1v1s80nmbgjslk8dd4vi",
    oauth: {
      domain: "wise-general-cog-beta.auth.us-west-2.amazoncognito.com",
      scope: base_config.TOKEN_SCOPES_ARRAY,
      redirectSignIn: base_config.CALL_BACK_URL,
      redirectSignOut: base_config.CALL_BACK_URL,
      responseType: base_config.OAUTH_RESPONSE_TYPE,
    },
  },

  // Base url
  BASE_URL: "https://f2fncq4ewa.execute-api.us-west-2.amazonaws.com/beta/v1",
  COMPLIANCE_BASE_URL:
    "https://gitiry4wkl.execute-api.us-west-2.amazonaws.com/beta/v1",
  BASE_AUTHZ_URL: "https://f2fncq4ewa.execute-api.us-west-2.amazonaws.com/beta",
  // Upload service base url
  UPLOAD_BASE_URL:
    "https://b6u1d4qumj.execute-api.us-west-2.amazonaws.com/beta",
  ANALYTICS_DASHBOARD: "",
  // Configuration for Cloudwatch RUM
  APPLICATION_ID: "b3047184-82da-4313-9c33-9b197ae46e41",
  APPLICATION_VERSION:"1.0.0",
  APPLICATION_REGION: "us-west-2",
  rum_config: {
    sessionSampleRate: 1,
    guestRoleArn: "arn:aws:iam::194096003022:role/RUM-Monitor-us-west-2-194096003022-9266282495861-Unauth",
    identityPoolId: "us-west-2:0bf55768-d52d-4ed5-86d8-b183ba0e7313",
    endpoint: "https://dataplane.rum.us-west-2.amazonaws.com",
    telemetries: ["performance","errors",["http",{recordAllRequests: true}]],
    allowCookies: true,
    enableXRay: false,
    disableAutoPageView: true
  },

};

export default beta_config;
