import { useState } from "react";
// redux
import { createFolder } from "../../../redux/actions/create-folder-action";
// sub components
import {
  Container,
  FormField,
  Header,
  Input,
  SpaceBetween,
  Button,
  ExpandableSection,
  TextContent,
  ColumnLayout,
} from "@amzn/awsui-components-react";

//config, constants
import { CHARS_TO_AVOID_MSG, CHARS_TO_AVOID_LIST } from "./folder-config";
import constants from "../../../config/constants";
import { createFolderRequest } from "../../../model/http-json";
//css

const FolderForm = ({ folderId, dispatch, history, loadingStatus }) => {
  //state
  const [folderName, setFolderName] = useState("");
  const [folderNameError, setFolderNameError] = useState("");

  const validate = () => {
    let isValid = true;
    if (!folderName) {
      setFolderNameError("Folder name cannot be empty");
      isValid = false;
    } else if (folderName.length > 30) {
      setFolderNameError("Max Length 30");
      isValid = false;
    } else {
      for (let charIndex in CHARS_TO_AVOID_LIST) {
        if (folderName.indexOf(CHARS_TO_AVOID_LIST[charIndex]) >= 0) {
          isValid = false;
          setFolderNameError(
            "Invalid character. Please check Characters To Avoid list."
          );
          break;
        }
      }
    }
    return isValid;
  };

  const onSubmit = () => {
    if (!validate()) return;
    const values: createFolderRequest = {
      name: folderName,
    };
    dispatch(createFolder(values, folderId, 1));
  };

  const onCancel = () => {
    history.push(`/folders/${folderId}/overview`);
  };

  const loading = loadingStatus === constants.LOADING_LOAD;

  return (
    <div className="create-form-container">
      <Container header={<Header variant="h2">Folder</Header>}>
        <FormField
          label="Folder Name"
          constraintText={
            <>
              <p>Folder names can't contain "/".</p>
              <p>
                Max Length 30. Remaining characters {30 - folderName.length}
              </p>
            </>
          }
          errorText={folderNameError}
        >
          <Input
            id={constants.FOLDER}
            placeholder="Enter Folder name"
            value={folderName}
            onChange={(event) => {
              setFolderName(event.detail.value);
              setFolderNameError("");
            }}
          />
        </FormField>
        <br />
        <ExpandableSection header="Characters to avoid">
          <TextContent>
            <strong>
              Avoid the following characters in a folder name because of
              significant special handling for consistency across all
              applications.{" "}
            </strong>
            <ColumnLayout columns={2} variant="text-grid">
              <ul>
                {CHARS_TO_AVOID_MSG[0].map((items, index) => (
                  <li key={index}>{items}</li>
                ))}
              </ul>
              <ul>
                {CHARS_TO_AVOID_MSG[1].map((items, index) => (
                  <li key={index}>{items}</li>
                ))}
              </ul>
            </ColumnLayout>
          </TextContent>
        </ExpandableSection>
      </Container>
      <br />
      <div
        style={{
          display: "flex",
          flexDirection: "row-reverse",
        }}
      >
        <SpaceBetween direction="horizontal" size="xs">
          <Button disabled={loading} onClick={onCancel}>
            Cancel
          </Button>
          <Button variant="primary" loading={loading} onClick={onSubmit}>
            Create Folder
          </Button>
        </SpaceBetween>
      </div>
    </div>
  );
};

export default FolderForm;
