import {
  Alert,
  Container,
  Header,
  SpaceBetween,
  Flashbar,
  ProgressBar,
  ColumnLayout,
  Link,
  StatusIndicator,
} from "@amzn/awsui-components-react";
import { ValueWithLabel } from "../../../../components/common/labels";

import DeleteStatusTable from "./table/table";

const DeleteStatus = ({
  docs,
  inProgressDocs,
  successfulDocs,
  failedDocs,
  folderName,
  folderPath,
}) => {
  // state
  const progressPercentage =
    (100 * (failedDocs + successfulDocs)) /
    (inProgressDocs + failedDocs + successfulDocs);

  return (
    <SpaceBetween size="m">
      {progressPercentage !== 100 && (
        <Flashbar
          items={[
            {
              content: (
                <ProgressBar
                  status="in-progress"
                  value={progressPercentage}
                  variant="flash"
                  label="Objects Deletion Progress"
                />
              ),
            },
          ]}
        />
      )}
      <Alert
        type="error"
        visible={progressPercentage === 100 && failedDocs !== 0}
        dismissAriaLabel="Close alert"
        header="Delete Failed"
      >
        Error deleting some files/folders. View details below.
      </Alert>
      <Alert
        type="success"
        visible={progressPercentage === 100 && failedDocs === 0}
        dismissAriaLabel="Close alert"
        header="Delete Successful"
      >
        View details below.
      </Alert>
      <Alert visible={true} dismissAriaLabel="Close alert">
        The information below will no longer be available after you navigate
        away from this page.
      </Alert>
      <Container header={<Header variant="h2">Summary</Header>}>
        <ColumnLayout columns={3} variant="text-grid">
          <ValueWithLabel label="Source">
            {folderName}
            <Link href={folderPath} external={true}></Link>
          </ValueWithLabel>
          <ValueWithLabel label="Succeeded">
            <StatusIndicator type="success">
              {successfulDocs} Objects (
              {(100 * successfulDocs) /
                (inProgressDocs + failedDocs + successfulDocs)}
              %)
            </StatusIndicator>
          </ValueWithLabel>
          <ValueWithLabel label="Failed">
            <StatusIndicator type="error">
              {failedDocs} Objects (
              {(100 * failedDocs) /
                (inProgressDocs + failedDocs + successfulDocs)}
              %)
            </StatusIndicator>
          </ValueWithLabel>
        </ColumnLayout>
      </Container>
      <DeleteStatusTable data={docs} loadingStatus={true} />
    </SpaceBetween>
  );
};

export default DeleteStatus;
