import { Box, Button } from "@amzn/awsui-components-react";

export const EmptyState = () => (
  <Box textAlign="center" color="inherit">
    <b>No data available</b>
    <Box variant="p" color="inherit">
      There is no data available
    </Box>
  </Box>
);

export const NoMatchState = () => (
  <Box textAlign="center" color="inherit">
    <b>No matching data</b>
    <Box variant="p" color="inherit">
      There is no matching data to display
    </Box>
    <Button>Clear filter</Button>
  </Box>
);
