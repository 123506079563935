import createAssociationType from "../types/create-association-type";
import constants, { NOT_AUTHORIZED_NOTIFICATION, HttpCodes } from "../../config/constants";
import { notification } from "../../model/doc-overview";

interface createAssociationState {
  loadingStatus: number;
  message: string;
  responseData: any;
  notifications: notification[];
}

const createAssociationInitialState: createAssociationState = {
  loadingStatus: constants.LOADING_DEFAULT,
  message: "",
  responseData: {},
  notifications: [],
};

export const createAssociationReducer = (
  state = createAssociationInitialState,
  action: any
) => {
  switch (action.type) {
    case createAssociationType.CREATE_ASSOCIATION:
      return {
        ...state,
        loadingStatus: constants.LOADING_LOAD,
      };
    case createAssociationType.CREATE_ASSOCIATION_COMMIT:
      return {
        ...state,
        loadingStatus: constants.LOADING_SUCCESS,
        message: action.payload.message,
        responseData: action.payload.data,
      };
    case createAssociationType.CREATE_ASSOCIATION_ROLLBACK:
      const notifications: notification[] = [
        ...(state.notifications
          ? state.notifications
          : []),
      ];

      if(action.statusCode === HttpCodes.FORBIDDEN)
        notifications.push(NOT_AUTHORIZED_NOTIFICATION)

      return {
        ...state,
        loadingStatus: constants.LOADING_FAIL,
        message: action.payload.message,
        responseData: action.payload.data,
        notifications
      };
    case createAssociationType.RESET_ASSOCIATION:
      return {
        ...state,
        loadingStatus: constants.LOADING_DEFAULT,
        message: "",
        responseData: {},
      };
    default:
      return state;
  }
};

export default createAssociationReducer;
