import { updateProjectType } from "../types/update-project-type";
import { config } from "../../config/config";
import { METRIC_INFO } from "../../config/constants";
import endpoints from "../../config/endpoints";
import { updateProjectRequest } from "../../model/http-json";

export const updateProject = (
  project_id: number,
  values: updateProjectRequest
): any => ({
  type: updateProjectType.UPDATE_PROJECT,
  http: {
    cognito: {
      effect: {
        url: config.BASE_URL + endpoints.updateProjectEndpoint(project_id),
        method: "PUT",
        json: values,
      },
      commit: {
        type: updateProjectType.UPDATE_PROJECT_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.PROJECT,
        },
      },
      rollback: {
        type: updateProjectType.UPDATE_PROJECT_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.PROJECT,
        },
      },
    },
  },
});

export const resetUpdateProject = (): any => ({
  type: updateProjectType.RESET_UPDATE_PROJECT,
});
