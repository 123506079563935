import React from "react"
import { memo } from "react";
// configs, contants
import constants from "../../../config/constants";
import { Box, Container, ColumnLayout, SpaceBetween} from "@amzn/awsui-components-react";
import "./style.css";
import { formatBytes } from "../../../utils/general-utils";
const ShowSearchResult = (props) => {
    if ([constants.LOADING_LOAD, constants.LOADING_DEFAULT].includes(props.loadingStatus)) {
        return <span>Loading...</span>
    }

    return (
        <SpaceBetween size="l">
            {
                props.data.map(
                    documentObject => {
                        switch (documentObject.type) {
                            case "category":
                                return <CategorySearchBox key={documentObject.doc_id} category={documentObject}/>;
                            case "project":
                                return <ProjectSearchBox key={documentObject.doc_id} doc={documentObject}/>;
                            case "file":
                                return <FileSearchBox key={documentObject.file_id} file={documentObject}/>;
                            default:
                                return <DocumentSearchBox key={documentObject.doc_id} document={documentObject}/>;
                        }
                    }
                )}
        </SpaceBetween>
    );
}

export default memo(ShowSearchResult);


const FileSearchBox = ({key, file}): JSX.Element => {
    return (
        <Container key={key}>
            <h3><a href={`/files/${file.file_id}/overview`}>{file.file_name}</a> <span className="tag">{file.type}</span></h3>
            <br />
            <ColumnLayout columns={4} variant="text-grid">
                <SpaceBetween size="l">
                    <ValueWithLabel label="Description">{file.description}</ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size="l">
                    <ValueWithLabel label="Uploaded by">{file.uploaded_by}</ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size="l">
                    <ValueWithLabel label="Upload time">{file.upload_time}</ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size="l">
                    <ValueWithLabel label="File size">{formatBytes(file.size)}</ValueWithLabel>
                </SpaceBetween>
            </ColumnLayout>

        </Container>
    );
}

const ValueWithLabel = ({ label, children }) => (
    <div>
        <Box variant="awsui-key-label">{label}</Box>
        <div>{children}</div>
    </div>
);

const ProjectSearchBox = ({key, doc}): JSX.Element => {
    return (
        <Container key={key}>
            <h3><a href={doc.path}>{doc.doc_name}</a> <span className="tag">{doc.type}</span></h3>
            <br />
            <ColumnLayout columns={3} variant="text-grid">
                <SpaceBetween size="l">
                    <ValueWithLabel label="Project status">{doc.project?.status ?? "Not available"}</ValueWithLabel>
                    <ValueWithLabel label="Model number">{doc.project?.model_number}</ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size="l">
                    <ValueWithLabel label="Created time">{doc.created_time}</ValueWithLabel>
                    <ValueWithLabel label="Created by">{doc.created_by}</ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size="l">
                    <ValueWithLabel label="Street date">{doc.project?.street_date ?? "Not available"}</ValueWithLabel>
                    <ValueWithLabel label="Category">{doc.category?.name}</ValueWithLabel>
                </SpaceBetween>
            </ColumnLayout>
        </Container>
    );
}


const CategorySearchBox = ({key, category}): JSX.Element => {
    return (
        <Container key={key}>
            <h3><a href={category.path}>{category.doc_name}</a> <span className="tag">{category.type}</span></h3>
            <br />
            <ColumnLayout columns={3} variant="text-grid">
                <SpaceBetween size="l">
                    <ValueWithLabel label="Description">{category.description}</ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size="l">
                    <ValueWithLabel label="Created by">{category.created_by}</ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size="l">
                    <ValueWithLabel label="Creation time">{category.created_time}</ValueWithLabel>
                </SpaceBetween>
            </ColumnLayout>
        </Container>
    )
}


const DocumentSearchBox = ({key, document}): JSX.Element => {
    return (
        <Container key={key}>
            <h3><a href={document.path}>{document.doc_name}</a> <span className="tag">{document.type}</span></h3>
            <br />
            <ColumnLayout columns={3} variant="text-grid">
                <SpaceBetween size="l">
                    <ValueWithLabel label="Description">{document.description}</ValueWithLabel>
                    <ValueWithLabel label="Created by">{document.created_by}</ValueWithLabel>
                    <ValueWithLabel label="Creation time">{document.created_time}</ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size="l">
                    <ValueWithLabel label="Project">{document.project?.name ?? "Not available"}</ValueWithLabel>
                    <ValueWithLabel label="Model number">{document.project?.model_number}</ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size="l">
                    <ValueWithLabel label="Category">{document.category?.name ?? "Not available"}</ValueWithLabel>
                    <ValueWithLabel label="Category description">{document.category?.description ?? "Not available"}</ValueWithLabel>
                </SpaceBetween>
            </ColumnLayout>
        </Container>
    )
}