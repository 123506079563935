import constants from "../../../config/constants";

export const upcomingFeature = {
  type: constants.STATUS_INFO,
  content: (
    <span>
      Feature is currently <strong>in development </strong>and will be available
      in <strong>next release</strong>.
    </span>
  ),
};
