import { RouteComponentProps } from "react-router-dom";
import { Component } from "react";
//redux
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  getDocOverview,
  deleteNotification,
  resetDocOverview,
  setNotifications,
  getDocAssociaitons,
} from "../../redux/actions/doc-overview-action";
import { setShareDocs } from "../../redux/actions/share-doc-action";
// sub components
import {
  AppLayout,
  Container,
  Header,
  ColumnLayout,
  Spinner,
  SpaceBetween,
  Button,
  ButtonDropdown,
  Box,
} from "@amzn/awsui-components-react";
import DocAssociationsTable from "./table/table";
import { ValueWithLabel } from "../../components/common/labels";
import Notification from "../../components/common/notification/notification";
import Breadcrumb from "../../components/common/breadcrumb/breadcrumb";
import Upload from "../upload/upload";
import NoAuthz from "../no-authz/no-authz";

//config, constants
import { documentOverviewData, notification } from "../../model/doc-overview";
import {
  changeToShortDateFormat,
  changeToShortDateFormatAndAddColor,
} from "../../utils/date-utils";
import { formatDocName } from "../../utils/doc-name-utils";
import { formatBytes } from "../../utils/general-utils";
import constants, {
  DOCUMENT_ACTIONS,
  ERROR_RENAME,
  RENAME_TYPE,
  DOWNLOAD_FAILED_NOTIFICATION
} from "../../config/constants";
import { resetRemoveAssociation } from "../../redux/actions/remove-association-action";
import { ExpiryDate } from "../common-container/expiry-date";
import Rename from "../common-container/rename";

import styled from "styled-components";

//utils
import { fileDownloadS3Call } from "../../utils"

const HeaderStyle = styled(Header)`
  padding-bottom: 20px !important;
`;

// declare prop check
interface StateProps {
  docOverviewReducer: any;
  removeAssociationReducer: any;
  updateDocReducer: any;
}

interface MatchParams {
  documentId: number;
}

type Props = {
  dispatch: Dispatch<any>;
  history: any;
} & RouteComponentProps<MatchParams> &
  StateProps;

// declare state check
type State = {
  documentDownloadStatus: number;
  isRename: boolean;
  fileName: string;
  breadcrumbData: Array<any>;
  loadDocumentUpload: boolean;
};

class DocumentOverview extends Component<Props, State> {
  state: State = Object.freeze({
    documentDownloadStatus: constants.LOADING_DEFAULT,
    isRename: false,
    fileName: "",
    breadcrumbData: [],
    loadDocumentUpload: false,
  });

  constructor(props) {
    super(props);
    this.setUploadDocument = this.setUploadDocument.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps: Props, prevstate: State) {
    const { documentId } = prevProps.match.params;
    if (
      this.props.match.params.documentId !== prevProps.match.params.documentId
    ) {
      this.props.dispatch(resetDocOverview(documentId));
      this.getData();
    }

    const removeAssociationLoadingStatus =
      this.props.removeAssociationReducer?.loadingStatus;

    if (removeAssociationLoadingStatus === constants.LOADING_SUCCESS) {
      this.getData();
      this.onDisassociationSuccess();
      this.setState({
        breadcrumbData:
          this.props.docOverviewReducer[documentId]?.docOverviewData
            ?.breadcrumb_data,
      });
    }
  }

  componentWillUnmount() {
    const { documentId } = this.props.match.params;
    this.props.dispatch(resetDocOverview(documentId));
  }

  getData = () => {
    const { documentId } = this.props.match.params;
    this.props.dispatch(getDocOverview(documentId, 0));
    this.getAssociatioData()
  };

  getAssociatioData = () => {
    const { documentId } = this.props.match.params;
    this.props.dispatch(getDocAssociaitons(documentId));
  }

  // TODO: udpate this once the actions are ready, can be a common function
  onActionButtonClick = ({ detail }) => {
    const { documentId } = this.props.match.params;
    if (detail.id === DOCUMENT_ACTIONS.RENAME.ID) {
      this.setState({ isRename: true });
    }
    if (detail.id === DOCUMENT_ACTIONS.SHARE.ID) {
      const { docOverviewData } = this.props.docOverviewReducer[documentId];
      this.props.dispatch(setShareDocs([docOverviewData]));
      this.props.history.push(`/files/${documentId}/share`);
    }
  };

  onDisassociationSuccess = () => {
    // set notification
    const notifications = [
      {
        type: constants.STATUS_SUCCESS,
        content: <span>Successfully removed association</span>,
      },
    ];

    const { documentId } = this.props.match.params;
    this.props.dispatch(setNotifications(notifications, documentId));
    this.props.dispatch(resetRemoveAssociation(documentId));
  };

  onDisassociationFailure = () => {
    // set notification
    const notifications = [
      {
        type: constants.STATUS_ERROR,
        content: <span>Failed to remove association, please try again.</span>,
      },
    ];

    const { documentId } = this.props.match.params;
    this.props.dispatch(setNotifications(notifications, documentId));
    this.props.dispatch(resetRemoveAssociation(documentId));
  };

  onDismissNotification = (index: number) => {
    const { documentId } = this.props.match.params;
    this.props.dispatch(deleteNotification(index, documentId));
  };

  onDownloadasZip = () => {
    this.setState({
      documentDownloadStatus: constants.LOADING_LOAD,
    });
    const { documentId } = this.props.match.params;
    fileDownloadS3Call(documentId).then((response) => {
      if (response.status === constants.STATUS_SUCCESS) {
        window.location.href = response.s3_url;
        this.setState({
          documentDownloadStatus: constants.LOADING_SUCCESS,
        });
      } else {
        this.setState({
          documentDownloadStatus: constants.LOADING_FAIL,
        });
        this.props.dispatch(setNotifications(DOWNLOAD_FAILED_NOTIFICATION, documentId));
      }
    });
  };

  setUploadDocument(){
    this.setState({loadDocumentUpload: !this.state.loadDocumentUpload});
    this.getAssociatioData();
  }

  render() {
    const { documentId } = this.props.match.params;
    if (
      !this.props.docOverviewReducer[documentId] ||
      !this.props.docOverviewReducer[documentId].loadingStatus ||
      [constants.LOADING_LOAD, constants.LOADING_DEFAULT].includes(
        this.props.docOverviewReducer[documentId].loadingStatus
      ) ||
      this.props.updateDocReducer.loadingStatus === constants.LOADING_LOAD
    ) {
      return (
        <div style={{ maxHeight: "500px", overflow: "auto", textAlign: "center", paddingTop: "200px" }}>
          <Spinner size="large" />
          <br/>
          Loading data
        </div>
      );
    }
    if(this.props.docOverviewReducer[documentId].unAuthorized)
      return <NoAuthz resource={`the given document`}/>
    
    const {
      docOverviewData,
      notifications,
      associationsLoadingStatus,
      associationsData,
    }: {
      docOverviewData: documentOverviewData;
      notifications: notification[];
      associationsLoadingStatus: number;
      associationsData: any;
    } = this.props.docOverviewReducer[documentId];
    const {
      doc_name,
      // doc_class,
      doc_type,
      created_by,
      created_time,
      breadcrumb_data,
      expiry_date,
      project,
      size,
    } = docOverviewData;
    const projectName = project?.name;
    const modelNumber = project?.model_number;

    //-----------------------------------------------Notifications-----------------------------------------------------//
    const notification = (
      <Notification
        notifications={notifications}
        onDismiss={this.onDismissNotification}
      />
    );

    //------------------------------------------------Breadcrumb------------------------------------------------------//
    const breadcrumb = (
      <Breadcrumb
        breadcrumb_data={
          this.state.breadcrumbData.length === 0
            ? breadcrumb_data
            : this.state.breadcrumbData
        }
        history={this.props.history}
      />
    );

    //--------------------------------------------------Header--------------------------------------------------------//
    const docAssociationsTable = (<DocAssociationsTable
      documentId={documentId}
      loadingStatus={associationsLoadingStatus}
      data={associationsData ? associationsData : []}
      dispatch={this.props.dispatch}
      setUploadDocument={this.setUploadDocument}
      onRefresh={this.getAssociatioData}
      projectName={projectName}
    />)

    const uploadComponent = (
      <Upload
      dispatch={this.props.dispatch}
      history={this.props.history}
      tagsConfigReducer={this.props.tagsConfigReducer}
      documentId={documentId}
      onCancel={this.setUploadDocument}
      />
    )

    const documentOverview = (
      <>
        <Container header={<Header variant="h2">Properties</Header>}>
          <ColumnLayout columns={3} variant="text-grid">
            <SpaceBetween size="l">
              <ValueWithLabel label="Type">{doc_type}</ValueWithLabel>
              <ValueWithLabel label="Expiry Status">
                {
                  <ExpiryDate
                    informatiomText={
                      expiry_date
                        ? changeToShortDateFormatAndAddColor(expiry_date).Date
                        : "N/A"
                    }
                    color={
                      expiry_date
                        ? changeToShortDateFormatAndAddColor(expiry_date).color
                        : "N/A"
                    }
                  />
                }
              </ValueWithLabel>
            </SpaceBetween>
            <SpaceBetween size="l">
              <ValueWithLabel label="Creation Time">
                {changeToShortDateFormat(created_time)}
              </ValueWithLabel>
              <ValueWithLabel label="Created By">
                {changeToShortDateFormat(created_by)}
              </ValueWithLabel>
            </SpaceBetween>
            <SpaceBetween size="l">
              <ValueWithLabel label="Size">
                {size ? formatBytes(size) : "__"}
              </ValueWithLabel>
            </SpaceBetween>
          </ColumnLayout>
        </Container>
        <br />
        <br />
        {this.state.loadDocumentUpload ? uploadComponent : docAssociationsTable}
      </>
    );

    const header = (
      <HeaderStyle
        variant="h1"
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <ButtonDropdown
              items={[
                {
                  text: DOCUMENT_ACTIONS.RENAME.TEXT,
                  id: DOCUMENT_ACTIONS.RENAME.ID,
                },
                {
                  text: DOCUMENT_ACTIONS.SHARE.TEXT,
                  id: DOCUMENT_ACTIONS.SHARE.ID,
                },
              ]}
              onItemClick={this.onActionButtonClick}
            >
              Actions
            </ButtonDropdown>
            <Button
              loading={
                this.state.documentDownloadStatus === constants.LOADING_LOAD
              }
              iconName="download"
              variant="primary"
              onClick={this.onDownloadasZip}
              disabled={associationsData === undefined}
            >
              Download
            </Button>
          </SpaceBetween>
        }
      >
        {this.state.isRename ? (
          <SpaceBetween direction="horizontal" size="xs">
            {formatDocName("", projectName, modelNumber)}
            <Rename
              doc_id={documentId}
              initial_value={
                this.state.fileName?.length === 0
                  ? doc_name
                  : this.state.fileName
              }
              setIsRename={(val) => {
                this.setState({ isRename: val });
              }}
              setFileName={(val) => {
                this.setState({ fileName: val });
                breadcrumb_data.length === 5 &&
                  (breadcrumb_data[4].doc_name = val);
                this.setState({ breadcrumbData: breadcrumb_data });
              }}
              type={RENAME_TYPE.DOC}
              loadingStatus={this.props.updateDocReducer.loadingStatus}
            />
          </SpaceBetween>
        ) : (
          formatDocName(
            this.state.fileName?.length === 0 ? doc_name : this.state.fileName,
            projectName,
            modelNumber
          )
        )}
        {this.props.updateDocReducer.loadingStatus ===
          constants.LOADING_FAIL && (
          <Box color="text-status-error" variant="p">
            {" "}
            {ERROR_RENAME.ERROR_DOC_NAME}
          </Box>
        )}
      </HeaderStyle>
    );

    //-----------------------------------------------Main Content-----------------------------------------------------//
    const mainContent = documentOverview;
    return (
      <AppLayout
        navigationHide
        toolsHide
        contentHeader={header}
        notifications={notification}
        breadcrumbs={breadcrumb}
        content={mainContent}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    docOverviewReducer: state.docOverviewReducer,
    removeAssociationReducer: state.removeAssociationReducer,
    updateDocReducer: state.updateDocReducer,
    tagsConfigReducer: state.tagsConfigReducer,
  };
};

export default connect(mapStateToProps)(DocumentOverview);
