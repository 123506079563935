import { deleteDocType } from "../types/delete-doc-type";

export const setDeleteDocs = (selectedDocs: Array<any>): any => ({
  type: deleteDocType.SET_DELETE_DOCS,
  selectedDocs,
});

export const resetDeleteDocs = (): any => ({
  type: deleteDocType.RESET_DELETE_DOCS,
});
