import moment from "moment";
import { EXPIRY_DATE_COLOR_CODE } from "../config/constants";

// change date iso string -> user friendly Time String
export const changeDateFormat = (isoDateStr: string): string => {
  // check iso format
  if (!moment(isoDateStr, moment.ISO_8601).isValid()) {
    return isoDateStr;
  }

  return moment(isoDateStr).format("ddd, MMMM Do YYYY, HH:mm:ss") + " GMT";
};

export const changeToShortDateFormat = (isoDateStr: string): string => {
  // check iso format
  if (!moment(isoDateStr, moment.ISO_8601).isValid()) {
    return isoDateStr;
  }

  return moment(isoDateStr).format("MMMM Do YYYY");
};

export const changeToShortDateFormatAndAddColor = (isoDateStr: string): any => {
  const date_format = "YYYY-MM-DD";
  const locale = window.navigator.language;
  const iso_date_str = moment(isoDateStr).locale(locale).format(date_format);
  const after_three_months = moment()
    .add(3, "months")
    .locale(locale)
    .format(date_format);
  const after_six_months = moment()
    .add(6, "months")
    .locale(locale)
    .format(date_format);

  var iso_date_str_update = new Date(iso_date_str.replace(/-/g, "/"));

  var after_three_months_update = new Date(
    after_three_months.replace(/-/g, "/")
  );
  var after_six_months_update = new Date(after_six_months.replace(/-/g, "/"));

  let color = EXPIRY_DATE_COLOR_CODE.RED_COLOR_HEX;
  if (after_three_months_update >= iso_date_str_update) {
    color = EXPIRY_DATE_COLOR_CODE.RED_COLOR_HEX;
  } else if (after_six_months_update >= iso_date_str_update) {
    color = EXPIRY_DATE_COLOR_CODE.YELLOW_COLOR_HEX;
  } else {
    color = EXPIRY_DATE_COLOR_CODE.GREEN_COLOR_HEX;
  }
  // check iso format
  if (!moment(isoDateStr, moment.ISO_8601).isValid()) {
    return {
      Date: isoDateStr,
      color: color,
    };
  }
  return {
    Date: moment(isoDateStr).format("MMMM Do YYYY"),
    color: color,
  };
};
