import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  FormField,
  Multiselect,
  ExpandableSection,
  ColumnLayout,
  Grid,
} from "@amzn/awsui-components-react";
import AddItemToChecklist from "./add-item-to-checklist";
import {
  initializeChecklist,
  updateChecklist,
} from "../../redux/actions/checklist-action";
import {
  formatChecklist,
  formatSelectedOptions,
  convertToDropdownOptions,
} from "./config";
import { DOCUMENT_TYPES, CHECKLIST } from "../../config/constants";
import { formatDocName } from "../../utils/doc-name-utils";
// import { showClassAsDescription } from "../../utils/general-utils";

const Checklist = ({
  grandparent,
  parent,
  initialChecklist,
  docList,
  project,
  modelNo,
  index,
}) => {
  const dispatch = useDispatch();

  // redux
  const { checklist } = useSelector((state: any) => ({
    checklist: state.checklistReducer.checklist,
  }));

  useEffect(() => {
    dispatch(
      initializeChecklist(
        grandparent,
        parent,
        formatChecklist(project, modelNo, initialChecklist)
      )
    );
  }, [parent]); // eslint-disable-line

  useEffect(() => {
    if (selectedTechnicalDocuments.length !== 0) {
      dispatch(
        updateChecklist(
          grandparent,
          parent,
          DOCUMENT_TYPES.TECHNICAL_DOCUMENTATION,
          formatSelectedOptions(project, modelNo, selectedTechnicalDocuments)
        )
      );
    }
  }, [project, modelNo]); // eslint-disable-line

  useEffect(() => {
    if (selectedLettersForms.length !== 0) {
      dispatch(
        updateChecklist(
          grandparent,
          parent,
          DOCUMENT_TYPES.LETTER_FORMS,
          formatSelectedOptions(project, modelNo, selectedLettersForms)
        )
      );
    }
  }, [project, modelNo]); // eslint-disable-line

  useEffect(() => {
    if (selectedCertificates.length !== 0) {
      dispatch(
        updateChecklist(
          grandparent,
          parent,
          DOCUMENT_TYPES.CERTIFICATES,
          formatSelectedOptions(project, modelNo, selectedCertificates)
        )
      );
    }
  }, [project, modelNo]); // eslint-disable-line

  useEffect(() => {
    if (selectedTestReports.length !== 0) {
      dispatch(
        updateChecklist(
          grandparent,
          parent,
          DOCUMENT_TYPES.TEST_REPORTS,
          formatSelectedOptions(project, modelNo, selectedTestReports)
        )
      );
    }
  }, [project, modelNo]); // eslint-disable-line

  useEffect(() => {
    if (selectedDevicePhotos.length !== 0) {
      dispatch(
        updateChecklist(
          grandparent,
          parent,
          DOCUMENT_TYPES.DEVICE_PHOTOS,
          formatSelectedOptions(project, modelNo, selectedDevicePhotos)
        )
      );
    }
  }, [project, modelNo]); // eslint-disable-line

  useEffect(() => {
    if (selectedTestPhotos.length !== 0) {
      dispatch(
        updateChecklist(
          grandparent,
          parent,
          DOCUMENT_TYPES.TEST_PHOTOS,
          formatSelectedOptions(project, modelNo, selectedTestPhotos)
        )
      );
    }
  }, [project, modelNo]); // eslint-disable-line

  // states
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState(
    DOCUMENT_TYPES.TECHNICAL_DOCUMENTATION
  );

  const selectedTechnicalDocuments =
    checklist[grandparent] &&
    checklist[grandparent][parent] &&
    checklist[grandparent][parent][DOCUMENT_TYPES.TECHNICAL_DOCUMENTATION]
      ? checklist[grandparent][parent][DOCUMENT_TYPES.TECHNICAL_DOCUMENTATION]
      : [];
  const selectedLettersForms =
    checklist[grandparent] &&
    checklist[grandparent][parent] &&
    checklist[grandparent][parent][DOCUMENT_TYPES.LETTER_FORMS]
      ? checklist[grandparent][parent][DOCUMENT_TYPES.LETTER_FORMS]
      : [];
  const selectedCertificates =
    checklist[grandparent] &&
    checklist[grandparent][parent] &&
    checklist[grandparent][parent][DOCUMENT_TYPES.CERTIFICATES]
      ? checklist[grandparent][parent][DOCUMENT_TYPES.CERTIFICATES]
      : [];
  const selectedTestReports =
    checklist[grandparent] &&
    checklist[grandparent][parent] &&
    checklist[grandparent][parent][DOCUMENT_TYPES.TEST_REPORTS]
      ? checklist[grandparent][parent][DOCUMENT_TYPES.TEST_REPORTS]
      : [];
  const selectedDevicePhotos =
    checklist[grandparent] &&
    checklist[grandparent][parent] &&
    checklist[grandparent][parent][DOCUMENT_TYPES.DEVICE_PHOTOS]
      ? checklist[grandparent][parent][DOCUMENT_TYPES.DEVICE_PHOTOS]
      : [];
  const selectedTestPhotos =
    checklist[grandparent] &&
    checklist[grandparent][parent] &&
    checklist[grandparent][parent][DOCUMENT_TYPES.TEST_PHOTOS]
      ? checklist[grandparent][parent][DOCUMENT_TYPES.TEST_PHOTOS]
      : [];

  const updateSelection = (item) => {
    let newSelection: any = [];
    let index = -1;
    switch (modalType) {
      case DOCUMENT_TYPES.TECHNICAL_DOCUMENTATION:
        newSelection = selectedTechnicalDocuments;
        index = selectedTechnicalDocuments.findIndex(
          (option) => option.value === item.doc_name
        );
        if (index === -1) {
          newSelection = [
            ...selectedTechnicalDocuments,
            {
              label: formatDocName(item.doc_name, project, modelNo),
              value: item.doc_name,
              // doc_class: item.doc_class,
              // description: showClassAsDescription(item.doc_class),
            },
          ];
        }
        break;
      case DOCUMENT_TYPES.LETTER_FORMS:
        newSelection = selectedLettersForms;
        index = selectedLettersForms.findIndex(
          (option) => option.value === item.doc_name
        );
        if (index === -1) {
          newSelection = [
            ...selectedLettersForms,
            {
              label: formatDocName(item.doc_name, project, modelNo),
              value: item.doc_name,
              // doc_class: item.doc_class,
              // description: showClassAsDescription(item.doc_class),
            },
          ];
        }
        break;
      case DOCUMENT_TYPES.CERTIFICATES:
        newSelection = selectedCertificates;
        index = selectedCertificates.findIndex(
          (option) => option.value === item.doc_name
        );
        if (index === -1) {
          newSelection = [
            ...selectedCertificates,
            {
              label: formatDocName(item.doc_name, project, modelNo),
              value: item.doc_name,
              // doc_class: item.doc_class,
              // description: showClassAsDescription(item.doc_class),
            },
          ];
        }
        break;
      case DOCUMENT_TYPES.TEST_REPORTS:
        newSelection = selectedTestReports;
        index = selectedTestReports.findIndex(
          (option) => option.value === item.doc_name
        );
        if (index === -1) {
          newSelection = [
            ...selectedTestReports,
            {
              label: formatDocName(item.doc_name, project, modelNo),
              value: item.doc_name,
              // doc_class: item.doc_class,
              // description: showClassAsDescription(item.doc_class),
            },
          ];
        }
        break;
      case DOCUMENT_TYPES.DEVICE_PHOTOS:
        newSelection = selectedDevicePhotos;
        index = selectedDevicePhotos.findIndex(
          (option) => option.value === item.doc_name
        );
        if (index === -1) {
          newSelection = [
            ...selectedDevicePhotos,
            {
              label: formatDocName(item.doc_name, project, modelNo),
              value: item.doc_name,
              // doc_class: item.doc_class,
              // description: showClassAsDescription(item.doc_class),
            },
          ];
        }
        break;
      case DOCUMENT_TYPES.TEST_PHOTOS:
        newSelection = selectedTestPhotos;
        index = selectedTestPhotos.findIndex(
          (option) => option.value === item.doc_name
        );
        if (index === -1) {
          newSelection = [
            ...selectedTestPhotos,
            {
              label: formatDocName(item.doc_name, project, modelNo),
              value: item.doc_name,
              // doc_class: item.doc_class,
              // description: showClassAsDescription(item.doc_class),
            },
          ];
        }
        break;
      default:
        newSelection = [];
    }
    dispatch(updateChecklist(grandparent, parent, modalType, newSelection));
  };

  // checks if the user entered document name is already selected
  const checkAlreadySelected = (item) => {
    let index = -1;
    switch (modalType) {
      case DOCUMENT_TYPES.TECHNICAL_DOCUMENTATION:
        index = selectedTechnicalDocuments.findIndex(
          (option) => option.value === item.doc_name
        );
        break;
      case DOCUMENT_TYPES.LETTER_FORMS:
        index = selectedLettersForms.findIndex(
          (option) => option.value === item.doc_name
        );
        break;
      case DOCUMENT_TYPES.CERTIFICATES:
        index = selectedCertificates.findIndex(
          (option) => option.value === item.doc_name
        );
        break;
      case DOCUMENT_TYPES.TEST_REPORTS:
        index = selectedTestReports.findIndex(
          (option) => option.value === item.doc_name
        );
        break;
      case DOCUMENT_TYPES.DEVICE_PHOTOS:
        index = selectedDevicePhotos.findIndex(
          (option) => option.value === item.doc_name
        );
        break;
      case DOCUMENT_TYPES.TEST_PHOTOS:
        index = selectedTestPhotos.findIndex(
          (option) => option.value === item.doc_name
        );
        break;
      default:
        index = -1;
    }
    return index !== -1;
  };

  // checks if the user entered document name already exists in dropdown
  const checkExistsInDropdown = (item) => {
    let dropDownOptions = [];
    switch (modalType) {
      case DOCUMENT_TYPES.TECHNICAL_DOCUMENTATION:
        dropDownOptions = convertToDropdownOptions(
          docList[DOCUMENT_TYPES.TECHNICAL_DOCUMENTATION],
          selectedTechnicalDocuments
        );
        break;
      case DOCUMENT_TYPES.LETTER_FORMS:
        dropDownOptions = convertToDropdownOptions(
          docList[DOCUMENT_TYPES.LETTER_FORMS],
          selectedLettersForms
        );
        break;
      case DOCUMENT_TYPES.CERTIFICATES:
        dropDownOptions = convertToDropdownOptions(
          docList[DOCUMENT_TYPES.CERTIFICATES],
          selectedCertificates
        );
        break;
      case DOCUMENT_TYPES.TEST_REPORTS:
        dropDownOptions = convertToDropdownOptions(
          docList[DOCUMENT_TYPES.TEST_REPORTS],
          selectedTestReports
        );
        break;
      case DOCUMENT_TYPES.DEVICE_PHOTOS:
        dropDownOptions = convertToDropdownOptions(
          docList[DOCUMENT_TYPES.DEVICE_PHOTOS],
          selectedDevicePhotos
        );
        break;
      case DOCUMENT_TYPES.TEST_PHOTOS:
        dropDownOptions = convertToDropdownOptions(
          docList[DOCUMENT_TYPES.TEST_PHOTOS],
          selectedTestPhotos
        );
        break;
      default:
        dropDownOptions = [];
    }
    const index = dropDownOptions.findIndex(
      (option: any) => option.value === item.doc_name
    );
    return index !== -1;
  };

  const editChecklist = (type) => {
    setModalType(type);
    setShowModal(true);
  };

  const onChange = (allSelectedOption, docListOption) => {
    if (
      allSelectedOption.length &&
      allSelectedOption[allSelectedOption.length - 1].value ===
        CHECKLIST.UNCHECK_ALL.value
    ) {
      allSelectedOption = [];
    } else if (
      allSelectedOption.length &&
      allSelectedOption[allSelectedOption.length - 1].value ===
        CHECKLIST.CHECK_ALL.value
    ) {
      let options: any = [];
      docListOption.forEach((item) => {
        options.push({
          label: item.doc_name,
          value: item.doc_name,
        });
      });
      allSelectedOption = options;
    }
    return allSelectedOption;
  };
  const onChangeTechnicalDocumentation = ({ detail }) => {
    let allSelectedOption = onChange(
      detail.selectedOptions,
      docList[DOCUMENT_TYPES.TECHNICAL_DOCUMENTATION]
    );
    dispatch(
      updateChecklist(
        grandparent,
        parent,
        DOCUMENT_TYPES.TECHNICAL_DOCUMENTATION,
        formatSelectedOptions(project, modelNo, allSelectedOption)
      )
    );
  };

  const onChangeLettersForms = ({ detail }) => {
    let allSelectedLettersFormsOption = onChange(
      detail.selectedOptions,
      docList[DOCUMENT_TYPES.LETTER_FORMS]
    );
    dispatch(
      updateChecklist(
        grandparent,
        parent,
        DOCUMENT_TYPES.LETTER_FORMS,
        formatSelectedOptions(project, modelNo, allSelectedLettersFormsOption)
      )
    );
  };

  const onChangeCertificates = ({ detail }) => {
    let allSelectedCertificatesOption = onChange(
      detail.selectedOptions,
      docList[DOCUMENT_TYPES.CERTIFICATES]
    );

    dispatch(
      updateChecklist(
        grandparent,
        parent,
        DOCUMENT_TYPES.CERTIFICATES,
        formatSelectedOptions(project, modelNo, allSelectedCertificatesOption)
      )
    );
  };

  const onChangeTestReports = ({ detail }) => {
    let allSelectedTestReports = onChange(
      detail.selectedOptions,
      docList[DOCUMENT_TYPES.TEST_REPORTS]
    );
    dispatch(
      updateChecklist(
        grandparent,
        parent,
        DOCUMENT_TYPES.TEST_REPORTS,
        formatSelectedOptions(project, modelNo, allSelectedTestReports)
      )
    );
  };

  const onChangeDevicePhotos = ({ detail }) => {
    let allSelectedDevicePhotos = onChange(
      detail.selectedOptions,
      docList[DOCUMENT_TYPES.DEVICE_PHOTOS]
    );
    dispatch(
      updateChecklist(
        grandparent,
        parent,
        DOCUMENT_TYPES.DEVICE_PHOTOS,
        formatSelectedOptions(project, modelNo, allSelectedDevicePhotos)
      )
    );
  };

  const onChangeTestPhotos = ({ detail }) => {
    let allSelectedTestPhotos = onChange(
      detail.selectedOptions,
      docList[DOCUMENT_TYPES.TEST_PHOTOS]
    );
    dispatch(
      updateChecklist(
        grandparent,
        parent,
        DOCUMENT_TYPES.TEST_PHOTOS,
        formatSelectedOptions(project, modelNo, allSelectedTestPhotos)
      )
    );
  };

  return (
    <>
      <ExpandableSection
        header={`Checklist for ${parent}`}
        defaultExpanded={index === 0 ? true : false}
      >
        <ColumnLayout columns={3}>
          <FormField
            label={DOCUMENT_TYPES.TECHNICAL_DOCUMENTATION}
            errorText={""}
          >
            <Grid
              disableGutters
              gridDefinition={[{ colspan: 11 }, { colspan: 1 }]}
            >
              <Multiselect
                options={convertToDropdownOptions(
                  docList[DOCUMENT_TYPES.TECHNICAL_DOCUMENTATION],
                  selectedTechnicalDocuments
                )}
                placeholder="Select Technical Docs"
                onChange={onChangeTechnicalDocumentation}
                selectedOptions={selectedTechnicalDocuments}
              />
              <Button
                iconName="edit"
                variant="icon"
                onClick={() =>
                  editChecklist(DOCUMENT_TYPES.TECHNICAL_DOCUMENTATION)
                }
              />
            </Grid>
          </FormField>
          <FormField label={DOCUMENT_TYPES.LETTER_FORMS} errorText={""}>
            <Grid
              disableGutters
              gridDefinition={[{ colspan: 11 }, { colspan: 1 }]}
            >
              <Multiselect
                options={convertToDropdownOptions(
                  docList[DOCUMENT_TYPES.LETTER_FORMS],
                  selectedLettersForms
                )}
                placeholder="Select Letter Forms"
                onChange={onChangeLettersForms}
                selectedOptions={selectedLettersForms}
              />
              <Button
                iconName="edit"
                variant="icon"
                onClick={() => editChecklist(DOCUMENT_TYPES.LETTER_FORMS)}
              />
            </Grid>
          </FormField>
          <FormField label={DOCUMENT_TYPES.CERTIFICATES} errorText={""}>
            <Grid
              disableGutters
              gridDefinition={[{ colspan: 11 }, { colspan: 1 }]}
            >
              <Multiselect
                options={convertToDropdownOptions(
                  docList[DOCUMENT_TYPES.CERTIFICATES],
                  selectedCertificates
                )}
                placeholder="Select Certificates"
                onChange={onChangeCertificates}
                selectedOptions={selectedCertificates}
              />
              <Button
                iconName="edit"
                variant="icon"
                onClick={() => editChecklist(DOCUMENT_TYPES.CERTIFICATES)}
              />
            </Grid>
          </FormField>
          <FormField label={DOCUMENT_TYPES.TEST_REPORTS} errorText={""}>
            <Grid
              disableGutters
              gridDefinition={[{ colspan: 11 }, { colspan: 1 }]}
            >
              <Multiselect
                options={convertToDropdownOptions(
                  docList[DOCUMENT_TYPES.TEST_REPORTS],
                  selectedTestReports
                )}
                placeholder="Select Test Report"
                onChange={onChangeTestReports}
                selectedOptions={selectedTestReports}
              />
              <Button
                iconName="edit"
                variant="icon"
                onClick={() => editChecklist(DOCUMENT_TYPES.TEST_REPORTS)}
              />
            </Grid>
          </FormField>
          <FormField label={DOCUMENT_TYPES.DEVICE_PHOTOS} errorText={""}>
            <Grid
              disableGutters
              gridDefinition={[{ colspan: 11 }, { colspan: 1 }]}
            >
              <Multiselect
                options={convertToDropdownOptions(
                  docList[DOCUMENT_TYPES.DEVICE_PHOTOS],
                  selectedDevicePhotos
                )}
                placeholder="Select Device Photo"
                onChange={onChangeDevicePhotos}
                selectedOptions={selectedDevicePhotos}
              />
              <Button
                iconName="edit"
                variant="icon"
                onClick={() => editChecklist(DOCUMENT_TYPES.DEVICE_PHOTOS)}
              />
            </Grid>
          </FormField>
          <FormField label={DOCUMENT_TYPES.TEST_PHOTOS} errorText={""}>
            <Grid
              disableGutters
              gridDefinition={[{ colspan: 11 }, { colspan: 1 }]}
            >
              <Multiselect
                options={convertToDropdownOptions(
                  docList[DOCUMENT_TYPES.TEST_PHOTOS],
                  selectedTestPhotos
                )}
                placeholder="Select Test Photo"
                onChange={onChangeTestPhotos}
                selectedOptions={selectedTestPhotos}
              />
              <Button
                iconName="edit"
                variant="icon"
                onClick={() => editChecklist(DOCUMENT_TYPES.TEST_PHOTOS)}
              />
            </Grid>
          </FormField>
        </ColumnLayout>
      </ExpandableSection>
      <AddItemToChecklist
        showModal={showModal}
        setShowModal={setShowModal}
        modalType={modalType}
        updateSelection={updateSelection}
        checkAlreadySelected={checkAlreadySelected}
        checkExistsInDropdown={checkExistsInDropdown}
      />
    </>
  );
};

export default Checklist;
