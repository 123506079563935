import {
  CollectionPreferences,
  PropertyFilterProps,
} from "@amzn/awsui-components-react";
import { changeToShortDateFormat } from "../../../utils/date-utils";
import { NavLink } from "react-router-dom";

// define columns
export const COLUMN_DEFINITIONS = [
  {
    id: "project",
    sortingField: "project",
    header: "Project",
    cell: (item) => item.project,
    visible: true,
    minWidth: 100,
  },
  {
    id: "country_or_industry",
    sortingField: "country_or_industry",
    header: "Country/Industry",
    cell: (item) => item.country,
    visible: true,
    minWidth: 100,
  },
  {
    id: "document",
    sortingField: "document",
    header: "Document",
    cell: (item) => (
      <NavLink to={`/documents/${item.doc_id}/overview`}>
        {item.doc_name}
      </NavLink>
    ),
    visible: true,
    minWidth: 100,
  },
  {
    id: "associated_by",
    sortingField: "associated_by",
    header: "Associated By",
    cell: (item) => item.associated_by,
    visible: true,
    minWidth: 100,
  },
  {
    id: "associated_time",
    sortingField: "associated_time",
    header: "Associated Time",
    cell: (item) => changeToShortDateFormat(item.associated_time),
    visible: true,
    minWidth: 100,
  },
];

// define visioble columns
export const VISIBLE_CONTENT_OPTIONS = [
  {
    label: "Properties",
    options: [
      { id: "project", label: "Project", visible: true, editable: false },
      {
        id: "country_or_industry",
        label: "Country/Industry",
        visible: true,
        editable: false,
      },
      { id: "document", label: "Document", visible: true, editable: false },
      {
        id: "associated_by",
        label: "Associated By",
        visible: true,
        editable: false,
      },
      {
        id: "associated_time",
        label: "Associated Time",
        visible: true,
        editable: true,
      },
    ],
  },
];

//define filtering properties
export const FILTERING_PROPERTIES: PropertyFilterProps.FilteringProperty[] = [
  {
    propertyLabel: "Project",
    key: "project",
    groupValuesLabel: "Project",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Country/Industry",
    key: "country_or_industry",
    groupValuesLabel: "Country/Industry",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Document",
    key: "document",
    groupValuesLabel: "Document",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Associated By",
    key: "associated_by",
    groupValuesLabel: "Associated By",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Associated Time",
    key: "associated_time",
    groupValuesLabel: "Associated Time",
    operators: [":", "!:", "=", "!="],
  },
];

// define page size options
export const PAGE_SIZE_OPTIONS = [
  { value: 50, label: "50 items" },
  { value: 100, label: "100 items" },
  { value: 300, label: "300 items" },
];

// define default preferences
export const DEFAULT_PREFERENCES = {
  pageSize: 300,
  visibleContent: COLUMN_DEFINITIONS.filter((column) => column.visible).map(
    (column) => column.id
  ),
  wrapLines: true,
};

export const Preferences = ({
  preferences,
  setPreferences,
  disabled,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
  visibleContentOptions = VISIBLE_CONTENT_OPTIONS,
}) => (
  <CollectionPreferences
    title="Preferences"
    confirmLabel="Confirm"
    cancelLabel="Cancel"
    disabled={disabled}
    preferences={preferences}
    onConfirm={({ detail }) => {
      setPreferences(detail);
    }}
    pageSizePreference={{ title: "Page size", options: pageSizeOptions }}
    wrapLinesPreference={{
      label: "Wrap lines",
      description: "Check to see all the text and wrap the lines",
    }}
    visibleContentPreference={{
      title: "Select visible content",
      options: visibleContentOptions,
    }}
  />
);
