import { documentOverviewData } from "../model/doc-overview";

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
export const pluralize = (word: string): string => {
  if (word.endsWith("y")) {
    word = word.substring(0, word.length - 1) + "ies";
  }
  return word;
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const formatTagsView = (tagsArray) => {
  let tagsString = "";
  tagsArray.forEach((item, index) => {
    if (index === tagsArray.length - 1) tagsString += `${item.value}`;
    else tagsString += `${item.value}, `;
  });
  return tagsString;
};

export const s3URIToLink = (uri) => {
  let uriParts = uri.split("/");
  const sourceKey = uri.split(uriParts[2])[1];
  const downloadableLink = `https://${uriParts[2]}.s3.us-west-2.amazonaws.com${sourceKey}`;
  return downloadableLink;
};

export const formatSearchResults = (items) => {
  let formattedItems: documentOverviewData[] = [];
  if (items && items.length > 0) {
    items.forEach((item) => {
      formattedItems.push(item._source);
    });
  }
  return formattedItems;
};

// export const showClassAsDescription = (doc_class: string) => {
//   return `${capitalizeFirstLetter(doc_class)} Document`;
// };
