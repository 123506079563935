import authzType from "../types/authz-type";
import { config } from "../../config/config";
import endpoints from "../../config/endpoints";

export const authZCall = (username: string, bindleType: string) => ({
  type: authzType.AUTHZ_CALL,
  http: {
    cognito: {
      effect: {
        url: config.BASE_AUTHZ_URL + endpoints.authzEndpoint(),
        method: "POST",
        json: {
          username: username,
          bindle_type: bindleType,
        },
      },
      commit: {
        type: authzType.AUTHZ_CALL_COMMIT,
      },
      rollback: {
        type: authzType.AUTHZ_CALL_ROLLBACK,
      },
    },
  },
});
