import { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
//redux
import { connect } from "react-redux";
import { getDeletedDocs } from "../../redux/actions/trash-action";
import { Dispatch } from "redux";
// sub components
import { AppLayout, Spinner } from "@amzn/awsui-components-react";
import TrashTable from "./table/table";
// configs, contants
import constants from "../../config/constants";
import { deletedDocData } from "../../model/trash";
//css
import "../../index.css";
import { HeaderStyle } from "../../config/constants";

// declare prop check
type Props = {
  dispatch: Dispatch<any>;
  history: any;
  trashReducer: any;
} & RouteComponentProps<any>;

// declare state check
type State = {};

class Trash extends Component<Props, State> {
  state: State = Object.freeze({});

  componentDidMount() {
    this.getData();
  }

  componentWillUnmount() {}

  getData = () => {
    this.props.dispatch(getDeletedDocs());
  };

  render() {
    if (
      [constants.LOADING_LOAD, constants.LOADING_DEFAULT].includes(
        this.props.trashReducer.loadingStatus
      )
    ) {
      return <Spinner size="large" />;
    }
    const {
      deletedDocData,
      loadingStatus,
    }: {
      deletedDocData: deletedDocData;
      loadingStatus: number;
    } = this.props.trashReducer;

    //--------------------------------------------------Header--------------------------------------------------------//
    const header = (
      <HeaderStyle variant="h1" className="pt-4">
        Trash
      </HeaderStyle>
    );

    //-----------------------------------------------Main Content-----------------------------------------------------//
    const mainContent = (
      <TrashTable
        data={deletedDocData}
        loadingStatus={loadingStatus}
        getData={this.getData}
      />
    );

    return (
      <AppLayout
        navigationHide
        toolsHide
        contentHeader={header}
        content={mainContent}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    trashReducer: state.trashReducer,
  };
};

export default connect(mapStateToProps)(Trash);
