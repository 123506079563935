import {
  Box,
  Button,
  Grid,
  Header,
  Modal,
  SpaceBetween,
  Link,
  Form,
  Container,
} from "@amzn/awsui-components-react";
import { Component } from "react";
import UploadFileDropzone from "./file-dropzone/file-dropzone";
import { fileObject } from "../../../model/file";
import FileDetailsForm from "./file-details/file-details-form";
import FileSummaryTable from "./table/table";
import { ValueWithLabel } from "../../../components/common/labels";
import { connect } from "react-redux";
// declare prop check
type Props = {
  history: any;
  onUpload: any;
  tagsConfigReducer: any;
  onCancel?: any;
};

// declare state check
type State = {
  selectedFiles: Array<fileObject>;
  addedFiles: Array<fileObject>;
  openModal: boolean;
  showErrorModal: boolean;
  errorModalData: string;
};

class UploadForm extends Component<Props> {
  state: State = Object.freeze({
    openModal: false,
    selectedFiles: [],
    addedFiles: [],
    showErrorModal: false,
    errorModalData: "",
  });
  onSubmitSelectedFiles = (selectedFiles: Array<fileObject>) => {
    this.setState({ selectedFiles, openModal: true });
  };

  onSubmitAddedFiles = (addedFiles: Array<fileObject>) => {
    this.setState({
      addedFiles: [...addedFiles, ...this.state.addedFiles],
      selectedFiles: [],
      openModal: false,
    });
  };

  setModalState = (isOpen: boolean) => {
    this.setState({ openModal: isOpen });
  };

  loadComponent = (componentName) => {
    this.setState({ componentToLoad: componentName });
  };
  deleteRows = (selectedItems: Array<any>, setSelectedItems: any): any => {
    let tableData = [...this.state.addedFiles];
    selectedItems.forEach((item) => {
      const index = tableData.indexOf(item);
      if (index > -1) {
        tableData.splice(index, 1);
      }
    });
    this.setState({
      addedFiles: tableData,
    });
    setSelectedItems([]);
  };
  validate = () => {
    let isValid = true;
    let duplicateFiles: Array<fileObject> = [];
    let fileNameSet = new Set();
    this.state.addedFiles.forEach((file: fileObject) => {
      if (fileNameSet.has(file.file.name)) {
        isValid = false;
        duplicateFiles.push(file);
      } else {
        fileNameSet.add(file.file.name);
      }
    });
    if (duplicateFiles.length > 0) {
      this.setState({
        showErrorModal: true,
        errorModalData: (
          <>
            <p>Files with duplicate names are not permitted</p>
            <Grid gridDefinition={[{ colspan: 7 }, { colspan: 5 }]}>
              <b>File Name</b>
            </Grid>
            {duplicateFiles.map((file: fileObject, fileIndex) => (
              <Grid
                gridDefinition={[{ colspan: 7 }, { colspan: 5 }]}
                key={fileIndex}
              >
                <p>{file.file.name}</p>
              </Grid>
            ))}
          </>
        ),
      });
    }
    return isValid;
  };
  onUpload = () => {
    if (this.validate()) {
      this.props.onUpload(this.state.addedFiles);
      this.reset();
    }
  };
  reset = () => {
    this.setState({
      openModal: false,
      selectedFiles: [],
      addedFiles: [],
      showErrorModal: false,
      errorModalData: "",
    });
  };
  render() {
    return (
      <Form
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button onClick={() =>
              // if onCancel is passed, call onCancel, otherwise redirect to repository page
              this.props.onCancel ? this.props.onCancel() : this.props.history.push("/repository")
            }>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={this.onUpload}
              disabled={
                !this.state.addedFiles || this.state.addedFiles.length === 0
              }
            >
              Upload
            </Button>
          </SpaceBetween>
        }
      >
        <SpaceBetween size="m">
          <Container>
            <UploadFileDropzone
              onSubmitSelectedFiles={this.onSubmitSelectedFiles}
            />
          </Container>

          {this.state.openModal && (
            <FileDetailsForm
              openModal={this.state.openModal}
              setModalState={this.setModalState}
              files={this.state.selectedFiles}
              tagsConfig={this.props.tagsConfigReducer.tagsConfigData}
              onSubmitAddedFiles={this.onSubmitAddedFiles}
            />
          )}
          <FileSummaryTable
            data={this.state.addedFiles}
            loadingStatus={true}
            history={this.props.history}
            deleteRows={this.deleteRows}
          />
          <Container header={<Header variant="h2">Destination</Header>}>
            <ValueWithLabel label="Destination Location">
              {"File Repository"}
              <br />
              <Link href="/repository" external={true}>
                {window.location.origin}
                {"/repository"}
              </Link>
            </ValueWithLabel>
          </Container>
          <Modal
            visible={this.state.showErrorModal}
            onDismiss={() => this.setState({ showRejectModal: false })}
            header="Error"
            footer={
              <Box float="right">
                <SpaceBetween direction="horizontal" size="xs">
                  <Button
                    variant="primary"
                    onClick={() => this.setState({ showErrorModal: false })}
                  >
                    Ok
                  </Button>
                </SpaceBetween>
              </Box>
            }
          >
            {this.state.errorModalData}
          </Modal>
        </SpaceBetween>
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tagsConfigReducer: state.tagsConfigReducer,
  };
};

export default connect(mapStateToProps)(UploadForm);
