import {
  CollectionPreferences,
  PropertyFilterProps,
} from "@amzn/awsui-components-react";
import { ResourceLabel } from "../../../components/common/labels";
import { changeToShortDateFormat } from "../../../utils/date-utils";
import { formatBytes } from "../../../utils/general-utils";

// define columns
export const COLUMN_DEFINITIONS = [
  {
    id: "doc_name",
    sortingField: "doc_name",
    header: "Name",
    cell: (item) => item.doc_name,
    visible: true,
    width: 300,
    minWidth: 200,
  },
  {
    id: "type",
    sortingField: "type",
    header: "Type",
    cell: (item) => <ResourceLabel resource={item.type} />,
    visible: true,
    width: 150,
  },
  {
    id: "deleted_by",
    sortingField: "deleted_by",
    header: "Deleted By",
    cell: (item) => item.deleted_by,
    visible: true,
    width: 150,
  },
  {
    id: "deleted_time",
    sortingField: "deleted_time",
    header: "Deletion Time",
    cell: (item) => changeToShortDateFormat(item.deleted_time),
    visible: true,
    width: 250,
    minWidth: 100,
  },
  {
    id: "created_time",
    sortingField: "created_time",
    header: "Creation Time",
    cell: (item) => changeToShortDateFormat(item.created_time),
    visible: false,
    minWidth: 100,
  },
  {
    id: "created_by",
    sortingField: "created_by",
    header: "Created By",
    cell: (item) => changeToShortDateFormat(item.created_by),
    visible: false,
    width: 150,
  },
  {
    id: "expiry_date",
    sortingField: "expiry_date",
    header: "Expiry Date",
    cell: (item) =>
      item.expiry_date ? changeToShortDateFormat(item.expiry_date) : "__",
    visible: false,
    minWidth: 100,
  },
  {
    id: "doc_type",
    sortingField: "doc_type",
    header: "Type of Document",
    cell: (item) => (item.doc_type ? item.doc_type : "__"),
    visible: true,
    width: 100,
  },
  {
    id: "size",
    sortingField: "size",
    header: "Size",
    cell: (item) => (item.size ? formatBytes(item.size) : "__"),
    visible: true,
    width: 150,
  },
];

// define visible columns
export const VISIBLE_CONTENT_OPTIONS = [
  {
    label: "Properties",
    options: [
      { id: "doc_name", label: "Name", visible: true, editable: false },
      { id: "type", label: "Type", visible: true, editable: false },
      { id: "size", label: "Size", visible: true, editable: false },
      { id: "deleted_by", label: "Deleted By", visible: true, editable: false },
      {
        id: "deleted_time",
        label: "Deletion Time",
        visible: true,
        editable: false,
      },
      {
        id: "created_time",
        label: "Creation Time",
        visible: false,
        editable: true,
      },
      {
        id: "created_by",
        label: "Created By",
        visible: false,
        editable: true,
      },
      {
        id: "expiry_date",
        label: "Expiry Date",
        visible: false,
        editable: true,
      },
      {
        id: "doc_type",
        label: "Type of Document",
        visible: false,
        editable: true,
      },
    ],
  },
];

// define filtering properties
export const FILTERING_PROPERTIES: PropertyFilterProps.FilteringProperty[] = [
  {
    propertyLabel: "Name",
    key: "doc_name",
    groupValuesLabel: "Name",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Type",
    key: "type",
    groupValuesLabel: "Type",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Size",
    key: "size",
    groupValuesLabel: "Size",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Deleted By",
    key: "deleted_by",
    groupValuesLabel: "Deleted By",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Deletion Time",
    key: "deleted_time",
    groupValuesLabel: "Deletion Time",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Creation Time",
    key: "created_time",
    groupValuesLabel: "Creation Time",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Created By",
    key: "created_by",
    groupValuesLabel: "Created By",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Expiry Date",
    key: "expiry_date",
    groupValuesLabel: "Expiry Date",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Type of Document",
    key: "doc_type",
    groupValuesLabel: "Type of Document",
    operators: [":", "!:", "=", "!="],
  },
];

// define page size options
export const PAGE_SIZE_OPTIONS = [
  { value: 50, label: "50 items" },
  { value: 100, label: "100 items" },
  { value: 300, label: "100 items" },
];

// define default preferences
export const DEFAULT_PREFERENCES = {
  pageSize: 300,
  visibleContent: COLUMN_DEFINITIONS.filter((column) => column.visible).map(
    (column) => column.id
  ),
  wrapLines: false,
};

export const Preferences = ({
  preferences,
  setPreferences,
  disabled,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
  visibleContentOptions = VISIBLE_CONTENT_OPTIONS,
}) => (
  <CollectionPreferences
    title="Preferences"
    confirmLabel="Confirm"
    cancelLabel="Cancel"
    disabled={disabled}
    preferences={preferences}
    onConfirm={({ detail }) => setPreferences(detail)}
    pageSizePreference={{
      title: "Page size",
      options: pageSizeOptions,
    }}
    wrapLinesPreference={{
      label: "Wrap lines",
      description: "Check to see all the text and wrap the lines",
    }}
    visibleContentPreference={{
      title: "Select visible content",
      options: visibleContentOptions,
    }}
  />
);
