import constants from "../../config/constants";
import { deleteDocType } from "../types/delete-doc-type";

interface state {
  selectedDocs: Array<any>;
}

const initialState: state = {
  selectedDocs: [],
};

const deleteDocReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case deleteDocType.SET_DELETE_DOCS:
      const selectedDocs = action.selectedDocs.map((doc) => {
        return { ...doc, deleteStatus: constants.STATUS_PROCESS };
      });
      return {
        ...state,
        selectedDocs,
      };
    case deleteDocType.RESET_DELETE_DOCS:
      return {
        ...state,
        selectedDocs: [],
      };
    default:
      return state;
  }
};

export default deleteDocReducer;
