import tagsConfigType from "../types/tags-config-type";
import constants from "../../config/constants";

export interface state {
  loadingStatus: number;
  message: string;
  tagsConfigData: any;
}

const initialState: state = {
  loadingStatus: constants.LOADING_DEFAULT,
  message: "",
  tagsConfigData: {},
};

const tagsConfigReducer = (state: state = initialState, action: any) => {
  switch (action.type) {
    case tagsConfigType.GET_TAGS_CONFIG:
      return {
        ...state,
        loadingStatus: constants.LOADING_LOAD,
      };
    case tagsConfigType.GET_TAGS_CONFIG_COMMIT:
      return {
        ...state,
        loadingStatus: constants.LOADING_SUCCESS,
        message: action.payload.message,
        tagsConfigData: action.payload.data,
      };

    case tagsConfigType.GET_TAGS_CONFIG_ROLLBACK:
      return {
        ...state,
        loadingStatus: constants.LOADING_FAIL,
        message: action.payload.message,
      };

    case tagsConfigType.RESET_TAGS_CONFIG:
      return {
        ...state,
        loadingStatus: constants.LOADING_DEFAULT,
        message: "",
        tagsConfigData: {},
      };

    default:
      return state;
  }
};

export default tagsConfigReducer;
