import {
  CollectionPreferences,
  PropertyFilterProps,
} from "@amzn/awsui-components-react";
import { StatusLabel } from "../../../../components/common/labels";

// define columns
export const COLUMN_DEFINITIONS = [
  {
    id: "email",
    sortingField: "email",
    header: "Email",
    cell: (item) => item.email,
    visible: true,
    minWidth: 350,
  },
  {
    id: "status",
    sortingField: "status",
    header: "Status",
    cell: (item) => <StatusLabel status={item.status} />,
    visible: true,
    minWidth: 80,
  },
  {
    id: "error",
    sortingField: "error",
    header: "Error",
    cell: (item) => (item.error ? item.error : "__"),
    visible: true,
    minWidth: 100,
  },
];

// define visible columns
export const VISIBLE_CONTENT_OPTIONS = [
  {
    label: "Properties",
    options: [
      { id: "email", label: "Email", visible: true, editable: false },
      { id: "status", label: "Status", visible: true, editable: false },
      { id: "error", label: "Error", visible: true, editable: false },
    ],
  },
];

// define filtering properties
export const FILTERING_PROPERTIES: PropertyFilterProps.FilteringProperty[] = [
  {
    propertyLabel: "Email",
    key: "email",
    groupValuesLabel: "Email",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Status",
    key: "status",
    groupValuesLabel: "Status",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Error",
    key: "error",
    groupValuesLabel: "Error",
    operators: [":", "!:", "=", "!="],
  },
];

// define page size options
export const PAGE_SIZE_OPTIONS = [
  { value: 5, label: "50 items" },
  { value: 10, label: "100 items" },
  { value: 30, label: "100 items" },
  { value: 100, label: "100 items" },
];

// define default preferences
export const DEFAULT_PREFERENCES = {
  pageSize: 5,
  visibleContent: COLUMN_DEFINITIONS.filter((column) => column.visible).map(
    (column) => column.id
  ),
  wrapLines: false,
};

export const Preferences = ({
  preferences,
  setPreferences,
  disabled,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
  visibleContentOptions = VISIBLE_CONTENT_OPTIONS,
}) => (
  <CollectionPreferences
    title="Preferences"
    confirmLabel="Confirm"
    cancelLabel="Cancel"
    disabled={disabled}
    preferences={preferences}
    onConfirm={({ detail }) => setPreferences(detail)}
    pageSizePreference={{
      title: "Page size",
      options: pageSizeOptions,
    }}
    wrapLinesPreference={{
      label: "Wrap lines",
      description: "Check to see all the text and wrap the lines",
    }}
    visibleContentPreference={{
      title: "Select visible content",
      options: visibleContentOptions,
    }}
  />
);
