import { updateFileType } from "../types/update-file-type";
import { config } from "../../config/config";
import { METRIC_INFO } from "../../config/constants";
import endpoints from "../../config/endpoints";
import { updateFileRequest } from "../../model/http-json";

export const updateFile = (
  file_id: string,
  values: updateFileRequest
): any => ({
  type: updateFileType.UPDATE_FILE,
  http: {
    cognito: {
      effect: {
        url: config.BASE_URL + endpoints.updateFileEndpoint(file_id),
        method: "PUT",
        json: values,
      },
      commit: {
        type: updateFileType.UPDATE_FILE_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.FILE,
        },
      },
      rollback: {
        type: updateFileType.UPDATE_FILE_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.FILE,
        },
      },
    },
  },
});

export const resetUpdateFile = (): any => ({
  type: updateFileType.RESET_UPDATE_FILE,
});
