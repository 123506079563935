import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
//redux
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { getProjectConfig } from "../../../redux/actions/create-project-action";
import {
  getDocOverview,
  setNotifications,
} from "../../../redux/actions/doc-overview-action";
import {
  addDocuments,
  resetAddDocuments,
} from "../../../redux/actions/add-documents-action";
import { resetChecklist } from "../../../redux/actions/checklist-action";
// sub components
import {
  AppLayout,
  Spinner,
  Header,
  Container,
  Form,
  SpaceBetween,
  Button,
} from "@amzn/awsui-components-react";
import AddDocChecklist from "../../../components/checklist/add-doc-checklist";
import Breadcrumb from "../../../components/common/breadcrumb/breadcrumb";
//config, constants
import { documentOverviewData } from "../../../model/doc-overview";
import { disableExistingDocFromDocList } from "./config";
import constants from "../../../config/constants";
//css

import styled from "styled-components";

export const ContainerStyle = styled.div`
  padding-top: 48px !important;
`;

// declare prop check
type Props = {
  dispatch: Dispatch<any>;
  history: any;
  docOverviewReducer: any;
  getProjectConfigReducer: any;
  addDocumentsReducer: any;
  checklistReducer: any;
} & RouteComponentProps<any>;

// declare state check
type State = {};

class AddDocuments extends Component<Props, State> {
  state: State = Object.freeze({});

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps: Props, prevstate: State) {
    if (this.props.match.params.folderId !== prevProps.match.params.folderId) {
      this.getData();
      this.resetForm();
    }
    const { loadingStatus } = this.props.addDocumentsReducer;
    if (loadingStatus === constants.LOADING_SUCCESS) {
      this.onSuccess();
    }
    if (loadingStatus === constants.LOADING_FAIL) {
      this.onFailure();
    }
  }

  componentWillUnmount() {
    this.resetForm();
  }

  getData = () => {
    const { documentId } = this.props.match.params;
    this.props.dispatch(getDocOverview(documentId, 0));
    this.props.dispatch(getProjectConfig());
  };

  resetForm = () => {
    this.props.dispatch(resetAddDocuments());
    this.props.dispatch(resetChecklist());
  };

  onSuccess = () => {
    // set notification
    const notifications = [
      {
        type: constants.STATUS_SUCCESS,
        content: <span>Successfully added Documents.</span>,
      },
    ];
    const { documentId } = this.props.match.params;
    this.props.dispatch(setNotifications(notifications, documentId));
    // redirect to parent page
    this.redirect();
  };

  onFailure = () => {
    // set notification
    const notifications = [
      {
        type: constants.STATUS_ERROR,
        content: <span>Failed to add documents.</span>,
      },
    ];
    const { documentId } = this.props.match.params;
    this.props.dispatch(setNotifications(notifications, documentId));
    // redirect to parent page
    this.redirect();
  };

  redirect = () => {
    this.resetForm();
    // redirect to parent page
    const { documentId } = this.props.match.params;
    this.props.history.push(`/folders/${documentId}/overview`);
  };

  // executed when form is submitted
  _onSubmit = () => {
    const { documentId } = this.props.match.params;
    const { docOverviewData }: { docOverviewData: documentOverviewData } =
      this.props.docOverviewReducer[documentId];
    const grandparent =
      docOverviewData.doc_type === constants.COUNTRY
        ? "countries"
        : "industries";
    // TODO: update below to handle industries also
    const checklist = this.props.checklistReducer.checklist[grandparent];
    const parentDocName = docOverviewData.doc_name;
    let docsToAdd: any = [];
    Object.keys(checklist[parentDocName]).forEach((type) => {
      docsToAdd = [
        ...docsToAdd,
        ...checklist[parentDocName][type].map((item) => {
          return { name: item.value, type: type, class: "submission" };
        }),
      ];
    });

    if (docsToAdd.length > 0) {
      // dispatch a request to create document
      const values = {
        parent_doc_id: documentId,
        documents: docsToAdd,
      };
      this.props.dispatch(addDocuments(values));
    }
  };

  _onCancel = () => {
    this.redirect();
  };

  render() {
    const documentId = this.props.match.params.documentId;
    if (
      !this.props.docOverviewReducer[documentId] ||
      [constants.LOADING_LOAD, constants.LOADING_DEFAULT].includes(
        this.props.docOverviewReducer[documentId].loadingStatus
      ) ||
      !this.props.getProjectConfigReducer ||
      [constants.LOADING_LOAD, constants.LOADING_DEFAULT].includes(
        this.props.getProjectConfigReducer.loadingStatus
      )
    ) {
      return <Spinner size="large" />;
    }
    const { docOverviewData }: { docOverviewData: documentOverviewData } =
      this.props.docOverviewReducer[documentId];
    const { doc_list } = this.props.getProjectConfigReducer.responseData;

    const { breadcrumb_data } = docOverviewData;
    const { loadingStatus } = this.props.addDocumentsReducer;
    const { checklist } = this.props.checklistReducer;

    //------------------------------------------------Breadcrumb------------------------------------------------------//
    const breadcrumb = (
      <Breadcrumb
        breadcrumb_data={[
          ...breadcrumb_data,
          {
            doc_name: "Add Documents",
            path: `documents/${documentId}/create`,
          },
        ]}
        history={this.props.history}
      />
    );

    //--------------------------------------------------Header--------------------------------------------------------//
    const header = (
      <Header variant="h1" description="use this page to use Documents">
        Add Documents
      </Header>
    );

    //-----------------------------------------------Main Content-----------------------------------------------------//
    const mainContent = (
      <Container>
        <Form
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                formAction="none"
                variant="link"
                loading={loadingStatus === constants.LOADING_LOAD}
                onClick={this._onCancel}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                loading={loadingStatus === constants.LOADING_LOAD}
                onClick={this._onSubmit}
                disabled={!Object.keys(checklist).length}
              >
                Submit
              </Button>
            </SpaceBetween>
          }
          header={<Header variant="h3">Select Documents</Header>}
        >
          <ContainerStyle>
            <AddDocChecklist
              // TODO: update below to handle industries also
              grandparent={
                docOverviewData.doc_type === constants.COUNTRY
                  ? "countries"
                  : "industries"
              }
              parent={docOverviewData.doc_name}
              project={docOverviewData.project?.name}
              docList={disableExistingDocFromDocList(
                // create deep copy of doc list before passing it
                { ...doc_list },
                docOverviewData.children
              )}
              modelNo={docOverviewData.project?.model_number}
            />
          </ContainerStyle>
        </Form>
      </Container>
    );

    return (
      <AppLayout
        navigationHide
        toolsHide
        contentHeader={header}
        breadcrumbs={breadcrumb}
        content={mainContent}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getProjectConfigReducer: state.getProjectConfigReducer,
    docOverviewReducer: state.docOverviewReducer,
    addDocumentsReducer: state.addDocumentsReducer,
    checklistReducer: state.checklistReducer,
  };
};

export default connect(mapStateToProps)(AddDocuments);
