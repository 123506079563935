import {
  DateRangePicker,
  DateRangePickerProps,
} from "@amzn/awsui-components-react";

import { TIME_UNIT } from "../../config/constants";

interface DateRangeSelectorModal {
  relativeOptions: {
    amount: number;
    unit: DateRangePickerProps.TimeUnit;
  }[];
  isFutureRange: boolean;
  value: any;
  setValue: Function;
  minimumRangeInDays: number;
}

export const DateRangeSelector: React.FC<DateRangeSelectorModal> = (props) => {
  const {
    relativeOptions,
    isFutureRange,
    value,
    setValue,
    minimumRangeInDays,
  } = props;

  const options = relativeOptions.map((option) => {
    const item = {
      key: `${isFutureRange ? "next" : "last"}-${option.amount}-${option.unit}${
        option.amount === 1 ? "" : "s"
      }`,
      amount: option.amount,
      unit: option.unit,
      type: "relative",
    };
    return item;
  });

  return (
    <DateRangePicker
      onChange={({ detail }) => {
        setValue(detail.value);
      }}
      value={value}
      dateOnly={true}
      //@ts-ignore
      relativeOptions={options}
      isValidRange={(e) => {
        const t = (e, t) => {
          // @ts-ignore
          const a = Math.abs(new Date(t) - new Date(e));
          return Math.ceil(a / 864e5);
        };
        if (e && "absolute" === e.type) {
          const [a] = e.startDate.split("T"),
            [n] = e.endDate.split("T");
          if (!a || !n)
            return {
              valid: !1,
              errorMessage:
                "The selected date range is incomplete. Select a start and end date for the date range.",
            };
          if (t(e.startDate, e.endDate) < minimumRangeInDays)
            return {
              valid: !1,
              errorMessage: `The selected date range is too small. Select a range equal or larger than ${minimumRangeInDays} day${
                minimumRangeInDays === 1 ? "" : "s"
              }.`,
            };
        } else if (e && "relative" === e.type) {
          if (isNaN(e.amount))
            return {
              valid: !1,
              errorMessage:
                "The selected date range is incomplete. Specify a duration for the date range.",
            };
          if (
            e &&
            ((TIME_UNIT.SECOND === e.unit &&
              e.amount < minimumRangeInDays * 86400) ||
              (TIME_UNIT.MINUTE === e.unit &&
                e.amount < minimumRangeInDays * 1440) ||
              (TIME_UNIT.HOUR === e.unit &&
                e.amount < minimumRangeInDays * 24) ||
              (TIME_UNIT.HOUR === e.unit &&
                e.amount < minimumRangeInDays * 1) ||
              0 === e.amount)
          )
            return {
              valid: !1,
              errorMessage: `The selected date range is too small. Select a range equal or larger than ${minimumRangeInDays} day${
                minimumRangeInDays === 1 ? "" : "s"
              }.`,
            };
        }
        return { valid: !0 };
      }}
      i18nStrings={{
        todayAriaLabel: "Today",
        nextMonthAriaLabel: "Next month",
        previousMonthAriaLabel: "Previous month",
        customRelativeRangeDurationLabel: "Duration",
        customRelativeRangeDurationPlaceholder: "Enter duration",
        customRelativeRangeOptionLabel: "Custom range",
        customRelativeRangeOptionDescription: `Set a custom range in the ${
          isFutureRange ? "future" : "past"
        }`,
        customRelativeRangeUnitLabel: "Unit of time",
        formatRelativeRange: (e) => {
          const t = 1 === e.amount ? e.unit : `${e.unit}s`;
          return `${isFutureRange ? "Next" : "Past"} ${e.amount} ${t}`;
        },
        formatUnit: (e, t) => (1 === t ? e : `${e}s`),
        dateTimeConstraintText: `Select a range equal or larger than ${minimumRangeInDays} day${
          minimumRangeInDays === 1 ? "" : "s"
        }.`,
        relativeModeTitle: "Relative range",
        absoluteModeTitle: "Absolute range",
        relativeRangeSelectionHeading: "Choose a range",
        startDateLabel: "Start date",
        endDateLabel: "End date",
        startTimeLabel: "Start time",
        endTimeLabel: "End time",
        clearButtonLabel: "Clear",
        cancelButtonLabel: "Cancel",
        applyButtonLabel: "Apply",
      }}
      placeholder="Filter by a date range"
    />
  );
};
