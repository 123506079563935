import { useState } from "react";
// sub components
import {
  Table,
  Header,
  Pagination,
  PropertyFilter,
  SpaceBetween,
  Button,
} from "@amzn/awsui-components-react";
import {
  TableEmptyState,
  TableNoMatchState,
} from "../../../components/polaris/table/common-components";
import { useCollection } from "@amzn/awsui-collection-hooks";
//config, constants
import {
  COLUMN_DEFINITIONS,
  DEFAULT_PREFERENCES,
  FILTERING_PROPERTIES,
  Preferences,
} from "./table-config";
import {
  paginationLabels,
  PROPERTY_FILTERING_I18N_CONSTANTS,
} from "../../../components/polaris/table/labels";
import { getFilterCounterText } from "../../../components/polaris/table/table-counter-strings";
// use this for sample data
// import { TABLE_DATA } from "./sample-data";
import constants from "../../../config/constants";
//css

const LandingTable = ({ data, loadingStatus, getData, history }) => {
  // selected Items in the table
  const [selectedItems, setSelectedItems] = useState<any | never>([]);

  // default table preferences
  const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);

  // using polaris useCollection hook to define table props
  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    propertyFilterProps,
    paginationProps,
  } = useCollection(data, {
    propertyFiltering: {
      filteringProperties: FILTERING_PROPERTIES,
      empty: <TableEmptyState resourceName={constants.CATEGORY} />,
      noMatch: (
        <TableNoMatchState
          onClearFilter={() =>
            actions.setPropertyFiltering({ tokens: [], operation: "and" })
          }
        />
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
  });

  // table loading status
  const loading = loadingStatus === constants.LOADING_LOAD;

  return (
    <Table
      {...collectionProps}
      columnDefinitions={COLUMN_DEFINITIONS}
      visibleColumns={preferences.visibleContent}
      items={items}
      selectionType="single"
      loadingText="Loading resources"
      loading={loading}
      resizableColumns={true}
      wrapLines={preferences.wrapLines}
      header={
        <Header
          counter={
            selectedItems.length
              ? `(${selectedItems.length}/${data.length})`
              : `(${data.length})`
          }
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button iconName="refresh" onClick={getData} />
              <Button
                variant="primary"
                onClick={() => history.push("/categories/create")}
              >
                Create Category
              </Button>
            </SpaceBetween>
          }
        >
          Categories
        </Header>
      }
      filter={
        <PropertyFilter
          i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
          {...propertyFilterProps}
          countText={getFilterCounterText(filteredItemsCount)}
        />
      }
      pagination={
        <Pagination {...paginationProps} ariaLabels={paginationLabels} />
      }
      preferences={
        <Preferences
          preferences={preferences}
          setPreferences={setPreferences}
          disabled={false}
        />
      }
      onSelectionChange={({ detail }) => setSelectedItems(detail.selectedItems)}
      selectedItems={selectedItems}
    />
  );
};

export default LandingTable;
