import trashType from "../types/trash-type";
import constants from "../../config/constants";
import { deletedDocData } from "../../model/trash";

export interface state {
  loadingStatus: number;
  message: string;
  deletedDocData: Array<deletedDocData>;
}

const initialState: state = {
  loadingStatus: constants.LOADING_DEFAULT,
  message: "",
  deletedDocData: [],
};

const trashReducer = (state: state = initialState, action: any) => {
  switch (action.type) {
    case trashType.GET_DELETED_DOCS:
      return {
        ...state,
        loadingStatus: constants.LOADING_LOAD,
      };
    case trashType.GET_DELETED_DOCS_COMMIT:
      return {
        ...state,
        loadingStatus: constants.LOADING_SUCCESS,
        message: action.payload.message,
        deletedDocData: action.payload.data,
      };
    case trashType.GET_DELETED_DOCS_ROLLBACK:
      return {
        ...state,
        loadingStatus: constants.LOADING_FAIL,
        message: action.payload.message,
      };
    default:
      return state;
  }
};

export default trashReducer;
