import { Component } from "react";
//redux
import { connect } from "react-redux";
import { Dispatch } from "redux";
// sub components
import { AppLayout } from "@amzn/awsui-components-react";
import ExpiringFileTable from "./table/table";
//config, constants
import { getExpiringFile } from "../../redux/actions/expiring-file-action";
import { repositoryOverviewData } from "../../model/repository";
import { HeaderStyle } from "../../config/constants";
//css

// declare prop check
interface StateProps {
  expiringFileReducer: any;
}

type Props = {
  dispatch: Dispatch<any>;
  history: any;
} & StateProps;

// declare state check
type State = {};

class ExpiringFiles extends Component<Props, State> {
  state: State = Object.freeze({});

  componentDidMount() {}

  getData = (startDate: string, endDate: string) => {
    this.props.dispatch(getExpiringFile(startDate, endDate));
  };

  render() {
    const {
      expiringFiles: expiringFilesOneWeek,
      loadingStatus: loadingStatusOneWeek,
    }: {
      expiringFiles: Array<repositoryOverviewData>;
      loadingStatus: number;
    } = this.props.expiringFileReducer;

    //--------------------------------------------------Header--------------------------------------------------------//
    const header = (
      <HeaderStyle variant="h1" className="pt-4">
        {"Expiring Files"}
      </HeaderStyle>
    );

    //-----------------------------------------------Main Content-----------------------------------------------------//
    const mainContent = (
      <ExpiringFileTable
        data={expiringFilesOneWeek}
        loadingStatus={loadingStatusOneWeek}
        getData={(startDate: string, endDate: string) =>
          this.getData(startDate, endDate)
        }
      />
    );

    return (
      <AppLayout
        navigationHide
        toolsHide
        contentHeader={header}
        content={mainContent}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    expiringFileReducer: state.expiringFileReducer,
  };
};

export default connect(mapStateToProps)(ExpiringFiles);
