import fileOverviewType from "../types/file-overview-type";
import { config } from "../../config/config";
import { METRIC_INFO } from "../../config/constants";
import endpoints from "../../config/endpoints";
import { notification } from "../../model/file-overview";

export const getFileOverview = (fileId: string) => ({
  type: fileOverviewType.GET_FILE_OVERVIEW,
  fileId: fileId,
  http: {
    cognito: {
      effect: {
        url: config.BASE_URL + endpoints.fileOverviewEndpoint(fileId),
        method: "GET",
        fileId: fileId,
      },
      commit: {
        type: fileOverviewType.GET_FILE_OVERVIEW_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
        },
        fileId: fileId,
      },
      rollback: {
        type: fileOverviewType.GET_FILE_OVERVIEW_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
        },
        fileId: fileId,
      },
    },
  },
});

export const resetFileOverview = (fileId: string) => ({
  type: fileOverviewType.RESET_FILE_OVERVIEW,
  fileId,
});

export const setNotifications = (
  notifications: notification[],
  fileId: string
): any => ({
  type: fileOverviewType.SET_FILE_NOTIFICATION,
  notifications,
  fileId,
});

export const deleteNotification = (index: number, fileId: string): any => ({
  type: fileOverviewType.DELETE_FILE_NOTIFICATION,
  index,
  fileId,
});
