const fileOverviewType = {
  GET_FILE_OVERVIEW: "GET_FILE_OVERVIEW",
  GET_FILE_OVERVIEW_COMMIT: "GET_FILE_OVERVIEW_COMMIT",
  GET_FILE_OVERVIEW_ROLLBACK: "GET_FILE_OVERVIEW_ROLLBACK",

  RESET_FILE_OVERVIEW: "RESET_FILE_OVERVIEW",

  SET_FILE_NOTIFICATION: "SET_FILE_NOTIFICATION",
  DELETE_FILE_NOTIFICATION: "DELETE_FILE_NOTIFICATION",
};

export default fileOverviewType;
