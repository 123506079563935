import {
  createProjectType,
  getProjectConfigType,
} from "../types/create-project-type";
import { config } from "../../config/config";
import { METRIC_INFO } from "../../config/constants";
import endpoints from "../../config/endpoints";
import { createProjectRequest } from "../../model/http-json";

export const createProject = (values: createProjectRequest): any => ({
  type: createProjectType.CREATE_PROJECT,
  http: {
    cognito: {
      effect: {
        url:
          config.COMPLIANCE_BASE_URL +
          endpoints.projectEndpoint(values.category_id),
        method: "POST",
        json: values,
      },
      commit: {
        type: createProjectType.CREATE_PROJECT_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.PROJECT,
        },
      },
      rollback: {
        type: createProjectType.CREATE_PROJECT_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.PROJECT,
        },
      },
    },
  },
});

export const resetProject = (): any => ({
  type: createProjectType.RESET_PROJECT,
});

export const getProjectConfig = () => ({
  type: getProjectConfigType.GET_PROJECT_CONFIG,
  http: {
    cognito: {
      effect: {
        url: config.BASE_URL + endpoints.projectConfigEndpoint(),
        method: "GET",
      },
      commit: {
        type: getProjectConfigType.GET_PROJECT_CONFIG_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
        },
      },
      rollback: {
        type: getProjectConfigType.GET_PROJECT_CONFIG_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
        },
      },
    },
  },
});
