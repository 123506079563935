import React, { Component } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
// keep this import temporarily to resolve css issue
import {} from "@amzn/awsui-components-react/polaris";
import {
  ColumnLayout,
  Button,
  Input,
  Textarea,
  Alert,
  Container,
  SpaceBetween,
  FormField,
} from "@amzn/awsui-components-react/polaris";

import "../create-form.css";
import constants, { COMPONENT_ID } from "../../../config/constants";
import {
  createCategory,
  resetCategory,
} from "../../../redux/actions/create-category-action";
import { createCategoryRequest } from "../../../model/http-json";
import { setNotifications } from "../../../redux/actions/doc-overview-action";
import { config } from "../../../config/config";

interface StateProps {
  createCategoryReducer: any;
}

// declare prop check
type Props = {
  dispatch: Dispatch<any>;
  history: any;
} & StateProps;

type State = {
  category: string; // user input for creating category
  comment: string; // user input
  categoryError: string; // input error
  commentError: string; //input error
  responseError: string; // response error from BE
};

class CategoryForm extends Component<Props, State> {
  state: State = Object.freeze({
    category: "",
    comment: "",
    categoryError: "",
    commentError: "",
    responseError: "",
  });

  componentDidUpdate() {
    const { loadingStatus, responseData } = this.props.createCategoryReducer;
    if (loadingStatus === constants.LOADING_SUCCESS) {
      this.onSuccessNotification(responseData.name);
    }
    if (loadingStatus === constants.LOADING_FAIL) {
      this.onFailureNotification();
    }
  }

  //validate input
  validate = (id: string) => {
    let isValid: boolean = true;

    // validate category
    if (id === COMPONENT_ID.CATEGORY) {
      const category = this.state.category;
      let categoryError: string = "";

      if (!category) {
        categoryError = "Required";
      } else if (!/^[A-Za-z]+$/i.test(category)) {
        categoryError = "Letters only";
      } else if (category.length > 30) {
        categoryError = "Max Length 30";
      }

      this.setState({
        categoryError,
      });

      if (categoryError) {
        isValid = false;
      }
    }

    // validate comment
    if (id === COMPONENT_ID.COMMENT) {
      const comment = this.state.comment;
      let commentError: string = "";

      if (comment && comment.length > 200) {
        commentError = "Max Length 200";
      }

      this.setState({
        commentError,
      });

      if (commentError) {
        isValid = false;
      }
    }

    return isValid;
  };

  /* 
    onClick submit button
    1. validate user input, show input error if find any
    2. sent request to BE
    3. show response to user
  */
  _onSubmit = () => {
    // clean up response error from last submit
    this.setState({ responseError: "" });

    const isValidCategory = this.validate(COMPONENT_ID.CATEGORY);
    const isValidComment = this.validate(COMPONENT_ID.COMMENT);
    if (!isValidCategory || !isValidComment) {
      return;
    }

    const category: string = this.state.category;
    const comment: string = this.state.comment;
    const values: createCategoryRequest = {
      name: category,
      comment: comment,
    };
    this.props.dispatch(createCategory(values));
  };

  clear = () => {
    this.setState({
      category: "",
      comment: "",
      categoryError: "",
      responseError: "",
    });
  };

  reset = (success: boolean) => {
    // reset reducer
    this.props.dispatch(resetCategory());
    if (success) this.clear();
  };

  _onChangeCategory = (event: any) => {
    const category: string = event.detail.value;
    this.setState({
      category,
      categoryError: "",
    });
  };

  _onChangeComment = (event: any) => {
    const comment: string = event.detail.value;
    this.setState({
      comment,
      commentError: "",
    });
  };

  onSuccessNotification = (categoryName: string) => {
    // set notification
    const notifications = [
      {
        type: constants.STATUS_SUCCESS,
        content: (
          <span>
            {this.props.createCategoryReducer.message}:
            <strong> {categoryName}</strong>
          </span>
        ),
      },
    ];
    this.props.dispatch(setNotifications(notifications, config.HOME_DOC_ID));
    this.redirect();
  };

  onFailureNotification = () => {
    // set notification
    const notifications = [
      {
        type: constants.STATUS_ERROR,
        content: <span>{this.props.createCategoryReducer.message}</span>,
      },
    ];
    this.props.dispatch(setNotifications(notifications, config.HOME_DOC_ID));
    this.redirect();
  };

  redirect = () => {
    this.reset(true);
    // navigate to landing page
    this.props.history.push("/");
  };

  render() {
    const { loadingStatus } = this.props.createCategoryReducer;
    return (
      <div className="create-form-container">
        <SpaceBetween direction="vertical" size="s">
          <Container>
            <div>
              {/* main form portion */}
              <ColumnLayout>
                <div data-awsui-column-layout-root="true">
                  <FormField
                    label="Category"
                    description="* Required"
                    errorText={this.state.categoryError}
                    constraintText={
                      "Letters only. Max Length 30. Remaining characters " +
                      (30 - this.state.category.length)
                    }
                  >
                    <div className="create-form-input">
                      <Input
                        id={COMPONENT_ID.CATEGORY}
                        placeholder="Enter category name"
                        value={this.state.category}
                        onChange={this._onChangeCategory}
                      />
                    </div>
                  </FormField>
                  <FormField
                    label="Description"
                    constraintText={
                      "Max Length 200. Remaining characters " +
                      (200 - this.state.comment.length)
                    }
                    errorText={this.state.commentError}
                  >
                    <div className="create-form-input">
                      <Textarea
                        id={COMPONENT_ID.COMMENT}
                        value={this.state.comment}
                        onChange={this._onChangeComment}
                      />
                    </div>
                  </FormField>
                </div>
              </ColumnLayout>
              <br />

              {this.state.responseError && (
                <>
                  <Alert
                    visible={this.state.responseError !== ""}
                    dismissAriaLabel="Close alert"
                    header="Errors Detected"
                    type="error"
                  >
                    {this.state.responseError}
                  </Alert>
                  <br />
                </>
              )}
            </div>
          </Container>
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
            }}
          >
            <SpaceBetween direction="horizontal" size="xs">
              <Button onClick={this.clear}>Clear</Button>
              <Button
                variant="primary"
                onClick={this._onSubmit}
                loading={loadingStatus === constants.LOADING_LOAD}
              >
                Submit
              </Button>
            </SpaceBetween>
          </div>
        </SpaceBetween>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    createCategoryReducer: state.createCategoryReducer,
  };
};

export default connect<StateProps>(mapStateToProps)(CategoryForm);
