import { useState } from "react";
import { useHistory } from "react-router";
import {
  Alert,
  Container,
  Header,
  FormField,
  Input,
  SpaceBetween,
  Button,
  TokenGroup,
  Textarea,
} from "@amzn/awsui-components-react";
import ShareDocsTable from "./table/table";
import constants from "../../../config/constants";
import "../share-doc.css";

const ShareForm = ({ data, docId, onShare }) => {
  // state
  const [userEmail, setUserEmail] = useState("");
  const [userEmailError, setUserEmailError] = useState("");
  const [userEmailList, setUserEmailList] = useState<
    { label: string; dismissLabel: string; email: string }[]
  >([]);
  const [message, setMessage] = useState("");

  const validate = (id: string) => {
    let isValid = true;
    if (id === "userEmail") {
      const email = userEmail;
      if (email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/i.test(email)) {
        setUserEmailError("Invalid Email format");
        isValid = false;
      }
    }
    return isValid;
  };

  const addEmail = () => {
    if (!validate("userEmail")) return;

    const currentEmailList = userEmailList.map((email) => email.email);
    if (currentEmailList.indexOf(userEmail.toLowerCase()) !== -1) {
      setUserEmail("");
      return;
    }

    const email = {
      label: userEmail.toLowerCase(),
      dismissLabel: "Remove Email",
      email: userEmail.toLowerCase(),
    };
    setUserEmailList([...userEmailList, email]);
    setUserEmail("");
  };

  const history = useHistory();
  const cancelShare = () => {
    history.push(`/folders/${docId}/overview`);
  };

  const share = () => {
    const emailList = userEmailList.map((item) => item.email);
    const docToShare = data.map((item) => {
      return {
        doc_id: item.doc_id,
        doc_name: item.doc_name,
        parent_doc_id: item.parent_doc_id,
      };
    });
    onShare(emailList, docToShare, message);
  };

  return (
    <div
      className="mb-5"
      style={{
        maxWidth: "800px",
      }}
    >
      <Alert
        visible={true}
        dismissAriaLabel="Close alert"
        header="Share Details"
      >
        - Added users will recieve an email with the{" "}
        <strong>download link </strong>of the shared objects. <br />- Only
        <strong> verified users </strong>are eligible to recieve emails.
      </Alert>
      <br />
      <ShareDocsTable data={data} loadingStatus={constants.LOADING_SUCCESS} />
      <br />
      <Container header={<Header> Add Users </Header>}>
        <FormField errorText={userEmailError}>
          <SpaceBetween direction="horizontal" size="l">
            <Input
              id={constants.FOLDER}
              placeholder="enter user email id"
              value={userEmail}
              onChange={(event) => {
                setUserEmail(event.detail.value);
                setUserEmailError("");
              }}
              className="email-input"
            />
            <Button
              variant="primary"
              onClick={addEmail}
              disabled={userEmail === ""}
              loading={false}
            >
              Add
            </Button>
          </SpaceBetween>
        </FormField>
        <br />
        <TokenGroup
          onDismiss={({ detail: { itemIndex } }) => {
            setUserEmailList([
              ...userEmailList.slice(0, itemIndex),
              ...userEmailList.slice(itemIndex + 1),
            ]);
          }}
          items={userEmailList}
        />
        {userEmailList.length !== 0 && (
          <>
            <br />
            <Textarea
              onChange={({ detail }) => setMessage(detail.value)}
              value={message}
              placeholder="Enter any message here you want to share with the users."
            />
          </>
        )}
      </Container>
      <br />
      <div className="float-right">
        <SpaceBetween direction="horizontal" size="xs">
          <Button onClick={cancelShare}>Cancel</Button>
          <Button variant="primary" onClick={share}>
            Share
          </Button>
        </SpaceBetween>
      </div>
    </div>
  );
};

export default ShareForm;
