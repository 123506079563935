import React, { Component } from "react";
import { colorCodeSchema } from "../../../model/overview";
import { EXPIRY_DATE_COLOR_CODE } from "../../../config/constants";
import styled from "styled-components";

const Span = styled.span`
  position: absolute;
  margin-top: 16px;
  display: none;
  z-index: 1;
  background-color: white;
  border: 1px solid
    ${({ color }) => color || EXPIRY_DATE_COLOR_CODE.RED_COLOR_HEX};
`;

const Div = styled.div`
  &:hover ${Span} {
    display: block;
  }
`;

const progressColor = {
  0: EXPIRY_DATE_COLOR_CODE.GREEN_COLOR_HEX, //green
  1: EXPIRY_DATE_COLOR_CODE.YELLOW_COLOR_HEX, //yellow
  2: EXPIRY_DATE_COLOR_CODE.RED_COLOR_HEX, //red
};

const expiryLabels = ["Expiring after 6 months", " Expiring in next 6 months", "Expiring in next 3 months"];

const expiryColorsKeys = ["green", "yellow", "red"];

// declare prop check
type Props = {
  progressData: colorCodeSchema;
} & typeof defaultProps;

// declare init state & default props
const defaultProps = Object.freeze({});

class ProjectExpiryStatusBar extends Component<Props> {
  static defaultProps = defaultProps;

  render() {
    const { progressData } = this.props;

    return (
      <Div
        style={{
          display: "flex",
          borderRadius: "6px",
          width: "100%",
          height: "15px",
        }}
      >
        {expiryColorsKeys.map((key, index) => (
          <div
            key={index}
            style={{
              //pass progress
              width: `${
                (progressData.total === 0
                  ? key === "green"
                    ? 1
                    : 0
                  : progressData[key] / progressData.total) * 100
              }%`,
              height: "100%",
              backgroundColor: progressColor[index],
              color: "white",
              fontSize: "14px",
              fontWeight: "bold",
              transition: "all .2s ease-out",
            }}
          ></div>
        ))}
        <Span color={"Red"}>
          {expiryColorsKeys.map((key, index) => (
            <div
              key={index}
              style={{
                paddingRight: "5px",
                display:
                  progressData[key] !== 0 ||
                  (progressData[key] === 0 &&
                    key === "green" &&
                    progressData.total === 0)
                    ? "block"
                    : "none",
              }}
            >
              <span
                style={{
                  height: "100%",
                  borderRadius: "16px",
                  padding: "2px",
                  color: progressColor[index],
                  fontSize: "12px",
                  fontWeight: "bold",
                  transition: "all .2s ease-out",
                }}
              >
                {expiryLabels[index]}
              </span>
              <span style={{ fontSize: "12px" }}>
                :{" "}
                {(
                  (progressData.total === 0
                    ? 1
                    : progressData[key] / progressData.total) * 100
                ).toFixed(2) + "%"}
              </span>
            </div>
          ))}
        </Span>
      </Div>
    );
  }
}

export default ProjectExpiryStatusBar;
