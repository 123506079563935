import { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
// redux
import {
  getRepositoryOverview,
  deleteNotification,
} from "../../redux/actions/repository-overview-action";
import { connect } from "react-redux";
import { Dispatch } from "redux";
// sub components
import { AppLayout } from "@amzn/awsui-components-react";
import RepositoryTable from "./table/table";
//config, constants
// import redux action
import { repositoryOverviewData, notification } from "../../model/repository";
import Notification from "../../components/common/notification/notification";
//css
import { HeaderStyle } from "../../config/constants";

// declare prop check

type Props = {
  dispatch: Dispatch<any>;
  history: any;
  repositoryOverviewReducer: any;
} & RouteComponentProps<any>;

// declare state check
type State = {};

class RepositoryOverview extends Component<Props, State> {
  state: State = Object.freeze({});

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    this.props.dispatch(getRepositoryOverview());
  };

  onDismissNotification = (index: number) => {
    this.props.dispatch(deleteNotification(index));
  };

  render() {
    const {
      repositoryOverviewData,
      loadingStatus,
      notifications,
    }: {
      repositoryOverviewData: repositoryOverviewData;
      loadingStatus: number;
      notifications: notification[];
    } = this.props.repositoryOverviewReducer;

    //-----------------------------------------------Notifications-----------------------------------------------------//
    const notification = (
      <Notification
        notifications={notifications}
        onDismiss={this.onDismissNotification}
      />
    );

    //--------------------------------------------------Header--------------------------------------------------------//
    const header = (
      <HeaderStyle variant="h1" className="pt-4">
        {"Repository"}
      </HeaderStyle>
    );

    //-----------------------------------------------Main Content-----------------------------------------------------//

    const mainContent = (
      <RepositoryTable
        data={repositoryOverviewData}
        loadingStatus={loadingStatus}
        getData={this.getData}
        history={this.props.history}
        dispatch={this.props.dispatch}
      />
    );

    return (
      <AppLayout
        navigationHide
        toolsHide
        notifications={notification}
        contentHeader={header}
        content={mainContent}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    repositoryOverviewReducer: state.repositoryOverviewReducer,
  };
};

export default connect(mapStateToProps)(RepositoryOverview);
