import { useState } from "react";
// sub components
import {
  Table,
  Header,
  Pagination,
  PropertyFilter,
  SpaceBetween,
  Button,
} from "@amzn/awsui-components-react";
import {
  TableEmptyState,
  TableNoMatchState,
} from "../../../components/polaris/table/common-components";
import { useCollection } from "@amzn/awsui-collection-hooks";
//config, constants
import {
  COLUMN_DEFINITIONS,
  DEFAULT_PREFERENCES,
  FILTERING_PROPERTIES,
  Preferences,
} from "./associations-table-config";
import {
  paginationLabels,
  PROPERTY_FILTERING_I18N_CONSTANTS,
} from "../../../components/polaris/table/labels";
import { getFilterCounterText } from "../../../components/polaris/table/table-counter-strings";
// use this for sample data
import { createDisassociationRequest } from "../../../model/http-json";
import constants from "../../../config/constants";
import Associations from "../modal/associations";
import { removeAssociation } from "../../../redux/actions/remove-association-action";
//css

const AssociationsTable = ({
  fileId,
  data,
  tableLoadingStatus,
  dispatch,
}: {
  fileId: string;
  data: any;
  tableLoadingStatus: Number;
  dispatch: any;
}) => {
  // selected items in the table
  const [selectedItems, setSelectedItems] = useState<any | never>([]);

  // default table preferences
  const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);

  // associations modal
  const [openAssociationsModal, setOpenAssociationsModal] = useState(false);

  // using polaris useCollection hooks to define table props
  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    propertyFilterProps,
    paginationProps,
  } = useCollection(data, {
    propertyFiltering: {
      filteringProperties: FILTERING_PROPERTIES,
      empty: <TableEmptyState resourceName={constants.FILE} />,
      noMatch: (
        <TableNoMatchState
          onClearFilter={() =>
            actions.setPropertyFiltering({ tokens: [], operation: "and" })
          }
        />
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
  });

  // table loading status
  const loading = tableLoadingStatus === constants.LOADING_LOAD;

  return (
    <>
      <Table
        {...collectionProps}
        columnDefinitions={COLUMN_DEFINITIONS}
        visibleColumns={preferences.visibleContent}
        items={items}
        selectionType="multi"
        loadingText="Loading associations"
        loading={loading}
        resizableColumns={true}
        wrapLines={preferences.wrapLines}
        header={
          <Header
            counter={
              selectedItems.length
                ? `(${selectedItems.length}/${data.length})`
                : `(${data.length})`
            }
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  onClick={() => {
                    let docsToDisassociate: any = [];
                    selectedItems.forEach((doc) => {
                      docsToDisassociate.push(doc.doc_id);
                    });

                    const values: createDisassociationRequest = {
                      associations: [
                        { file_id: fileId, docs: docsToDisassociate },
                      ],
                    };

                    dispatch(removeAssociation(values));
                  }}
                  disabled={selectedItems.length === 0}
                >
                  Disassociate
                </Button>
                <Button
                  onClick={() => {
                    setOpenAssociationsModal(true);
                  }}
                >
                  Associate
                </Button>
              </SpaceBetween>
            }
          >
            Documents
          </Header>
        }
        filter={
          <PropertyFilter
            i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
            {...propertyFilterProps}
            countText={getFilterCounterText(filteredItemsCount)}
          />
        }
        pagination={
          <Pagination {...paginationProps} ariaLabels={paginationLabels} />
        }
        preferences={
          <Preferences
            preferences={preferences}
            setPreferences={setPreferences}
            disabled={false}
          />
        }
        onSelectionChange={({ detail }) => {
          setSelectedItems(detail.selectedItems);
        }}
        selectedItems={selectedItems}
      ></Table>
      {openAssociationsModal && (
        <Associations
          dispatch={dispatch}
          fileId={fileId}
          openAssociationsModal={openAssociationsModal}
          setOpenAssociationsModal={setOpenAssociationsModal}
          associatedDocs={items}
        />
      )}
    </>
  );
};

export default AssociationsTable;
