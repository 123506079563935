import React, { Component } from "react";
import { connect } from "react-redux";
import Amplify, { Hub } from "@aws-amplify/core";
import { getUserInfo, redirectToLoginPage } from "./cognito-auth/session";
import { config } from "./config/config";
import constants from "./config/constants";
import NoAuthz from "./containers/no-authz/no-authz";
import Main from "./containers/main/main";
import { Spinner } from "@amzn/awsui-components-react";
import { RunningMan } from "./components/loading-indicator/loading-indicators";
import "./App.css";
interface Props {
  authzReducer: any;
}

type State = {
  authState: string;
  userInfo: any;
} & typeof initialState;

const initialState = Object.freeze({
  authState: constants.UNAUTHENTICATED,
  userInfo: null,
});

class App extends Component<Props, State> {
  constructor(props) {
    super(props);
    Amplify.configure(config.AMPLIFY_CONFIG);
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case constants.SIGNIN:
          getUserInfo().then((userInfo) => {
            if (userInfo) {
              this.setState({
                authState: constants.AUTHENTICATED,
                userInfo: userInfo,
              });
            }
          });
          break;

        case constants.SIGNIN_FAILURE:
          this.setState({
            authState: constants.SIGNIN_FAILURE,
            userInfo: null,
          });
          break;

        case constants.SIGNOUT:
          redirectToLoginPage();
          break;

        case constants.CUSTOM_OAUTH_STATE:
          window.location.href = decodeURIComponent(data);
          break;
        default:
          return;
      }
    });

    this.state = {
      userInfo: null,
      authState: constants.UNAUTHENTICATED,
    };
  }

  componentDidMount() {
    // login cognito
    setTimeout(
      () => {
        getUserInfo().then((userInfo) => {
          if (userInfo) {
            this.setState({
              authState: constants.AUTHENTICATED,
              userInfo: userInfo,
            });
          } else {
            redirectToLoginPage();
          }
        });
      },
      new URLSearchParams(window.location.search).has("code") ? 3000 : 500
    );
  }

  render() {
    const { authz } = this.props.authzReducer;
    return (
      <React.Fragment>
        {this.state.authState === constants.AUTHENTICATED && authz === 0 && (
          <div className={"app-loading-div"} style={{ color: "black" }}>
            <Spinner />
            Authorizing...
            <RunningMan />
          </div>
        )}
        {this.state.authState === constants.AUTHENTICATED && authz === -1 && (
          <NoAuthz />
        )}
        {this.state.authState === constants.AUTHENTICATED && authz === 1 && (
          <Main />
        )}
        {this.state.authState === constants.SIGNIN_FAILURE && (
          <div style={{ color: "black" }}>Logging failed</div>
        )}
        {this.state.authState === constants.UNAUTHENTICATED && (
          <div className={"app-loading-div"} style={{ color: "black" }}>
            <Spinner />
            Authenticating...
            <RunningMan />
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => {
  return { authzReducer: state.authzReducer };
};
export default connect(mapStateToProps)(App);
