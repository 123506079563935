import { Component } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { Dispatch } from "redux";
import {
  AppLayout,
  Spinner,
  Header,
  Button,
} from "@amzn/awsui-components-react";
import Breadcrumb from "../../../components/common/breadcrumb/breadcrumb";
import DeleteForm from "./delete-form/delete-form";
import DeleteStatus from "./delete-status/delete-status";
import constants from "../../../config/constants";
import { documentOverviewData } from "../../../model/doc-overview";
import { deleteDocObject } from "../../../model/delete-doc";
import {
  getDocOverview,
  resetDocOverview,
} from "../../../redux/actions/doc-overview-action";
import { resetDeleteDocs } from "../../../redux/actions/delete-doc-action";
import { deleteDocCall } from "../utils";
// declare prop check
type Props = {
  dispatch: Dispatch<any>;
  history: any;
  docOverviewReducer: any;
  deleteDocReducer: any;
} & RouteComponentProps<any>;

// declare state check
type State = {
  docs: Array<deleteDocObject>;
  isFormSubmitted: boolean;
  inProgressDocs: number;
  successfulDocs: number;
  failedDocs: number;
};

class DeleteDoc extends Component<Props> {
  state: State = Object.freeze({
    docs: [],
    isFormSubmitted: false,
    inProgressDocs: 0,
    successfulDocs: 0,
    failedDocs: 0,
  });
  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps: Props, prevstate: State) {
    if (this.props.match.params.folderId !== prevProps.match.params.folderId) {
      const { folderId } = prevProps.match.params;
      this.props.dispatch(resetDocOverview(folderId));
      this.getData();
    }
  }

  componentWillUnmount() {
    const { folderId } = this.props.match.params;
    this.props.dispatch(resetDocOverview(folderId));
    this.props.dispatch(resetDeleteDocs());
  }

  getData = () => {
    const { folderId } = this.props.match.params;
    this.props.dispatch(getDocOverview(folderId, 0));
  };

  onDelete = (docsToDelete: Array<deleteDocObject>) => {
    this.setState({
      docs: docsToDelete,
      isFormSubmitted: true,
      inProgressDocs: docsToDelete.length,
    });
    docsToDelete.forEach((doc: deleteDocObject, index) => {
      deleteDocCall(doc).then((response) => {
        let { docs, inProgressDocs, successfulDocs, failedDocs } = this.state;
        docs[index].deleteStatus = response.status;
        docs[index].deleteMessage = response.message;
        inProgressDocs--;
        if (response.status === constants.STATUS_SUCCESS) {
          successfulDocs++;
        } else {
          failedDocs++;
        }
        this.setState({
          docs,
          inProgressDocs,
          successfulDocs,
          failedDocs,
        });
      });
    });
  };
  render() {
    const folderId = this.props.match.params.folderId;
    if (
      !this.props.docOverviewReducer[folderId] ||
      !this.props.docOverviewReducer[folderId].loadingStatus ||
      [constants.LOADING_LOAD, constants.LOADING_DEFAULT].includes(
        this.props.docOverviewReducer[folderId].loadingStatus
      )
    ) {
      return <Spinner size="large" />;
    }
    const { docOverviewData }: { docOverviewData: documentOverviewData } =
      this.props.docOverviewReducer[folderId];
    const { doc_name, path, breadcrumb_data } = docOverviewData;
    const { selectedDocs } = this.props.deleteDocReducer;

    //------------------------------------------------Breadcrumb------------------------------------------------------//
    const breadcrumb = (
      <Breadcrumb
        breadcrumb_data={[
          ...breadcrumb_data,
          { doc_name: "Delete Documents", path: "#" },
        ]}
        history={this.props.history}
      />
    );

    //--------------------------------------------------Header--------------------------------------------------------//
    const header = this.state.isFormSubmitted ? (
      <Header
        variant="h1"
        actions={
          <Button
            variant="primary"
            onClick={() =>
              this.props.history.push(`/folders/${folderId}/overview`)
            }
          >
            Close
          </Button>
        }
      >
        Delete Documents: Status
      </Header>
    ) : (
      <Header variant="h1">Delete Documents</Header>
    );

    //-----------------------------------------------Main Content-----------------------------------------------------//
    const deleteForm = (
      <DeleteForm
        data={selectedDocs}
        folderId={folderId}
        onDelete={this.onDelete}
        history={this.props.history}
      />
    );
    const deletePage = (
      <DeleteStatus
        docs={this.state.docs}
        inProgressDocs={this.state.inProgressDocs}
        successfulDocs={this.state.successfulDocs}
        failedDocs={this.state.failedDocs}
        folderName={doc_name}
        folderPath={path}
      />
    );
    const mainContent = this.state.isFormSubmitted ? deletePage : deleteForm;

    return (
      <AppLayout
        navigationHide
        toolsHide
        contentHeader={header}
        breadcrumbs={breadcrumb}
        content={mainContent}
      />
    );
  }
}
const mapStateToProps = (state) => {
  return {
    docOverviewReducer: state.docOverviewReducer,
    deleteDocReducer: state.deleteDocReducer,
  };
};
export default connect(mapStateToProps)(DeleteDoc);
