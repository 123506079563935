class Endpoints {
  // authz
  authzEndpoint = () => "/authz";

  // category endpoint
  categoryEndpoint = () => `/categories`;

  // doc overview endpoint
  docOverviewEndpoint = (docId) => `/docs/${docId}`;

  getColorCodeEndpoint = (docId) => `/docs/color/${docId}`;

  // doc associations endpoint
  docAssociationsEndpoint = (docId) => `/docs/${docId}/associations`;

  // download doc endpoint
  downloadDocEndpoint = (docId) => `/docs/${docId}/download`;

  // download file endpoint
  downloadFileEndpoint = (fileId) => `/files/${fileId}/download`;

  // create doc endpoint
  docEndpont = (docId) => `/docs/${docId}`;

  // delete file endpoint
  fileEndpoint = (fileId) => `/files/${fileId}`;

  // add doc endpoint
  addDocsEndpoint = () => `/docs/add`;

  // project endpoint
  projectEndpoint = (category_id) => `/categories/${category_id}/projects`;

  // update project endpoint
  updateProjectEndpoint = (project_id) => `/projects/${project_id}`;

  // project config endpoint
  projectConfigEndpoint = () => `/projects/config`;

  // generate pre-signed url
  uploadPreSignedEndpoint = () => `/upload`;

  // file upload
  fileUploadEndpoint = () => `/upload/files`;

  // new version upload endpoint
  fileNewVersionUploadEndpoint = () => `/upload/file_new_version`;

  // file config
  fileTypeConfigEndpoint = () => `/upload/file_config`;

  // file's tag config
  fileTagConfigEndpoint = () => `/upload/tags_config`;

  // update file endpoint
  updateFileEndpoint = (file_id) => `/files/update/${file_id}`;

  // expiring documents
  expiringDocEndpoint = () => `/expiring_docs`;

  // expiring files
  expiringFileEndpoint = () => `/expiring_files`;

  // get deleted docs
  deletedDocEnpoint = () => `/deleted_docs`;

  // get deleted files
  deletedFilesEndpoint = () => `/deleted_files`;

  // search
  searchEnpoint = () => `/search`;

  // update doc endpoint
  updateDocEndpoint = (docId) => `/docs/${docId}`;

  // doc public url
  publicURLEndpoint = () => `/docs/public_url`;

  // share doc
  shareDocEndpoint = () => `/docs/share`;

  // repository endpoint
  repositoryOverviewEndpoint = () => `/repository`;

  // file overview endpoint
  fileOverviewEndpoint = (file_id) => `/files/${file_id}`;

  // association dropdown config endpoint
  associationConfigEndpoint = (parent_doc_id) =>
    `/docs/${parent_doc_id}/association_config`;

  // associate docs to a file endpoint
  associateDocsToFileEndpoint = () => `/files/associate`;

  // disassociate docs from a file endpoint
  disassociateDocsFromFileEndpoint = () => `/files/disassociate`;

  // download multiple files as zip format endpint
  downloadFilesAsZipGetPresignedURLEndpoint = () => `/download/files`;

  // download multiple folder or documents as zip format endpint
  downloadFolderAsZipGetPresignedURLEndpoint = () => `/download/folders`;

  //update project details

  updateProjectDetailsEndpoint = (project_id) =>
    `/projects/${project_id}/update`;
}

const endpoints = new Endpoints();
export default endpoints;
