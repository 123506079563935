// AJAX request related
import { config } from "../../config/config.js";
import endpoints from "../../config/endpoints";
import {
  UploadPreSignedURLRequest,
  UploadPreSignedURLResponse,
} from "../../model/http-json";
import { postData, Response } from "../../utils/cognito-fetch";
// constatns
import { FileStatus } from "../../model/upload";
import constants from "../../config/constants";
import { username } from "../../cognito-auth/session";
import { fileObject } from "../../model/file.js";

export async function fileUploadS3Call(file: fileObject): Promise<FileStatus> {
  /*
      Ajax call to upload 1 file to s3
      
      Two steps:
       1. send call to upload service to get pre-signed url
       2. put request to s3
  */
  // init return
  const result: FileStatus = {
    status: constants.STATUS_ERROR,
    message: "",
    s3_link: "",
  };

  try {
    const filename = file.file.name;
    if (filename) {
      // step 1: get pre-signed url
      const url: string =
        config.UPLOAD_BASE_URL + endpoints.uploadPreSignedEndpoint();
      const data: UploadPreSignedURLRequest = {
        initiative: constants.INITIATIVE,
        file_name: filename,
        user_name: username,
      };

      // the response1 is our own defined type
      const response1: Response = await postData(url, data);
      if (!response1.ok) {
        throw response1.status_text;
      }

      const preSignedURLResponse: UploadPreSignedURLResponse = response1.json;
      const preSignedUrl = preSignedURLResponse.url;
      const metaDataDict = preSignedURLResponse.meta_data_result;

      // step2: put request to s3
      // the response2 is fetch native response type
      const response2 = await fetch(preSignedUrl, {
        method: "PUT",
        body: file.file,
        headers: {
          "content-type": "",
          ...metaDataDict,
        },
      });

      if (!response2.ok) {
        throw response2.statusText;
      }

      result.status = constants.STATUS_SUCCESS;
      result.message = "";
      result.s3_link = preSignedUrl.split("?")[0];
    } else {
      throw new Error("File Name missing");
    }
  } catch (error: any) {
    result.status = constants.STATUS_ERROR;
    result.message = error["message"];
  } finally {
    return result;
  }
}

export async function uploadFileToCabinet(
  name: string,
  key: string,
  size: Number,
  expiryDate?: string,
  description?: string,
  tags?: Array<any>,
  documentId?: string,
): Promise<FileStatus> {
  const result: FileStatus = {
    status: constants.STATUS_ERROR,
    message: "",
    s3_link: "",
    file_id: "",
  };
  try {
    if (name && key && size) {
      const url: string = config.BASE_URL + endpoints.fileUploadEndpoint();
      const data = {
        name: name,
        key: key,
        size: size
      };
      if (expiryDate) {
        data["expiry_date"] = expiryDate;
      }
      if (description) {
        data["description"] = description;
      }
      if (tags) {
        data["tags"] = formatTags(tags);
      }
      if(documentId) {
        data["document_id"] = documentId;
      }

      // the response is our own defined type
      const response: Response = await postData(url, data);
      if (!response.ok) {
        throw response.json;
      }
      result.status = constants.STATUS_SUCCESS;
      result.message = response.json?.message ?? "";
      result.file_id = response.json.data.file_id;
    } else {
      throw new Error("Missing parameters");
    }
  } catch (error: any) {
    result.status = constants.STATUS_ERROR;
    result.message = error["message"];
  } finally {
    return result;
  }
}

const formatTags = (tags) => {
  let formattedTags = tags.map((item) => item.value);
  return formattedTags;
};
