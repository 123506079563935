const docOverviewType = {
  GET_DOC_OVERVIEW: "GET_DOC_OVERVIEW",
  GET_DOC_OVERVIEW_COMMIT: "GET_DOC_OVERVIEW_COMMIT",
  GET_DOC_OVERVIEW_ROLLBACK: "GET_DOC_OVERVIEW_ROLLBACK",

  GET_DOC_ASSOCIATIONS: "GET_DOC_ASSOCIATIONS",
  GET_DOC_ASSOCIATIONS_COMMIT: "GET_DOC_ASSOCIATIONS_COMMIT",
  GET_DOC_ASSOCIATIONS_ROLLBACK: "GET_DOC_ASSOCATIONS_ROLLBACK",

  RESET_DOC_OVERVIEW: "RESET_DOC_OVERVIEW",

  SET_NOTIFICATION: "SET_NOTIFICATION",
  DELETE_NOTIFICATION: "DELETE_NOTIFICATION",

  GET_COLOR_CODE: "GET_COLOR_CODE",
  GET_COLOR_CODE_COMMIT: "GET_COLOR_CODE_COMMIT",
  GET_COLOR_CODE_ROLLBACK: "GET_COLOR_CODE_ROLLBACK",

  UPDATE_PROJECT_DETAILS: "UPDATE_PROJECT_DETAILS",
  UPDATE_PROJECT_DETAILS_COMMIT: "UPDATE_PROJECT_DETAILS_COMMIT",
  UPDATE_PROJECT_DETAILS_ROLLBACK: "UPDATE_PROJECT_DETAILS_ROLLBACK",
};

export default docOverviewType;
