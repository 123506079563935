import associationConfigType from "../types/association-config-type";
import { config } from "../../config/config";
import { METRIC_INFO } from "../../config/constants";
import endpoints from "../../config/endpoints";

export const getAssociationDropdownConfig = (
  parentDocId: string,
  parentType: string
) => ({
  type: associationConfigType.GET_ASSOCIATION_CONFIG,
  parentType: parentType,
  http: {
    cognito: {
      effect: {
        url:
          config.BASE_URL +
          endpoints.associationConfigEndpoint(parentDocId) +
          "?parent_type=" +
          parentType,
        method: "GET",
        parentType: parentType,
      },
      commit: {
        type: associationConfigType.GET_ASSOCIATION_CONFIG_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
        },
        parentType: parentType,
      },
      rollback: {
        type: associationConfigType.GET_ASSOCIATION_CONFIG_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
        },
        parentType: parentType,
      },
    },
  },
});

export const resetAssociationDropdownConfig = (parentType: string): any => ({
  type: associationConfigType.RESET_ASSOCIATION_CONFIG,
  parentType,
});
