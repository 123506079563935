import trashType from "../types/trash-type";
import { config } from "../../config/config";
import { METRIC_INFO } from "../../config/constants";
import endpoints from "../../config/endpoints";

export const getDeletedDocs = () => ({
  type: trashType.GET_DELETED_DOCS,
  http: {
    cognito: {
      effect: {
        url: config.BASE_URL + endpoints.deletedDocEnpoint(),
        method: "GET",
      },
      commit: {
        type: trashType.GET_DELETED_DOCS_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
        },
      },
      rollback: {
        type: trashType.GET_DELETED_DOCS_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
        },
      },
    },
  },
});
