import { Component } from "react";
import Dropzone, { DropEvent, FileRejection } from "react-dropzone";
import constants, { FILE_UPLOAD } from "../../../../config/constants";
import "./file-dropzone.css";
import {
  Box,
  Button,
  Modal,
  SpaceBetween,
  Form,
  Grid,
  FormField,
  TextContent,
} from "@amzn/awsui-components-react";
import { fileObject } from "../../../../model/file";
import { StatusLabel } from "../../../../components/common/labels";

// declare prop check
type Props = {
  onSubmitSelectedFiles: any;
} & typeof defaultProps;

type State = {
  files: Array<fileObject>;
  showRejectModal: boolean;
  rejectModalData: any;
  fileError: string;
};

// declare init state & default props
const defaultProps = Object.freeze({});

const initialState = Object.freeze({
  files: [],
  showRejectModal: false,
  rejectModalData: null,
  fileError: "",
});

class UploadFileDropzone extends Component<Props, State> {
  static defaultProps = defaultProps;
  state = initialState;

  onDrop = (acceptedFiles, rejectedFiles) => {
    let previousFileSet = new Set(
      this.state.files.map((file: fileObject) => file.file.name)
    );
    let fileArray: Array<fileObject> = acceptedFiles
      .filter((file) => !previousFileSet.has(file.name))
      .map((file) => {
        return {
          file: file,
          type: "",
        };
      });
    fileArray = [...this.state.files, ...fileArray];

    this.setState({ files: fileArray, fileError: "" });
  };
  onClearFiles = () => {
    this.setState({ files: [], fileError: "" });
  };
  onFileReject = (fileRejections: FileRejection[], event: DropEvent) => {
    let rejectModalData = fileRejections.map((file) => (
      <Grid
        key={file.file.name}
        gridDefinition={[{ colspan: 6 }, { colspan: 2 }, { colspan: 4 }]}
      >
        <p key={file.file.name}>{file.file.name}</p>
        <StatusLabel
          key={constants.STATUS_ERROR}
          status={constants.STATUS_ERROR}
        ></StatusLabel>
        <p key={file.errors[0].code}>{file.errors[0].code}</p>
      </Grid>
    ));

    this.setState({ showRejectModal: true, rejectModalData });
  };
  deleteFile = (name: string) => {
    let files = [...this.state.files];
    let indexToDelete = -1;
    files.forEach((file: fileObject, index) => {
      if (file.file.name === name) {
        indexToDelete = index;
      }
    });
    if (indexToDelete > -1) {
      files.splice(indexToDelete, 1);
      this.setState({ files });
    }
  };

  validate = () => {
    let isValid = true;
    if (this.state.files.length === 0) {
      this.setState({ fileError: "Required" });
      isValid = false;
    }
    return isValid;
  };
  _onSubmit = () => {
    // validate
    if (this.validate()) {
      // submit form
      this.props.onSubmitSelectedFiles(this.state.files);
      this.setState({ ...initialState });
    }
  };
  render() {
    return (
      <Form
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button onClick={this.onClearFiles} iconAlign="left">
              Clear
            </Button>
            <Button
              iconName="caret-right-filled"
              iconAlign="left"
              onClick={this._onSubmit}
            >
              Next
            </Button>
          </SpaceBetween>
        }
      >
        <SpaceBetween size="s">
          <FormField
            label="Drag and drop to upload files"
            errorText={this.state.fileError}
            description={
              <small>
                * Required. File max size: {FILE_UPLOAD.MAX_FILE_SIZE_IN_MB} MB
              </small>
            }
            stretch
          >
            <Dropzone
              maxSize={FILE_UPLOAD.MAX_FILE_SIZE_IN_MB * 1024 * 1024}
              onDrop={this.onDrop}
              noClick={true}
              onDropRejected={this.onFileReject}
            >
              {({ getRootProps, getInputProps, open }) => {
                return (
                  <div className="upload-dropzone-container">
                    <div
                      {...getRootProps({
                        className: "upload-dropzone",
                      })}
                    >
                      <input {...getInputProps()} />
                      <i
                        className="fas fa-cloud-upload-alt"
                        style={{ fontSize: "30px" }}
                      ></i>
                      <p style={{ fontSize: "18px" }}>
                        Drag and drop files here
                      </p>
                      <br />
                      <SpaceBetween direction="horizontal" size="xs">
                        <Button
                          onClick={open}
                          iconName="upload"
                          iconAlign="left"
                        >
                          Click to select File
                        </Button>
                      </SpaceBetween>
                    </div>
                  </div>
                );
              }}
            </Dropzone>
          </FormField>
          {this.state.files.length > 0 && (
            <TextContent>
              <b>Files :</b>
              <ul>
                {this.state.files.map((file: fileObject) => (
                  <li key={file.file.name}>
                    {file.file.name}
                    <Button
                      iconAlign="left"
                      iconName="close"
                      variant="icon"
                      onClick={() => this.deleteFile(file.file.name)}
                    />
                  </li>
                ))}
              </ul>
            </TextContent>
          )}

          <Modal
            visible={this.state.showRejectModal}
            onDismiss={() => this.setState({ showRejectModal: false })}
            header="Failure"
            footer={
              <Box float="right">
                <SpaceBetween direction="horizontal" size="xs">
                  <Button
                    variant="primary"
                    onClick={() => this.setState({ showRejectModal: false })}
                  >
                    Ok
                  </Button>
                </SpaceBetween>
              </Box>
            }
          >
            <Grid
              gridDefinition={[{ colspan: 6 }, { colspan: 2 }, { colspan: 4 }]}
            >
              <b>File Name</b>
              <b>Status</b>
              <b>Error Message</b>
            </Grid>
            {this.state.rejectModalData}
          </Modal>
        </SpaceBetween>
      </Form>
    );
  }
}

export default UploadFileDropzone;
