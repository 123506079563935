import docOverviewType from "../types/doc-overview-type";
import constants, {
  HttpCodes,
  NOT_AUTHORIZED_NOTIFICATION,
} from "../../config/constants";
import { documentOverviewData, notification } from "../../model/doc-overview";

export interface state {}

const defaultDocOverviewData: documentOverviewData = {
  doc_id: "-1",
  parent_doc_id: "-1",
  type: constants.FOLDER,
  doc_name: "",
  description: "",
  created_by: "",
  created_time: "",
  size: null,
  status: "",
  s3_link: "",
  expiry_date: "",
  path: "",
  doc_type: "",
  category_id: -1,
  project_id: -1,
  cem_project_id: "",
  cem_category_id: "",
  breadcrumb_data: [],
  children: [],
  unAuthorized: false,
};

const initialState: state = {
  colorCode: [],
  notifications: [],
  loadingStatusUpdateProjectDetails: constants.LOADING_DEFAULT,
  projectData: {},
};

const docOverviewReducer = (state: state = initialState, action: any) => {
  let docId = action.docId;
  switch (action.type) {
    case docOverviewType.GET_DOC_OVERVIEW: {
      state[docId] = {
        ...state[docId],
        loadingStatus: constants.LOADING_LOAD,
        message: "",
        docOverviewData: defaultDocOverviewData,
      };
      return {
        ...state,
      };
    }
    case docOverviewType.GET_DOC_OVERVIEW_COMMIT: {
      state[docId] = {
        ...state[docId],
        loadingStatus: constants.LOADING_SUCCESS,
        message: action.payload.message,
        docOverviewData: action.payload.data,
      };
      return {
        ...state,
      };
    }
    case docOverviewType.GET_DOC_OVERVIEW_ROLLBACK: {
      state[docId] = {
        ...state[docId],
        loadingStatus: constants.LOADING_FAIL,
        message: action.payload?.message,
        unAuthorized: action.statusCode === HttpCodes.FORBIDDEN,
      };
      return {
        ...state,
      };
    }
    case docOverviewType.SET_NOTIFICATION: {
      const notifications: notification[] = [
        ...(state[docId] && state[docId].notifications
          ? state[docId].notifications
          : []),
        ...action.notifications,
      ];
      state[docId] = {
        ...state[docId],
        notifications,
      };
      return {
        ...state,
      };
    }
    case docOverviewType.DELETE_NOTIFICATION: {
      state[docId].notifications.splice(action.index, 1);
      return {
        ...state,
      };
    }
    case docOverviewType.GET_DOC_ASSOCIATIONS: {
      console.log(action);
      state[docId] = {
        ...state[docId],
        associationsLoadingStatus: constants.LOADING_LOAD,
        associationsMessage: "",
        associationsData: [],
      };
      return {
        ...state,
      };
    }
    case docOverviewType.GET_DOC_ASSOCIATIONS_COMMIT: {
      console.log(action);
      state[docId] = {
        ...state[docId],
        associationsLoadingStatus: constants.LOADING_SUCCESS,
        associationsMessage: action.payload.message,
        associationsData: action.payload.data,
      };
      return {
        ...state,
      };
    }
    case docOverviewType.GET_DOC_ASSOCIATIONS_ROLLBACK: {
      const notifications: notification[] = [
        ...(state[docId] && state[docId].notifications
          ? state[docId].notifications
          : []),
      ];
      if (action.statusCode === HttpCodes.FORBIDDEN)
        notifications.push(NOT_AUTHORIZED_NOTIFICATION);

      state[docId] = {
        ...state[docId],
        associationsLoadingStatus: constants.LOADING_FAIL,
        associationsMessage: action.payload.message,
        associationsData: [],
        notifications,
      };
      return {
        ...state,
      };
    }
    case docOverviewType.RESET_DOC_OVERVIEW: {
      delete state[docId];
      return {
        ...state,
      };
    }

    case docOverviewType.GET_COLOR_CODE: {
      state[docId] = {
        ...state[docId],
        loadingStatus: constants.LOADING_LOAD,
      };
      return {
        ...state,
      };
    }
    case docOverviewType.GET_COLOR_CODE_COMMIT: {
      return {
        ...state,
        loadingStatus: constants.LOADING_SUCCESS,
        message: action.payload.message,
        colorCode: action.payload.data,
      };
    }
    case docOverviewType.GET_COLOR_CODE_ROLLBACK: {
      state[docId] = {
        ...state[docId],
        loadingStatus: constants.LOADING_FAIL,
        message: action.payload?.message,
      };

      return {
        ...state,
      };
    }

    case docOverviewType.UPDATE_PROJECT_DETAILS: {
      return {
        ...state,
        loadingStatusUpdateProjectDetails: constants.LOADING_LOAD,
      };
    }
    case docOverviewType.UPDATE_PROJECT_DETAILS_COMMIT: {
      return {
        ...state,
        loadingStatusUpdateProjectDetails: constants.LOADING_SUCCESS,
        message: action.payload.message,
        projectData: action.payload.data,
      };
    }
    case docOverviewType.UPDATE_PROJECT_DETAILS_ROLLBACK: {
      return {
        ...state,
        loadingStatusUpdateProjectDetails: constants.LOADING_FAIL,
        message: action.payload?.message,
      };
    }
    default:
      return state;
  }
};

export default docOverviewReducer;
