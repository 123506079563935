import searchType from "../types/search-type";
import { config } from "../../config/config";
import { METRIC_INFO } from "../../config/constants";
import endpoints from "../../config/endpoints";
import { searchObject } from "../../model/search";

export const getSearchResults = (values: searchObject) => ({
  type: searchType.GET_SEARCH_RESULTS,
  http: {
    cognito: {
      effect: {
        url: config.BASE_URL + endpoints.searchEnpoint(),
        method: "POST",
        json: values,
      },
      commit: {
        type: searchType.GET_SEARCH_RESULTS_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
        },
      },
      rollback: {
        type: searchType.GET_SEARCH_RESULTS_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
        },
      },
    },
  },
});
