import { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
//redux
import { connect } from "react-redux";
import { getDeletedFiles } from "../../redux/actions/file-trash-action";
import { Dispatch } from "redux";
// sub components
import { AppLayout, Spinner } from "@amzn/awsui-components-react";
import TrashTable from "./table/table";
// configs, contants
import constants from "../../config/constants";
import { deletedFileData } from "../../model/trash";
import { HeaderStyle } from "../../config/constants";
//css
import "../../index.css";

// declare prop check
type Props = {
  dispatch: Dispatch<any>;
  history: any;
  fileTrashReducer: any;
} & RouteComponentProps<any>;

// declare state check
type State = {};

class FileTrash extends Component<Props, State> {
  state: State = Object.freeze({});

  componentDidMount() {
    this.getData();
  }

  componentWillUnmount() {}

  getData = () => {
    this.props.dispatch(getDeletedFiles());
  };

  render() {
    if (
      [constants.LOADING_LOAD, constants.LOADING_DEFAULT].includes(
        this.props.fileTrashReducer.loadingStatus
      )
    ) {
      return <Spinner size="large" />;
    }
    const {
      deletedFileData,
      loadingStatus,
    }: {
      deletedFileData: deletedFileData;
      loadingStatus: number;
    } = this.props.fileTrashReducer;

    //--------------------------------------------------Header--------------------------------------------------------//
    const header = (
      <HeaderStyle variant="h1" className="pt-4">
        File Trash
      </HeaderStyle>
    );

    //-----------------------------------------------Main Content-----------------------------------------------------//
    const mainContent = (
      <TrashTable
        data={deletedFileData}
        loadingStatus={loadingStatus}
        getData={this.getData}
      />
    );

    return (
      <AppLayout
        navigationHide
        toolsHide
        contentHeader={header}
        content={mainContent}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fileTrashReducer: state.fileTrashReducer,
  };
};

export default connect(mapStateToProps)(FileTrash);
