import repositoryOverviewType from "../types/repository-overview-type";
import { config } from "../../config/config";
import { METRIC_INFO } from "../../config/constants";
import endpoints from "../../config/endpoints";
import { notification } from "../../model/repository";

export const getRepositoryOverview = () => ({
  type: repositoryOverviewType.GET_REPOSITORY_OVERVIEW,
  http: {
    cognito: {
      effect: {
        url: config.BASE_URL + endpoints.repositoryOverviewEndpoint(),
        method: "GET",
      },
      commit: {
        type: repositoryOverviewType.GET_REPOSITORY_OVERVIEW_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
        },
      },
      rollback: {
        type: repositoryOverviewType.GET_REPOSITORY_OVERVIEW_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
        },
      },
    },
  },
});

export const setNotifications = (notifications: notification[]): any => ({
  type: repositoryOverviewType.SET_REPOSITORY_NOTIFICATION,
  notifications,
});

export const deleteNotification = (index: number): any => ({
  type: repositoryOverviewType.DELETE_REPOSITORY_NOTIFICATION,
  index,
});
