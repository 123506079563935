import React from "react";
import styled from "styled-components";
import { EXPIRY_DATE_COLOR_CODE } from "../../config/constants";

const Span = styled.span`
  position: absolute;
  margin-top: 20px;
  display: none;
  z-index: 1;
  background-color: white;
  border: 1px solid
    ${({ color }) => color || EXPIRY_DATE_COLOR_CODE.RED_COLOR_HEX};
`;

const Div = styled.div`
  height: 20px;
  width: 25px;
  margin-bottom: 15px;
  border: 1px solid
    ${({ color }) => color || EXPIRY_DATE_COLOR_CODE.RED_COLOR_HEX};
  background-color: ${({ color }) =>
    color || EXPIRY_DATE_COLOR_CODE.RED_COLOR_HEX};
  &:hover ${Span} {
    display: block;
  }
`;

type Props = {
  informatiomText: String;
  color: String;
};
export const ExpiryDate = ({ informatiomText, color }: Props) => {
  return (
    <Div color={color}>
      <Span color={color}>
        {informatiomText !== "No expiry date available" && (
          <span
            style={{
              height: "100%",
              borderRadius: "16px",
              padding: "2px",
              color: `${color}`,
              fontSize: "12px",
              fontWeight: "bold",
              transition: "all .2s ease-out",
            }}
          >
            Date:
          </span>
        )}{" "}
        {informatiomText}{" "}
      </Span>
    </Div>
  );
};
