import { RouteComponentProps } from "react-router-dom";
import { Component } from "react";

//redux
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { resetFileOverview } from "../../redux/actions/file-overview-action";
import {
  AppLayout,
  Button,
  ButtonDropdown,
  ColumnLayout,
  Container,
  FormField,
  Header,
  Multiselect,
  SpaceBetween,
  Spinner,
  Tabs,
} from "@amzn/awsui-components-react";
import { fileOverviewData } from "../../model/file-overview";
import { notification } from "../../model/file-overview";
import { updateFileRequest } from "../../model/http-json";
import { formatBytes } from "../../utils/general-utils";
import Breadcrumb from "../../components/common/breadcrumb/breadcrumb";
import { fileDownloadS3Call } from "../../utils/file-download";
import { FILE_DOWNLOAD_FAIL_NOTIFICATION } from "../../config/constants";
import constants, {
  DOCUMENT_ACTIONS,
  FILE_UPDATE_NOTIFICATION,
  RENAME_TYPE,
} from "../../config/constants";
import { ValueWithLabel } from "../../components/common/labels";
import AssociationsTable from "./table/associations-table";
import { TABS } from "./config";
import FileVersioningTable from "./table/versions-table";
import { getFileOverview } from "../../redux/actions/file-overview-action";
import { getTagsConfig } from "../../redux/actions/tags-config-action";
import {
  updateFile,
  resetUpdateFile,
} from "../../redux/actions/update-file-action";
import {
  setNotifications,
  deleteNotification,
} from "../../redux/actions/file-overview-action";
import { upcomingFeature } from "../../components/common/notification/common-notification";
import Notification from "../../components/common/notification/notification";
import { resetAssociation } from "../../redux/actions/create-association-action";
import { resetRemoveAssociation } from "../../redux/actions/remove-association-action";
import {
  changeToShortDateFormat,
  changeToShortDateFormatAndAddColor,
} from "../../utils/date-utils";
import { ExpiryDate } from "../common-container/expiry-date";
import { EXPIRY_DATE_COLOR_CODE } from "../../config/constants";
import Rename from "../common-container/rename";
import { HeaderStyle } from "../../config/constants";
import NoAuthz from "../no-authz/no-authz";

//css

// declare prop check
interface StateProps {
  fileOverviewReducer: any;
  tagsConfigReducer: any;
  updateFileReducer: any;
  createAssociationReducer: any;
  removeAssociationReducer: any;
}

interface MatchParams {
  fileId: number;
}

type Props = {
  dispatch: Dispatch<any>;
  history: any;
} & RouteComponentProps<MatchParams> &
  StateProps;

// declare state check
type State = {
  activeTabId: string;
  selectedTags: Array<any>;
  isTagsInitialized: boolean;
  openAssociationsModal: boolean;
  showAssociationsErrorModal: boolean;
  associationsErrorModalData: string;
  isRename: boolean;
  fileName: string;
  fileDownloadStatus: number;
};

class FileOverview extends Component<Props, State> {
  state: State = Object.freeze({
    activeTabId: TABS[0].ID,
    selectedTags: [],
    isTagsInitialized: false,

    openAssociationsModal: false,
    showAssociationsErrorModal: false,
    associationsErrorModalData: "",
    isRename: false,
    fileName: "",
    fileDownloadStatus: constants.LOADING_DEFAULT,
  });

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.match.params.fileId !== prevProps.match.params.fileId) {
      const { fileId } = prevProps.match.params;
      this.props.dispatch(resetFileOverview(fileId));
      this.props.dispatch(resetUpdateFile());
      this.getData();
    }

    const { loadingStatus } = this.props.updateFileReducer;

    const createAssociationLoadingStatus =
      this.props.createAssociationReducer?.loadingStatus;
    const removeAssociationLoadingStatus =
      this.props.removeAssociationReducer?.loadingStatus;

    if (createAssociationLoadingStatus === constants.LOADING_SUCCESS) {
      this.getData();
      this.onAssociationSuccess();
    }
    if (removeAssociationLoadingStatus === constants.LOADING_SUCCESS) {
      this.getData();
      this.onDisassociationSuccess();
    }

    if (createAssociationLoadingStatus === constants.LOADING_FAIL)
      this.onAssociationFailure();
    if (removeAssociationLoadingStatus === constants.LOADING_FAIL)
      this.onDisassociationFailure();

    if (loadingStatus === constants.LOADING_SUCCESS) this.onUpdationSuccess();
    if (loadingStatus === constants.LOADING_FAIL) this.onUpdationFailure();
  }

  componentWillUnmount() {
    const { fileId } = this.props.match.params;
    this.props.dispatch(resetFileOverview(fileId));
  }

  onAssociationSuccess = () => {
    // set notification
    const notifications = [
      {
        type: constants.STATUS_SUCCESS,
        content: <span>Successfully created association</span>,
      },
    ];

    const { fileId } = this.props.match.params;
    this.props.dispatch(setNotifications(notifications, fileId));
    this.props.dispatch(resetAssociation(fileId));
  };

  onDisassociationSuccess = () => {
    // set notification
    const notifications = [
      {
        type: constants.STATUS_SUCCESS,
        content: <span>Successfully removed association</span>,
      },
    ];

    const { fileId } = this.props.match.params;
    this.props.dispatch(setNotifications(notifications, fileId));
    this.props.dispatch(resetRemoveAssociation(fileId));
  };

  onAssociationFailure = () => {
    // set notification
    const notifications = [
      {
        type: constants.STATUS_ERROR,
        content: <span>Failed to create association, please try again.</span>,
      },
    ];

    const { fileId } = this.props.match.params;
    this.props.dispatch(setNotifications(notifications, fileId));
    this.props.dispatch(resetAssociation(fileId));
  };

  onDisassociationFailure = () => {
    // set notification
    const notifications = [
      {
        type: constants.STATUS_SUCCESS,
        content: <span>Failed to remove association, please try again.</span>,
      },
    ];

    const { fileId } = this.props.match.params;
    this.props.dispatch(setNotifications(notifications, fileId));
    this.props.dispatch(resetRemoveAssociation(fileId));
  };

  onUpdationSuccess = () => {
    // set notification
    const notifications = [
      {
        type: constants.STATUS_SUCCESS,
        content: <span>{FILE_UPDATE_NOTIFICATION.SUCCESS}</span>,
      },
    ];

    const { fileId } = this.props.match.params;
    this.props.dispatch(setNotifications(notifications, fileId));
    this.props.dispatch(resetUpdateFile());
  };

  onUpdationFailure = () => {
    // set notification
    const notifications = [
      {
        type: constants.STATUS_ERROR,
        content: <span>{FILE_UPDATE_NOTIFICATION.FAILED}</span>,
      },
    ];
    const { fileId } = this.props.match.params;
    this.props.dispatch(setNotifications(notifications, fileId));
    this.props.dispatch(resetUpdateFile());
  };

  getData = () => {
    const { fileId } = this.props.match.params;
    this.props.dispatch(getFileOverview(fileId));
    this.props.dispatch(getTagsConfig());
  };

  onActionButtonClick = ({ detail }) => {
    const fileId = this.props.match.params.fileId;
    if (detail.id === DOCUMENT_ACTIONS.RENAME.ID) {
      this.setState({ isRename: true });
      console.log("Rename button clicked");
    }
    if (detail.id === DOCUMENT_ACTIONS.SHARE.ID) {
      console.log("Share button clicked");
      this.props.dispatch(setNotifications([upcomingFeature], fileId));
      // TODO: update this when share functionality is ready
    }
  };

  onTabChange = (event) => {
    this.setState({ activeTabId: event.detail.activeTabId });
  };

  onChangeTags = (event) => {
    const tags = event.detail.selectedOptions;
    this.setState({ selectedTags: tags });
  };

  formatTagOptions = (options: any) => {
    const formattedOptions = options.map((x: any) => {
      return { label: x, value: x };
    });
    return formattedOptions;
  };

  _onApplyChanges = (event) => {
    const fileId = this.props.match.params.fileId;

    const updatedTags = this.state.selectedTags.map((tag) => tag.value);
    const values: updateFileRequest = {
      tags: updatedTags,
    };

    this.props.dispatch(updateFile(fileId, values));
  };

  onDismissNotification = (index: number) => {
    const fileId = this.props.match.params.fileId;
    this.props.dispatch(deleteNotification(index, fileId));
  };

  onDownloadButtonClick = () => {
    this.setState({
      fileDownloadStatus: constants.LOADING_LOAD,
    });
    const fileId = this.props.match.params.fileId;

    fileDownloadS3Call(fileId).then((response) => {
      if (response.status === constants.STATUS_SUCCESS) {
        window.location.href = response.s3_url;
        this.setState({
          fileDownloadStatus: constants.LOADING_SUCCESS,
        });
      } else {
        this.setState({
          fileDownloadStatus: constants.LOADING_FAIL,
        });
        // set notification
        this.props.dispatch(
          setNotifications([FILE_DOWNLOAD_FAIL_NOTIFICATION], fileId)
        );
      }
    });
  };

  render() {
    const { fileId } = this.props.match.params;
    if (
      !this.props.fileOverviewReducer[fileId] ||
      !this.props.fileOverviewReducer[fileId].loadingStatus ||
      [constants.LOADING_LOAD, constants.LOADING_DEFAULT].includes(
        this.props.fileOverviewReducer[fileId].loadingStatus
      ) ||
      !this.props.tagsConfigReducer ||
      !this.props.tagsConfigReducer.loadingStatus ||
      [constants.LOADING_LOAD, constants.LOADING_DEFAULT].includes(
        this.props.tagsConfigReducer.loadingStatus
      ) ||
      !this.props.createAssociationReducer ||
      !this.props.createAssociationReducer.loadingStatus ||
      this.props.createAssociationReducer.loadingStatus ===
        constants.LOADING_LOAD ||
      !this.props.removeAssociationReducer ||
      !this.props.removeAssociationReducer.loadingStatus ||
      this.props.removeAssociationReducer.loadingStatus ===
        constants.LOADING_LOAD
    )
      return <Spinner size="large"></Spinner>;

    const {
      fileOverviewData,
      fileOverviewLoadingStatus,
      notifications,
    }: {
      fileOverviewData: fileOverviewData;
      fileOverviewLoadingStatus: number;
      notifications: notification[];
    } = this.props.fileOverviewReducer[fileId];

    const { loadingStatus } = this.props.updateFileReducer;

    if (loadingStatus === constants.LOADING_LOAD) {
      return <Spinner size="large"></Spinner>;
    }

    if (this.props.fileOverviewReducer[fileId].unAuthorized)
      return <NoAuthz resource={`the given file`} />;

    const TAGS = this.props.tagsConfigReducer.tagsConfigData;
    const TAG_OPTIONS: any = [];
    Object.entries(TAGS).forEach(([key, values]) => {
      TAG_OPTIONS.push({ label: key, options: this.formatTagOptions(values) });
    });

    if (!this.state.isTagsInitialized) {
      this.setState({
        selectedTags:
          fileOverviewData.tags === null
            ? []
            : this.formatTagOptions(fileOverviewData.tags),
        isTagsInitialized: true,
      });
    }

    //-----------------------------------------------Notifications-----------------------------------------------------//
    const notification = (
      <Notification
        notifications={notifications}
        onDismiss={this.onDismissNotification}
      />
    );

    //------------------------------------------------Breadcrumb------------------------------------------------------//
    const breadcrumb = (
      <Breadcrumb
        breadcrumb_data={[
          { doc_name: "File Repository", path: "/repository" },
          {
            doc_name: `${
              this.state.fileName.length === 0
                ? fileOverviewData.file_name
                : this.state.fileName
            }`,
            path: "#",
          },
        ]}
        history={this.props.history}
      />
    );

    //--------------------------------------------------Header--------------------------------------------------------//
    const header = (
      <HeaderStyle
        variant="h1"
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <ButtonDropdown
              items={[
                {
                  text: DOCUMENT_ACTIONS.RENAME.TEXT,
                  id: DOCUMENT_ACTIONS.RENAME.ID,
                },
                {
                  text: DOCUMENT_ACTIONS.SHARE.TEXT,
                  id: DOCUMENT_ACTIONS.SHARE.ID,
                },
              ]}
              onItemClick={this.onActionButtonClick}
            >
              Actions
            </ButtonDropdown>
            <Button
              iconName="download"
              variant="primary"
              onClick={this.onDownloadButtonClick}
              disabled={false}
            >
              Download
            </Button>
          </SpaceBetween>
        }
      >
        {this.state.isRename ? (
          <Rename
            doc_id={fileOverviewData.file_id}
            initial_value={
              this.state.fileName.length === 0
                ? fileOverviewData.file_name
                : this.state.fileName
            }
            setIsRename={(val) => {
              this.setState({ isRename: val });
            }}
            setFileName={(val) => {
              this.setState({ fileName: val });
            }}
            type={RENAME_TYPE.REPOSITORY}
            loadingStatus={loadingStatus}
          />
        ) : this.state.fileName.length === 0 ? (
          fileOverviewData.file_name
        ) : (
          this.state.fileName
        )}
      </HeaderStyle>
    );

    //-----------------------------------------------Main Content-----------------------------------------------------//

    /*
        1. Properties Container
        2. Details Container
            A. Associations View
            B. Versions View
            C. Tags View
    */

    const mainContent = (
      <>
        <Container header={<Header variant="h2">Properties</Header>}>
          <ColumnLayout columns={4} variant="text-grid">
            <SpaceBetween size="l">
              <ValueWithLabel label="File Id">
                {fileOverviewData.file_id}
              </ValueWithLabel>
              <ValueWithLabel label="Description">
                {fileOverviewData.description}
              </ValueWithLabel>
            </SpaceBetween>

            <SpaceBetween size="l">
              <ValueWithLabel label="Expiry Status">
                <ExpiryDate
                  informatiomText={
                    fileOverviewData.expiry_date
                      ? changeToShortDateFormatAndAddColor(
                          fileOverviewData.expiry_date
                        ).Date
                      : "No expiry date available"
                  }
                  color={
                    fileOverviewData.expiry_date
                      ? changeToShortDateFormatAndAddColor(
                          fileOverviewData.expiry_date
                        ).color
                      : EXPIRY_DATE_COLOR_CODE.GREEN_COLOR_HEX
                  }
                />
              </ValueWithLabel>
              <ValueWithLabel label="Tags">
                {this.state.selectedTags.map((tag) => tag.label).length === 0
                  ? "---"
                  : this.state.selectedTags.map((tag) => tag.label).join(", ")}
              </ValueWithLabel>
            </SpaceBetween>

            <SpaceBetween size="l">
              <ValueWithLabel label="Size">
                {formatBytes(fileOverviewData.size)}
              </ValueWithLabel>
              <ValueWithLabel label="Associations">
                {fileOverviewData.associations?.length}
              </ValueWithLabel>
            </SpaceBetween>

            <SpaceBetween size="l">
              <ValueWithLabel label="Upload Time">
                {changeToShortDateFormat(fileOverviewData.upload_time)}
              </ValueWithLabel>
              <ValueWithLabel label="Uploaded By">
                {fileOverviewData.uploaded_by}
              </ValueWithLabel>
            </SpaceBetween>
          </ColumnLayout>
          <br />
        </Container>
        <br />

        <Tabs
          activeTabId={this.state.activeTabId}
          onChange={this.onTabChange}
          tabs={[
            {
              label: TABS[0].LABEL,
              id: TABS[0].ID,
              content: (
                <AssociationsTable
                  fileId={fileOverviewData.file_id}
                  data={fileOverviewData?.associations}
                  tableLoadingStatus={fileOverviewLoadingStatus}
                  dispatch={this.props.dispatch}
                />
              ),
            },

            {
              label: TABS[1].LABEL,
              id: TABS[1].ID,
              content: (
                <FileVersioningTable
                  fileId={fileOverviewData.file_id}
                  data={fileOverviewData?.versions}
                  loadingStatus={fileOverviewLoadingStatus}
                  dispatch={this.props.dispatch}
                />
              ),
            },

            {
              label: TABS[2].LABEL,
              id: TABS[2].ID,
              content: (
                <Container>
                  <FormField
                    label={
                      <Header counter={`(${this.state.selectedTags.length})`}>
                        Tags
                      </Header>
                    }
                    description="A tag is a label that you assign to a file. You can use tags to search and filter your files."
                  >
                    <SpaceBetween size="xs">
                      <Multiselect
                        options={TAG_OPTIONS}
                        placeholder="Select Tags"
                        selectedOptions={this.state.selectedTags}
                        onChange={(event) => this.onChangeTags(event)}
                      />

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row-reverse",
                        }}
                      >
                        <Button
                          variant="primary"
                          onClick={this._onApplyChanges}
                          loading={loadingStatus === constants.LOADING_LOAD}
                        >
                          Apply Changes
                        </Button>
                      </div>
                    </SpaceBetween>
                  </FormField>
                </Container>
              ),
            },
          ]}
        />
      </>
    );

    return (
      <AppLayout
        navigationHide
        toolsHide
        notifications={notification}
        breadcrumbs={breadcrumb}
        contentHeader={header}
        content={mainContent}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fileOverviewReducer: state.fileOverviewReducer,
    tagsConfigReducer: state.tagsConfigReducer,
    updateFileReducer: state.updateFileReducer,
    createAssociationReducer: state.createAssociationReducer,
    removeAssociationReducer: state.removeAssociationReducer,
  };
};

export default connect(mapStateToProps)(FileOverview);
