import { useState } from "react";
// sub components
import {
  Table,
  Header,
  Pagination,
  PropertyFilter,
  SpaceBetween,
  Button,
} from "@amzn/awsui-components-react";
import {
  TableEmptyState,
  TableNoMatchState,
} from "../../../components/polaris/table/common-components";
import { useCollection } from "@amzn/awsui-collection-hooks";
//config, constants
import {
  COLUMN_DEFINITIONS,
  DEFAULT_PREFERENCES,
  FILTERING_PROPERTIES,
  Preferences,
} from "./versions-table-config";
import {
  paginationLabels,
  PROPERTY_FILTERING_I18N_CONSTANTS,
} from "../../../components/polaris/table/labels";
import { getFilterCounterText } from "../../../components/polaris/table/table-counter-strings";
import NewVersion from "../modal/new-version";
// use this for sample data
import constants from "../../../config/constants";
//css

const FileVersioningTable = ({
  fileId,
  data,
  loadingStatus,
  dispatch,
}: {
  fileId: string;
  data: any;
  loadingStatus: number;
  dispatch: any;
}) => {
  // selected Items in the table
  const [selectedItems, setSelectedItems] = useState<any | never>([]);

  // upload new version modal
  const [openUploadNewVersionModal, setOpenUploadNewVersionModal] =
    useState(false);

  // default table preferences
  const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);

  // using polaris useCollection hook to define table props
  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    propertyFilterProps,
    paginationProps,
  } = useCollection(data, {
    propertyFiltering: {
      filteringProperties: FILTERING_PROPERTIES,
      empty: <TableEmptyState resourceName={constants.FILE} />,
      noMatch: (
        <TableNoMatchState
          onClearFilter={() =>
            actions.setPropertyFiltering({ tokens: [], operation: "and" })
          }
        />
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
  });

  // table loading status
  const loading = loadingStatus === constants.LOADING_LOAD;

  return (
    <>
      <Table
        {...collectionProps}
        columnDefinitions={COLUMN_DEFINITIONS}
        visibleColumns={preferences.visibleContent}
        items={items}
        selectionType="multi"
        loadingText="Loading resources"
        loading={loading}
        resizableColumns={true}
        wrapLines={preferences.wrapLines}
        header={
          <Header
            counter={
              selectedItems.length
                ? `(${selectedItems.length}/${data.length})`
                : `(${data.length})`
            }
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  onClick={() => {
                    setOpenUploadNewVersionModal(true);
                  }}
                >
                  Upload New Version
                </Button>
                <Button
                  iconName="download"
                  href={
                    selectedItems[0] === undefined
                      ? undefined
                      : selectedItems[0].s3_link === null
                      ? undefined
                      : selectedItems[0].s3_link
                  }
                  disabled={selectedItems.length !== 1}
                >
                  Download
                </Button>
              </SpaceBetween>
            }
          >
            Versions
          </Header>
        }
        filter={
          <PropertyFilter
            i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
            {...propertyFilterProps}
            countText={getFilterCounterText(filteredItemsCount)}
          />
        }
        pagination={
          <Pagination {...paginationProps} ariaLabels={paginationLabels} />
        }
        preferences={
          <Preferences
            preferences={preferences}
            setPreferences={setPreferences}
            disabled={false}
          />
        }
        onSelectionChange={({ detail }) =>
          setSelectedItems(detail.selectedItems)
        }
        selectedItems={selectedItems}
      />
      {openUploadNewVersionModal && (
        <NewVersion
          dispatch={dispatch}
          fileId={fileId}
          openUploadNewVersionModal={openUploadNewVersionModal}
          setOpenUploadNewVersionModal={setOpenUploadNewVersionModal}
        ></NewVersion>
      )}
    </>
  );
};

export default FileVersioningTable;
