import { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
//redux
import { connect } from "react-redux";
import { Dispatch } from "redux";
// sub components
import { Header, Spinner, ContentLayout } from "@amzn/awsui-components-react";
// configs, contants
import constants from "../../config/constants";
//css
import "../../index.css";
import { documentOverviewData } from "../../model/doc-overview";
import { getSearchResults } from "../../redux/actions/search-action";
import ShowSearchResult from "./table/search-result-container";

// declare prop check
type Props = {
  dispatch: Dispatch<any>;
  history: any;
  searchReducer: any;
} & RouteComponentProps<any>;

// declare state check
type State = {};

class SearchResults extends Component<Props, State> {
  state: State = Object.freeze({});

  componentDidMount() {
    this.getData();
  }

  componentWillUnmount() { }

  componentDidUpdate(prevProps: Props) {
    const search = this.props.location.search;
    const searchString = new URLSearchParams(search).get("search_string");
    const prevSearch = prevProps.location.search;
    const prevSearchString = new URLSearchParams(prevSearch).get(
      "search_string"
    );
    if (prevSearchString !== searchString) {
      this.getData();
    }
  }
  getData = () => {
    const search = this.props.location.search;
    const search_string = new URLSearchParams(search).get("search_string");
    this.props.dispatch(
      getSearchResults({ search_string: search_string ? search_string : "" })
    );
  };

  render() {
    const search = this.props.location.search;
    const search_string = new URLSearchParams(search).get("search_string");
    if (
      [constants.LOADING_LOAD, constants.LOADING_DEFAULT].includes(
        this.props.searchReducer.loadingStatus
      )
    ) {
      return (
        <div style={{ maxHeight: "500px", overflow: "auto", textAlign: "center", paddingTop: "200px"}}>
          <Spinner size="large" />
          <br />
          Searching documents
        </div>
      )

    }
    const {
      searchResults,
      loadingStatus,
    }: {
      searchResults: documentOverviewData;
      loadingStatus: number;
    } = this.props.searchReducer;
    return (
      <div style={{ marginLeft: "10%", marginRight: "10%" }}>
        <ContentLayout
          header={
            <Header variant="h1">Result for "{search_string}"</Header>
          }
        >
          <ShowSearchResult
            data={searchResults}
            loadingStatus={loadingStatus}
          />

        </ContentLayout>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    searchReducer: state.searchReducer,
  };
};

export default connect(mapStateToProps)(SearchResults);
