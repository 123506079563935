import {
  ColumnLayout,
  Container,
  Header,
  ProgressBar,
  SpaceBetween,
  Link,
  Flashbar,
  StatusIndicator,
  Alert,
} from "@amzn/awsui-components-react";
import { Component } from "react";
import { ValueWithLabel } from "../../../components/common/labels";
import { fileObject } from "../../../model/file";
import UploadSummaryTable from "./table/table";

// declare prop check
type Props = {
  files: Array<fileObject>;
  history: any;
  inProgressFiles: number;
  successfulFiles: number;
  failedFiles: number;
} & typeof defaultProps;

type State = {};

// declare init state & default props
const defaultProps = Object.freeze({});

const initialState = Object.freeze({});

class UploadPage extends Component<Props, State> {
  static defaultProps = defaultProps;
  state = initialState;

  render() {
    let progressPercentage =
      (100 * (this.props.failedFiles + this.props.successfulFiles)) /
      (this.props.inProgressFiles +
        this.props.failedFiles +
        this.props.successfulFiles);
    return (
      <SpaceBetween size="m">
        {progressPercentage !== 100 && (
          <Flashbar
            items={[
              {
                content: (
                  <ProgressBar
                    status="in-progress"
                    value={progressPercentage}
                    variant="flash"
                    label="File Upload Progress"
                  />
                ),
              },
            ]}
          />
        )}
        <Alert
          type="error"
          visible={progressPercentage === 100 && this.props.failedFiles !== 0}
          dismissAriaLabel="Close alert"
          header="Upload Failed"
        >
          Error uploading some files. View details below.
        </Alert>
        <Alert
          type="success"
          visible={progressPercentage === 100 && this.props.failedFiles === 0}
          dismissAriaLabel="Close alert"
          header="Upload Successful"
        >
          View details below.
        </Alert>
        <Alert visible={true} dismissAriaLabel="Close alert">
          The information below will no longer be available after you navigate
          away from this page.
        </Alert>
        <Container header={<Header variant="h2">Summary</Header>}>
          <ColumnLayout columns={3} variant="text-grid">
            <ValueWithLabel label="Destination">
              {"File Repository"}
              <Link href="" external={true}></Link>
            </ValueWithLabel>
            <ValueWithLabel label="Succeeded">
              <StatusIndicator type="success">
                {this.props.successfulFiles} files (
                {(100 * this.props.successfulFiles) /
                  (this.props.inProgressFiles +
                    this.props.failedFiles +
                    this.props.successfulFiles)}
                %)
              </StatusIndicator>
            </ValueWithLabel>
            <ValueWithLabel label="Failed">
              <StatusIndicator type="error">
                {this.props.failedFiles} files (
                {(100 * this.props.failedFiles) /
                  (this.props.inProgressFiles +
                    this.props.failedFiles +
                    this.props.successfulFiles)}
                %)
              </StatusIndicator>
            </ValueWithLabel>
          </ColumnLayout>
        </Container>
        <UploadSummaryTable
          data={this.props.files}
          loadingStatus={true}
          history={this.props.history}
        />
      </SpaceBetween>
    );
  }
}

export default UploadPage;
