import { config } from "../../config/config.js";
import constants from "../../config/constants";
import { documentOverviewData } from "../../model/doc-overview";
import { repositoryOverviewData } from "../../model/repository";
import { deleteDocStatus } from "../../model/delete-doc";
import { deleteFileStatus } from "../../model/delete-file";
import { deleteData, Response } from "../../utils/cognito-fetch";
import endpoints from "../../config/endpoints";

export async function deleteDocCall(
  doc: documentOverviewData
): Promise<deleteDocStatus> {
  const result: deleteDocStatus = {
    status: constants.STATUS_ERROR,
    message: "",
  };
  try {
    const doc_id = doc.doc_id;
    const url: string = config.BASE_URL + endpoints.docEndpont(doc_id);

    const response: Response = await deleteData(url);
    if (!response.ok) {
      throw response.json ? response.json : response.status_text;
    }
    result.status = constants.STATUS_SUCCESS;
    result.message = "";
  } catch (error: any) {
    result.status = constants.STATUS_ERROR;
    result.message = error["message"];
  } finally {
    return result;
  }
}

export async function deleteFileCall(
  file: repositoryOverviewData
): Promise<deleteFileStatus> {
  const result: deleteFileStatus = {
    status: constants.STATUS_ERROR,
    message: "",
  };
  try {
    const file_id = file.file_id;
    const url: string = config.BASE_URL + endpoints.fileEndpoint(file_id);

    const response: Response = await deleteData(url);
    if (!response.ok) {
      throw response.json ? response.json : response.status_text;
    }
    result.status = constants.STATUS_SUCCESS;
    result.message = "";
  } catch (error: any) {
    result.status = constants.STATUS_ERROR;
    result.message = error["message"];
  } finally {
    return result;
  }
}
