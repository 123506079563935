import {
  Box,
  Button,
  DatePicker,
  Form,
  FormField,
  Grid,
  Input,
  Modal,
  Multiselect,
  SpaceBetween,
  Textarea,
  TextContent,
} from "@amzn/awsui-components-react";
import { Component } from "react";
import constants, { FILE_UPLOAD } from "../../../../config/constants";
// redux
import { fileObject } from "../../../../model/file";

type State = {
  formValues: Array<any>;
};

// declare prop check
type Props = {
  setModalState: any;
  openModal: boolean;
  files: Array<fileObject>;
  onSubmitAddedFiles: any;
  tagsConfig: any;
};

// declare init state & default props
const defaultProps = Object.freeze({});
const initialState = Object.freeze({ formValues: [] });

class FileDetailsForm extends Component<Props> {
  static readonly defaultProps = defaultProps;
  readonly state: State = initialState;

  componentDidMount() {
    let formValues: Array<fileObject> = this.props.files.map(
      (file: fileObject) => {
        return {
          file: file.file,
          expiryDate: "",
          description: "",
          tags: []
        };
      }
    );
    this.setState({
      formValues,
    });
  }

  _onChangeDescription = (fileIndex, event) => {
    const { formValues } = this.state;
    const description = event.detail.value;
    formValues[fileIndex].description = description;
    formValues[fileIndex].descriptionError = "";
    this.setState({ formValues });
  };

  _onChangeTags = (fileIndex, event) => {
    const { formValues } = this.state;
    const tags = event.detail.selectedOptions;
    formValues[fileIndex].tags = tags;
    this.setState({ formValues });
  };

  _onChangeExpiryDate = (fileIndex, event) => {
    const { formValues } = this.state;
    const expiryDate = event.detail.value;
    formValues[fileIndex].expiryDate = expiryDate;
    formValues[fileIndex].expiryDateError = "";
    this.setState({ formValues });
  };

  validateForm = () => {
    const { formValues } = this.state;
    let isValid = true;
    formValues.forEach((file: any) => {
      if (!file.description) {
        file.descriptionError = "Required";
        isValid = false;
      } else if (file.description.length > FILE_UPLOAD.DESCRIPTION_CHARACTER_LENGTH) {
        file.descriptionError = `Max length ${FILE_UPLOAD.DESCRIPTION_CHARACTER_LENGTH}`;
        isValid = false;
      }
    });
    this.setState({ formValues });
    return isValid;
  };

  _onSubmit = () => {
    const { formValues } = this.state;
    if (this.validateForm()) {
      formValues.forEach((file: any) => {
        file.status = constants.STATUS_PROCESS;
      });
      this.props.onSubmitAddedFiles(formValues);
      this.setState({ formValues: [] });
    }
  };

  getGridDefinition = () => {
    let gridDefinition: Array<any> = [
      { colspan: FILE_UPLOAD.GRID_SIZE.FILE_NAME },
      { colspan: FILE_UPLOAD.GRID_SIZE.DESCRIPTION },
      { colspan: FILE_UPLOAD.GRID_SIZE.TAGS },
      { colspan: FILE_UPLOAD.GRID_SIZE.EXPIRY_DATE }
    ];
    return gridDefinition;
  };

  formatTagOptions = (options: any) => {
    const formattedOptions = options.map((x: any) => {
      return { label: x, value: x };
    });
    return formattedOptions;
  };

  render() {
    const TAGS = this.props.tagsConfig;
    const TAG_OPTIONS: any = [];
    Object.entries(TAGS).forEach(([key, values]) => {
      TAG_OPTIONS.push({ label: key, options: this.formatTagOptions(values) });
    });

    const { formValues } = this.state;
    return (
      <Modal
        size="max"
        visible={this.props.openModal}
        onDismiss={() => this.props.setModalState(false)}
        header="File Details"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button onClick={() => this.props.setModalState(false)}>
                Cancel
              </Button>
              <Button
                iconName="caret-right-filled"
                iconAlign="left"
                variant="primary"
                onClick={this._onSubmit}
              >
                Next
              </Button>
            </SpaceBetween>
          </Box>
        }
        className="awsui-visual-refresh"
      >
        <Form>
          <Grid gridDefinition={this.getGridDefinition()}>
            <TextContent>
              <h4 key="file_name">File name</h4>
            </TextContent>
            <TextContent>
              <h4 key="description">
                Description <small> *Required</small>
              </h4>
            </TextContent>
            <TextContent>
              <h4 key="tags">Tags</h4>
            </TextContent>
            <TextContent>
              <h4 key="expiryDate">Expiry Date</h4>
            </TextContent>
          </Grid>
          {this.props.files.map((file: fileObject, fileIndex) => (
            <Grid key={fileIndex} gridDefinition={this.getGridDefinition()}>
              <FormField>
                <Input
                  key={file.file.name}
                  value={file.file.name}
                  disabled={true}
                />
              </FormField>
              <FormField
                key={fileIndex}
                errorText={formValues?.[fileIndex]?.descriptionError}
              >
                <Textarea
                  key={file.file.name}
                  value={formValues?.[fileIndex]?.description ?? ""}
                  placeholder="Enter file description"
                  rows={1}
                  onChange={(e) => this._onChangeDescription(fileIndex, e)}
                />
              </FormField>
              <FormField>
                <Multiselect
                  key={file.file.name}
                  options={TAG_OPTIONS}
                  placeholder="Select Tags"
                  selectedOptions={formValues?.[fileIndex]?.tags ?? []}
                  onChange={(e) => this._onChangeTags(fileIndex, e)}
                />
              </FormField>
              <FormField
                errorText={file.expiryDateError}
                key={fileIndex + "_expiryDate"}
              >
                <DatePicker
                  id="expiry_date"
                  placeholder="YYYY/MM/DD"
                  todayAriaLabel="Today"
                  nextMonthAriaLabel="Next month"
                  previousMonthAriaLabel="Previous month"
                  onChange={(e) => this._onChangeExpiryDate(fileIndex, e)}
                  value={
                    this.state.formValues[fileIndex] &&
                    this.state.formValues[fileIndex].expiryDate
                      ? this.state.formValues[fileIndex].expiryDate
                      : ""
                  }
                ></DatePicker>
              </FormField>
            </Grid>
          ))}
        </Form>
      </Modal>
    );
  }
}

export default FileDetailsForm;
