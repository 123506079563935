import { RouteComponentProps, withRouter } from "react-router-dom";
import React, { Component } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  SpaceBetween,
  FormField,
  ColumnLayout,
  Button,
  Input,
  Textarea,
  DatePicker,
  SelectProps,
  Multiselect,
  Container,
  Select,
} from "@amzn/awsui-components-react/polaris";
import {
  createProject,
  resetProject,
} from "../../../redux/actions/create-project-action";
import "../create-form.css";
import constants, {
  COMPONENT_ID,
  deviceCategories,
} from "../../../config/constants";
import { createProjectRequest } from "../../../model/http-json";
import { setNotifications } from "../../../redux/actions/doc-overview-action";
import {
  childrenTable,
  documentOverviewData,
} from "../../../model/doc-overview";
import Checklist from "../../../components/checklist/checklist";

interface StateProps {
  createProjectReducer: any;
  getProjectConfigReducer: any;
  docOverviewReducer: any;
  checklistReducer: any;
}

// declare prop check
type Props = {
  dispatch: Dispatch<any>;
  history: any;
} & StateProps &
  RouteComponentProps<any>;

type State = {
  selectedCoutries: SelectProps.Option[];
  selectedTechnologies: SelectProps.Option[];
  countryError: string; // input error
  technologyError: string; // input error
  project: string; // user input for creating project
  projectError: string; // input error
  model: string; // user input for model number
  modelError: string; // input error
  selectedDeviceCategory: SelectProps.Option; // user input
  deviceCategoryError: string; // input error
  pvtRr: string; // user input
  pvtRrError: string; // input error
  announceDate: string; // user input
  announceDateError: string; // input error
  streetDate: string; // input error
  streetDateError: string; // input error
  commentError: string; // input error
  responseError: string; // response error from BE
  comment: string; // user input
};

class ProjectForm extends Component<Props, State> {
  state: State = Object.freeze({
    selectedCoutries: [],
    selectedTechnologies: [],
    countryError: "",
    technologyError: "",
    project: "",
    projectError: "",
    model: "",
    modelError: "",
    selectedDeviceCategory: { value: "V1", label: "V1" },
    deviceCategoryError: "",
    pvtRr: "",
    pvtRrError: "",
    announceDate: "",
    announceDateError: "",
    streetDate: "",
    streetDateError: "",
    commentError: "",
    responseError: "",
    comment: "",
  });

  componentDidUpdate() {
    const { loadingStatus, responseData } = this.props.createProjectReducer;
    const categoryId = this.props.match.params.categoryId;
    const {
      docOverviewData,
    }: {
      docOverviewData: documentOverviewData;
    } = this.props.docOverviewReducer[categoryId];

    const category = docOverviewData;
    if (loadingStatus === constants.LOADING_SUCCESS) {
      this.onSuccessNotification(responseData.name, category);
    }
    if (loadingStatus === constants.LOADING_FAIL) {
      this.onFailureNotification(category);
    }
  }

  //validate input
  validate = (validateAll: boolean, id: string) => {
    const isProjectValid: boolean = this.validateById(COMPONENT_ID.PROJECT);
    const isCommentValid: boolean = this.validateById(COMPONENT_ID.COMMENT);
    const isModelNumberValid: boolean = this.validateById(COMPONENT_ID.MODEL);
    const isDeviceCategoryValid: boolean = this.validateById(
      COMPONENT_ID.DEVICE_CATEGORY
    );
    const isPvtRrValid: boolean = this.validateById(COMPONENT_ID.PVT_RR);
    const isAnnounceDateValid: boolean = this.validateById(
      COMPONENT_ID.ANNOUNCE_DATE
    );
    const isStreetDateValid: boolean = this.validateById(
      COMPONENT_ID.STREET_DATE
    );
    const isCountriesValid: boolean = this.validateById(COMPONENT_ID.COUNTRY);
    const isTechnologiesValid: boolean = this.validateById(
      COMPONENT_ID.TECHNOLOGY
    );
    return (
      isProjectValid &&
      isCommentValid &&
      isModelNumberValid &&
      isDeviceCategoryValid &&
      isPvtRrValid &&
      isAnnounceDateValid &&
      isStreetDateValid &&
      isCountriesValid &&
      isTechnologiesValid
    );
  };

  validateById = (id: string): boolean => {
    let isValid: boolean = true;

    // validate project
    if (id === COMPONENT_ID.PROJECT) {
      const project = this.state.project;
      let projectError: string = "";

      if (!project) {
        projectError = "Required";
      } else if (!/^[A-Za-z0-9]+$/i.test(project)) {
        projectError = "Letters and numbers only";
      } else if (project.length > 30) {
        projectError = "Max Length 30";
      }

      this.setState({
        projectError,
      });

      if (projectError) {
        isValid = false;
      }
    }

    // validate comment
    if (id === COMPONENT_ID.COMMENT) {
      const comment = this.state.comment;
      let commentError: string = "";

      if (comment && comment.length > 200) {
        commentError = "Max Length 200";
      }

      this.setState({
        commentError,
      });

      if (commentError) {
        isValid = false;
      }
    }

    // Validate Model Number
    if (id === COMPONENT_ID.MODEL) {
      const model = this.state.model;
      let modelError: string = "";

      if (!model) {
        modelError = "Required";
      }

      this.setState({
        modelError,
      });

      if (modelError) {
        isValid = false;
      }
    }

    // Validate Device Category
    if (id === COMPONENT_ID.DEVICE_CATEGORY) {
      const deviceCategory = this.state.selectedDeviceCategory;
      let deviceCategoryError: string = "";
      if (!deviceCategory || Object.keys(deviceCategory).length === 0) {
        deviceCategoryError = "Required";
      }
      this.setState({
        deviceCategoryError,
      });

      if (deviceCategoryError) {
        isValid = false;
      }
    }

    // Validate PVT RR
    if (id === COMPONENT_ID.PVT_RR) {
      let pvtRrError: string = "";

      // No input validation according to current requirements

      this.setState({
        pvtRrError,
      });

      if (pvtRrError) {
        isValid = false;
      }
    }

    // Validate Announce Date
    if (id === COMPONENT_ID.ANNOUNCE_DATE) {
      let announceDateError: string = "";

      // No input validation according to current requirements

      this.setState({
        announceDateError,
      });

      if (announceDateError) {
        isValid = false;
      }
    }

    // Validate Street Date
    if (id === COMPONENT_ID.STREET_DATE) {
      const streetDate = this.state.streetDate;
      let streetDateError: string = "";
      if (!streetDate) {
        streetDateError = "Required";
      }
      this.setState({
        streetDateError,
      });

      if (streetDateError) {
        isValid = false;
      }
    }

    // Validate Country
    if (id === COMPONENT_ID.COUNTRY) {
      const countries = this.state.selectedCoutries;
      let countryError: string = "";
      if (!countries || countries.length === 0) {
        countryError = "Required";
      }
      this.setState({
        countryError,
      });

      if (countryError) {
        isValid = false;
      }
    }

    // Validate Technology
    if (id === COMPONENT_ID.TECHNOLOGY) {
      // const technologies = this.state.selectedTechnologies;
      let technologyError: string = "";

      // No input validation according to current requirements
      // if (!technologies || technologies.length === 0) {
      //   technologyError = "Required";
      // }
      this.setState({
        technologyError,
      });

      if (technologyError) {
        isValid = false;
      }
    }

    return isValid;
  };

  /* 
    onClick submit button
    1. validate user input, show input error if find any
    2. sent request to BE
    3. show response to user
      3.1 If failed, show response error
  */
  _onSubmit = () => {
    // clean up response error from last submit
    this.setState({ responseError: "" });

    const isValid = this.validate(true, "");
    if (!isValid) {
      return;
    }

    const categoryId = this.props.match.params.categoryId;
    const {
      docOverviewData,
    }: {
      docOverviewData: documentOverviewData;
    } = this.props.docOverviewReducer[categoryId];

    const category = docOverviewData;

    const category_id = category.category?.category_id ?? "";
    const project: string = this.state.project;
    const model: string = this.state.model;
    const deviceCategories: {} = this.state.selectedDeviceCategory;
    const pvtRr: string = this.state.pvtRr;
    const announceDate: string = this.state.announceDate;
    const streetDate: string = this.state.streetDate;
    const comment: string = this.state.comment;

    let countries: any = [];
    const countryChecklist = this.props.checklistReducer.checklist.countries;
    this.state.selectedCoutries.forEach((country: any) => {
      let updatedCountryChecklist: any = [];
      Object.keys(countryChecklist[country.value]).forEach((type) => {
        updatedCountryChecklist = [
          ...updatedCountryChecklist,
          ...countryChecklist[country.value][type].map((item) => {
            return { name: item.value, type: type, class: "submission" };
          }),
        ];
      });
      if (country.value) {
        countries.push({
          country: country.value,
          checklist: updatedCountryChecklist,
        });
      }
    });

    let technologies: any = [];
    const technologyChecklist =
      this.props.checklistReducer.checklist.industries;
    this.state.selectedTechnologies.forEach((technology: any) => {
      let updatedTechnologyChecklist: any = [];
      Object.keys(technologyChecklist[technology.value]).forEach((type) => {
        updatedTechnologyChecklist = [
          ...updatedTechnologyChecklist,
          ...technologyChecklist[technology.value][type].map((item) => {
            return { name: item.value, type: type, class: "submission" };
          }),
        ];
      });
      if (technology.value) {
        technologies.push({
          technology: technology.value,
          checklist: updatedTechnologyChecklist,
        });
      }
    });

    const values: createProjectRequest = {
      name: project,
      model_number: model,
      device_category: deviceCategories["value"],
      pvt_rr: pvtRr,
      announce_date: announceDate,
      street_date: streetDate,
      comment,
      category_id: category_id,
      countries: countries,
      technologies: technologies,
      initiative: constants.INITIATIVE,
    };
    this.props.dispatch(createProject(values));
  };

  reset = (success: boolean) => {
    // reset reducer
    this.props.dispatch(resetProject());
    if (success) this.clear();
  };

  clear = () => {
    this.setState({
      selectedCoutries: [],
      selectedTechnologies: [],
      project: "",
      projectError: "",
      model: "",
      modelError: "",
      selectedDeviceCategory: {},
      deviceCategoryError: "",
      pvtRr: "",
      pvtRrError: "",
      announceDate: "",
      announceDateError: "",
      streetDate: "",
      streetDateError: "",
      commentError: "",
      responseError: "",
      comment: "",
    });
  };

  _onChangeProject = (event: any) => {
    const project: string = event.detail.value;
    this.setState({
      projectError: "",
      project,
    });
  };

  _onChangeModel = (event: any) => {
    const model: string = event.detail.value;
    this.setState({
      modelError: "",
      model,
    });
  };

  _onChangeDeviceCategory = (event) => {
    this.setState({
      deviceCategoryError: "",
      selectedDeviceCategory: event.detail.selectedOption,
    });
  };

  _onChangePvtRr = (event: any) => {
    const pvtRr: string = event.detail.value;
    this.setState({
      pvtRrError: "",
      pvtRr,
    });
  };

  _onChangeAnnounceDate = (event: any) => {
    const announceDate: string = event.detail.value;
    this.setState({
      announceDate: announceDate,
      announceDateError: "",
    });
  };

  _onChangeStreetDate = (event: any) => {
    const streetDate: string = event.detail.value;
    this.setState({
      streetDate: streetDate,
      streetDateError: "",
    });
  };

  _onChangeComment = (event: any) => {
    const comment: string = event.detail.value;
    this.setState({
      comment,
      commentError: "",
    });
  };

  _onChangeCountry = (event) => {
    this.setState({
      countryError: "",
      selectedCoutries: event.detail.selectedOptions,
    });
  };

  _onChangeTechnology = (event) => {
    this.setState({
      technologyError: "",
      selectedTechnologies: event.detail.selectedOptions,
    });
  };

  redirect = (category: childrenTable) => {
    this.reset(true);
    // navigate to project overview page
    this.props.history.push(category.path);
  };

  onSuccessNotification = (projectName: string, category) => {
    // set notification
    const notifications = [
      {
        type: constants.STATUS_SUCCESS,
        content: (
          <span>
            {this.props.createProjectReducer.message}:
            <strong> {projectName}</strong>
          </span>
        ),
      },
    ];
    this.props.dispatch(setNotifications(notifications, category.doc_id));
    this.redirect(category);
  };

  onFailureNotification = (category) => {
    // set notification
    const notifications = [
      {
        type: constants.STATUS_ERROR,
        content: <span>{this.props.createProjectReducer.message}</span>,
      },
    ];
    this.props.dispatch(setNotifications(notifications, category.doc_id));
    this.redirect(category);
  };

  getOptions = (values: string[]) => {
    let options: Array<SelectProps.Option> = [];
    Array.from(values).forEach((value) => {
      options.push({
        value: value,
        label: value,
      });
    });
    return options;
  };

  render() {
    const { loadingStatus } = this.props.createProjectReducer;
    const { countries, technologies, doc_list } =
      this.props.getProjectConfigReducer.responseData;

    return (
      <div className="create-form-container">
        <SpaceBetween direction="vertical" size="s">
          <Container>
            <div>
              {/* main form portion */}
              <ColumnLayout>
                <SpaceBetween size="l">
                  <ColumnLayout columns={2} borders="vertical">
                    <FormField
                      label="Project"
                      constraintText={
                        "Letters and numbers only. Max Length 30. Remaining characters " +
                        (30 - this.state.project.length)
                      }
                      description="* Required"
                      errorText={this.state.projectError}
                    >
                      <div className="create-form-input">
                        <Input
                          id={COMPONENT_ID.PROJECT}
                          placeholder="Enter project name"
                          value={this.state.project}
                          onChange={this._onChangeProject}
                        ></Input>
                      </div>
                    </FormField>

                    <FormField
                      label="Model Number"
                      description="* Required"
                      errorText={this.state.modelError}
                    >
                      <div className="create-form-input">
                        <Input
                          id={COMPONENT_ID.MODEL}
                          placeholder="Enter Model Number"
                          value={this.state.model}
                          onChange={this._onChangeModel}
                        ></Input>
                      </div>
                    </FormField>

                    <FormField
                      label="Device Category"
                      errorText={this.state.deviceCategoryError}
                      description="* Required"
                    >
                      <Select
                        className="create-form-dropdown"
                        onChange={this._onChangeDeviceCategory}
                        selectedOption={this.state.selectedDeviceCategory}
                        options={this.getOptions(deviceCategories)}
                        placeholder="Device Category"
                      ></Select>
                    </FormField>

                    <FormField label="PVT RR" errorText={this.state.pvtRrError}>
                      <div className="create-form-date-picker">
                        <DatePicker
                          id={COMPONENT_ID.PVT_RR}
                          placeholder="YYYY/MM/DD"
                          todayAriaLabel="Today"
                          nextMonthAriaLabel="Next month"
                          previousMonthAriaLabel="Previous month"
                          onChange={this._onChangePvtRr}
                          value={this.state.pvtRr}
                        ></DatePicker>
                      </div>
                    </FormField>

                    <FormField
                      label="Announce Date"
                      errorText={this.state.announceDateError}
                    >
                      <div className="create-form-date-picker">
                        <DatePicker
                          id="announce_date"
                          placeholder="YYYY/MM/DD"
                          todayAriaLabel="Today"
                          nextMonthAriaLabel="Next month"
                          previousMonthAriaLabel="Previous month"
                          onChange={this._onChangeAnnounceDate}
                          value={this.state.announceDate}
                        ></DatePicker>
                      </div>
                    </FormField>

                    <FormField
                      label="Street Date"
                      errorText={this.state.streetDateError}
                      description="* Required"
                    >
                      <div className="create-form-date-picker">
                        <DatePicker
                          id="street_date"
                          placeholder="YYYY/MM/DD"
                          todayAriaLabel="Today"
                          nextMonthAriaLabel="Next month"
                          previousMonthAriaLabel="Previous month"
                          onChange={this._onChangeStreetDate}
                          value={this.state.streetDate}
                        ></DatePicker>
                      </div>
                    </FormField>
                  </ColumnLayout>
                </SpaceBetween>
              </ColumnLayout>
              <br />
              <SpaceBetween size="s">
                <FormField
                  label="Countries"
                  errorText={this.state.countryError}
                  description="* Required"
                >
                  <Multiselect
                    className="create-form-multiselect-dropdown"
                    tokenLimit={5}
                    options={this.getOptions(Object.keys(countries))}
                    placeholder="Countries"
                    onChange={this._onChangeCountry}
                    selectedOptions={this.state.selectedCoutries}
                  ></Multiselect>
                </FormField>
                <SpaceBetween size="xxxs" direction="vertical">
                  {this.state.selectedCoutries.length !== 0 &&
                    this.state.selectedCoutries.map((item, index) => {
                      return (
                        item.value && (
                          <Checklist
                            grandparent="countries"
                            parent={item.value}
                            initialChecklist={countries[item.value]}
                            project={this.state.project}
                            docList={doc_list}
                            modelNo={this.state.model}
                            index={index}
                            key={index}
                          ></Checklist>
                        )
                      );
                    })}
                </SpaceBetween>
                <FormField
                  label="Industries"
                  errorText={this.state.technologyError}
                  // description="* Required"
                >
                  <Multiselect
                    className="create-form-multiselect-dropdown"
                    tokenLimit={5}
                    options={this.getOptions(Object.keys(technologies))}
                    placeholder="Industries"
                    onChange={this._onChangeTechnology}
                    selectedOptions={this.state.selectedTechnologies}
                  ></Multiselect>
                </FormField>
                <SpaceBetween size="xxxs" direction="vertical">
                  {this.state.selectedTechnologies.length !== 0 &&
                    this.state.selectedTechnologies.map((item, index) => {
                      return (
                        item.value && (
                          <Checklist
                            grandparent="industries"
                            parent={item.value}
                            initialChecklist={technologies[item.value]}
                            project={this.state.project}
                            docList={doc_list}
                            modelNo={this.state.model}
                            index={index}
                            key={index}
                          ></Checklist>
                        )
                      );
                    })}
                </SpaceBetween>
                <FormField
                  label="Description"
                  errorText={this.state.commentError}
                  constraintText={
                    "Max Length 200. Remaining characters " +
                    (200 - this.state.comment.length)
                  }
                >
                  <div className="create-form-input-description">
                    <Textarea
                      value={this.state.comment}
                      onChange={this._onChangeComment}
                    ></Textarea>
                  </div>
                </FormField>
              </SpaceBetween>
            </div>
          </Container>
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
            }}
          >
            <SpaceBetween direction="horizontal" size="xs">
              <Button onClick={this.clear}>Clear</Button>
              <Button
                variant="primary"
                onClick={this._onSubmit}
                loading={loadingStatus === constants.LOADING_LOAD}
              >
                Submit
              </Button>
            </SpaceBetween>
          </div>
        </SpaceBetween>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    createProjectReducer: state.createProjectReducer,
    getProjectConfigReducer: state.getProjectConfigReducer,
    docOverviewReducer: state.docOverviewReducer,
    checklistReducer: state.checklistReducer,
  };
};

export default withRouter(connect<StateProps>(mapStateToProps)(ProjectForm));
