import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AppLayout, Spinner, Button } from "@amzn/awsui-components-react";
import Breadcrumb from "../../components/common/breadcrumb/breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import {
  getDocOverview,
  resetDocOverview,
} from "../../redux/actions/doc-overview-action";
import ShareForm from "./share-form/share-form";
import ShareStatus from "./share-status/share-status";
import { documentOverviewData } from "../../model/doc-overview";
import { resetShareDocs } from "../../redux/actions/share-doc-action";
import { getShareURLCall, shareDocCall } from "./utils";
import constants, { FOLDER_GROUP } from "../../config/constants";
import { HeaderStyle } from "../../config/constants";

const ShareDoc = ({ match }) => {
  // history
  const history = useHistory();

  // redux
  const { shareDocReducer, docOverviewReducer } = useSelector((state: any) => ({
    shareDocReducer: state.shareDocReducer,
    docOverviewReducer: state.docOverviewReducer,
  }));
  const { selectedDocs } = shareDocReducer;

  // state
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [docsWithShareURL, setDocsWithShareURL] = useState<any>([]);
  const [shareByEmailStatus, setShareByEmailStatus] = useState<any>([]);

  const dispatch = useDispatch();

  // fetch data when after rendering
  useEffect(() => {
    getData();
    return () => {
      cleanUp();
    };
  }, []); // eslint-disable-line

  const getData = () => {
    const docId = match.params.folderId || match.params.fileId;
    dispatch(getDocOverview(docId, 0));
  };

  const cleanUp = () => {
    const docId = match.params.folderId || match.params.fileId;
    dispatch(resetDocOverview(docId));
    dispatch(resetShareDocs());
  };

  const onShare = (emailList: string[], docToShare: any[], message: string) => {
    setFormSubmitted(true);
    setInProgress(true);
    const docsWithShareURL = docToShare.map((doc) => {
      return {
        ...doc,
        share_url: null,
        status: constants.STATUS_PROGRESS,
        error: null,
      };
    });
    setDocsWithShareURL(docsWithShareURL);
    const shareByEmailStatus = emailList.map((email) => {
      return {
        email: email,
        status: constants.STATUS_PROGRESS,
        error: null,
      };
    });
    setShareByEmailStatus(shareByEmailStatus);

    getShareURLCall(docToShare).then((response) => {
      if (response.status === constants.STATUS_SUCCESS) {
        const respData = response.data;
        const docsWithShareURL = docToShare.map((doc) => {
          return {
            ...doc,
            share_url: respData[doc.doc_id],
            status: constants.STATUS_SUCCESS,
            error: null,
          };
        });
        setDocsWithShareURL(docsWithShareURL);
        shareDocCall(emailList, docsWithShareURL, message).then((response) => {
          if (response.status === constants.STATUS_SUCCESS) {
            const respData = response.data;
            const shareByEmailStatus = emailList.map((email) => {
              return {
                email: email,
                status: respData[email].status
                  ? respData[email].status
                  : constants.STATUS_ERROR,
                error: respData[email].error,
              };
            });
            setShareByEmailStatus(shareByEmailStatus);
            setInProgress(false);
          } else {
            const shareByEmailStatus = emailList.map((email) => {
              return {
                email: email,
                status: constants.STATUS_ERROR,
                error: response.message,
              };
            });
            setShareByEmailStatus(shareByEmailStatus);
            setInProgress(false);
          }
        });
      } else {
        const docsWithShareURL = docToShare.map((doc) => {
          return {
            ...doc,
            share_url: null,
            status: constants.STATUS_ERROR,
            error: response.message,
          };
        });
        setDocsWithShareURL(docsWithShareURL);
        const shareByEmailStatus = emailList.map((email) => {
          return {
            email: email,
            status: constants.STATUS_ERROR,
            error: response.message,
          };
        });
        setShareByEmailStatus(shareByEmailStatus);
        setInProgress(false);
      }
    });
  };

  const docId = match.params.folderId || match.params.fileId;

  if (
    !docOverviewReducer[docId] ||
    !docOverviewReducer[docId].loadingStatus ||
    [constants.LOADING_LOAD, constants.LOADING_DEFAULT].includes(
      docOverviewReducer[docId].loadingStatus
    )
  ) {
    return <Spinner size="large" />;
  }

  const { docOverviewData }: { docOverviewData: documentOverviewData } =
    docOverviewReducer[docId];
  const { breadcrumb_data, type } = docOverviewData;

  //------------------------------------------------Breadcrumb------------------------------------------------------//
  const breadcrumb = (
    <Breadcrumb
      breadcrumb_data={[
        ...breadcrumb_data,
        { doc_name: "Share Objects", path: "#" },
      ]}
      history={history}
    />
  );

  //--------------------------------------------------Header--------------------------------------------------------//
  const header = formSubmitted ? (
    <HeaderStyle
      variant="h1"
      actions={
        <Button
          variant="primary"
          onClick={() => {
            FOLDER_GROUP.includes(type)
              ? history.push(`/folders/${docId}/overview`)
              : history.push(`/files/${docId}/overview`);
          }}
        >
          Close
        </Button>
      }
    >
      Share Objects: Status
    </HeaderStyle>
  ) : (
    <HeaderStyle variant="h1">Share Objects</HeaderStyle>
  );

  //-----------------------------------------------Main Content-----------------------------------------------------//
  const shareForm = (
    <ShareForm data={selectedDocs} docId={docId} onShare={onShare} />
  );
  const sharePage = (
    <ShareStatus
      inProgress={inProgress}
      docsWithShareURL={docsWithShareURL}
      shareByEmailStatus={shareByEmailStatus}
    />
  );
  const mainContent = formSubmitted ? sharePage : shareForm;

  return (
    <AppLayout
      navigationHide
      toolsHide
      contentHeader={header}
      breadcrumbs={breadcrumb}
      content={mainContent}
    />
  );
};

export default ShareDoc;
