import { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
//redux
import { connect } from "react-redux";
import {
  getDocOverview,
  deleteNotification,
  resetDocOverview,
  getColorCode,
} from "../../redux/actions/doc-overview-action";
import { Dispatch } from "redux";
// sub components
import {
  ColumnLayout,
  Container,
  SpaceBetween,
  Header,
  PieChart,
  Spinner,
  AppLayout,
} from "@amzn/awsui-components-react";
import Notification from "../../components/common/notification/notification";
import Breadcrumb from "../../components/common/breadcrumb/breadcrumb";
import { ValueWithLabel } from "../../components/common/labels";
import CategoryOverviewTable from "./table/table";
import {
  EmptyState,
  NoMatchState,
} from "../../components/polaris/pie-chart/common-components";
//config, constants
import { PIE_CHART_I18N_CONSTANTS } from "../../components/polaris/pie-chart/labels";
import constants from "../../config/constants";
import { documentOverviewData, notification } from "../../model/doc-overview";
import { changeToShortDateFormat } from "../../utils/date-utils";
//css
import "../../index.css";
import { resetUpdateDoc, updateDocDissmissNotification } from "../../redux/actions/update-doc-action";
import { HeaderStyle } from "../../config/constants";
import NoAuthz from "../no-authz/no-authz"

// declare prop check
type Props = {
  dispatch: Dispatch<any>;
  history: any;
  docOverviewReducer: any;
} & RouteComponentProps<any>;

// declare state check
type State = {};

class CategoryOverview extends Component<Props, State> {
  state: State = Object.freeze({});

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps: Props, prevstate: State) {
    if (
      this.props.match.params.categoryId !== prevProps.match.params.categoryId
    ) {
      const { categoryId } = prevProps.match.params;
      this.props.dispatch(resetDocOverview(categoryId));
      this.getData();
    }
    if (
      this.props.updateDocReducer.loadingStatus === constants.LOADING_SUCCESS
    ) {
      this.getData();
      this.props.dispatch(resetUpdateDoc());
    }
  }

  componentWillUnmount() {
    const { categoryId } = this.props.match.params;
    this.props.dispatch(resetDocOverview(categoryId));
  }

  getData = () => {
    const { categoryId } = this.props.match.params;
    this.props.dispatch(getDocOverview(categoryId, 0));
    this.props.dispatch(getColorCode(categoryId));
  };

  getPieChartData = (projects) => {
    const inProgressCount = projects.filter(
      (project) => project.status === constants.STATUS_PROGRESS
    ).length;
    const upcomingCount = projects.filter(
      (project) => project.status === constants.STATUS_UPCOMING
    ).length;
    const completedCount = projects.filter(
      (project) => project.status === constants.STATUS_COMPLETED
    ).length;
    const totalCount = projects.length;
    const pieChartData = [
      {
        title: "In Progress",
        value: (inProgressCount / totalCount) * 100,
        count: inProgressCount,
        color: "#ea7158",
      },
      {
        title: "Upcoming",
        value: (upcomingCount / totalCount) * 100,
        count: upcomingCount,
        color: "#0972d3",
      },
      {
        title: "Completed",
        value: (completedCount / totalCount) * 100,
        count: completedCount,
        color: "#037f0c",
      },
    ];
    return pieChartData;
  };

  onDismissNotification = (index: number) => {
    const { categoryId } = this.props.match.params;
    this.props.dispatch(deleteNotification(index, categoryId));
  };

  onDismissUpdateNotification = (index: number) => {
    this.props.dispatch(updateDocDissmissNotification(index));
  };

  render() {
    const categoryId = this.props.match.params.categoryId;
    if (
      !this.props.docOverviewReducer[categoryId] ||
      !this.props.docOverviewReducer[categoryId].loadingStatus ||
      [constants.LOADING_LOAD, constants.LOADING_DEFAULT].includes(
        this.props.docOverviewReducer[categoryId].loadingStatus
      )
    ) {
      return <Spinner size="large" />;
    }
    if(this.props.docOverviewReducer[categoryId].unAuthorized)
      return <NoAuthz resource={`the given category`}/>
    const {
      docOverviewData,
      loadingStatus,
      notifications,
    }: {
      docOverviewData: documentOverviewData;
      loadingStatus: number;
      notifications: notification[];
    } = this.props.docOverviewReducer[categoryId];
    const {
      doc_name,
      category,
      created_by,
      created_time,
      breadcrumb_data,
      children,
    } = docOverviewData;

    const pieChartData = this.getPieChartData(children);
    const pieChartLoadingStatus =
      loadingStatus === constants.LOADING_LOAD ? "loading" : "finished";

    //-----------------------------------------------Notifications-----------------------------------------------------//
    const notification = (
      <>
        <Notification
          notifications={notifications}
          onDismiss={this.onDismissNotification}
        />
        <Notification
          notifications={this.props.updateDocReducer.notifications}
          onDismiss={this.onDismissUpdateNotification}
        />
      </>
    );

    //------------------------------------------------Breadcrumb------------------------------------------------------//
    const header = <HeaderStyle variant="h1">{doc_name}</HeaderStyle>;

    //--------------------------------------------------Header--------------------------------------------------------//
    const breadcrumb = (
      <Breadcrumb
        breadcrumb_data={breadcrumb_data}
        history={this.props.history}
      />
    );

    //-----------------------------------------------Main Content-----------------------------------------------------//
    const mainContent = (
      <>
        <Container header={<Header variant="h2">Category Overview</Header>}>
          <ColumnLayout columns={2} variant="text-grid">
            <SpaceBetween size="l">
              <ValueWithLabel label="Name">{doc_name}</ValueWithLabel>
              <ValueWithLabel label="Description">
                {category?.comment
                  ? category.comment
                  : "No description available"}
              </ValueWithLabel>
              <ValueWithLabel label="Created By">{created_by}</ValueWithLabel>
              <ValueWithLabel label="Creation Time">
                {changeToShortDateFormat(created_time)}
              </ValueWithLabel>
            </SpaceBetween>
            <PieChart
              data={pieChartData}
              detailPopoverContent={(datum, sum) => [
                { key: "Count", value: datum.count },
                {
                  key: "Percentage",
                  value: `${((datum.value / sum) * 100).toFixed(0)}%`,
                },
              ]}
              segmentDescription={(datum, sum) =>
                `${datum.count} projects, ${((datum.value / sum) * 100).toFixed(
                  0
                )}%`
              }
              i18nStrings={PIE_CHART_I18N_CONSTANTS}
              errorText="Error loading data."
              hideFilter
              loadingText="Loading chart"
              statusType={pieChartLoadingStatus}
              recoveryText="Retry"
              size="medium"
              empty={<EmptyState />}
              noMatch={<NoMatchState />}
            />
          </ColumnLayout>
        </Container>
        <br />
        <CategoryOverviewTable
          categoryId={categoryId}
          data={this.props.docOverviewReducer.colorCode}
          loadingStatus={loadingStatus}
          getData={this.getData}
          history={this.props.history}
          dispatch={this.props.dispatch}
          updateDocLoadingStatus={this.props.updateDocReducer.loadingStatus}
        />
      </>
    );

    return (
      <AppLayout
        navigationHide
        toolsHide
        contentHeader={header}
        notifications={notification}
        breadcrumbs={breadcrumb}
        content={mainContent}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    docOverviewReducer: state.docOverviewReducer,
    updateDocReducer: state.updateDocReducer,
  };
};

export default connect(mapStateToProps)(CategoryOverview);
