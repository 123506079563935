import { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
//redux
import { connect } from "react-redux";
import {
  deleteNotification,
  getDocOverview,
  resetDocOverview,
  setNotifications,
  updateProjectDetails,
} from "../../redux/actions/doc-overview-action";
import { Dispatch } from "redux";
// sub components
import {
  AppLayout,
  ColumnLayout,
  Container,
  SpaceBetween,
  Header,
  Spinner,
  Tabs,
  Button,
  Input,
  DateInput,
  Box,
} from "@amzn/awsui-components-react";
import { ValueWithLabel } from "../../components/common/labels";
import CertificationOverviewTable from "./table/table";
import Notification from "../../components/common/notification/notification";
import Breadcrumb from "../../components/common/breadcrumb/breadcrumb";
import constants, {
  DOWNLOAD_FAILED_NOTIFICATION,
  PROJECT_DETAILS,
} from "../../config/constants";
import {
  childrenTable,
  documentOverviewData,
  notification,
} from "../../model/doc-overview";
import { changeToShortDateFormat } from "../../utils/date-utils";
//css
import "../../index.css";
import { resetUpdateDoc } from "../../redux/actions/update-doc-action";
import { HeaderStyle } from "../../config/constants";

//utils
import { fileDownloadS3Call } from "../../utils";
import NoAuthz from "../no-authz/no-authz";

import DocumentPercentage from "./document-percentage";

// declare prop check
type Props = {
  dispatch: Dispatch<any>;
  history: any;
  docOverviewReducer: any;
} & RouteComponentProps<any>;

// declare state check
type State = {
  activeTabId: string;
  documentDownloadStatus: number;
  editProjectDetails: boolean;
  projectDetails: {
    model_number?: string;
    device_category?: string;
    description?: string | null;
    pvt_rr?: string | null;
    announce_date?: string | null;
    street_date?: string | null;
  };
  errorMessage?: string;
};

class ProjectOverview extends Component<Props, State> {
  state: State = Object.freeze({
    activeTabId: "0",
    documentDownloadStatus: constants.LOADING_DEFAULT,
    editProjectDetails: false,
    projectDetails: {
      model_number: "",
      device_category: "",
      description: null,
      pvt_rr: null,
      announce_date: null,
      street_date: null,
    },
    errorMessage: "",
  });

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps: Props, prevstate: State) {
    if (
      this.props.match.params.projectId !== prevProps.match.params.projectId
    ) {
      const { projectId } = prevProps.match.params;
      this.props.dispatch(resetDocOverview(projectId));
      this.getData();
    }
    if (
      this.props.updateDocReducer.loadingStatus === constants.LOADING_SUCCESS
    ) {
      this.getData();
      this.props.dispatch(resetUpdateDoc());
    }

    if (
      this.props.docOverviewReducer.loadingStatusUpdateProjectDetails !==
        prevProps.docOverviewReducer.loadingStatusUpdateProjectDetails &&
      this.props.docOverviewReducer.loadingStatusUpdateProjectDetails ===
        constants.LOADING_FAIL
    ) {
      this.setState({
        projectDetails: {
          model_number: "",
          device_category: "",
          description: "",
          pvt_rr: "",
          announce_date: "",
          street_date: "",
        },
        errorMessage:
          this.props.docOverviewReducer?.message || "Something went wrong",
      });
    }
  }

  componentWillUnmount() {
    const { projectId } = this.props.match.params;
    this.props.dispatch(resetDocOverview(projectId));
  }

  getData = () => {
    const { projectId } = this.props.match.params;
    this.props.dispatch(getDocOverview(projectId, 1));
  };

  getChildTabs = (children: childrenTable[], loadingStatus: Number) => {
    let tabs: any[] = [];
    const { categoryId, projectId } = this.props.match.params;
    for (let i = 0; i < children.length; i++) {
      let child = children[i];
      let grandChildType = child.doc_name.split(" ")[0];
      if (!child.children) child.children = [];
      tabs.push({
        label:
          child.doc_name === "Country Certificates"
            ? "Country Documents"
            : "Industry Documents",
        id: i.toString(),
        content: (
          <CertificationOverviewTable
            folderId={child.doc_id}
            categoryId={categoryId}
            projectId={projectId}
            data={child.children}
            loadingStatus={loadingStatus}
            getData={this.getData}
            history={this.props.history}
            dispatch={this.props.dispatch}
            grandChildType={grandChildType}
            updateDocLoadingStatus={this.props.updateDocReducer.loadingStatus}
          />
        ),
      });
    }
    return tabs;
  };
  onTabChange = (event) => {
    this.setState({ activeTabId: event.detail.activeTabId });
  };
  onDismissNotification = (index: number) => {
    const { projectId } = this.props.match.params;
    this.props.dispatch(deleteNotification(index, projectId));
  };

  onDownloadasZip = () => {
    this.setState({
      documentDownloadStatus: constants.LOADING_LOAD,
    });
    const { projectId } = this.props.match.params;
    fileDownloadS3Call(projectId).then((response) => {
      if (response.status === constants.STATUS_SUCCESS) {
        window.location.href = response.s3_url;
        this.setState({
          documentDownloadStatus: constants.LOADING_SUCCESS,
        });
      } else {
        this.setState({
          documentDownloadStatus: constants.LOADING_FAIL,
        });
        this.props.dispatch(
          setNotifications(DOWNLOAD_FAILED_NOTIFICATION, projectId)
        );
      }
    });
  };

  render() {
    const { projectId } = this.props.match.params;
    if (
      !this.props.docOverviewReducer[projectId] ||
      !this.props.docOverviewReducer[projectId].loadingStatus ||
      [constants.LOADING_LOAD, constants.LOADING_DEFAULT].includes(
        this.props.docOverviewReducer[projectId].loadingStatus
      ) ||
      this.props.docOverviewReducer.loadingStatusUpdateProjectDetails ===
        constants.LOADING_LOAD
    ) {
      return <Spinner size="large" />;
    }

    if (this.props.docOverviewReducer[projectId].unAuthorized)
      return <NoAuthz resource={`the given project`} />;

    const {
      docOverviewData,
      loadingStatus,
      notifications,
    }: {
      docOverviewData: documentOverviewData;
      loadingStatus: number;
      notifications: notification[];
    } = this.props.docOverviewReducer[projectId];
    const {
      doc_name,
      created_by,
      created_time,
      breadcrumb_data,
      children,
      status,
      project,
      project_id,
      doc_id,
      cem_project_id,
    } = docOverviewData;

    const street_date = project?.street_date;
    const announce_date = project?.announce_date;
    const model_number = project?.model_number;
    const device_category = project?.device_category;
    const pvt_rr = project?.pvt_rr;
    const comment = project?.comment;

    const editProjectModelNumber = (detail) => {
      this.setState({
        projectDetails: {
          ...this.state.projectDetails,
          model_number: detail.value,
        },
      });
    };

    const editProjectDeviceCategory = (detail) => {
      this.setState({
        projectDetails: {
          ...this.state.projectDetails,
          device_category: detail.value,
        },
      });
    };

    const editProjectDescription = (detail) => {
      this.setState({
        projectDetails: {
          ...this.state.projectDetails,
          description: detail.value,
        },
      });
    };

    const editProjectDetail = (value, editableFuntion, value_display, type) => {
      return this.state.editProjectDetails ? (
        <Input
          onChange={({ detail }) => {
            editableFuntion(detail);
          }}
          value={value}
          placeholder={value === "" ? `No ${type} available` : ""}
        />
      ) : value_display === "" ? (
        "No description available"
      ) : (
        value_display
      );
    };

    const displayDateDetails = (value, type) => {
      return value === "" || value === null
        ? `No ${type} available`
        : changeToShortDateFormat(value || "");
    };

    const editProjectDetailInDateFormat = (
      value,
      uneditableValue,
      key_value
    ) => {
      return this.state.editProjectDetails ? (
        <DateInput
          onChange={({ detail }) => {
            this.setState({
              projectDetails: {
                ...this.state.projectDetails,
                [key_value]: detail.value,
              },
            });
          }}
          value={value}
          placeholder="YYYY/MM/DD"
        />
      ) : (
        uneditableValue
      );
    };
    const editProjectStatus = (status) => {
      const split_status = status.split("_");

      for (var i = 0; i < split_status.length; i++) {
        split_status[i] =
          split_status[i].charAt(0).toUpperCase() + split_status[i].slice(1);
      }
      const status_with_first_letter_uppercase = split_status.join(" ");

      return status_with_first_letter_uppercase;
    };
    //-----------------------------------------------Notifications-----------------------------------------------------//
    const notification = (
      <Notification
        notifications={notifications}
        onDismiss={this.onDismissNotification}
      />
    );

    //------------------------------------------------Breadcrumb------------------------------------------------------//
    const breadcrumb = (
      <Breadcrumb
        breadcrumb_data={breadcrumb_data}
        history={this.props.history}
      />
    );

    //--------------------------------------------------Header--------------------------------------------------------//
    const header = (
      <HeaderStyle
        variant="h1"
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              loading={
                this.state.documentDownloadStatus === constants.LOADING_LOAD
              }
              iconName="download"
              variant="primary"
              onClick={this.onDownloadasZip}
            >
              Download
            </Button>
          </SpaceBetween>
        }
      >
        {doc_name}
      </HeaderStyle>
    );

    //-----------------------------------------------Main Content-----------------------------------------------------//
    const mainContent = (
      <>
        <Container
          header={
            <SpaceBetween direction="vertical" size="xs">
              <SpaceBetween direction="horizontal" size="xs">
                <Header variant="h2">Project Overview</Header>{" "}
                <Button
                  iconName="edit"
                  variant="icon"
                  onClick={() => {
                    this.setState({
                      editProjectDetails: !this.state.editProjectDetails,
                      projectDetails: {
                        model_number:
                          this.state.projectDetails.model_number ||
                          model_number,
                        device_category:
                          this.state.projectDetails.device_category ||
                          device_category,
                        description:
                          this.state.projectDetails.description || comment,
                        pvt_rr: this.state.projectDetails.pvt_rr || pvt_rr,
                        announce_date:
                          this.state.projectDetails.announce_date ||
                          announce_date,
                        street_date:
                          this.state.projectDetails.street_date || street_date,
                      },
                    });
                  }}
                />
                {this.state.editProjectDetails && (
                  <Button
                    variant="primary"
                    onClick={() => {
                      const project = {
                        model_number: this.state.projectDetails.model_number,
                        device_category:
                          this.state.projectDetails.device_category,
                        description: this.state.projectDetails.description,
                        street_date: this.state.projectDetails.street_date,
                        announce_date: this.state.projectDetails.announce_date,
                        pvt_rr: this.state.projectDetails.pvt_rr,
                        cem_project_id: cem_project_id,
                      };

                      this.props.dispatch(
                        updateProjectDetails(project_id, project, doc_id)
                      );
                      this.setState({
                        editProjectDetails: !this.state.editProjectDetails,
                      });
                    }}
                  >
                    {" "}
                    Save
                  </Button>
                )}
              </SpaceBetween>
              {this.state.errorMessage !== "" &&
                !this.state.editProjectDetails && (
                  <Box color="text-status-error">{this.state.errorMessage}</Box>
                )}
            </SpaceBetween>
          }
        >
          <ColumnLayout columns={3} variant="text-grid">
            <SpaceBetween size="l">
              <ValueWithLabel label={PROJECT_DETAILS.NAME}>
                {doc_name}
              </ValueWithLabel>
              <ValueWithLabel label={PROJECT_DETAILS.MODEL_NUMBER}>
                {editProjectDetail(
                  this.state.projectDetails.model_number,
                  editProjectModelNumber,
                  this.state.projectDetails.model_number || model_number,
                  "model number"
                )}
              </ValueWithLabel>
              <ValueWithLabel label={PROJECT_DETAILS.DEVICE_CATEGORY}>
                {editProjectDetail(
                  this.state.projectDetails.device_category,
                  editProjectDeviceCategory,
                  this.state.projectDetails.device_category || device_category,
                  "device category"
                )}
              </ValueWithLabel>
              <ValueWithLabel label={PROJECT_DETAILS.STATUS}>
                {editProjectStatus(status)}
              </ValueWithLabel>
              <ValueWithLabel label={PROJECT_DETAILS.DESCRIPTION}>
                {editProjectDetail(
                  this.state.projectDetails.description
                    ? this.state.projectDetails.description
                    : "",
                  editProjectDescription,
                  this.state.projectDetails.description === null
                    ? comment
                    : this.state.projectDetails.description,
                  "description"
                )}
              </ValueWithLabel>
            </SpaceBetween>
            <SpaceBetween size="l">
              <ValueWithLabel label={PROJECT_DETAILS.PVT_RR}>
                {editProjectDetailInDateFormat(
                  this.state.projectDetails.pvt_rr,
                  this.state.projectDetails.pvt_rr === null
                    ? displayDateDetails(pvt_rr, PROJECT_DETAILS.PVT_RR)
                    : displayDateDetails(
                        this.state.projectDetails.pvt_rr,
                        PROJECT_DETAILS.PVT_RR
                      ),
                  "pvt_rr"
                )}
              </ValueWithLabel>
              <ValueWithLabel label={PROJECT_DETAILS.ANNOUNCE_DATE}>
                {editProjectDetailInDateFormat(
                  this.state.projectDetails.announce_date,
                  this.state.projectDetails.announce_date === null
                    ? displayDateDetails(
                        announce_date,
                        PROJECT_DETAILS.ANNOUNCE_DATE
                      )
                    : displayDateDetails(
                        this.state.projectDetails.announce_date,
                        PROJECT_DETAILS.ANNOUNCE_DATE
                      ),
                  "announce_date"
                )}
              </ValueWithLabel>
              <ValueWithLabel label={PROJECT_DETAILS.STREET_DATE}>
                {editProjectDetailInDateFormat(
                  this.state.projectDetails.street_date,
                  this.state.projectDetails.street_date === null
                    ? displayDateDetails(
                        street_date,
                        PROJECT_DETAILS.STREET_DATE
                      )
                    : displayDateDetails(
                        this.state.projectDetails.street_date,
                        PROJECT_DETAILS.STREET_DATE
                      ),
                  "street_date"
                )}
              </ValueWithLabel>
              <ValueWithLabel label={PROJECT_DETAILS.CREATION_TIME}>
                {changeToShortDateFormat(created_time)}
              </ValueWithLabel>
              <ValueWithLabel label={PROJECT_DETAILS.CREATED_BY}>
                {created_by}
              </ValueWithLabel>
            </SpaceBetween>

            <DocumentPercentage
              children={children}
              loadingStatus={loadingStatus}
              activeTabId={this.state.activeTabId}
            />
          </ColumnLayout>
          <br />
        </Container>
        <br />
        <Tabs
          activeTabId={this.state.activeTabId}
          onChange={this.onTabChange}
          tabs={this.getChildTabs(children, loadingStatus)}
        />
      </>
    );

    return (
      <AppLayout
        navigationHide
        toolsHide
        contentHeader={header}
        notifications={notification}
        breadcrumbs={breadcrumb}
        content={mainContent}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    docOverviewReducer: state.docOverviewReducer,
    updateDocReducer: state.updateDocReducer,
  };
};

export default connect(mapStateToProps)(ProjectOverview);
