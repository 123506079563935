import { config } from "../../config/config";
import { METRIC_INFO } from "../../config/constants";
import endpoints from "../../config/endpoints";
import { expiringDocType } from "./../types/expiring-doc-type";

export const getExpiringDoc = (startDate: string, endDate: string) => ({
  type: expiringDocType.GET_EXPIRING_DOC,
  startDate,
  endDate,
  http: {
    cognito: {
      effect: {
        url:
          config.BASE_URL +
          endpoints.expiringDocEndpoint() +
          "?start_date=" +
          startDate +
          "&end_date=" +
          endDate,
        method: "GET",
      },
      commit: {
        type: expiringDocType.GET_EXPIRING_DOC_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
        },
      },
      rollback: {
        type: expiringDocType.GET_EXPIRING_DOC_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
        },
      },
    },
  },
});
