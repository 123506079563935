import { shareDocType } from "../types/share-doc-type";

export const setShareDocs = (selectedDocs: Array<any>): any => ({
  type: shareDocType.SET_SHARE_DOCS,
  selectedDocs,
});

export const resetShareDocs = (): any => ({
  type: shareDocType.RESET_SHARE_DOCS,
});
