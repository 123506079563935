import checklistType from "../types/checklist-type";

interface State {
  checklist: any;
}

const initialState: State = {
  checklist: {},
};

const checklistReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case checklistType.INTIALIZE_CHECKLIST: {
      return {
        checklist: {
          ...state.checklist,
          [action.grandparent]: {
            ...state.checklist[action.grandparent],
            [action.parent]: action.value,
          },
        },
      };
    }
    case checklistType.UPDATE_CHECKLIST: {
      return {
        checklist: {
          ...state.checklist,
          [action.grandparent]: {
            // add condition to check if the key exists before spreading
            ...(state.checklist[action.grandparent] &&
              state.checklist[action.grandparent]),
            [action.parent]: {
              ...(state.checklist[action.grandparent] &&
                state.checklist[action.grandparent][action.parent] &&
                state.checklist[action.grandparent][action.parent]),
              [action.docType]: action.value,
            },
          },
        },
      };
    }
    case checklistType.RESET_CHECKLIST: {
      return {
        checklist: {},
      };
    }
    default:
      return state;
  }
};

export default checklistReducer;
