import React from "react";
import { awsRum } from "../../index"
import { useLocation, matchPath } from "react-router-dom";
import { routes } from "../../containers/main/routes";


function LogPageView(props){
    let location = useLocation();
    React.useEffect(() => {
        let match = matchPath(location.pathname, {path: routes.map(route => route.path), exact: true, strict: false});
        if(match !==  null) awsRum?.recordPageView(match.path);
    }, [location]);
    return props.children;
}
export default LogPageView;
