import removeAssociationType from "../types/remove-association-type";
import { config } from "../../config/config";
import { METRIC_INFO } from "../../config/constants";
import endpoints from "../../config/endpoints";
import { createDisassociationRequest } from "../../model/http-json";

export const removeAssociation = (
  values: createDisassociationRequest
): any => ({
  type: removeAssociationType.REMOVE_ASSOCIATION,
  http: {
    cognito: {
      effect: {
        url: config.BASE_URL + endpoints.disassociateDocsFromFileEndpoint(),
        method: "PUT",
        json: values,
      },
      commit: {
        type: removeAssociationType.REMOVE_ASSOCIATION_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.REMOVE_ASSOCIATION,
        },
      },
      rollback: {
        type: removeAssociationType.REMOVE_ASSOCIATION_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.REMOVE_ASSOCIATION,
        },
      },
    },
  },
});

export const resetRemoveAssociation = (file_id: string): any => ({
  type: removeAssociationType.RESET_REMOVE_ASSOCIATION,
  file_id,
});
