import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { getProjectConfig } from "../../redux/actions/create-project-action";
import constants from "../../config/constants";
import {
  Button,
  Container,
  FormField,
  Multiselect,
  SelectProps,
  SpaceBetween,
  AppLayout,
  Spinner,
} from "@amzn/awsui-components-react";
import Breadcrumb from "../../components/common/breadcrumb/breadcrumb";
import Checklist from "../../components/checklist/checklist";
import {
  getDocOverview,
  setNotifications,
} from "../../redux/actions/doc-overview-action";
import {
  documentOverviewData,
  childrenTable,
  grandChildrenTable,
} from "../../model/doc-overview";
import "./update-form.css";
import { updateProjectRequest } from "../../model/http-json";
import {
  resetUpdateProject,
  updateProject,
} from "../../redux/actions/update-project-action";
import { HeaderStyle } from "../../config/constants";

interface StateProps {}

type State = {
  selectedCountries: SelectProps.Option[];
  countryError: string; // input error
};

// declare prop check
type Props = {
  dispatch: Dispatch<any>;
} & StateProps &
  RouteComponentProps<any>;

class AddCountry extends Component<Props, State> {
  state: State = Object.freeze({
    selectedCountries: [],
    countryError: "",
  });
  componentDidMount() {
    this.getData();
  }
  componentDidUpdate(prevProps: Props, prevstate: State) {
    if (
      this.props.match.params.projectId !== prevProps.match.params.projectId
    ) {
      this.getData();
      this.props.dispatch(resetUpdateProject());
    }

    const { loadingStatus } = this.props.updateProjectReducer;
    if (loadingStatus === constants.LOADING_SUCCESS) {
      this.onSuccess();
    }
    if (loadingStatus === constants.LOADING_FAIL) {
      this.onFailure();
    }
  }
  onSuccess = () => {
    // set notification
    const notifications = [
      {
        type: constants.STATUS_SUCCESS,
        content: <span>Successfully added Countries</span>,
      },
    ];
    const { projectId } = this.props.match.params;
    this.props.dispatch(setNotifications(notifications, projectId));
    this.props.dispatch(resetUpdateProject());
    // redirect to parent page
    this.redirect();
  };

  onFailure = () => {
    // set notification
    const notifications = [
      {
        type: constants.STATUS_ERROR,
        content: <span>Failed to add Countries, please try again.</span>,
      },
    ];
    const { projectId } = this.props.match.params;
    this.props.dispatch(setNotifications(notifications, projectId));
    this.props.dispatch(resetUpdateProject());
    // redirect to parent page
    this.redirect();
  };

  redirect = () => {
    // redirect to parent page
    const { projectId } = this.props.match.params;
    const { docOverviewData }: { docOverviewData: documentOverviewData } =
      this.props.docOverviewReducer[projectId];
    const { path } = docOverviewData;
    this.props.history.push(path);
  };
  getData = () => {
    const { projectId } = this.props.match.params;
    this.props.dispatch(getDocOverview(projectId, 1));
    this.props.dispatch(getProjectConfig());
  };
  getOptions = (values: string[]) => {
    let options: Array<SelectProps.Option> = [];
    //   indentify already added countries
    const existingCountries = new Set();
    const { projectId } = this.props.match.params;
    const { children } =
      this.props.docOverviewReducer[projectId].docOverviewData;
    children.forEach((child: childrenTable) => {
      if (child.doc_name.toLowerCase() === constants.COUNTRY_CERTIFICATE) {
        const grandChildren = child.children;
        grandChildren.forEach((grandChild: grandChildrenTable) =>
          existingCountries.add(grandChild.doc_name.toLowerCase())
        );
      }
    });

    Object.keys(values).forEach((value) => {
      options.push({
        value: value,
        label: value,
        disabled: existingCountries.has(value.toLowerCase()),
      });
    });
    return options;
  };
  _onChangeCountry = (event: any) => {
    const { selectedOptions } = event.detail;
    this.setState({
      countryError: "",
      selectedCountries: selectedOptions,
    });
  };
  _onSubmit = () => {
    const { projectId } = this.props.match.params;
    if (this.state.selectedCountries.length > 0) {
      let countries: any = [];
      const checklist = this.props.checklistReducer.checklist.countries;
      this.state.selectedCountries.forEach((country: any) => {
        let udpatedChecklist: any = [];
        Object.keys(checklist[country.value]).forEach((type) => {
          udpatedChecklist = [
            ...udpatedChecklist,
            ...checklist[country.value][type].map((item) => {
              return { name: item.value, type: type, class: "submission" };
            }),
          ];
        });
        if (country.value) {
          countries.push({
            country: country.value,
            checklist: udpatedChecklist,
          });
        }
      });
      // TODO: define type later
      const values: updateProjectRequest = {
        countries: countries,
      };
      // TODO: update doc name to exclude project name and model no
      this.props.dispatch(updateProject(projectId, values));
    } else {
      this.setState({
        countryError: "Please select atleast 1 country",
      });
    }
  };
  _onCancel = () => {
    this.setState({
      countryError: "",
      selectedCountries: [],
    });
    const { categoryId, projectId } = this.props.match.params;
    this.props.history.push(
      `/categories/${categoryId}/projects/${projectId}/overview`
    );
  };
  render() {
    const projectId = this.props.match.params.projectId;
    if (
      !this.props.docOverviewReducer[projectId] ||
      [constants.LOADING_LOAD, constants.LOADING_DEFAULT].includes(
        this.props.docOverviewReducer[projectId].loadingStatus
      ) ||
      [constants.LOADING_LOAD, constants.LOADING_DEFAULT].includes(
        this.props.getProjectConfigReducer.loadingStatus
      )
    ) {
      return <Spinner size="large" />;
    }
    const { docOverviewData }: { docOverviewData: documentOverviewData } =
      this.props.docOverviewReducer[projectId];

    const { breadcrumb_data, doc_name, project } = docOverviewData;
    const { countries, doc_list } =
      this.props.getProjectConfigReducer.responseData;
    const loading =
      this.props.updateProjectReducer.loadingStatus === constants.LOADING_LOAD;
    const modelNo = project?.model_number;

    //------------------------------------------------Breadcrumb------------------------------------------------------//
    const breadcrumb = (
      <Breadcrumb
        breadcrumb_data={[
          ...breadcrumb_data,
          {
            doc_name: "Add Countries",
            path: `projects/${projectId}/update/addCountries`,
          },
        ]}
        history={this.props.history}
      />
    );

    //--------------------------------------------------Header--------------------------------------------------------//
    const header = (
      <HeaderStyle
        variant="h1"
        description="use this page to add countries to projects"
      >
        Add Countries
      </HeaderStyle>
    );

    //-----------------------------------------------Main Content-----------------------------------------------------//
    const { selectedCountries } = this.state;
    const mainContent = (
      <div className="update-form-container">
        <Container>
          <SpaceBetween size="l">
            <FormField
              label="Countries"
              errorText={this.state.countryError}
              description="* Required"
            >
              <Multiselect
                tokenLimit={2}
                options={this.getOptions(countries)}
                placeholder="Countries"
                onChange={this._onChangeCountry}
                selectedOptions={this.state.selectedCountries}
              ></Multiselect>
            </FormField>
          </SpaceBetween>
          <br />
          {selectedCountries.length !== 0 &&
            selectedCountries.map((item, index) => {
              if (item.value) {
                return (
                  <Checklist
                    grandparent="countries"
                    parent={item.value}
                    initialChecklist={countries[item.value]}
                    project={doc_name}
                    docList={doc_list}
                    modelNo={modelNo}
                    index={index}
                    key={index}
                  />
                );
              }
              return <></>;
            })}
        </Container>
        <br />
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
          }}
        >
          <SpaceBetween direction="horizontal" size="xs">
            <Button disabled={loading} onClick={this._onCancel}>
              Cancel
            </Button>
            <Button
              variant="primary"
              loading={loading}
              onClick={this._onSubmit}
            >
              Add Countries
            </Button>
          </SpaceBetween>
        </div>
      </div>
    );

    return (
      <AppLayout
        navigationHide
        toolsHide
        contentHeader={header}
        breadcrumbs={breadcrumb}
        content={mainContent}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getProjectConfigReducer: state.getProjectConfigReducer,
    updateProjectReducer: state.updateProjectReducer,
    docOverviewReducer: state.docOverviewReducer,
    checklistReducer: state.checklistReducer,
  };
};

export default withRouter(connect<StateProps>(mapStateToProps)(AddCountry));
