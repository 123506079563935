import { updateDocType } from "../types/update-doc-type";
import constants, { HttpCodes, NOT_AUTHORIZED_NOTIFICATION } from "../../config/constants";
import { notification } from "../../model/repository";

interface updateDocState {
  loadingStatus: number;
  message: string;
  responseData: any;
  notifications: notification[];
}

const updateDocInitialState: updateDocState = {
  loadingStatus: constants.LOADING_DEFAULT,
  message: "",
  responseData: {},
  notifications: [],
};

export const updateDocReducer = (
  state = updateDocInitialState,
  action: any
) => {
  switch (action.type) {
    case updateDocType.UPDATE_DOC:
      return {
        ...state,
        loadingStatus: constants.LOADING_LOAD,
      };
    case updateDocType.UPDATE_DOC_COMMIT:
      return {
        ...state,
        loadingStatus: constants.LOADING_SUCCESS,
        responseData: action.payload.data,
      };
    case updateDocType.UPDATE_DOC_ROLLBACK:
      console.log(action.statusCode)
      const notifications: notification[] = [
        ...(state.notifications
          ? state.notifications
          : [])
      ];
      if(action.statusCode === HttpCodes.FORBIDDEN)
        notifications.push(NOT_AUTHORIZED_NOTIFICATION)
      console.log(notifications)
      return {
        ...state,
        loadingStatus: constants.LOADING_FAIL,
        notifications
      };
    case updateDocType.RESET_UPDATE_DOC:
      return {
        ...state,
        loadingStatus: constants.LOADING_DEFAULT,
        message: "",
        responseData: {},
      };
    case updateDocType.DELETE_NOTIFICATION:
      state.notifications.splice(action.index, 1);
      return {
        ...state,
      };
    default:
      return state;
  }
};
