import { Flashbar } from "@amzn/awsui-components-react";
import "./notification.css";

const Notification = ({ notifications, onDismiss }) => {
  const items = notifications
    ? notifications.map((item, index) => ({
        type: item.type,
        content: item.content,
        dismissible: true,
        dismissLabel: "Dismiss message",
        onDismiss: () => onDismiss(index),
        id: index,
      }))
    : [];
  return (
    <>
      {items && (
        <div>
          <Flashbar items={items} className="flashbar" />
        </div>
      )}
    </>
  );
};

export default Notification;
