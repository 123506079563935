import { useState } from "react";
// sub components
import {
  Header,
  Pagination,
  PropertyFilter,
  SpaceBetween,
  Button,
  Modal,
  Box,
  FormField,
  Checkbox,
} from "@amzn/awsui-components-react";
import Table from "@amzn/awsui-components-react/polaris/table";
import {
  TableEmptyState,
  TableNoMatchState,
} from "../../../components/polaris/table/common-components";
import { useCollection } from "@amzn/awsui-collection-hooks";
//config, constants
import {
  COLUMN_DEFINITIONS,
  DEFAULT_PREFERENCES,
  FILTERING_PROPERTIES,
  Preferences,
} from "./table-config";
import {
  paginationLabels,
  PROPERTY_FILTERING_I18N_CONSTANTS,
} from "../../../components/polaris/table/labels";
import { getFilterCounterText } from "../../../components/polaris/table/table-counter-strings";
// use this for sample data
// import { TABLE_DATA } from "./sample-data";
import { downloadDocumentAsZip } from "../../../utils"
import { childrenTable } from "../../../model/doc-overview";
import constants, {
  DOCUMENT_ACTIONS,
  DOWNLOAD_START_NOTIFICATION,
  DOWNLOAD_FAILED_NOTIFICATION,
  RENAME_TYPE,
} from "../../../config/constants";
import { setNotifications } from "../../../redux/actions/doc-overview-action";
import { upcomingFeature } from "../../../components/common/notification/common-notification";
import { updateDoc } from "../../../redux/actions/update-doc-action";
import { setDeleteDocs } from "../../../redux/actions/delete-doc-action";
import { setShareDocs } from "../../../redux/actions/share-doc-action";
import Rename from "../../common-container/rename";

//css

const FolderOverviewTable = ({
  data,
  folderId,
  loadingStatus,
  parent_doc_type,
  name,
  getData,
  history,
  dispatch,
  updateDocLoadingStatus,
  is_file_upload_allowed,
  is_country_or_industry_group_doc,
  projectName,
  modelNumber,
}: {
  data: childrenTable[];
  folderId: string;
  loadingStatus: number;
  parent_doc_type: string;
  name: string;
  getData: any;
  history: any;
  dispatch: any;
  updateDocLoadingStatus: number;
  is_file_upload_allowed?: boolean;
  is_country_or_industry_group_doc?: boolean;
  projectName?: string;
  modelNumber?: string;
}) => {
  // selected Items in the table
  const [selectedItems, setSelectedItems] = useState<any | never>([]);
  const [showModal, setShowModal] = useState(false);
  const [fileData, setFileData] = useState({
    fileId: "",
    fileName: "",
  });
  const [documentDownloadStatus, setDocumentDownloadStatus] = useState(constants.LOADING_DEFAULT)
  // default table preferences
  const [preferences, setPreferences] = useState(
    DEFAULT_PREFERENCES(
      is_file_upload_allowed,
      is_country_or_industry_group_doc,
      loadingStatus,
      setShowModal,
      fileData,
      setFileData
    )
  );

  // using polaris useCollection hook to define table props
  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    propertyFilterProps,
    paginationProps,
  } = useCollection(data, {
    propertyFiltering: {
      filteringProperties: FILTERING_PROPERTIES,
      empty: <TableEmptyState resourceName={constants.FOLDER} />,
      noMatch: (
        <TableNoMatchState
          onClearFilter={() =>
            actions.setPropertyFiltering({ tokens: [], operation: "and" })
          }
        />
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
  });

  // table loading status
  const loading = loadingStatus === constants.LOADING_LOAD;

  const isCountryCertificate =
    parent_doc_type === constants.PROJECT &&
    name.toLowerCase() === constants.COUNTRY_CERTIFICATE;
  const isIndustryCertificate =
    parent_doc_type === constants.PROJECT &&
    name.toLowerCase() === constants.INDUSTRY_CERTIFICATE;

  // TODO: udpate this once the actions are ready
  const onShareButtonClick = ({ detail }) => {
    dispatch(setShareDocs(selectedItems));
    history.push(`/folders/${folderId}/share`);
  };

  const onDeleteButtonClick = () => {
    let isAssociationCountZero = true;
    if (selectedItems.length > 0) {
      for (let item of selectedItems) {
        if (item.upload_status === "success" || item.upload_status === "completed") {
          isAssociationCountZero = false;
        }
      }
    }

    if (!isAssociationCountZero) {
      dispatch(
        setNotifications(
          [
            {
              type: constants.STATUS_ERROR,
              content: (
                <span>
                  Can not delete document(s) as one or more selected document
                  has files <strong>associated</strong> with it.
                </span>
              ),
            },
          ],
          folderId
        )
      );
    } else {
      dispatch(setDeleteDocs(selectedItems));
      history.push(`/folders/${folderId}/delete`);
    }
  };

  // show modal to edit document
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);

  // update the value of attribute "required"
  // "required" attirbute indicates if the document belongs to checklist
  const onUpdateRequiredField = () => {
    dispatch(
      updateDoc(selectedItems[0].doc_id, {
        required: checked,
      })
    );
  };

  const onDownloadasZip = () => {
    setDocumentDownloadStatus(constants.LOADING_LOAD);
    dispatch(setNotifications(DOWNLOAD_START_NOTIFICATION, folderId));
    downloadDocumentAsZip(selectedItems.map(document => document.doc_id), `${projectName}_CabinetDocumentsFolder-${(new Date()).toISOString()}`).then((response) => {
      if (response.status === constants.STATUS_SUCCESS) {
        window.location.href = response.s3_url;
        setDocumentDownloadStatus(constants.LOADING_SUCCESS);
      } else {
        setDocumentDownloadStatus(constants.LOADING_FAIL);
        dispatch(setNotifications(DOWNLOAD_FAILED_NOTIFICATION, folderId));
      }
    });
  };

  return (
    <>
      <Table
        {...collectionProps}
        columnDefinitions={COLUMN_DEFINITIONS(
          is_file_upload_allowed,
          is_country_or_industry_group_doc,
          loadingStatus,
          projectName,
          modelNumber,
          setShowModal,
          fileData,
          setFileData
        )}
        visibleColumns={preferences.visibleContent}
        items={items}
        selectionType="multi"
        loadingText="Loading resources"
        loading={loading}
        resizableColumns={true}
        wrapLines={preferences.wrapLines}
        header={
          <Header
            counter={
              selectedItems.length
                ? `(${selectedItems.length}/${data.length})`
                : `(${data.length})`
            }
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button iconName="refresh" onClick={getData} />
                {!isCountryCertificate && !isIndustryCertificate && (
                  <Button
                    loading={documentDownloadStatus === constants.LOADING_LOAD}
                    disabled={
                      selectedItems.length === 0
                    }
                    iconName="download"
                    onClick={onDownloadasZip}
                  >
                    Download
                  </Button>
                )}
                {!isCountryCertificate && !isIndustryCertificate && (
                  <Button
                    onClick={onDeleteButtonClick}
                    disabled={!selectedItems.length}
                  >
                    Delete
                  </Button>
                )}

                <Button
                  onClick={onShareButtonClick}
                  disabled={!selectedItems.length}
                >
                  {DOCUMENT_ACTIONS.SHARE.TEXT}
                </Button>

                {!isCountryCertificate && !isIndustryCertificate && (
                  <Button
                    onClick={() => {
                      setShowEditModal(true);
                      setChecked(selectedItems[0]["required"]);
                    }}
                    disabled={selectedItems.length !== 1}
                  >
                    Edit
                  </Button>
                )}
                <Button
                  variant={"primary"}
                  onClick={() => {
                    isCountryCertificate || isIndustryCertificate
                      ? dispatch(setNotifications([upcomingFeature], folderId))
                      : history.push(`/documents/${folderId}/add`);
                  }}
                >
                  {isCountryCertificate
                    ? "Add Country"
                    : isIndustryCertificate
                    ? "Add Industry"
                    : "Add Documents"}
                </Button>
              </SpaceBetween>
            }
          >
            {isCountryCertificate
              ? "Countries"
              : isIndustryCertificate
              ? "Industries"
              : "Objects"}
          </Header>
        }
        filter={
          <PropertyFilter
            i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
            {...propertyFilterProps}
            countText={getFilterCounterText(filteredItemsCount)}
          />
        }
        pagination={
          <Pagination {...paginationProps} ariaLabels={paginationLabels} />
        }
        preferences={
          <Preferences
            preferences={preferences}
            setPreferences={setPreferences}
            disabled={false}
          />
        }
        onSelectionChange={({ detail }) => {
          setSelectedItems(detail.selectedItems);
        }}
        selectedItems={selectedItems}
      />
      <Modal
        header={selectedItems.length !== 0 ? selectedItems[0]["doc_name"] : ""}
        visible={showEditModal}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link" onClick={() => setShowEditModal(false)}>
                Cancel
              </Button>
              <Button
                loading={updateDocLoadingStatus === constants.LOADING_LOAD}
                variant="primary"
                onClick={() => onUpdateRequiredField()}
              >
                Update
              </Button>
            </SpaceBetween>
          </Box>
        }
        onDismiss={() => setShowEditModal(false)}
      >
        <FormField constraintText="Add/Remove Item from Checklist">
          <Checkbox
            onChange={({ detail }) => setChecked(detail.checked)}
            checked={checked}
          >
            Required
          </Checkbox>
        </FormField>
      </Modal>

      <Modal
        visible={showModal}
        onDismiss={() => setShowModal(false)}
        header="Rename Doc"
      >
        <Rename
          doc_id={fileData.fileId}
          initial_value={fileData.fileName}
          setIsRename={(val) => {
            setShowModal(val);
          }}
          setFileName={(val) => {
            setFileData({ ...fileData, fileName: val });
          }}
          type={RENAME_TYPE.DOC}
          loadingStatus={loadingStatus}
        />
      </Modal>
    </>
  );
};

export default FolderOverviewTable;
