import { expiringDocType } from "./../types/expiring-doc-type";
import constants, { HttpCodes, NOT_AUTHORIZED_NOTIFICATION } from "../../config/constants";
import { documentOverviewData } from "../../model/doc-overview";
import { formatSearchResults } from "../../utils/general-utils";
import { notification } from "../../model/doc-overview";

interface ExpiringDocsObj {
  loadingStatus: number;
  message: string;
  expiringDocs: Array<documentOverviewData>;
  notifications: notification[];
}

const initialState: ExpiringDocsObj = {
  loadingStatus: constants.LOADING_DEFAULT,
  message: "",
  expiringDocs: [],
  notifications: [],
};

const expiringDocReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case expiringDocType.GET_EXPIRING_DOC:
      return {
        ...state,
        loadingStatus: constants.LOADING_LOAD,
        message: "",
        expiringDocs: [],
      };
    case expiringDocType.GET_EXPIRING_DOC_COMMIT:
      return {
        ...state,
        loadingStatus: constants.LOADING_SUCCESS,
        message: action.payload.message,
        expiringDocs: formatSearchResults(action.payload.data.hits.hits),
      };
    case expiringDocType.GET_EXPIRING_DOC_ROLLBACK:
      const notifications: notification[] = [
        ...(state.notifications
          ? state.notifications
          : []),
      ];
      if(action.statusCode === HttpCodes.FORBIDDEN)
        notifications.push(NOT_AUTHORIZED_NOTIFICATION)

      return {
        ...state,
        loadingStatus: constants.LOADING_FAIL,
        message: action.payload.message,
        expiringDocs: [],
        notifications,
      };
    default:
      return state;
  }
};

export default expiringDocReducer;
