import {
  createProjectType,
  getProjectConfigType,
} from "../types/create-project-type";
import constants, { HttpCodes, NOT_AUTHORIZED_NOTIFICATION } from "../../config/constants";
import { notification } from "../../model/doc-overview";

interface createProjectState {
  loadingStatus: number;
  message: string;
  responseData: any;
  notifications: notification[];
}

const createProjectInitialState: createProjectState = {
  loadingStatus: constants.LOADING_DEFAULT,
  message: "",
  responseData: {},
  notifications: [],
};

export const createProjectReducer = (
  state = createProjectInitialState,
  action: any
) => {
  switch (action.type) {
    case createProjectType.CREATE_PROJECT:
      return {
        ...state,
        loadingStatus: constants.LOADING_LOAD,
      };
    case createProjectType.CREATE_PROJECT_COMMIT:
      return {
        ...state,
        loadingStatus: constants.LOADING_SUCCESS,
        message: action.payload.message,
        responseData: action.payload.data,
      };
    case createProjectType.CREATE_PROJECT_ROLLBACK:
      const notifications: notification[] = [
        ...(state.notifications
          ? state.notifications
          : [])
      ];

      if(action.statusCode === HttpCodes.FORBIDDEN)
        notifications.push(NOT_AUTHORIZED_NOTIFICATION)

      return {
        ...state,
        loadingStatus: constants.LOADING_FAIL,
        message: action.payload?.message,
        responseData: action.payload?.data,
        notifications
      };
    case createProjectType.RESET_PROJECT:
      return {
        ...state,
        loadingStatus: constants.LOADING_DEFAULT,
        message: "",
        responseData: {},
      };
    default:
      return state;
  }
};

interface projectConfigState {
  loadingStatus: number;
  message: string;
  responseData: any;
}

const projectConfigInitialState: projectConfigState = {
  loadingStatus: constants.LOADING_DEFAULT,
  message: "",
  responseData: {},
};

export const getProjectConfigReducer = (
  state = projectConfigInitialState,
  action: any
) => {
  switch (action.type) {
    case getProjectConfigType.GET_PROJECT_CONFIG:
      return {
        ...state,
        loadingStatus: constants.LOADING_LOAD,
      };
    case getProjectConfigType.GET_PROJECT_CONFIG_COMMIT:
      return {
        ...state,
        loadingStatus: constants.LOADING_SUCCESS,
        message: action.payload.message,
        responseData: {
          ...action.payload.data,
        },
      };
    case getProjectConfigType.GET_PROJECT_CONFIG_ROLLBACK:
      return {
        ...state,
        loadingStatus: constants.LOADING_FAIL,
        message: action.payload.message,
        responseData: action.payload.data,
      };
    default:
      return state;
  }
};
