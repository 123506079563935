import { TIME_UNIT } from "../../config/constants";

export const getFutureDate = (amount: number, unit: string): string => {
  switch (unit) {
    case TIME_UNIT.SECOND: {
      const date = new Date(
        new Date().setDate(new Date().getDate() + Math.ceil(amount / 86400))
      ).toISOString();
      return date.split("T")[0];
    }
    case TIME_UNIT.MINUTE: {
      const date = new Date(
        new Date().setDate(new Date().getDate() + Math.ceil(amount / 1440))
      ).toISOString();
      return date.split("T")[0];
    }
    case TIME_UNIT.HOUR: {
      const date = new Date(
        new Date().setDate(new Date().getDate() + Math.ceil(amount / 24))
      ).toISOString();
      return date.split("T")[0];
    }
    case TIME_UNIT.DAY: {
      const date = new Date(
        new Date().setDate(new Date().getDate() + amount)
      ).toISOString();
      return date.split("T")[0];
    }
    case TIME_UNIT.WEEK: {
      const date = new Date(
        new Date().setDate(new Date().getDate() + 7 * amount)
      ).toISOString();
      return date.split("T")[0];
    }
    case TIME_UNIT.MONTH: {
      const date = new Date(
        new Date().setDate(new Date().getDate() + 30 * amount)
      ).toISOString();
      return date.split("T")[0];
    }
    case TIME_UNIT.YEAR: {
      const date = new Date(
        new Date().setDate(new Date().getDate() + 365 * amount)
      ).toISOString();
      return date.split("T")[0];
    }
    default: {
      const date = new Date(
        new Date().setDate(new Date().getDate() + 7)
      ).toISOString();
      return date.split("T")[0];
    }
  }
};

export const getTodayDate = (): string => {
  const date = new Date().toISOString();
  return date.split("T")[0];
};
