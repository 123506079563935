import {
  CollectionPreferences,
  PropertyFilterProps,
} from "@amzn/awsui-components-react";
import { ResourceLabel } from "../../../components/common/labels";
import { changeToShortDateFormatAndAddColor } from "../../../utils/date-utils";
import {
  formatBytes,
  // showClassAsDescription,
} from "../../../utils/general-utils";
import { NavLink } from "react-router-dom";
import { ExpiryDate } from "../../common-container/expiry-date";
import { EXPIRY_DATE_COLOR_CODE } from "../../../config/constants";

// define columns
export const COLUMN_DEFINITIONS = [
  {
    id: "file_id",
    sortingField: "file_id",
    header: "File ID",
    cell: (item) => (
      <NavLink to={`/files/${item.file_id}/overview`}>{item.file_id}</NavLink>
    ),
    visible: true,
    maxWidth: 150,
    width: 150,
  },
  {
    id: "file_name",
    sortingField: "file_name",
    header: "Name",
    cell: (item) => <ResourceLabel resource={item.file_name} />,
    visible: true,
    minWidth: 100,
  },
  {
    id: "description",
    sortingField: "description",
    header: "Description",
    cell: (item) => item.description,
    visible: true,
    minWidth: 100,
  },
  {
    id: "expiry_date",
    sortingField: "expiry_date",
    header: "Expiry Status",
    cell: (item) => (
      <ExpiryDate
        informatiomText={
          item.expiry_date
            ? changeToShortDateFormatAndAddColor(item.expiry_date).Date
            : "No expiry date available"
        }
        color={
          item.expiry_date
            ? changeToShortDateFormatAndAddColor(item.expiry_date).color
            : EXPIRY_DATE_COLOR_CODE.GREEN_COLOR_HEX
        }
      />
    ),
    visible: true,
    minWidth: 220,
  },
  {
    id: "associated_by",
    sortingField: "associated_by",
    header: "Associated By",
    cell: (item) => item.associated_by,
    visible: false,
    minWidth: 100,
  },
  {
    id: "associated_time",
    sortingField: "associated_time",
    header: "Associated Time",
    cell: (item) => item.associated_time,
    visible: false,
    minWidth: 100,
  },
  {
    id: "size",
    sortingField: "size",
    header: "Size",
    cell: (item) => (item.size ? formatBytes(item.size) : "__"),
    visible: true,
    minWidth: 100,
  },
];

// define visible columns
export const VISIBLE_CONTENT_OPTIONS = [
  {
    label: "Properties",
    options: [
      { id: "file_id", label: "File Id", visible: true, editable: true },
      { id: "file_name", label: "Name", visible: true, editable: true },
      {
        id: "description",
        label: "Description",
        visible: true,
        editable: true,
      },
      {
        id: "size",
        label: "Size",
        visible: false,
        editable: true,
      },
      {
        id: "expiry_date",
        label: "Expiry Status",
        visible: true,
        editable: true,
      },
      {
        id: "associated_by",
        label: "Associated By",
        visible: false,
        editable: true,
      },
      {
        id: "associated_time",
        label: "Associated Time",
        visible: false,
        editable: true,
      },
    ],
  },
];

// define filtering properties
export const FILTERING_PROPERTIES: PropertyFilterProps.FilteringProperty[] = [
  {
    propertyLabel: "File Id",
    key: "file_id",
    groupValuesLabel: "File Id",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Name",
    key: "file_name",
    groupValuesLabel: "Name",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Description",
    key: "description",
    groupValuesLabel: "Description",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Expiry Status",
    key: "expiry_date",
    groupValuesLabel: "Expiry Status",
    operators: [":", "!:", "=", "!="],
  },
];

// define page size options
export const PAGE_SIZE_OPTIONS = [
  { value: 50, label: "50 items" },
  { value: 100, label: "100 items" },
  { value: 300, label: "300 items" },
];

// define default preferences
export const DEFAULT_PREFERENCES = {
  pageSize: 300,
  visibleContent: COLUMN_DEFINITIONS.filter((column) => column.visible).map(
    (column) => column.id
  ),
  wrapLines: false,
};

export const Preferences = ({
  preferences,
  setPreferences,
  disabled,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
  visibleContentOptions = VISIBLE_CONTENT_OPTIONS,
}) => (
  <CollectionPreferences
    title="Preferences"
    confirmLabel="Confirm"
    cancelLabel="Cancel"
    disabled={disabled}
    preferences={preferences}
    onConfirm={({ detail }) => setPreferences(detail)}
    pageSizePreference={{
      title: "Page size",
      options: pageSizeOptions,
    }}
    wrapLinesPreference={{
      label: "Wrap lines",
      description: "Check to see all the text and wrap the lines",
    }}
    visibleContentPreference={{
      title: "Select visible content",
      options: visibleContentOptions,
    }}
  />
);
